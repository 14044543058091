import isNil from 'lodash/isNil';
import { Button, Divider, Icon, Label, Menu, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getCustomerCasesRoute } from 'components/customer/CustomerRouter';
import { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import Authority from 'common/auth/Authority';
import React from 'react';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

/**
 * A Popup window for a Customer
 * @param customer
 * @param onDelete
 * @param onView
 * @returns {*}
 * @constructor
 */
const CustomerPopup = ({customer, onDelete, onView}) => {
    const caseSummary = customer.statusCounts;
    const getValue = (state) => {
        return !isNil(state) ? state : 0;
    };
    const notStarted = getValue(caseSummary.NotStarted);
    const activeCases = getValue(caseSummary.Active);
    const onHold = getValue(caseSummary.OnHold);
    const closed = getValue(caseSummary.Closed);
    const openCases = notStarted + activeCases + onHold;

    return (
        <Popup
            trigger={<Button basic icon={'ellipsis horizontal'} />}
            flowing hoverable
            position="left center"
        >
            <Menu vertical secondary>
                {activeCases === 0 &&
                    <Menu.Item><b>No Active Cases</b></Menu.Item>
                }
                {activeCases > 0 &&
                    <>
                        <Menu.Item link>
                            <Icon name="external" />
                            <b>
                                <Link to={getCustomerCasesRoute(getProgrammeIdFromCurrentPath(), customer.customerId)}>
                                    {activeCases} Active Case{activeCases > 1 && 's'}
                                </Link>
                            </b>
                        </Menu.Item>
                        <Divider fitted />
                        <Menu.Item name="open">
                            <Label color="teal">{openCases}</Label>
                            Open
                        </Menu.Item>
                        <Menu.Item name="closed">
                            <Label color="teal">{closed}</Label>
                            Closed
                        </Menu.Item>
                        {!isNil(caseSummary.Aborted) &&
                            <Menu.Item name="closed">
                                <Label color="red">{caseSummary.Aborted}</Label>
                                Aborted
                            </Menu.Item>
                        }
                    </>
                }
                <>
                    <Divider fitted />
                    <Menu.Item name="view" onClick={onView}>
                        <Icon name="external" />
                        Customer Details
                    </Menu.Item>
                </>
                <ShowWithOneOfPermission permissions={[Authority.DeleteCustomer]}>
                    <>
                        <Divider fitted />
                        <Menu.Item name="delete" onClick={onDelete} disabled={activeCases > 0}>
                            <Icon name="trash" />
                            Delete {activeCases > 0 && '(active cases)'}
                        </Menu.Item>
                    </>
                </ShowWithOneOfPermission>

            </Menu>
        </Popup>
    );
};

export default CustomerPopup;