import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Label } from 'semantic-ui-react';

export const SavedStatus = {
    Init: 'Init',
    Saved: 'Saved',
    Unsaved: 'Unsaved',
}

export const SavedStateLabel = (props) => {
    const getStatusLabel = () => {
        if (props.status === SavedStatus.Init) {
            return null;
        }
        const color = props.status === SavedStatus.Unsaved ? 'red' : 'green';
        return <Label basic color={color}>{props.status}</Label>
    }

    return (
        <>
            <Prompt
                when={props.status === SavedStatus.Unsaved}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            {getStatusLabel()}
        </>
    )
}

SavedStatus.propTypes = {
    status: PropTypes.oneOf(Object.values(SavedStatus)).isRequired,
}

SavedStatus.defaultProps = {
    status: SavedStatus.Init,
}

export default SavedStateLabel;