import { Icon, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import './TrafficLightCell.scss';

const CellLevel = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
}

const TrafficLightCell = ({level, content, textAlign}) => {
    let icon, className;
    if (level === CellLevel.Low) {
        className = "warning-low"
    } else if (level === CellLevel.Medium) {
        className = "warning-medium";
        icon = 'attention';
    } else if (level === CellLevel.High) {
        className = "warning-high";
        icon = 'attention';
    }

    return (
        <Table.Cell className={`traffic-light-cell ${ className }`} textAlign={textAlign}>
            {icon && <Icon name={icon} size="large" className="warning-icon" />}{content}
        </Table.Cell>
    );
}

TrafficLightCell.propTypes = {
    content: PropTypes.string.isRequired,
    textAlign: PropTypes.string,
}

TrafficLightCell.defaultProps = {
    level: undefined,
    textAlign: undefined,
}

export {
    TrafficLightCell as default,
    CellLevel,
};