import React from 'react';
import withProgramme from 'components/programme/ProgrammeProps';
import { ProgrammeOfWork } from 'components/programme/ProgrammeTypes';

type Props = {
    programme: ProgrammeOfWork | null
};

const ProgrammeBreadcrumbLink = (props: Props): JSX.Element | null => {
    if (props.programme == null) {
        return null;
    }
    return (
        <>{props.programme.name}</>
    );
};

export default withProgramme(ProgrammeBreadcrumbLink);
