import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import 'components/admin/GroupPermissionTable.scss';
import { cloneDeep } from 'lodash';

const GroupPermissionCell = ({group, permission, updateGroup, checked, editing}) => {

    const onCheckboxToggle = (e, {checked}) => {
        const newGroup = cloneDeep(group);
        if (checked) {
            if (newGroup.permissions.includes(permission)) {
                return
            }
            newGroup.permissions.push(permission);
        } else {
            newGroup.permissions = newGroup.permissions.filter(oldPermission => oldPermission !== permission);
        }
        updateGroup(newGroup);
    }

    return (
        <Table.Cell
            textAlign="center"
        >
            {editing && (
                <Checkbox
                    checked={checked}
                    onClick={onCheckboxToggle}
                />
            )}

            {!editing && checked && (<Icon name="check" color="green" />)}
        </Table.Cell>
    )
}

GroupPermissionCell.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.string,
        permissions: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
    }).isRequired,
    permission: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    updateGroup: PropTypes.func,
}

GroupPermissionCell.defaultProps = {
    updateGroup: () => {},
    group: {},
}

const GroupPermissionTable = ({groups, permissions, editing, onUpdateGroup, onEditGroup}) => {
    return (
        <Segment className="group-permission-table-segment" attached='bottom'>
            <Table
                className="group-permission-table"
                striped
                unstackable
                celled
            >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="mask sticky-x sticky-y border-r" />
                        <Table.HeaderCell
                            colSpan={groups.length}
                            textAlign="center"
                            className="permission-table-top-header "
                        >
                            Groups
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="mask sticky-y sticky-x border-b border-r" />
                        {groups.map(group => (
                            <Table.HeaderCell key={group.id} className="group-header-label-row sticky-y nowrap" >
                                {!editing && <>{group.name}</> }
                                {editing && <Popup
                                    content='Click to edit'
                                    inverted
                                    mouseEnterDelay={400}
                                    position="top right"
                                    trigger={
                                        <span>
                                            {group.name}
                                            <Icon
                                                name="edit"
                                                className="edit-icon"
                                                onClick={() => onEditGroup(group.id)}
                                            />
                                        </span>
                                    }
                                />}
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {permissions.map(permission => (
                        <Table.Row key={permission.id}>
                            <Table.Cell
                                className="floating-table-header sticky-x nowrap"
                            >
                                {permission.displayName}
                            </Table.Cell>
                            {groups.map((group, groupIndex) => (
                                <GroupPermissionCell
                                    key={group.id}
                                    permission={permission.id}
                                    group={group}
                                    checked={group.permissions.includes(permission.id)}
                                    editing={editing}
                                    updateGroup={(newGroup) => onUpdateGroup(groupIndex, newGroup)}
                                />),
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    )
}

GroupPermissionTable.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        permissions: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
    })).isRequired,
    permissions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            displayName: PropTypes.string,
            active: PropTypes.bool,
        }),
    ).isRequired,
    editing: PropTypes.bool,
    onUpdateGroup: PropTypes.func,
    onEditGroup: PropTypes.func,
};

GroupPermissionTable.defaultProps = {
    groups: [],
    permissions: [],
    editing: false,
    updateGroup: () => {},
    editGroup: () => {},
};

export default GroupPermissionTable;
