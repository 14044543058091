import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';

const OnHoldLabel = (props) => {
    if (!props.onHold) {
        return null;
    }

    return (
        <Label basic color="orange">
            <Icon name="pause" />
            On Hold
            {props.onHoldReason && <Label.Detail>({props.onHoldReason})</Label.Detail>}
        </Label>
    );
}

OnHoldLabel.propTypes = {
    onHold: PropTypes.bool.isRequired,
    onHoldReason: PropTypes.string,
}

OnHoldLabel.defaultProps = {
    onHold: false,
}

export default OnHoldLabel;