import React from 'react';
import { Route } from "react-router-dom";
import ImportExportScreen from 'components/admin/dataLoad/ImportExportScreen';
import DatabaseManagementScreen from 'components/admin/dataLoad/DatabaseManagementScreen';

const dataLoadRoute = "/admin";
const DataLoadRouter = ({match}) => (
    <>
        <Route path={`${dataLoadRoute}/etl`} >
            <ImportExportScreen />
        </Route>
        <Route path={`${dataLoadRoute}/database`} exact>
            <DatabaseManagementScreen />
        </Route>
    </>
);

export default DataLoadRouter;
