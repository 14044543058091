import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Header, Icon, Segment } from 'semantic-ui-react';
import './StyledAccordion.scss';

const StyledAccordion = ({children, title}) => {
    const [active, setActive] = useState(false);

    return (
        <Accordion fluid>
            <Accordion.Title
                active={active}
                onClick={() => setActive(!active)}
                secondary
                as={Segment}
                attached='top'
            >
                <Header as='h4'>
                    <Icon name="dropdown" />
                    {title}
                </Header>
            </Accordion.Title>
            <Accordion.Content
                active={active}
                as={Segment}
                attached='bottom'
                className='styled-accordion-content'
            >
                {children}
            </Accordion.Content>
        </Accordion>
    )
}

StyledAccordion.propTypes = {
    title: PropTypes.string.isRequired,
};

StyledAccordion.defaultProps = {};

export default StyledAccordion;
