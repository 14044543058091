import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { executeDefaultValidators } from 'external/form/Validators';

class FieldText extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
    }

    componentDidMount() {
        this.props.registerValidator(this.props.name, executeDefaultValidators);
        if (this.props.autoFocus === true) {
            this.inputField.focus();
        }
    }

    componentWillUnmount() {
        this.props.deregisterValidator(this.props.name);
    }

    valueChanged(event) {
        const target = event.target;
        const newValue = target.type === 'checkbox' ? target.checked : target.value;
        this.props.onChange(this.props.name, newValue);
    }

    render() {
        return (
            <input
                aria-describedby={this.props.name}
                className="form-control"
                name={this.props.name}
                type={this.props.inputType}
                ref={(inputField) => {this.inputField = inputField;}}
                onChange={this.valueChanged}
                value={this.props.value}
                disabled={this.props.disabled}
                placeholder={this.props.placeHolder}
                spellCheck
            />
        );
    }
}

FieldText.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    autoFocus: PropTypes.bool,
    inputType: PropTypes.string,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldText.defaultProps = {
    value: '',
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    autoFocus: false,
    inputType: 'text',
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldText;
