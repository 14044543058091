import React from 'react';
import { Route, Switch } from "react-router-dom";
import CustomerListPage from 'components/customer/CustomerListPage';
import RequireAuthentication from 'common/auth/RequireAuthentication';
import CustomerPage from 'components/customer/CustomerPage';
import { subRoute } from 'common/util/RouteHelpers';
import CreateCustomerPage from 'components/customer/CreateCustomerPage';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

/**
 * Function component encapsulating all routes for customer views
 * @returns A collection of available routes for the authenticated user
 */
// TODO: Add Roles to allow certain users to add/edit/view customers
const CustomerRouter = ({match}) => (
    <RequireAuthentication>
        <Switch>
            <Route
                path={match.url}
                exact
                render={({match}) => (
                    <CustomerListPage
                        match={match}
                        programmeId={match.params.programmeId}
                    />
                )}
            />
            <Route
                path={subRoute(match, '/new')}
                exact
                render={({match}) => (
                    <CreateCustomerPage />
                )}
            />
            <Route
                path={subRoute(match, '/:customerId/:activeItem?')}
                render={({match}) => (
                    <CustomerPage
                        customerId={match.params.customerId}
                        activeItem={match.params.activeItem}
                    />
                )}
            />
        </Switch>
    </RequireAuthentication>
);

/**
 * Get a customer list route
 */
function getCustomerListRoute(programmeId) {
    return `${getProgrammeRoute(programmeId)}/customers`;
}

/**
 * Get a customer route
 */
function getCustomerRoute(programmeId, customerId) {
    return `${getProgrammeRoute(programmeId)}/customers/${customerId}`;
}

/**
 * Get a route to display a list of customer cases
 */
function getCustomerCasesRoute(programmeId, customerId, status) {
    let route = `${getProgrammeRoute(programmeId)}/customers/${customerId}/cases`;
    if (status) {
        route = `${route}?state=${status}`;
    }
    return route;
}

export {
    CustomerRouter as default,
    getCustomerListRoute,
    getCustomerRoute,
    getCustomerCasesRoute,
};
