import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

const ButtonWithDropdown = ({
    size,
    primary,
    compact,
    color,
    options,
    text,
    onClick
}) => {

    return (<Button.Group
            primary={primary}
            compact={compact}
            size={size}
            color={color}
        >
            <Button
                onClick={onClick}
                content={text}
            />
            <Dropdown
                className="button icon"
            >
                <Dropdown.Menu>
                    {options.map((option, index) => (<Dropdown.Item key={index} text={option.text} onClick={option.onClick} />))}
                </Dropdown.Menu>
            </Dropdown>
        </Button.Group>
    )
}

export default ButtonWithDropdown;