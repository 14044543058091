import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import Api from 'api/Api';
import autoBind from 'common/util/AutoBind';
import LoaderIcon from 'common/LoaderIcon';
import CustomerNotes from 'components/customer/CustomerNotes';
import InterestedParties from 'components/customer/InterestedParties';
import { withUserContextProp } from 'UserContext';
import { ShowWithCustomerDeletePermission, ShowWithCustomerEditPermission } from 'components/customer/CustomerHelpers';
import CustomerForm from 'components/customer/CustomerForm';
import CustomerDetailsTable from 'components/customer/CustomerDetailsTable';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';

const getDefaultSchema = () => {
    return {
        customerEditSchema: [
            {
                customerKey: "title",
                label: "Title",
                placeHolder: "Title",
                type: "text",
                required: true,
                custom: false,
            },
            {
                customerKey: "firstName",
                label: "First Name",
                placeHolder: "First Name",
                type: "text",
                required: true,
                custom: false,
            },
            {
                customerKey: "lastName",
                label: "Last Name",
                placeHolder: "Last Name",
                type: "text",
                required: true,
                custom: false,
            },
            {
                customerKey: "email",
                label: "Email",
                placeHolder: "",
                type: "text",
                required: true,
                custom: false,
            },
            {
                customerKey: "dateOfBirth",
                label: "Date Of Birth",
                placeHolder: "",
                type: "date",
                required: true,
                custom: false,
            },
            {
                customerKey: "homePhoneNumber",
                label: "Home Phone Number",
                placeHolder: "",
                type: "text",
                required: false,
                custom: false,
            },
            {
                customerKey: "mobilePhoneNumber",
                label: "Mobile Phone Number",
                placeHolder: "",
                type: "text",
                required: false,
                custom: false,
            },
            {
                customerKey: "address",
                label: "Postal Address",
                placeHolder: "",
                type: "address",
                required: true,
                custom: false,
            },
            {
                customerKey: "referenceNumber",
                label: "Customer Number",
                placeHolder: "",
                type: "text",
                required: true,
                custom: false,
            },
            {
                customerKey: "vulnerable",
                label: "Vulnerable Customer",
                placeHolder: "",
                type: "toggle",
                required: true,
                custom: false,
            },
            {
                customerKey: "hearingImpaired",
                label: "Hearing Impaired",
                placeHolder: "",
                type: "toggle",
                required: true,
                custom: false,
            },
            {
                customerKey: "visionImpaired",
                label: "Vision Impaired",
                placeHolder: "",
                type: "toggle",
                required: true,
                custom: false,
            },
            {
                customerKey: "deceased",
                label: "Deceased Customer",
                placeHolder: "",
                type: "toggle",
                required: true,
                custom: false,
            },
        ],
        customerDisplaySchema: [
            [
                {
                    customerKey: "name",
                    label: "Name",
                    type: "string",
                    custom: false,
                },
                {
                    customerKey: "email",
                    label: "Email",
                    type: "string",
                    custom: false,
                },
                {
                    customerKey: "dateOfBirth",
                    label: "Date of Birth",
                    type: "date",
                    "custom": false,
                },
                {
                    customerKey: "homePhoneNumber",
                    label: "Home Phone Number",
                    type: "string",
                    custom: false,
                },
                {
                    customerKey: "mobilePhoneNumber",
                    label: "Mobile Phone Number",
                    type: "string",
                    custom: false,
                },
                {
                    customerKey: "address",
                    label: "Address",
                    type: "address",
                    custom: false,
                },
                {
                    customerKey: "referenceNumber",
                    label: "Customer Number",
                    type: "string",
                    custom: false,
                },
            ],
            [
                {
                    customerKey: "vulnerable",
                    label: "Vulnerable Customer",
                    type: "toggle",
                    custom: false,
                },
                {
                    customerKey: "hearingImpaired",
                    label: "Hearing Impaired",
                    type: "toggle",
                    custom: false,
                },
                {
                    customerKey: "visionImpaired",
                    label: "Vision Impaired",
                    type: "toggle",
                    custom: false,
                },
                {
                    customerKey: "deceased",
                    label: "Deceased Customer",
                    type: "toggle",
                    custom: false,
                },
            ],
        ],
    }
}

class CustomerDetails extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            customerDetails: {
                customerId: undefined,
                name: undefined,
                email: undefined,
                dateOfBirth: undefined,
                homePhoneNumber: undefined,
                mobilePhoneNumber: undefined,
                address: {},
                referenceNumber: undefined,
                remediationStatus: undefined,
                vulnerable: undefined,
                hearingImpaired: undefined,
                visionImpaired: undefined,
                deceased: undefined,
                tags: [],
                notes: [],
                interestedParties: [],
                customAttributes: {},
            },
            cases: [],
            schema: getDefaultSchema(),
            loading: false,
            editing: false,
        }
    }

    componentDidMount() {
        this.getCustomerAndCases();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customerId !== this.props.customerId) {
            this.getCustomerAndCases();
        }
    }

    getCustomerAndCases() {
        this.setState({loading: true});
        Promise.all([
                this.getCustomerDetails(),
                this.getCustomerCases(),
                this.getCustomerSchema(),
            ])
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}))
    }

    getCustomerDetails() {
        return Api.get(`/api/customers/${this.props.customerId}`)
            .then(this.setCustomerDetails)
    }

    getCustomerCases() {
        return Api.get(`/api/customers/${this.props.customerId}/cases`)
            .then(this.setCustomerCases);
    }

    getCustomerSchema() {
        return Api.get('/api/customers/schema')
            .then(this.setCustomerSchema);
    }

    setCustomerCases(data) {
        this.setState({cases: data});
    }

    setCustomerDetails(data) {
        this.setState({customerDetails: data});
    }

    setCustomerSchema(data) {
        this.setState({schema: data});
    }

    onSaveNote(text) {
        const note = {
            customerId: this.props.customerId,
            text: text,
        }
        Api.post(`/api/customers/${this.props.customerId}/note`, note)
            .then(this.getCustomerDetails)
            .catch(Api.logError);
    }

    getCurrentCustomerState() {
        return {
            customerId: this.props.customerId,
            vulnerable: this.state.customerDetails.vulnerable,
            hearingImpaired: this.state.customerDetails.hearingImpaired,
            visionImpaired: this.state.customerDetails.visionImpaired,
            deceased: this.state.customerDetails.deceased,
        }
    }

    onAddInterestedParty(party) {
        const data = {
            customerId: this.props.customerId,
            name: party.name,
            relationship: party.relationship,
        }
        Api.post(`/api/customers/${this.props.customerId}/party`, data)
            .then(this.getCustomerDetails)
            .catch(Api.logError);
    }

    updateCustomerDetails(key, newValue) {
        const data = {...this.getCurrentCustomerState(), [key]: newValue}

        Api.put(`/api/customers/${this.props.customerId}`, data)
            .then(this.setCustomerDetails)
            .catch(Api.logError);
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Customers', content: 'Customers'},
            {key: 'Customer', content: `${this.state.customerDetails.name}`},
        ];
    }

    setEditingCustomer() {
        this.setState({editing: true});
    }

    onCustomerSaved() {
        this.setState({editing: false});
        this.getCustomerAndCases();
        if (this.props.onUpdate) {
            this.props.onUpdate();
        }
    }

    customerCanBeEdited() {
        return !this.state.customerDetails.deleted;
    }

    customerCanBeDeleted() {
        return this.props.onDelete && !this.state.customerDetails.deleted;
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <LoaderIcon icon="user" loading={this.state.loading} />
                </div>
            )
        }

        const activeCases = this.state.customerDetails.activeCases;
        return (
            <>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h4" attached={'top'}>
                                <Header.Content style={{width: '100%'}}>
                                    <Grid columns="equal">
                                        <Grid.Row columns="equal">
                                            <Grid.Column textAlign="left" verticalAlign="middle">
                                                {this.state.customerDetails.name} {this.state.customerDetails?.deleted && <>(Deleted)</>}
                                            </Grid.Column>
                                            <Grid.Column textAlign="right">
                                                {!this.state.editing && <ShowWithCustomerEditPermission>
                                                    {this.customerCanBeEdited() && <Button
                                                        compact
                                                        primary
                                                        size="tiny"
                                                        onClick={this.setEditingCustomer}
                                                    >
                                                        <Icon name="edit" />
                                                        Edit Details
                                                    </Button>}
                                                </ShowWithCustomerEditPermission>}
                                                {this.customerCanBeDeleted() &&
                                                    <ShowWithCustomerDeletePermission>
                                                        <Button
                                                            compact
                                                            primary
                                                            size="tiny"
                                                            onClick={this.props.onDelete}
                                                            disabled={!this.state.customerDetails.deletable}
                                                        >
                                                            <Icon name="trash" />
                                                            Delete Customer
                                                            {!this.state.customerDetails.deletable &&
                                                                ` (${activeCases} Case${activeCases > 1 ? 's' : ''})`
                                                            }
                                                        </Button>
                                                    </ShowWithCustomerDeletePermission>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Header.Content>
                            </Header>
                            {this.state.editing &&
                                <Segment attached="bottom">
                                    <ShowWithCustomerEditPermission>
                                        <CustomerForm
                                            customerId={this.props.customerId}
                                            onCustomerChanged={this.onCustomerSaved}
                                            onCancel={() => {this.setState({editing: false})}}
                                            onError={this.props.onError}
                                        />
                                    </ShowWithCustomerEditPermission>
                                </Segment>}

                            {!this.state.editing &&
                                <CustomerDetailsTable
                                    customerDetails={this.state.customerDetails}
                                    customerCanBeEdited={this.customerCanBeEdited()}
                                    updateCustomer={this.updateCustomerDetails}
                                    schema={this.state.schema.customerDisplaySchema}
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Header
                                attached="top"
                                as="h4"
                                textAlign="left"
                            >
                                <Header.Content>
                                    <Icon name="edit" />
                                    Interested Parties
                                </Header.Content>
                            </Header>
                            <InterestedParties
                                attached={'bottom'}
                                parties={this.state.customerDetails.interestedParties}
                                onSave={this.onAddInterestedParty}
                                readOnly={!this.customerCanBeEdited()}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Header
                                attached="top"
                                as="h4"
                                textAlign="left"
                            >
                                <Header.Content>
                                    <Icon name="edit" />
                                    Notes
                                </Header.Content>
                            </Header>
                            <Segment attached="bottom">
                                <CustomerNotes
                                    notes={this.state.customerDetails.notes}
                                    onSave={this.onSaveNote}
                                    readOnly={!this.customerCanBeEdited()}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        )
    }

}

CustomerDetails.propTypes = {
    customerId: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
};

CustomerDetails.defaultProps = {
    onDelete: undefined,
    onUpdate: undefined,
}

export default withUserContextProp(CustomerDetails);
