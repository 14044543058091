import { Button, Header, Icon, Modal, SemanticICONS } from 'semantic-ui-react';
import React, { SyntheticEvent } from 'react';

interface ConfirmDeleteProps {

    /** Is this modal open **/
    open: boolean;

    /** Function to call when confirmed **/
    onConfirm: (event: SyntheticEvent) => void;

    /** Function to call when cancelling **/
    onCancel: () => void;

    /** The icon to display **/
    icon?: SemanticICONS;

    /** The title of the modal **/
    title: string;

    /** The modal content **/
    content?: React.ReactNode;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = (props) => {
    return (
        <Modal open={props.open} size="small" closeOnEscape={true} onClose={props.onCancel} centered={false}>
            <Header icon={props.icon} content={props.title} />
            <Modal.Content>{props.content}</Modal.Content>
            <Modal.Actions>
                <Button color="grey" onClick={props.onCancel}>
                    <Icon name="remove" /> No
                </Button>
                <Button negative onClick={props.onConfirm}>
                    <Icon name="trash" /> Yes
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmDelete;
