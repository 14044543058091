import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Document , Page} from 'react-pdf/dist/esm/entry.webpack';
import 'common/media/pdfPreview.css';
import { Divider, Grid, Icon } from 'semantic-ui-react';

class PdfViewer extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            page: 1,
            numPages: null,
            pageNumber: 1,
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    onDocumentComplete(pages) {
        this.setState({page: 1, pages});
    }

    onPageComplete(page) {
        this.setState({page});
    }

    onDocumentLoad({numPages}) {
        this.setState({numPages});
    }

    handlePrevious() {
        this.setState({page: this.state.page - 1});
    }

    handleNext() {
        this.setState({page: this.state.page + 1});
    }

    handleKeyPress(event) {
        if ((event.key === 'ArrowLeft' || event.key === 'PageUp') && this.state.page > 1) {
            this.handlePrevious();
        } else if ((event.key === 'ArrowRight' || event.key === 'PageDown') && this.state.page < this.state.numPages) {
            this.handleNext();
        } else if (event.key === 'Home') {
            this.setState({page: 1});
        } else if (event.key === 'End') {
            this.setState({page: this.state.numPages});
        }
    }

    renderLeftArrow(page, pages) {
        if (page === 1) {
            return null;
        }
        return <Icon name="arrow left" onClick={this.handlePrevious} size="large" className="hover-select" />
    }

    renderRightArrow(page, pages) {
        if (page === pages) {
            return null;
        }
        return <Icon name="arrow right" onClick={this.handleNext} size="large" className="hover-select" />;
    }

    render() {
        return (
            <div onKeyDown={this.handleKeyPress}>
                <Grid textAlign="center" centered>
                    <Grid.Column verticalAlign="middle">
                        {this.renderLeftArrow(this.state.page, this.state.numPages)}
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" width={12}>
                        <Document
                            file={this.props.url}
                            onLoadSuccess={this.onDocumentLoad}
                            className="PDFDocument"
                        >
                            <Page pageNumber={this.state.page} className="PDFPage" />
                        </Document>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle">
                        {this.renderRightArrow(this.state.page, this.state.numPages)}
                    </Grid.Column>
                </Grid>
                <Divider hidden />
                Page {this.state.page} of {this.state.numPages}
            </div>
        );
    }
}

PdfViewer.propTypes = {
    url: PropTypes.string.isRequired,
};

export default PdfViewer;
