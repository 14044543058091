import React, { Component } from "react";
import bindClassMethods from 'common/util/AutoBind';
import Api from 'api/Api';
import CustomerTagSearch from 'common/form/fields/FieldCustomerTagSearch';
import PropTypes from 'prop-types';
import { Button, Form, Header, Icon, Message, Modal } from 'semantic-ui-react';
import withProgramme from 'components/programme/ProgrammeProps';

/**
 * This class implements a CaseForm component to be used for
 * adding and editing cases.
 */
class CaseForm extends Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            loading: false,
            posting: false,
            error: undefined,
            customerIds: this.props.customerIds,
            customerOptions: [],
            selectedCustomers: [],
            createdCaseLoading: false,
            tagDisabled: false,
        };
    }

    onMount() {
        this.setState({
            customerIds: this.props.customerIds || []
        });
    }

    close() {
        this.setState({
            posting: false,
            customerIds: [],
            customerOptions: [],
            selectedCustomers: [],
            error: undefined,
        });
        this.props.setOpen(false);
    }

    onError(error) {
        Api.logError(error);
        this.setState({error: error.message});
    }

    handleCreateCase() {
        const createCaseRequest = {
            customerIds: this.state.customerIds,
            programmeId: this.props.programme.id,
        };

        this.setState({
            createdCaseLoading: true,
        })

        Api.post(`/api/cases`, createCaseRequest)
            .then((response) => {
                this.close();
                this.props.onSubmit(response);
            })
            .then(() => {
                this.setState({
                    posting: true,
                    customerIds: [],
                    customerOptions: [],
                    selectedCustomers: [],
                    createdCaseLoading: false,
                });
            })
            .catch(this.onError);
    };

    submitDisabled() {
        return (
            (this.state.customerIds.length === 0
                || this.state.createdCaseLoading)
        );
    }

    getTrigger() {
        if (!this.props.trigger) {
            return (
                <Button primary onClick={() => this.props.setOpen(true)}>
                    <Icon name="plus" size="small" /> Create Case
                </Button>
            );
        }

        const Trigger = this.props.trigger;
        return <Trigger onClick={() => this.props.setOpen(true)} />;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open && this.props.customerId) {
            this.setState({customerId: this.props.customerId});
        }
    }

    onInputChanged(name, value) {
        this.setState({
            selectedCustomers: value.selectedTags,
            customerIds: value.tagIds,
        });
    }

    registerValidator(name, validationFunction) {
        this.setState(prevState => ({
            validators: {
                ...prevState.validators,
                [name]: validationFunction,
            },
        }));
    }

    deregisterValidator(name) {
        this.setState(prevState => {
            const validators = {...prevState.validators};
            delete validators[name];
            return validators;
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.getTrigger()}
                <Modal
                    open={this.props.open}
                    size="small"
                    closeOnEscape={true}
                    onMount={this.onMount}
                    onClose={this.close}
                    centered={false}
                >
                    <Header icon="briefcase" content="Create Case" />
                    <Modal.Content>
                        {this.state.error &&
                            <Message negative>
                                <Message.Header>There was an error</Message.Header>
                                <p>{this.state.error}</p>
                            </Message>
                        }
                        <Form loading={this.state.loading} autoComplete="off">
                            <label>Customers</label>
                            <CustomerTagSearch
                                maxSelections={2}
                                onChange={this.onInputChanged}
                                disabled={this.tagDisabled}
                                fetchType={"customer"}
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close}>
                            <Icon name="remove" /> Cancel
                        </Button>
                        <Button
                            disabled={this.submitDisabled()}
                            color="green"
                            onClick={this.handleCreateCase}
                            loading={this.state.createdCaseLoading}
                        >
                            <Icon name="checkmark" /> Create
                        </Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}

CaseForm.propTypes = {
    customerIds: PropTypes.arrayOf(PropTypes.string),
    onSubmit: PropTypes.func,
    trigger: PropTypes.func,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
}

CaseForm.defaultProps = {
    customerIds: [],
    onSubmit: () => undefined,
    trigger: undefined,
}

export default withProgramme(CaseForm);
