import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';
import Api from 'api/Api';
import PageHeader from 'components/common/PageHeader';
import LoaderIcon from 'common/LoaderIcon';
import { getAdminRoute } from 'components/admin/AdminRouter';
import 'components/admin/ScreenList.scss';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';
import withProgramme from 'components/programme/ProgrammeProps';

class ScreenList extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            screens: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.loadSummary();
    }

    loadSummary() {
        this.setState({loading: true});
        Api.get(`/api/screens/programme/${getProgrammeIdFromCurrentPath()}`)
            .then(this.setScreens)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setScreens(data) {
        this.setState({
            screens: data,
        });
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'ProgramAdministration', content: 'Program Administration'},
            {key: 'Workflow Screens', content: 'Workflow Screens'},
        ];
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader
                    text="Workflow Screens"
                    icon={<LoaderIcon icon="lock" onClick={this.loadSummary} loading={this.state.loading} />}
                    breadcrumbs={this.getBreadcrumbs()}
                />
                <div className="workflow-screens">
                    <Table attached="bottom" selectable className="workflow-screens">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Stage ID</Table.HeaderCell>
                                <Table.HeaderCell>
                                    Case Stage
                                    <div className="meta-text">
                                        Description
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.screens.map(screen => (
                                <Table.Row key={`screen-${screen.screenTag}`}>
                                    <Table.Cell>
                                        {screen.screenTag}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {screen.name}
                                        <div className="meta-text">
                                            {screen.description}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Link
                                            to={`${getAdminRoute(getProgrammeIdFromCurrentPath())}/screens/${screen.workflowId}/${screen.screenId}`}
                                            className="nowrap"
                                        >
                                            Edit &raquo;
                                        </Link>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </React.Fragment>
        );
    }
}

ScreenList.propTypes = {
    children: PropTypes.node,
};

ScreenList.defaultProps = {
    children: undefined,
};

export default withProgramme(ScreenList);
