import React from 'react';
import PageHeader from 'components/common/PageHeader';
import MenuSelect from 'common/layout/MenuSelect';
import Authority from 'common/auth/Authority';
import { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import OffsetColumnLayout from 'common/layout/OffsetColumnLayout';

interface AdminMenuItem {
    id: string,
    name: string,
    meta: string,
    uri: string,
    icon: any,
    permissions: [string]
}

const adminItems: Array<AdminMenuItem> = [
    {
        id: '1',
        name: 'Users & User Groups',
        meta: 'Add and manage user access',
        uri: `/admin/users`,
        icon: 'user',
        permissions: [Authority.UserPermissions],
    },
    {
        id: '2',
        name: 'User Group Permissions',
        meta: 'Edit and manage user permissions',
        uri: `/admin/groups`,
        icon: 'users',
        permissions: [Authority.GroupPermissions],
    },
    {
        id: '3',
        name: 'Audit Log',
        meta: 'View and search system audit logs',
        uri: `/admin/audit`,
        icon: 'tasks',
        permissions: [Authority.ViewAuditLog],
    },
    {
        id: '4',
        name: 'Import and Export',
        meta: 'Import and export case data from the system',
        uri: `/admin/etl`,
        icon: 'exchange',
        permissions: [Authority.ImportExport],
    },
    {
        id: '5',
        name: 'Database Management',
        meta: 'Backup and restore the database',
        uri: `/admin/database`,
        icon: 'database',
        permissions: [Authority.DatabaseManagement],
    },
    {
        id: '6',
        name: 'Programs of Work',
        meta: 'Manage Programs of Work',
        uri: '/admin/programs',
        icon: 'balance scale',
        permissions: [Authority.ManageProgrammes],
    },
];

const AppAdminPage = () => {

    const renderAdminItem = (item: AdminMenuItem) => {
        return (
            <ShowWithOneOfPermission permissions={item.permissions} key={item.id}>
                <MenuSelect
                    name={item.name}
                    uri={item.uri}
                    meta={item.meta}
                    icon={item.icon} onSelect={undefined}
                />
            </ShowWithOneOfPermission>
        );
    };

    return (
        <React.Fragment>
            <PageHeader text="Administration" />
            <OffsetColumnLayout>
                {adminItems.map((item: AdminMenuItem) => renderAdminItem(item))}
            </OffsetColumnLayout>
        </React.Fragment>
    );

};

export default AppAdminPage;
