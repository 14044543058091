import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Form, Header, Icon, Label, Pagination, Segment, Select } from 'semantic-ui-react';
import Api from 'api/Api';
import autoBind from 'common/util/AutoBind';
import CaseForm from 'components/case/CaseForm';
import CaseList from 'components/case/CaseList';
import { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import Authority from 'common/auth/Authority';
import withCaseQueryParams, {
    CASE_STATE_FILTER_OPTIONS,
    REVIEW_STATE_FILTER_OPTIONS,
} from 'components/case/withCaseQueryParams';
import { getCaseCreatedMessageProps } from 'components/case/CaseCreatedMessage';

class CustomerCases extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            cases: [],
            loading: false,
            sortState: {},
            createCaseOpen: false,
            paginationState: {
                activePage: 1,
                boundaryRange: 3,
                totalPages: 0,
                firstItem: null,
                lastItem: null,
            },
        }
    }

    componentDidMount() {
        this.getCases();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customerId !== this.props.customerId) {
            this.getCases();
        }

        if (prevProps.caseParams.queryString !== this.props.caseParams.queryString) {
            this.getCases();
        }
    }

    getCases() {
        this.setState({loading: true})
        Api.get(`/api/customers/${this.props.customerId}/cases${this.props.caseParams.queryString}`)
            .then(this.setCustomerCases)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}))
    }

    onCaseCreated(caseData) {
        this.props.setAlerts([getCaseCreatedMessageProps(caseData)]);
        return setTimeout(() => this.getCases(), 1000);
    }

    setCustomerCases(data) {
        this.setState({cases: data.entries});
        this.setPagination(data.current, data.total);
    }

    setPagination(current, total) {
        this.setState({
            paginationState: {
                ...this.state.paginationState,
                activePage: current,
                totalPages: total,
            },
        })
    }

    onSortUpdate(sortState) {
        this.props.updateCaseQuery("sortState", sortState);
    }

    onResetFilters() {
        this.props.onResetFilters();
    }

    render() {
        return (
            <>
                <Segment attached='top'>
                    <Form>
                        <Form.Group style={{marginBottom: 0}}>
                            {this.props.caseParams.stage &&
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Label size="large" color="green">
                                    In stage: {this.props.caseParams.stage}
                                    <Icon
                                        name='delete'
                                        onClick={() => this.props.updateCaseQuery("stage", undefined)}
                                    />
                                </Label>
                            </Form.Field>
                            }
                            <Form.Field>
                                <label>Case Status</label>
                                <Select
                                    width={2}
                                    options={CASE_STATE_FILTER_OPTIONS}
                                    value={this.props.caseParams.caseStateOption}
                                    onChange={(event, {value}) => this.props.updateCaseQuery(
                                        "caseStateOption",
                                        value,
                                    )}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Stage Status</label>
                                <Select
                                    width={2}
                                    options={REVIEW_STATE_FILTER_OPTIONS}
                                    onChange={(event, {value}) => this.props.updateCaseQuery(
                                        "reviewStateOption",
                                        value,
                                    )}
                                    value={this.props.caseParams.reviewStateOption}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Button onClick={this.onResetFilters} style={{whiteSpace: 'nowrap'}}>
                                    Clear Filters
                                </Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Segment>
                {this.state.cases.length > 0 && (
                    <React.Fragment>
                        <CaseList
                            cases={this.state.cases}
                            loading={this.state.loading}
                            sortState={this.props.caseParams.sortState}
                            onSortUpdate={this.onSortUpdate}
                            allowActions={false}
                            showCustomerColumns={false}
                        />
                        <Container textAlign="center" style={{marginBottom: '1em'}}>
                            <Pagination
                                onPageChange={(event, {activePage}) => this.props.updateCaseQuery(
                                    "page",
                                    activePage,
                                )}
                                activePage={this.props.caseParams.page}
                                boundaryRange={this.state.paginationState.boundaryRange}
                                totalPages={this.state.paginationState.totalPages}
                                firstItem={this.state.paginationState.firstItem}
                                lastItem={this.state.paginationState.lastItem}

                            />
                        </Container>
                    </React.Fragment>
                )}
                {this.state.cases.length === 0 && (
                    <Segment textAlign="center" attached="bottom">
                        <Header as='h4' content='No cases' />
                    </Segment>
                )}
                {!this.props.readOnly &&
                <Container textAlign="center">
                    <ShowWithOneOfPermission permissions={[Authority.CreateCase]}>
                        <CaseForm
                            customerIds={[this.props.customerId]}
                            onSubmit={this.onCaseCreated}
                            open={this.state.createCaseOpen}
                            setOpen={(value) => this.setState({createCaseOpen: value})}
                        />
                    </ShowWithOneOfPermission>
                </Container>
                }
            </>
        )
    }

}

CustomerCases.propTypes = {
    customerId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    setAlerts: PropTypes.func,
};

CustomerCases.defaultProps = {
    readOnly: false,
    setAlerts: () => {},
}

export default withCaseQueryParams(CustomerCases);
