import React from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import 'common/layout/MenuSelect.css'

const MenuSelect = ({uri, icon, name, meta, onSelect}) => {
    return (
        <Link
            to={uri}
            className="menu-select"
            onClick={onSelect ? () => {onSelect()} : () => {}}
        >
            <Header as="h3">
                {typeof icon === "string" && <Icon name={icon} circular />}
                {React.isValidElement(icon) && icon}
                <Header.Content>
                    {name}
                    {meta && <Header.Subheader>{meta}</Header.Subheader>}
                </Header.Content>
            </Header>
        </Link>
    );
};

MenuSelect.propTypes = {
    uri: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string.isRequired,
    meta: PropTypes.string,
};

MenuSelect.defaultProps = {
    uri: '#',
    icon: undefined,
    meta: undefined,
};

export default MenuSelect;