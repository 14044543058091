import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import SortDirection from "components/common/SortDirection";
import {getSortByFromUrlValue, getSortByUrlQueryValue} from "common/util/UrlUtils";

type AuditLogQuery = {
    sortState?: {
        sortKey: ValidSortKeys | undefined
        direction: SortDirection
    }
    fromDate?: string
    toDate?: string
    searchQuery?: string
    debouncedSearchQuery?: string
    user?: string
    orderBy?: string
    page?: number
    size?: number
    queryString?: string
}
type ValidSortKeys = "time"
type AuditLogQueryKey = keyof AuditLogQuery

const AUDIT_LOG_QUERY_KEYS_TO_RESET_PAGING: AuditLogQueryKey[] = ["user", "fromDate", "toDate", "searchQuery"]

const USER_URL_PARAMETER = "user";
const SORT_BY_URL_PARAMETER = "sort";
const FROM_DATE_URL_PARAMETER = "from";
const TO_DATE_URL_PARAMETER = "to";
const SEARCH_QUERY_URL_PARAMETER = "query"
const PAGE_URL_PARAMETER = "page"

const ORDER_BY_API_PARAMETER = "orderBy";
const SIZE_API_PARAMETER = "size"

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_SORT_KEY = "time"

const getBaseUrlQueryParams = (query: AuditLogQuery) => {
    const queryParams = new URLSearchParams();

    if (query.page) {
        queryParams.append(PAGE_URL_PARAMETER, query.page.toString());
    }

    if (query.fromDate) {
        queryParams.append(FROM_DATE_URL_PARAMETER, query.fromDate);
    }

    if (query.toDate) {
        queryParams.append(TO_DATE_URL_PARAMETER, query.toDate);
    }

    if (query.user) {
        queryParams.append(USER_URL_PARAMETER, query.user);
    }

    if (query.debouncedSearchQuery) {
        queryParams.append(SEARCH_QUERY_URL_PARAMETER, query.debouncedSearchQuery);
    }
    return queryParams;
}
const getClientQueryParams = (query: AuditLogQuery) => {

    const queryParams = getBaseUrlQueryParams(query);

    const sortByQueryString = getSortByUrlQueryValue(query.sortState);
    if (sortByQueryString) {
        queryParams.append(SORT_BY_URL_PARAMETER, sortByQueryString);
    }

    return queryParams;
}

const getApiQueryParams = (query: AuditLogQuery) => {

    const queryParams = getBaseUrlQueryParams(query);

    if (query.sortState?.direction && query.sortState.sortKey) {
        queryParams.append(ORDER_BY_API_PARAMETER, getSortByUrlQueryValue(query.sortState)!);
    }

    if (query.size) {
        queryParams.append(SIZE_API_PARAMETER, query.size.toString());
    }
    return queryParams;
}

const getDefaultQuery = (): AuditLogQuery => {
    const defaultQueryString = new URLSearchParams([[SIZE_API_PARAMETER, DEFAULT_PAGE_SIZE.toString()]]);
    return {
        size: DEFAULT_PAGE_SIZE,
        sortState: {
            sortKey: DEFAULT_SORT_KEY,
            direction: SortDirection.Descending
        },
        queryString: `?${defaultQueryString.toString()}`
    };
}

export type WithAuditLogQueryParamsProps = {
    auditLogQuery: AuditLogQuery
    updateAuditLogQuery: <T extends AuditLogQueryKey>(key: T, newValue: AuditLogQuery[T]) => void
    onResetFilters: () => void
}

const getQueryFromUrl = (urlString: string) => {

    const getDataFromUrl = (queryParameter: string) => {
        const query = new URLSearchParams(urlString);
        return query.get(queryParameter);
    }

    const getUserParameterFromUrl = () => {
        const userFilter = getDataFromUrl(USER_URL_PARAMETER);
        return userFilter === null ? undefined : userFilter;
    }

    const getFromDateFromUrl = () => {
        const fromDate = getDataFromUrl(FROM_DATE_URL_PARAMETER);
        return fromDate === null ? undefined : fromDate;
    }

    const getToDateFromUrl = () => {
        const toDate = getDataFromUrl(TO_DATE_URL_PARAMETER);
        return toDate === null ? undefined : toDate;
    }

    const getSearchQueryFromUrl = () => {
        const searchQuery = getDataFromUrl(SEARCH_QUERY_URL_PARAMETER);
        return searchQuery === null ? undefined : searchQuery;
    }

    const getPageFromUrl = () => {
        const pageNumber = getDataFromUrl(PAGE_URL_PARAMETER);
        return pageNumber === null || isNaN(+pageNumber) ? 1 : +pageNumber;
    }

    const query = getDefaultQuery();

    const sortState = getSortByFromUrlValue(getDataFromUrl(SORT_BY_URL_PARAMETER));
    if (sortState.sortKey && sortState.direction !== SortDirection.None) {
        query.sortState = sortState;
    }

    const page = getPageFromUrl();
    if (page) {
        query.page = page;
    }

    const user = getUserParameterFromUrl();
    if (user) {
        query.user = user;
    }

    const fromDate = getFromDateFromUrl();
    if (fromDate) {
        query.fromDate = fromDate;
    }

    const toDate = getToDateFromUrl();
    if (toDate) {
        query.toDate = toDate;
    }

    const searchQuery = getSearchQueryFromUrl();
    if (searchQuery) {
        query.searchQuery = searchQuery;
        query.debouncedSearchQuery = searchQuery;
    }

    query.queryString = "?" + getApiQueryParams(query).toString();

    return query;
}

const withAuditLogQueryParams = <P, >(Component: React.ComponentType<P>): React.FunctionComponent<P & WithAuditLogQueryParamsProps> => {
    return ({...props}) => {
        const location = useLocation();
        const history = useHistory();
        const [query, setQuery] = useState<AuditLogQuery>(getQueryFromUrl(location.search));

        const setUrl = useCallback(
            (newQuery: AuditLogQuery) => {
                const newQueryString = getClientQueryParams(newQuery).toString();
                const currentQueryString = getClientQueryParams(query).toString();
                if (newQueryString !== currentQueryString) {
                    history.push(`${location.pathname}?${newQueryString}`);
                }
            }, [location.pathname, history, query]);

        const updateAuditLogQuery = useCallback(
            <T extends AuditLogQueryKey>(key: T, newValue: AuditLogQuery[T]) => {
                if (query[key] === newValue) {
                    return
                }
                const newQuery = {...query, [key]: newValue};
                if (AUDIT_LOG_QUERY_KEYS_TO_RESET_PAGING.includes(key)) {
                    newQuery.page = 1;
                }
                setQuery(newQuery);
                setUrl(newQuery);

            }, [query, setUrl])

        useEffect(() => {
            setQuery(getQueryFromUrl(location.search));
        }, [location.search])

        useEffect(() => {
            const handler = setTimeout(() => {
                updateAuditLogQuery("debouncedSearchQuery", query.searchQuery);
            }, 500);
            return () => clearTimeout(handler);
        }, [query.searchQuery, updateAuditLogQuery])

        const onResetFilters = () => {
            const newQuery = getDefaultQuery();
            setUrl(newQuery);
        }

        return (
            <Component
                auditLogQuery={query}
                updateAuditLogQuery={updateAuditLogQuery}
                onResetFilters={onResetFilters}
                {...props as P}
            />
        )
    }
}

export {
    withAuditLogQueryParams as default,
};
