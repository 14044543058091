import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withUserContextProp } from 'UserContext';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Table } from 'semantic-ui-react';
import { toDurationTextFromJson } from 'common/util/DateHelpers';
import AllocateTaskModal from 'components/workflow/AllocateTaskModal';
import ReopenCaseModal from 'components/workflow/ReopenCaseModal';
import Authority from 'common/auth/Authority';
import ButtonWithDropdown from 'common/ButtonWithDropdown';
import { getCaseRoute } from 'components/case/CaseRouter';
import { getCustomerRoute } from 'components/customer/CustomerRouter';
import { pascalCaseWithSpaces } from 'common/util/StringHelpers';
import CaseStatus from 'components/case/CaseStatus';
import TrafficLightCell, { CellLevel } from 'components/case/TrafficLightCell';
import SortableHeader from 'components/common/SortableHeader';
import 'components/case/CaseList.scss';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const CaseAction = {
    Allocate: 'Allocate',
    Abort: 'Abort',
    Reopen: 'Reopen',
};

/**
 *
 */
class CaseList extends Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            allocateModalVisible: false,
            reopenModalVisible: false,
            selectedTaskId: null,
            selectedCase: null,
        };
    }

    showAllocateTaskModal(taskId) {
        this.setState({allocateModalVisible: true, selectedTaskId: taskId});
    }

    showReopenTaskModal(theCase) {
        this.setState({reopenModalVisible: true, selectedCase: theCase});
    }

    getActionButtonOptions(theCase) {
        const options = [];

        if (theCase.status === CaseStatus.Aborted || theCase.status === CaseStatus.Closed) {
            if (this.props.userContext.hasPermissions(Authority.ReopenCase)) {
                options.push({
                    text: CaseAction.Reopen,
                    onClick: () => this.showReopenTaskModal(theCase),
                });
            }
            return options;
        }

        if (this.props.onAllocateTask && this.props.userContext.hasPermissions(Authority.AllocateCase)) {
            options.push({
                text: CaseAction.Allocate,
                onClick: () => this.showAllocateTaskModal(theCase.reviewTaskId),
            });
        }
        if (this.props.onAbortCase &&
            this.props.userContext.hasPermissions(Authority.AbortCase)) {
            options.push({
                text: CaseAction.Abort,
                onClick: () => this.props.onAbortCase(theCase),
            });
        }

        return options;
    }

    getCaseActionButton(theCase) {

        const options = this.getActionButtonOptions(theCase);

        if (options.length > 1) {
            const primaryOption = options.shift();
            return (<ButtonWithDropdown
                compact
                size="small"
                text={primaryOption.text}
                onClick={primaryOption.onClick}
                color={'blue'}
                options={options}
            />);
        } else if (options.length === 1) {
            const primaryOption = options.shift();
            return (<Button
                compact
                size="small"
                content={primaryOption.text}
                onClick={primaryOption.onClick}
                color="blue"
            />);
        }
        return '';
    }

    renderCustomerNames(customers) {
        return (
            <Table.Cell>
                {customers.map((customer) => (
                    <div key={customer.customerId}>
                        <Link
                            key={customer.customerId}
                            to={`${getCustomerRoute(getProgrammeIdFromCurrentPath(), customer.customerId)}`}
                        >{customer.customerName}</Link>
                    </div>
                ))}
            </Table.Cell>
        );
    }

    renderCustomerNumbers(customers) {
        return (
            <Table.Cell>
                {customers.map((customer) => (
                    <div key={customer.customerId}>
                        <Link
                            key={customer.customerId}
                            to={`${getCustomerRoute(getProgrammeIdFromCurrentPath(), customer.customerId)}`}
                        >{customer.customerReferenceNumber}</Link>
                    </div>
                ))}
            </Table.Cell>
        );
    }

    render() {
        return (
            <>
                {this.props.onAllocateTask &&
                    <AllocateTaskModal
                        visible={this.state.allocateModalVisible}
                        taskId={this.state.selectedTaskId}
                        onSubmit={this.props.onAllocateTask}
                        onClose={() => this.setState({allocateModalVisible: false, selectedTaskId: null})}
                    />
                }
                {this.state.reopenModalVisible &&
                    <ReopenCaseModal
                        visible={this.state.reopenModalVisible}
                        onSubmit={this.props.onReopenCase}
                        selectedCase={this.state.selectedCase}
                        onClose={() => this.setState({reopenModalVisible: false, selectedCase: null})}
                    />
                }
                <Table attached={'bottom'} celled className="case-list">
                    <Table.Header>
                        <Table.Row>
                            <SortableHeader
                                className="caseId"
                                sortKey="caseNumber"
                                sortState={this.props.sortState}
                                onSortUpdate={this.props.onSortUpdate}
                                text="Case Number"
                                verticalAlign="top"
                            />
                            {this.props.showCustomerColumns &&
                                <SortableHeader
                                    className="customer"
                                    sortKey="customer"
                                    sortState={this.props.sortState}
                                    onSortUpdate={this.props.onSortUpdate}
                                    text="Customers"
                                    verticalAlign="top"
                                />
                            }
                            {this.props.showCustomerColumns &&
                                <SortableHeader
                                    className="customerReferenceNumber"
                                    sortKey="customerReferenceNumber"
                                    sortState={this.props.sortState}
                                    onSortUpdate={this.props.onSortUpdate}
                                    text="Customer Number(s)"
                                    verticalAlign="top"
                                />
                            }
                            <SortableHeader
                                className="caseStage"
                                sortKey="stage"
                                sortState={this.props.sortState}
                                onSortUpdate={this.props.onSortUpdate}
                                text="Case Stage"
                                verticalAlign="top"
                            />
                            <SortableHeader
                                className="allocatedTo"
                                sortKey="allocatedTo"
                                sortState={this.props.sortState}
                                onSortUpdate={this.props.onSortUpdate}
                                text="Allocated To"
                                verticalAlign="top"
                            />
                            <SortableHeader
                                className="caseState"
                                sortKey="state"
                                sortState={this.props.sortState}
                                onSortUpdate={this.props.onSortUpdate}
                                text="Case Status"
                                verticalAlign="top"
                            />
                            <SortableHeader
                                className="startTime"
                                sortKey="startTime"
                                sortState={this.props.sortState}
                                onSortUpdate={this.props.onSortUpdate}
                                text="Case Age"
                                verticalAlign="top"
                            />
                            <SortableHeader
                                className="reviewState"
                                sortKey="reviewState"
                                sortState={this.props.sortState}
                                onSortUpdate={this.props.onSortUpdate}
                                text="Stage Status"
                                verticalAlign="top"
                            />
                            <SortableHeader
                                className="stageStartTime"
                                sortKey="stageStartTime"
                                sortState={this.props.sortState}
                                onSortUpdate={this.props.onSortUpdate}
                                text="Stage Age"
                                verticalAlign="top"
                            />
                            {this.props.allowActions && this.props.cases.length > 0 &&
                                <Table.HeaderCell verticalAlign="top" className="action">Action</Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.cases.map(theCase => (
                            <Table.Row key={`case-${theCase.caseId}`}>
                                <Table.Cell>
                                    <Link
                                        to={`${getCaseRoute(
                                            getProgrammeIdFromCurrentPath(),
                                            theCase.caseId,
                                        )}/summary`}
                                    >{theCase.displayId}</Link>
                                </Table.Cell>
                                {this.props.showCustomerColumns && this.renderCustomerNames(theCase.customers)}
                                {this.props.showCustomerColumns && this.renderCustomerNumbers(theCase.customers)}
                                <Table.Cell>
                                    <Link
                                        to={`${getCaseRoute(
                                            getProgrammeIdFromCurrentPath(),
                                            theCase.caseId,
                                        )}/stage`}
                                    >{theCase.stageScreenName}</Link>
                                </Table.Cell>
                                <TrafficLightCell
                                    content={theCase.allocatedTo ? theCase.allocatedTo.name : "Unallocated"}
                                    level={theCase.allocatedTo ? undefined : CellLevel.Medium}
                                />
                                <Table.Cell>
                                    {pascalCaseWithSpaces(theCase.status) || ""}
                                </Table.Cell>
                                <TrafficLightCell
                                    level={theCase.alertLevel ? theCase.alertLevel : CellLevel.Low}
                                    content={toDurationTextFromJson(theCase.startTime)}
                                    textAlign="right"
                                />
                                <Table.Cell>
                                    {pascalCaseWithSpaces(theCase.stageStatus || "")}
                                </Table.Cell>
                                <TrafficLightCell
                                    level={theCase.stageAlertLevel ? theCase.stageAlertLevel : CellLevel.Low}
                                    content={toDurationTextFromJson(theCase.stageStartTime)}
                                    textAlign="right"
                                />
                                {this.props.allowActions &&
                                    <Table.Cell>
                                        {this.getCaseActionButton(theCase)}
                                    </Table.Cell>
                                }
                            </Table.Row>))
                        }
                    </Table.Body>
                </Table>
            </>
        );
    }
}

CaseList.propTypes = {
    cases: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    loading: PropTypes.bool.isRequired,
    sortState: PropTypes.shape(),
    allowActions: PropTypes.bool,
    onSortUpdate: PropTypes.func,
    onAllocateTask: PropTypes.func,
    onReopenCase: PropTypes.func,
    onAbortCase: PropTypes.func,
    showCustomerColumns: PropTypes.bool,
};

CaseList.defaultProps = {
    sortState: {},
    allowActions: true,
    onSortUpdate: () => {},
    onAllocateTask: undefined,
    onReopenCase: undefined,
    onAbortCase: undefined,
    showCustomerColumns: true,
};

export default withUserContextProp(CaseList);
