import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import 'components/common/SortToggle.scss';
import SortDirection from 'components/common/SortDirection';

const NEXT_SORT_STATE = {
    [SortDirection.Ascending]: SortDirection.Descending,
    [SortDirection.Descending]: SortDirection.Ascending,
    [SortDirection.None]: SortDirection.Ascending,
}

const NEXT_SORT_STATE_REVERSED = {
    [SortDirection.Ascending]: SortDirection.Descending,
    [SortDirection.Descending]: SortDirection.Ascending,
    [SortDirection.None]: SortDirection.Descending,
}

const SORT_STATE_ICON_MAP = {
    [SortDirection.Ascending]: 'sort up',
    [SortDirection.Descending]: 'sort down',
    [SortDirection.None]: 'sort',
}

function getNextSortDirection(currentSortDirection, isReverseSort) {
    if (isReverseSort) {
        return NEXT_SORT_STATE_REVERSED[currentSortDirection];
    }
    return NEXT_SORT_STATE[currentSortDirection];
}

/**
 * Adds togglable sort icons. To be used to sort tables
 * @param sortState {object, {sortKey: string, direction: SortState}} An object with the current field and direction
 *     that is being sored by
 * @param sortKey {string} The key that this particular toggle is for
 * @param setSortState {function} A function that takes in the newSortState as a parameter and overwrites the old sort
 *     state
 * */
const SortToggle = (props) => {

    const getCurrentSortDirection = () => {
        if (props.sortState.sortKey === props.sortKey) {
            if (props.reverseSort) {
                return props.sortState.direction;
            } else {
                return props.sortState.direction;
            }
        }

        return SortDirection.None;
    }

    const currentSortDirection = getCurrentSortDirection();

    const iconName = SORT_STATE_ICON_MAP[currentSortDirection];

    const onSortToggle = () => {
        const newSortState = {
            sortKey: props.sortKey,
            direction: getNextSortDirection( currentSortDirection, props.reverseSort),
        }
        props.setSortState(newSortState);
    }

    let className = 'sort-toggle';
    if (props.right) {
        className = 'sort-toggle right'
    }

    return (
        <div onClick={onSortToggle} className={className}>
            <Icon name={iconName} className="icon" />
            <div>{props.headerText}</div>
        </div>
    )
}
SortToggle.propTypes = {
    sortKey: PropTypes.string.isRequired,
    sortState: PropTypes.shape({
        sortKey: PropTypes.string,
        direction: PropTypes.string,
    }).isRequired,
    setSortState: PropTypes.func.isRequired,
    headerText: PropTypes.node,
    right: PropTypes.bool,
    reverseSort: PropTypes.bool,
}

SortToggle.defaultProps = {
    headerText: '',
    right: false,
    reverseSort: false,
};

export default SortToggle;