import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Container, Icon, List, Modal } from 'semantic-ui-react'

class PauseButton extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            showReasonPicker: false,
            holdReason: null,
        };
    }

    onClickHoldButton(e) {
        e.stopPropagation();
        if (this.props.holdReasons.length === 0) {
            this.props.onSuspendReview(null);
        } else {
            this.showReasonPickerDialog();
        }
    }

    showReasonPickerDialog() {
        this.setState({showReasonPicker: true});
    }

    hideReasonPickerDialog() {
        this.setState({showReasonPicker: false});
    }

    confirmHoldReason(reasonText) {
        this.hideReasonPickerDialog();
        this.props.onSuspendReview(reasonText);
    }

    render() {
        return (
            <>
                {this.state.showReasonPicker &&
                 <Modal
                     onClose={this.hideReasonPickerDialog}
                     open={this.state.showReasonPicker}
                 >
                     <Modal.Header>Please select the reason for placing the review stage on hold</Modal.Header>
                     <Modal.Content>
                         <List selection animated verticalAlign='middle'>
                             {this.props.holdReasons.map(reasonText => (
                                 <List.Item key={reasonText} onClick={() => this.confirmHoldReason(reasonText)}>
                                     <Icon name="angle right" />
                                     <List.Content>
                                         <List.Header>{reasonText}</List.Header>
                                     </List.Content>
                                 </List.Item>
                             ))}
                         </List>
                     </Modal.Content>
                     <Modal.Actions>
                         <Container textAlign="center">
                             <Button
                                 content="Cancel"
                                 onClick={this.hideReasonPickerDialog}
                                 primary
                             />
                         </Container>
                     </Modal.Actions>

                 </Modal>
                }
                <Button
                    color="orange"
                    onClick={this.onClickHoldButton}
                    disabled={this.props.onHold}
                >
                    <Icon name="pause" />
                    On Hold
                </Button>
            </>
        );
    }
}

PauseButton.propTypes = {
    onHold: PropTypes.bool.isRequired,
    holdReasons: PropTypes.arrayOf(PropTypes.string),
    onSuspendReview: PropTypes.func.isRequired,
};

PauseButton.defaultProps = {
    holdReasons: [],
};

export default PauseButton;