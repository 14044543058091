import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import {
    ERROR_FIELD_REQUIRED,
    getErrorResponse,
    NoValidationError,
    isFieldRequiredAndEmpty,
} from 'external/form/Validators';

function validator(fieldValue, fieldConfiguration) {
    if (isFieldRequiredAndEmpty(fieldValue, fieldConfiguration)) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }
    const integerValue = parseInt(fieldValue);
    if (isNaN(integerValue)) {
        return getErrorResponse(`The data "${fieldValue}" cannot be converted to a number`);
    }
    if (integerValue < fieldConfiguration.minimum) {
        return getErrorResponse(`Value must be greater than or equal to ${fieldConfiguration.minimum}`);
    }
    if (integerValue > fieldConfiguration.maximum) {
        return getErrorResponse(`Value must be less than or equal to ${fieldConfiguration.maximum}`);
    }
    return NoValidationError;
}

function getLabelProps(error) {
    return {
        label: {
            pointing: 'left',
            color: 'orange',
            basic: true,
            content: error,
        },
        labelPosition: 'right',
    }
}

function getWarningProps(value, required, minimum, maximum) {
    if (required && (value < minimum || value > maximum)) {
        return getLabelProps(`Value must be in range from ${minimum} to ${maximum}`);
    }
    return {};
}

const FieldInteger = ({name, label, value, onChange, disabled, required, minimum, maximum, registerValidator, deregisterValidator}) => {
    useEffect(() => {
        registerValidator(name, validator);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const valueChanged = (event, data) => {
        let integerValue = parseInt(data.value);
        if (isNaN(integerValue)) {
            integerValue = "";
        }
        onChange(name, integerValue);
    };

    const hintProps = getWarningProps(value, required, minimum, maximum);
    return (
        <label htmlFor={name}>
            <Input
                type="number"
                name={name}
                onChange={valueChanged}
                value={value}
                disabled={disabled}
                min={minimum}
                max={maximum}
                {...hintProps}
            />
        </label>
    );
};

FieldInteger.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldInteger.defaultProps = {
    value: "",
    required: false,
    onChange: () => {},
    error: undefined,
    disabled: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
    minimum: Number.MIN_SAFE_INTEGER,
    maximum: Number.MAX_SAFE_INTEGER,
};

export default FieldInteger;
