import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldText from 'common/form/fields/FieldText';
import { executeDefaultValidators } from 'external/form/Validators';

const FieldPassword = (props) => {
    const {name, registerValidator, deregisterValidator} = props;
    useEffect(() => {
        registerValidator(name, executeDefaultValidators);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const newProps = Object.assign({}, props, {inputType: 'password'});
    return (
        <FieldText {...newProps} />
    );
};

FieldPassword.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    autoFocus: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldPassword.defaultProps = {
    value: '',
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    autoFocus: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldPassword;
