import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import XLSX from 'xlsx';
import { Container, Tab, Table } from 'semantic-ui-react';

class SheetPreview extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            workbook: null,
        };
    }

    componentDidMount() {
        fetch(this.props.url, {method: 'GET'})
            .then((data) => {
                return data.arrayBuffer();
            })
            .then(this.setSheetData)
    }

    setSheetData(arrayBuf) {
        var data = new Uint8Array(arrayBuf);
        var workbook = XLSX.read(data, {type: "array"});
        this.setState({workbook})
    }

    // to_csv(workbook) {
    //     var result = [];
    //     workbook.SheetNames.forEach(function(sheetName) {
    //         var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
    //         if(csv.length > 0){
    //             result.push("SHEET: " + sheetName);
    //             result.push("");
    //             result.push(csv);
    //             result.push("done");
    //         }
    //     });
    //     return result.join("\n");
    // }
    //
    //
    getSheetData(sheet) {
        return XLSX.utils.sheet_to_json(sheet, {header: 1});
    }

    renderBody(sheetData) {
        if (sheetData.length <= 1) {
            return null;
        }
        return sheetData
            .splice(1)
            .map(this.renderRow);
    }

    renderRow(row, rowNumber) {
        return (
            <Table.Row key={`ROW-${rowNumber}`}>
                {row.map((item, index) => <Table.Cell key={`CELL-${rowNumber}-${index}`}>{item}</Table.Cell>)}
            </Table.Row>
        );
    }

    renderSheet(sheetName) {
        const sheetData = this.getSheetData(this.state.workbook.Sheets[sheetName]);
        return (
            <Table>
                {sheetData.length > 0 &&
                 <Table.Header>
                     <Table.Row key={"ROW-0"}>
                         {sheetData[0].map((data, index) => {
                             return <Table.HeaderCell key={`CELL-0-${index}`}>{data}</Table.HeaderCell>
                         })}
                     </Table.Row>
                 </Table.Header>
                }
                <Table.Body>
                    {this.renderBody(sheetData)}
                </Table.Body>
            </Table>
        );
    }

    render() {
        if (!this.state.workbook) {
            return <>Loading...</>
        }
        console.log('-->', this.state.workbook);

        const panes = this.state.workbook.SheetNames.map(item => {
                return {menuItem: item, render: () => this.renderSheet(item)};
            },
        );
        return (
            <React.Fragment>
                <Container fluid>
                    <Tab panes={panes} />
                </Container>
            </React.Fragment>
        );
    }
}

SheetPreview.propTypes = {
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
};

SheetPreview.defaultProps = {};

export default SheetPreview;