import _isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

const getNameFieldFromObjectList = (listOfObjects, fieldName) => (
    listOfObjects.find(x => x.name === fieldName).value
);

// Taken from http://www.jacklmoore.com/notes/rounding-in-javascript/
const round = (value, decimals) => {
    const wholePart = Math.round(`${value}e${decimals}`);
    const decimalPart = `e-${decimals}`;
    return Number(`${wholePart}${decimalPart}`);
};

const KILOBYTE = 1000;
const MEGABYTE = 1000 * KILOBYTE;
const GIGABYTE = 1000 * MEGABYTE;
const TERABYTE = 1000 * GIGABYTE; // eslint-disable-line no-unused-vars

const KIBIBYTE = 1024;
const MEBIBYTE = 1024 * KILOBYTE;
const GIBIBYTE = 1024 * MEGABYTE;
const TEBIBYTE = 1024 * GIGABYTE;

const getHumanReadableFileSize = (fileSizeInBytes) => {
    if (fileSizeInBytes < KIBIBYTE) {
        return `${fileSizeInBytes} B`;
    } else if (fileSizeInBytes < MEBIBYTE) {
        return `${round(fileSizeInBytes / KIBIBYTE, 1)} KiB`;
    } else if (fileSizeInBytes < GIBIBYTE) {
        return `${round(fileSizeInBytes / MEBIBYTE, 1)} MiB`;
    } else if (fileSizeInBytes < TEBIBYTE) {
        return `${round(fileSizeInBytes / GIBIBYTE, 1)} GiB`;
    }

    return `${round(fileSizeInBytes / TEBIBYTE, 1)} TiB`;
};

// From https://stackoverflow.com/a/16436975
function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.

    for (let i = 0; i < a.length; i += 1) {
        if (!_isEqual(a[i], b[i])) {
            return false;
        }
    }
    return true;
}

/**
 * Calculates whether there are more results to load based on the current page number, page size and expected result set
 */
function hasMoreResults(pageNumber, pageSize, totalResults) {
    return (pageNumber + 1) * pageSize < totalResults;
}

function moveCursorToEndOfText(htmlElement) {
    if (typeof htmlElement.selectionStart === 'number') {
        htmlElement.focus();
        htmlElement.selectionStart = htmlElement.value.length; // eslint-disable-line no-param-reassign
        htmlElement.selectionEnd = htmlElement.value.length; // eslint-disable-line no-param-reassign
    } else if (typeof htmlElement.createTextRange !== 'undefined') {
        htmlElement.focus();
        const range = htmlElement.createTextRange();
        range.collapse(false);
        range.select();
    }
}

// From: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
function generateUuid() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (d + (Math.random() * 16)) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}

/**
 * Helper method to check if a supplied value is false, if it is not a boolean
 */
function isValueFalse(value) {
    const stringValue = `${value}`.toLowerCase();
    return isNil(value)
           || stringValue === 'false'
           || stringValue === 'no'
           || stringValue === '0';
}

export {
    getNameFieldFromObjectList,
    round,
    getHumanReadableFileSize,
    arraysEqual,
    hasMoreResults,
    moveCursorToEndOfText,
    generateUuid,
    isValueFalse,
};