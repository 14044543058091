import React, { Component } from "react";
import { withUserContextProp } from 'UserContext';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Container, Form, Header, Icon, Label, Modal, Pagination, Select } from 'semantic-ui-react';
import LoaderIcon from 'common/LoaderIcon';
import Api from 'api/Api';
import CaseList from 'components/case/CaseList';
import PageHeader from 'components/common/PageHeader';
import { withRouter } from 'react-router-dom';
import CaseStatus from 'components/case/CaseStatus';
import cloneDeep from 'lodash/cloneDeep';
import withCaseQueryParams, {
    CASE_STATE_FILTER_OPTIONS,
    REVIEW_STATE_FILTER_OPTIONS,
} from 'components/case/withCaseQueryParams';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';

const DELAYED_REFRESH_TIME = 2300;

/**
 *
 */
class CaseSummaryListPage extends Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            cases: [],
            loading: false,
            paginationState: {
                activePage: 1,
                boundaryRange: 3,
                totalPages: 0,
                firstItem: null,
                lastItem: null,
            },
        };
    }

    componentDidMount() {
        this.getCases();
        if (this.props.location && this.props.location.state && this.props.location.state.delayedRefresh) {
            setTimeout(this.getCases, DELAYED_REFRESH_TIME);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.caseParams.queryString !== this.props.caseParams.queryString) {
            this.getCases();
        }
    }

    getCases() {
        this.setState({loading: true});
        Api.get(`/api/cases${this.props.caseParams.queryString}`)
            .then(this.setCases)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setCases(data) {
        this.setPagination(data.current, data.total);
        this.setState({cases: data.entries});
    }

    setPagination(current, total) {

        this.setState({
            paginationState: {
                ...this.state.paginationState,
                activePage: current,
                totalPages: total,
            },
        })
    }

    handlePaginationChange(event, {activePage}) {
        this.props.updateCaseQuery("page", activePage);
    }

    onResetFilters() {
        this.props.onResetFilters();
    }

    onAllocateTask(taskId, userId = this.props.userContext.user.username) {
        this.setState({loading: true})
        return Api.post(`/api/tasks/${taskId}/allocate`, {reviewerId: userId})
            .then(() => this.getUpdatedCaseByTaskId(taskId))
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    onReopenCase(caseId, screenConfigurationId) {
        this.setState({loading: true})
        return Api.put(`/api/cases/${caseId}/reopen`, {screenConfigurationId})
            .then(() => {this.getUpdatedCaseByCaseId(caseId)})
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    getUpdatedCaseByTaskId(taskId) {
        const theCase = this.state.cases.find(item => item.reviewTaskId === taskId);
        if (theCase) {
            this.getUpdatedCaseByCaseId(theCase.caseId);
        }
    }

    getUpdatedCaseByCaseId(caseId) {
        this.setState({loading: true})
        return Api.get(`/api/cases/${caseId}`)
            .then(this.updateCaseInPlace)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    updateCaseInPlace(caseData) {
        const newCaseData = cloneDeep(this.state.cases)
            .map(item => {
                if (item.caseId === caseData.caseId) {
                    return caseData;
                }
                return item;
            });
        this.setState({cases: newCaseData});
    }

    abortCase(theCase) {
        if (theCase.status !== CaseStatus.Aborted && theCase.status !== CaseStatus.Closed) {
            Api.delete(`/api/cases/${theCase.caseId}`)
                .then(() => this.getUpdatedCaseByCaseId(theCase.caseId))
                .catch(Api.logError)
                .finally(this.cancelAbortCase)
        }
    }

    onAbortCase(theCase) {
        this.setState({
            abortModalVisible: true,
            abortingCase: theCase,
        });
    }

    cancelAbortCase() {
        this.setState({
            abortModalVisible: false,
            abortingCase: undefined,
        })
    }

    getAbortCaseModal() {
        return (
            <Modal open={this.state.abortModalVisible} onClose={this.cancelAbortCase}>
                <Header>Abort Case</Header>
                <Modal.Content>
                    <p>Are you sure you wish to abort this case?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.cancelAbortCase}
                        content="Cancel"
                    />
                    <Button
                        negative
                        onClick={() => this.abortCase(this.state.abortingCase)}
                        content="Abort"
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Cases', content: 'My Cases'},
        ];
    }

    onSortUpdate(sortState) {
        this.props.updateCaseQuery("sortState", sortState);
    }

    render() {
        return (
            <>
                {this.getAbortCaseModal()}
                <PageHeader
                    icon={<LoaderIcon icon="briefcase" onClick={this.getCases} loading={this.state.loading} />}
                    text="My Cases"
                    breadcrumbs={this.getBreadcrumbs()}
                    headerRightComponent={
                        <div style={{float: 'right', textAlign: 'left'}}>
                            <Form>
                                <Form.Group inline>
                                    {this.props.caseParams.stage &&
                                        <Form.Field>
                                            <label>&nbsp;</label>
                                            <Label size="large" color="green">
                                                In stage: {this.props.caseParams.stage}
                                                <Icon
                                                    name="delete"
                                                    onClick={() => this.props.updateCaseQuery("stage", undefined)}
                                                />
                                            </Label>
                                        </Form.Field>
                                    }
                                    <Form.Field>
                                        <label>Case Status</label>
                                        <Select
                                            width={2}
                                            options={CASE_STATE_FILTER_OPTIONS}
                                            value={this.props.caseParams.caseStateOption}
                                            onChange={(event, {value}) => this.props.updateCaseQuery(
                                                "caseStateOption",
                                                value,
                                            )}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Stage Status</label>
                                        <Select
                                            width={2}
                                            options={REVIEW_STATE_FILTER_OPTIONS}
                                            value={this.props.caseParams.reviewStateOption}
                                            onChange={(
                                                event,
                                                {value},
                                            ) => this.props.updateCaseQuery("reviewStateOption", value)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>&nbsp;</label>
                                        <Button onClick={this.onResetFilters} style={{whiteSpace: 'nowrap'}}>
                                            Clear Filters
                                        </Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </div>
                    }
                />
                <CaseList
                    cases={this.state.cases}
                    loading={this.state.loading}
                    sortState={this.props.caseParams.sortState}
                    onSortUpdate={this.onSortUpdate}
                    onAllocateTask={this.onAllocateTask}
                    onReopenCase={this.onReopenCase}
                    onAbortCase={this.onAbortCase}
                />
                <Container textAlign="center">
                    <Pagination
                        onPageChange={(event, {activePage}) => this.props.updateCaseQuery(
                            "page",
                            activePage,
                        )} {...this.state.paginationState} />
                </Container>
            </>
        );
    }
}

export default withCaseQueryParams(withRouter(withUserContextProp(CaseSummaryListPage)));
