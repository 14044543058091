import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import InformationModal from 'common/form/InformationModal';
import { Icon } from 'semantic-ui-react';
import Markdown from 'common/Markdown';

const FieldHelp = ({title, helpText}) => {
    if (isEmpty(helpText)) {
        return null;
    }
    return (
        <InformationModal
            trigger={
                <Icon name="help circle" color="teal" />
            }
            title={title}
            body={
                <Markdown text={helpText} />
            }
        />
    );
};

FieldHelp.propTypes = {
    title: PropTypes.node.isRequired,
    helpText: PropTypes.node,
};

FieldHelp.defaultProps = {
    helpText: undefined,
};

export {
    FieldHelp as default,
};
