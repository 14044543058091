// import $ from 'jquery';
// import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
// import _join from 'lodash/join';
import trim from 'lodash/trim';
// import * as Enums from 'common/enum/Enums';
//
// const getTextSnippet = (text, length, showEllipses = true) => {
//     if (text.length > length) {
//         return `${text.slice(0, length)} ${showEllipses ? '...' : ''}`;
//     }
//     return text;
// };
//
// const getTextFromHtml = (htmlString) => {
//     return $(`<div>${htmlString}</div>`).text();
// };
//
// const getTextSnippetFromHtml = (htmlString, snippetLength) => {
//     return getTextSnippet(getTextFromHtml(htmlString), snippetLength);
// };
//
// const getFilteredTableId = (contentType) => {
//     return `${contentType.toLowerCase()}-list`;
// };
//
/**
 * Determine whether a string (or object) is blank.
 *
 * Non strings are tested using isEmpty. So an empty array,
 * for example, returns true.
 *
 * isBlank(undefined) => true
 * isBlank('') => true
 * isBlank('  ') => true
 * isBlank('ABC') => false
 *
 * @param str String to be tested
 * @returns {boolean} True if undefined or empty
 */
const isBlank = (str) => {
    // empty string are falsey
    if (isNil(str)) {
        return true;
    }
    return isString(str) ? isEmpty(trim(str)) : isEmpty(str);
};
//
// function getContentId(entityType, id) {
//     let idPrefix;
//     switch (entityType) {
//         case Enums.ContentType.Person:
//             idPrefix = 'PER';
//             break;
//         case Enums.ContentType.Group:
//             idPrefix = 'GRP';
//             break;
//         case Enums.ContentType.Note:
//             idPrefix = 'NOT';
//             break;
//         case Enums.ContentType.Task:
//             idPrefix = 'TSK';
//             break;
//         case Enums.ContentType.File:
//             idPrefix = 'FLE';
//             break;
//         case Enums.ContentType.Object:
//             idPrefix = 'OBJ';
//             break;
//         case Enums.ContentType.Location:
//             idPrefix = 'LOC';
//             break;
//         case Enums.ContentType.Brief:
//             idPrefix = 'BRF';
//             break;
//         case Enums.ContentType.Event:
//             idPrefix = 'EVT';
//             break;
//         case Enums.ContentType.LineOfInquiry:
//             idPrefix = 'LOI';
//             break;
//         default:
//             idPrefix = 'ID';
//             break;
//     }
//     return `${idPrefix}-au.com.nqry.cms:remediation:jar:1.0.0-SNAPSHOT`;
// }
//
// const formatPersonId = (id) => {
//     return getContentId(Enums.ContentType.Person, id);
// };
//
// const formatGroupId = (id) => {
//     return getContentId(Enums.ContentType.Group, id);
// };
//
// const formatFileId = (id) => {
//     return getContentId(Enums.ContentType.File, id);
// };
//
// const formatNoteId = (id) => {
//     return getContentId(Enums.ContentType.Note, id);
// };
//
// const formatObjectId = (id) => {
//     return getContentId(Enums.ContentType.Object, id);
// };
//
// const formatLocationId = (id) => {
//     return getContentId(Enums.ContentType.Location, id);
// };
//
// const formatTaskId = (id) => {
//     return getContentId(Enums.ContentType.Task, id);
// };
//
// const formatInquiryId = (id) => {
//     return getContentId(Enums.ContentType.LineOfInquiry, id);
// };
//
// const formatBriefId = (id) => {
//     return getContentId(Enums.ContentType.Brief, id);
// };
//
// const formatEventId = (id) => {
//     return getContentId(Enums.ContentType.Event, id);
// };
//
// function capitaliseFirstLetter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }
//
// const createOptionSelectText = (text, id) => {
//     return `au.com.nqry.cms:remediation:jar:1.0.0-SNAPSHOT : ${text}`;
// };
//
// const getHash = (str) => {
//     let hash = 0;
//     if (str.length === 0) return hash;
//     for (let i = 0; i < str.length; i += 1) {
//         const char = str.charCodeAt(i);
//         hash = ((hash << 5) - hash) + char; // eslint-disable-line no-bitwise
//         hash &= hash; // eslint-disable-line no-bitwise
//     }
//     return hash;
// };
//
// const getFlatArrayFromObjectArrayByKey = (objArray, key) => {
//     return objArray.map((item) => {
//         return `${item[key]}`;
//     });
// };
//
// function camelCaseToText(camelCase) {
//     if (isEmpty(camelCase)) {
//         return camelCase;
//     }
//     return camelCase
//         .replace(/([A-Z])/g, ' $1')
//         .replace(/^./, (str) => { return str.toUpperCase(); });
// }
//
// function join(strArray, separator, doCompact = true) {
//     const compacted = doCompact ? compact(strArray) : strArray;
//     return _join(compacted, separator);
// }
//

const AUDollarFormatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
});

function pascalCaseWithSpaces(theString) {
    return theString.replace(/([A-Z])/g, ' $1');
}

export {
    isBlank,
    AUDollarFormatter,
    pascalCaseWithSpaces,
//     getTextSnippet,
//     getTextFromHtml,
//     getTextSnippetFromHtml,
//     getFilteredTableId,
//     getContentId,
//     join,
//     formatPersonId,
//     formatGroupId,
//     formatFileId,
//     formatNoteId,
//     formatTaskId,
//     formatInquiryId,
//     formatObjectId,
//     formatLocationId,
//     formatBriefId,
//     formatEventId,
//     capitaliseFirstLetter,
//     camelCaseToText,
//     createOptionSelectText,
//     getHash,
//     getFlatArrayFromObjectArrayByKey,
};