import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CaseStatus from 'components/case/CaseStatus';
import TaskProcessState from 'components/case/reviews/TaskProcessState';
import { hasKey } from 'common/util/TypescriptHelpers';
import SortDirection from 'components/common/SortDirection';
import { getSortByFromUrlValue, getSortByUrlQueryValue } from 'common/util/UrlUtils';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

type ValidSortKeys =
    'caseNumber' |
    'customer' |
    'customerReferenceNumber' |
    'stage' |
    'allocatedTo' |
    'state' |
    'startTime' |
    'reviewState' |
    'reviewStartTime'

type CaseQuery = {
    sortState?: {
        sortKey: ValidSortKeys | undefined
        direction: SortDirection
    }
    orderBy?: string
    page?: number
    caseState?: CaseStatus[]
    programmeId?: string
    caseStateOption?: CaseStateFilterOption['value']
    reviewState?: TaskProcessState[]
    reviewStateOption?: ReviewStateFilterOption['value']
    size?: number
    stage?: string
    queryString?: string
}

enum CaseStateFilters {
    All = 'all',
    Open = 'open',
    NotStarted = 'notstarted',
    Active = 'active',
    OnHold = 'onhold',
    Closed = 'closed',
    Aborted = 'aborted'
}

const CASE_STATE_FILTERS = {
    [CaseStateFilters.All]: undefined,
    [CaseStateFilters.Open]: [CaseStatus.NotStarted, CaseStatus.Active, CaseStatus.OnHold],
    [CaseStateFilters.NotStarted]: [CaseStatus.NotStarted],
    [CaseStateFilters.Active]: [CaseStatus.Active],
    [CaseStateFilters.OnHold]: [CaseStatus.OnHold],
    [CaseStateFilters.Closed]: [CaseStatus.Closed],
    [CaseStateFilters.Aborted]: [CaseStatus.Aborted],
};

interface CaseStateFilterOption {
    key: string;
    text: string;
    value: CaseStateFilters;
}

const CASE_STATE_FILTER_OPTIONS: CaseStateFilterOption[] = [
    {key: 'all', text: 'All', value: CaseStateFilters.All},
    {key: 'open', text: 'Open Cases', value: CaseStateFilters.Open},
    {key: 'notstarted', text: 'Not Started', value: CaseStateFilters.NotStarted},
    {key: 'active', text: 'Active', value: CaseStateFilters.Active},
    {key: 'onhold', text: 'On Hold', value: CaseStateFilters.OnHold},
    {key: 'closed', text: 'Closed', value: CaseStateFilters.Closed},
    {key: 'aborted', text: 'Aborted', value: CaseStateFilters.Aborted},
];

enum ReviewStateFilters {
    All = 'all',
    Open = 'open',
    Unallocated = 'unallocated',
    NotStarted = 'notstarted',
    InProgress = 'inprogress',
    OnHold = 'onhold',
    Closed = 'closed',
}

const REVIEW_STATE_FILTERS = {
    [ReviewStateFilters.All]: undefined,
    [ReviewStateFilters.Open]: [
        TaskProcessState.Unallocated,
        TaskProcessState.NotStarted,
        TaskProcessState.InProgress,
        TaskProcessState.OnHold,
    ],
    [ReviewStateFilters.Unallocated]: [TaskProcessState.Unallocated],
    [ReviewStateFilters.NotStarted]: [TaskProcessState.NotStarted],
    [ReviewStateFilters.InProgress]: [TaskProcessState.InProgress],
    [ReviewStateFilters.OnHold]: [TaskProcessState.OnHold],
    [ReviewStateFilters.Closed]: [TaskProcessState.Closed],
};

interface ReviewStateFilterOption {
    key: string;
    text: string;
    value: ReviewStateFilters;
}

const REVIEW_STATE_FILTER_OPTIONS: ReviewStateFilterOption[] = [
    {key: 'all', text: 'All', value: ReviewStateFilters.All},
    {key: 'unallocated', text: 'Unallocated', value: ReviewStateFilters.Unallocated},
    {key: 'notstarted', text: 'Not Started', value: ReviewStateFilters.NotStarted},
    {key: 'inprogress', text: 'In Progress', value: ReviewStateFilters.InProgress},
    {key: 'onhold', text: 'On Hold', value: ReviewStateFilters.OnHold},
    {key: 'closed', text: 'Closed', value: ReviewStateFilters.Closed},
];

const DEFAULT_CASE_STATE_FILTER_VALUE = CASE_STATE_FILTER_OPTIONS[1].value;
const DEFAULT_REVIEW_STATE_FILTER_VALUE = REVIEW_STATE_FILTER_OPTIONS[0].value;

const CASE_STATE_URL_PARAMETER = 'state';
const REVIEW_STATE_URL_PARAMETER = 'reviewState';
const STAGE_URL_PARAMETER = 'stage';
const PAGE_URL_PARAMETER = 'page';
const SORT_BY_URL_PARAMETER = 'sort';

const ORDER_BY_API_PARAMETER = 'orderBy';
const CASE_STATE_API_PARAMETER = 'status';
const REVIEW_STATE_API_PARAMETER = 'reviewState';
const SIZE_API_PARAMETER = 'size';
const PROGRAMME_ID = 'programmeId';
const DEFAULT_PAGE_SIZE = 10;

const CASE_QUERY_KEYS_TO_RESET_PAGING_ON_UPDATE: (keyof CaseQuery)[] = [
    'stage',
    'reviewStateOption',
    'caseStateOption',
];

const getBaseUrlQueryParams = (query: CaseQuery) => {
    const queryParams = new URLSearchParams();

    if (query.stage) {
        queryParams.append(STAGE_URL_PARAMETER, query.stage);
    }

    if (query.page) {
        queryParams.append(PAGE_URL_PARAMETER, query.page.toString());
    }

    if (query.programmeId && !queryParams.get(PROGRAMME_ID)) {
        queryParams.append(PROGRAMME_ID, query.programmeId);
    }
    return queryParams;

};
const getClientQueryParams = (query: CaseQuery) => {
    const queryParams = getBaseUrlQueryParams(query);

    if (query.caseStateOption) {
        queryParams.append(CASE_STATE_URL_PARAMETER, query.caseStateOption);
    }

    if (query.reviewStateOption) {
        queryParams.append(REVIEW_STATE_URL_PARAMETER, query.reviewStateOption);
    }
    const sortByQueryString = getSortByUrlQueryValue(query.sortState);
    if (sortByQueryString) {
        queryParams.append(SORT_BY_URL_PARAMETER, sortByQueryString);
    }

    return queryParams;

};

const getApiQueryParams = (query: CaseQuery) => {

    const queryParams = getBaseUrlQueryParams(query);

    if (query.sortState?.direction && query.sortState.sortKey) {
        queryParams.append(ORDER_BY_API_PARAMETER, getSortByUrlQueryValue(query.sortState)!);
    }

    if (query.reviewState) {
        queryParams.append(REVIEW_STATE_API_PARAMETER, query.reviewState.join(','));
    }

    if (query.caseState) {
        queryParams.append(CASE_STATE_API_PARAMETER, query.caseState.join(','));
    }

    if (query.size) {
        queryParams.append(SIZE_API_PARAMETER, query.size.toString());
    }

    return queryParams;
};

const getDefaultQuery = (): CaseQuery => {
    const defaultQueryString = new URLSearchParams([[SIZE_API_PARAMETER, DEFAULT_PAGE_SIZE.toString()]]);
    const programmeId = getProgrammeIdFromCurrentPath();
    if (programmeId) {
        defaultQueryString.append(PROGRAMME_ID, programmeId);
    }
    return {
        size: DEFAULT_PAGE_SIZE,
        sortState: {
            sortKey: undefined,
            direction: SortDirection.None,
        },
        reviewStateOption: DEFAULT_REVIEW_STATE_FILTER_VALUE,
        caseStateOption: DEFAULT_CASE_STATE_FILTER_VALUE,
        programmeId: programmeId,
        queryString: `?${defaultQueryString.toString()}`,
    };
};

type WithCaseQueryParamsProps = {
    caseParams: CaseQuery
    updateCaseQuery: <T extends keyof CaseQuery>(key: T, newValue: CaseQuery[T]) => void
    onResetFilters: () => void
}

const withCaseQueryParams = <P, >(Component: React.ComponentType<P>): React.FunctionComponent<P & WithCaseQueryParamsProps> => {
    return ({...props}) => {
        const location = useLocation();
        const history = useHistory();
        const [query, setQuery] = useState<CaseQuery>(getDefaultQuery());

        useEffect(() => {

            const getDataFromUrl = (queryParameter: string) => {
                const query = new URLSearchParams(location.search);
                return query.get(queryParameter);
            };

            const getCaseStateFromUrl = () => {
                const caseFilter = getDataFromUrl(CASE_STATE_URL_PARAMETER);
                return caseFilter === null ? DEFAULT_CASE_STATE_FILTER_VALUE : caseFilter;
            };

            const getReviewStateFromUrl = () => {
                const reviewStateFilter = getDataFromUrl(REVIEW_STATE_URL_PARAMETER);
                return reviewStateFilter === null ? DEFAULT_REVIEW_STATE_FILTER_VALUE : reviewStateFilter;
            };

            const getStageFromUrl = () => {
                const stageFilter = getDataFromUrl(STAGE_URL_PARAMETER);
                return stageFilter === null ? undefined : stageFilter;
            };

            const getPageFromUrl = () => {
                const pageNumber = getDataFromUrl(PAGE_URL_PARAMETER);
                return pageNumber === null || isNaN(+pageNumber) ? 1 : +pageNumber;
            };
            const query = getDefaultQuery();

            query.sortState = getSortByFromUrlValue(getDataFromUrl(SORT_BY_URL_PARAMETER));

            const caseState = getCaseStateFromUrl();
            if (hasKey<typeof CASE_STATE_FILTERS>(CASE_STATE_FILTERS, caseState)) {
                query.caseState = CASE_STATE_FILTERS[caseState];
                query.caseStateOption = caseState;
            }

            const reviewState = getReviewStateFromUrl();
            if (hasKey<typeof REVIEW_STATE_FILTERS>(REVIEW_STATE_FILTERS, reviewState)) {
                query.reviewState = REVIEW_STATE_FILTERS[reviewState];
                query.reviewStateOption = reviewState;
            }

            const stage = getStageFromUrl();
            if (stage) {
                query.stage = stage;
            }

            const page = getPageFromUrl();
            if (page) {
                query.page = page;
            }

            query.queryString = '?' + getApiQueryParams(query).toString();
            setQuery(query);

        }, [location.search]);

        const setUrl = (query: CaseQuery) => {
            const queryString = getClientQueryParams(query).toString();
            history.push(`${location.pathname}?${queryString}`);
        };

        const updateCaseQuery = <T extends keyof CaseQuery>(key: T, newValue: CaseQuery[T]) => {
            const newQuery = {...query, [key]: newValue};
            if (CASE_QUERY_KEYS_TO_RESET_PAGING_ON_UPDATE.includes(key)) {
                newQuery.page = 1;
            }
            setUrl(newQuery);
        };

        const onResetFilters = () => {
            const newQuery = getDefaultQuery();
            newQuery.reviewStateOption = ReviewStateFilters.All;
            newQuery.caseStateOption = CaseStateFilters.All;
            setUrl(newQuery);
        };

        return <Component
            caseParams={query}
            updateCaseQuery={updateCaseQuery}
            onResetFilters={onResetFilters}
            {...props as P}
        />;
    };
};

export {
    withCaseQueryParams as default,
    CASE_STATE_FILTER_OPTIONS,
    REVIEW_STATE_FILTER_OPTIONS,
    CASE_STATE_URL_PARAMETER,
    REVIEW_STATE_URL_PARAMETER,
    STAGE_URL_PARAMETER,
    CaseStateFilters,
    ReviewStateFilters,
};
