import React, { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { ERROR_FIELD_REQUIRED, getErrorResponse, isNilOrEmpty, NoValidationError } from 'external/form/Validators';
import { isEqual } from 'lodash';

const validateField = (fieldData, fieldConfiguration) => {
    if (fieldConfiguration.allowMultipleFields) {
        return validateMultiField(fieldData, fieldConfiguration);
    }
    return validateSingleField(fieldData, fieldConfiguration);
}

const validateMultiField = (arrayFieldData, fieldConfiguration) => {
    return arrayFieldData
        .map(fieldData => validateSingleField(fieldData, fieldConfiguration))
        .reduce((finalVal, currentVal) => isEqual(finalVal, NoValidationError) ? currentVal : finalVal, NoValidationError);
}

const validateSingleField = (fieldData, fieldConfiguration) => {
    if(!fieldData){
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }

    const {ruleName, description, rule, nextScreen} = fieldData;

    if (fieldConfiguration.required && (
        isNilOrEmpty(ruleName) ||
        isNilOrEmpty(description) ||
        isNilOrEmpty(rule) ||
        isNilOrEmpty(nextScreen)
    )) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }

    // @TODO: Validate MVEL rule syntax.
    // if (validateMVELRule(rule)) {
    //
    // }

    return NoValidationError;
}

const FieldRoutingCondition = ({onChange, disabled, name, value, registerValidator, deregisterValidator, required, options}) => {

    const {ruleName, description, rule, nextScreen} = value;

    useEffect(() => {
        registerValidator(name, validateField);
        return deregisterValidator;
    }, [registerValidator, deregisterValidator, name]);

    const onFormDataChange = (changedKey, newValue) => {
        const newFieldValue = {
            ruleName: ruleName.trim(),
            description: description.trim(),
            rule: rule.trim(),
            nextScreen: nextScreen.trim(),
        }
        newFieldValue[changedKey] = newValue;
        onChange(name, newFieldValue);
    }

    const getOptions = () => {
        if (options) {
            return options;
        }
        return [];
    }

    const onFieldChange = (e, {name, value}) => {
        onFormDataChange(name, value);
    }

    return (
        <>
            <Form.Group style={{flexGrow: 1, marginLeft: 0}}>
                <Form.Input
                    type='text'
                    label='Name'
                    placeholder='Name'
                    onChange={onFieldChange}
                    value={ruleName}
                    name='ruleName'
                    disabled={disabled}
                    width={3}
                />
                <Form.Input
                    type='text'
                    label='Description'
                    placeholder='Description'
                    onChange={onFieldChange}
                    value={description}
                    name='description'
                    disabled={disabled}
                    width={5}
                />
                <Form.Input
                    type='text'
                    label='MVEL Rule'
                    placeholder='MVEL Rule'
                    onChange={onFieldChange}
                    value={rule}
                    name='rule'
                    disabled={disabled}
                    width={5}
                />
                <Form.Dropdown
                    disabled={disabled}
                    search
                    placeholder='Next screen'
                    selection
                    label='Next Screen'
                    name='nextScreen'
                    onChange={onFieldChange}
                    value={nextScreen}
                    options={getOptions()}
                    fluid
                    width={3}
                />
            </Form.Group>
        </>
    )
}

FieldRoutingCondition.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        ruleName: PropTypes.string,
        description: PropTypes.string,
        rule: PropTypes.string,
        nextScreen: PropTypes.string,
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldRoutingCondition.defaultProps = {
    value: {
        ruleName: "",
        description: "",
        rule: "",
        nextScreen: "",
    },
    disabled: false,
    options: [],
    onChange: () => {},
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldRoutingCondition;
