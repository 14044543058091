import React from 'react';
import { Route } from "react-router-dom";
import RequireAuthentication from 'common/auth/RequireAuthentication';
import { subRoute } from 'common/util/RouteHelpers';
import CaseSummaryListPage from 'components/case/CaseSummaryListPage';
import CasePage from 'components/case/CasePage';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

/**
 * Function component encapsulating all routes for case views
 * @returns A collection of available routes for the authenticated user
 */
const CaseRouter = ({match}) => (
    <RequireAuthentication>
        <Route path={match.url} exact component={CaseSummaryListPage} />

        <Route
            path={subRoute(match, '/:caseId/:activeItem?')}
            render={({match}) => (
                <CasePage
                    caseId={match.params.caseId}
                    activeItem={match.params.activeItem}
                />
            )}
        />

    </RequireAuthentication>
);

function getCaseListRoute(programmeId) {
    return `${getProgrammeRoute(programmeId)}/cases`;
}

function getCaseRoute(programmeId, caseId) {
    return `${getProgrammeRoute(programmeId)}/cases/${caseId}`;
}

function getHistoricReviewRoute(programmeId, caseId, reviewStepId) {
    return `${getProgrammeRoute(programmeId)}/cases/${caseId}/history/${reviewStepId}`;
}

export {
    CaseRouter as default,
    getCaseRoute,
    getHistoricReviewRoute,
    getCaseListRoute,
};