import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form, Table } from 'semantic-ui-react';
import autoBind from 'common/util/AutoBind';
import LoaderIcon from 'common/LoaderIcon';
import ShowWithCustomerEditPermission from 'components/customer/CustomerHelpers';

const EditableInterestedParty = ({onSaved, onCancel}) => {

    const [partyName, setPartyName] = useState("");
    const [relationshipType, setRelationshipType] = useState("");

    const relationshipOptions = [
        {key: "poa", value: "Power of Attorney", text: "Power of Attorney"},
        {key: "fm", value: "Family Member", text: "Family Member"},
        {key: "ac", value: "Alternate Contact", text: "Alternate Contact"},
    ];

    const onSave = (e) => {
        onSaved({
            name: partyName,
            relationship: relationshipType,
        });
    }

    const finishButtonEnabled = partyName && relationshipType;
    return (
        <Table.Row>
           <Table.Cell width={12}>
               <Form.Input
                   fluid
                   labelPosition='left'
                   placeholder='Party name or details'
                   onChange={(n, v) => setPartyName(v.value)}
                   value={partyName}
               />
           </Table.Cell>
            <Table.Cell width={4}>
                <Dropdown
                    selection
                    fluid
                    placeholder={'Party Relationship'}
                    onChange={(e, data) => setRelationshipType(data.value)}
                    options={relationshipOptions}
                    value={relationshipType}
                />
            </Table.Cell>
            <Table.Cell>
                <Button.Group fluid>
                    <Button
                        positive
                        icon="check"
                        disabled={!finishButtonEnabled}
                        onClick={onSave}
                    />
                    <Button icon="cancel" onClick={onCancel} />
                </Button.Group>
            </Table.Cell>
        </Table.Row>
    );
}

class InterestedParties extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            loading: false,
            isAdding: false,
        }
    }

    onCancelAdd() {
        this.setState({isAdding: false});
    }

    onAddClicked() {
        this.setState({isAdding: true});
    }

    onSave(data) {
        this.setState({isAdding: false});
        this.props.onSave(data);
    }

    renderPartyRow(party) {
        return (
        <Table.Row key={party.partyId}>
            <Table.Cell>{party.name}</Table.Cell>
            <Table.Cell>{party.relationship}</Table.Cell>
            {this.state.isAdding && <Table.Cell />}
        </Table.Row>
        );
    }

    render() {

        if (this.state.loading) {
            return (
                <LoaderIcon icon="user" loading={this.state.loading} />
            )
        }

        return (
            <Table attached={this.props.attached} celled striped unstackable>
                {this.props.parties.length > 0 &&
                 <Table.Header>
                     <Table.Row>
                         <Table.HeaderCell width={12} textAlign="left">Name / Details</Table.HeaderCell>
                         <Table.HeaderCell width={4} textAlign="center">Relationship</Table.HeaderCell>
                         {this.state.isAdding && <Table.HeaderCell />}
                     </Table.Row>
                 </Table.Header>
                }
                <Table.Body>
                    {this.props.parties.map(this.renderPartyRow)}
                    {(this.props.parties.length === 0 && !this.state.isAdding) &&
                     <Table.Row>
                         <Table.Cell textAlign="center" colSpan={3}>There are no parties</Table.Cell>
                     </Table.Row>
                    }
                    {this.state.isAdding &&
                     <EditableInterestedParty onSaved={this.onSave} onCancel={this.onCancelAdd} />
                    }
                    {!this.props.readOnly && !this.state.isAdding &&
                     <ShowWithCustomerEditPermission>
                         <Table.Row>
                             <Table.Cell textAlign="center" colSpan={3}>
                                 <Button
                                     primary
                                     onClick={this.onAddClicked}
                                     labelPosition={"left"}
                                     icon={'plus'}
                                     content='Add Interested Party'
                                 />
                             </Table.Cell>
                         </Table.Row>
                     </ShowWithCustomerEditPermission>
                    }
                </Table.Body>
            </Table>
        )
    }

}

InterestedParties.propTypes = {
    parties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSave: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    attached: PropTypes.any,
};

InterestedParties.defaultProps = {
    readOnly: false,
    attached: false,
}

export default InterestedParties;