/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
// import { ActionButton, ButtonBar } from 'common/components/Button';
// import { ActionType } from 'common/enum/Enums';
// import * as FormField from 'common/form/FormField';
import { Form } from 'semantic-ui-react'

class Multifield extends React.Component {

    static getNonNullFields(fields) {
        return fields.filter(item => item !== null);
    }

    constructor(props) {
        super(props);
        bindClassMethods(this);

        let value = props.value;
        if (props.allowMultipleFields) {
            if (isNil(value)) {
                value = [];
            } else if (props.value.constructor !== Array) {
                value = [props.value];
            } else {
                value = Multifield.getNonNullFields(value);
            }
        }

        this.state = {
            value: value,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let value = nextProps.value;
        if (nextProps.allowMultipleFields) {
            if (isNil(value)) {
                value = [];
            } else {
                value = Multifield.getNonNullFields(value);
            }
        }

        return {
            value: value,
        };
    }

    onFieldChanged(fieldName, fieldValue, index) {
        const newValue = cloneDeep(this.state.value);
        newValue[index] = fieldValue;
        this.props.onChange(this.props.name, Multifield.getNonNullFields(newValue));
    }

    getSingleErrorText() {
        if (this.state.value.length === 0) {
            if (!Array.isArray(this.props.error)) {
                return this.props.error;
            }
        }
        return undefined;
    }

    getMultiErrorText(index) {
        if (Array.isArray(this.props.error)) {
            return this.props.error[index];
        }
        return this.props.error;
    }

    isMoreFieldsAllowed() {
        let allowMoreFields;
        if (this.props.maxFields === 0) {
            allowMoreFields = true;
        } else {
            allowMoreFields = this.state.value.length < this.props.maxFields;
        }
        return allowMoreFields;
    }

    addField() {
        const newValue = cloneDeep(this.state.value).concat([undefined]);
        this.setState({
            value: newValue,
        });
        this.props.onChange(this.props.name, Multifield.getNonNullFields(newValue));
    }

    removeField(index) {
        const newValue = cloneDeep(this.state.value);
        newValue.splice(index, 1);
        this.setState({
            value: newValue,
        });
        this.props.onChange(this.props.name, Multifield.getNonNullFields(newValue));
    }

    validateMultipleFields(errors) {
        const foundErrorArray = this.props.value.map(fieldValue => this.props.validate(fieldValue));
        if (foundErrorArray.find(item => !isNil(item))) {
            errors = errors.set(this.props.name, foundErrorArray);
        }
        return errors;
    }

    validateSingleField(errors) {
        const foundError = this.props.validate(this.props.value);
        if (isNil(foundError)) {
            errors = errors.set(this.props.name, foundError);
        }
        return errors;
    }

    validate(errors) {
        if (typeof this.props.validate === 'function') {
            if (this.props.allowMultipleFields && Array.isArray(this.props.value)) {
                return this.validateMultipleFields(errors);
            }
            return this.validateSingleField(errors);
        }
        return errors;
    }

    renderSingleField() {
        const Field = this.props.fieldControl;
        const FormField = this.props.theme.formField;
        return (
            <FormField
                {...this.props}
                propertyName={this.props.name}
                error={this.props.error}
            >
                <Field {...this.props} />
            </FormField>
        );
    }

    render() {
        if (!this.props.allowMultipleFields) {
            return this.renderSingleField();
        }

        const Field = this.props.fieldControl;
        const FormField = this.props.theme.formField;
        const FormButton = this.props.theme.formButton;
        return (
            <div>
                {this.state.value.map((data, index) => {
                    const key = index;
                    return (
                        <FormField
                            key={key}
                            label={index === 0 ? this.props.label : ''}
                            required={index === 0 ? this.props.required : false}
                            propertyName={`${this.props.name}[${index}]`}
                            error={this.getMultiErrorText(index)}
                        >
                            <Form.Group inline={this.props.inline}>
                                <Field
                                    {...this.props}
                                    label={index === 0 ? this.props.label : ''}
                                    value={this.state.value[index]}
                                    onChange={(fieldName, fieldValue) => this.onFieldChanged(fieldName, fieldValue, index)}
                                />
                                {/*<ButtonBar tools>*/}
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FormButton
                                        // action={ActionType.Delete}
                                        // label="Remove"
                                        onClick={() => this.removeField(index)}
                                    >Remove</FormButton>
                                </div>
                                {/*</ButtonBar>*/}
                            </Form.Group>
                        </FormField>
                    );
                })}
                {this.isMoreFieldsAllowed() &&
                    <FormField
                        label={this.state.value.length === 0 ? this.props.label : ''}
                        propertyName={this.props.name}
                        error={this.getSingleErrorText()}
                        required={this.state.value.length === 0 ? this.props.required : undefined}
                    >
                        <FormButton
                            // label="Add"
                            // action={ActionType.Add}
                            // actionableType={this.props.label}
                            onClick={this.addField}
                            // showText
                        >Add</FormButton>
                    </FormField>
                }
            </div>
        );
    }
}

Multifield.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    fieldControl: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.shape(),
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    required: PropTypes.bool,
    onChange: PropTypes.func,
    maxFields: PropTypes.number,
    allowMultipleFields: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    disabled: PropTypes.bool,
    validate: PropTypes.func,
    theme: PropTypes.shape({
        formField: PropTypes.elementType.isRequired,
        formButton: PropTypes.elementType.isRequired,
    }).isRequired,
    inline: PropTypes.bool,
};

Multifield.defaultProps = {
    maxFields: 0,
    allowMultipleFields: false,
    value: undefined,
    onChange: () => {},
    required: false,
    error: undefined,
    disabled: false,
    validate: undefined,
    inline: true
};

export default Multifield;