import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

class ConfirmModal extends Component {
    state = { open: this.props.open }
    basic = !this.props.lightMode;

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })
    onClick = () => {
        this.close();
        this.props.onConfirm()
    }

    render() {
        return (
            <React.Fragment>
                {this.props.buttonText && <Button onClick={this.open}>{this.props.buttonText}</Button>}
                <Modal open={this.state.open} basic={this.basic} size='small' closeOnEscape={true} onClose={this.close}>
                    <Header icon='archive' content={this.props.title}/>
                    <Modal.Content>
                        {this.props.message}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic={this.basic} color='red' inverted={this.basic} onClick={this.close}>
                            <Icon name='remove'/> No
                        </Button>
                        <Button color='green' inverted={this.basic} onClick={this.onClick}>
                            <Icon name='checkmark'/> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}

ConfirmModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.node.isRequired,
    open: PropTypes.bool,
    buttonText: PropTypes.string,
    lightMode: PropTypes.bool,
};

ConfirmModal.defaultProps = {
    open: false,
    buttonText: undefined,
    lightMode: false,
};

export default ConfirmModal;