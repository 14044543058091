import React, { useState } from 'react';
import LoaderIcon from 'common/LoaderIcon';
import { Icon, Segment } from 'semantic-ui-react';
import { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import Authority from 'common/auth/Authority';
import CustomerForm from 'components/customer/CustomerForm';
import PageHeader from 'components/common/PageHeader';
import { useHistory } from 'react-router-dom';
import AlertMessage, { AlertLevel } from 'components/common/AlertMessage';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import withProgramme from 'components/programme/ProgrammeProps';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

const CreateCustomerPage = ({programme}) => {
    const history = useHistory();
    const [error, setError] = useState(null);

    const getBreadcrumbs = () => {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Customers', content: 'Customers'},
            {key: 'New', content: 'New'},
        ];
    };

    const onCancel = () => {
        setError(null);
        history.push(`${getProgrammeRoute(programme.id)}/customers`);
    };

    const onCustomerChanged = (customerId) => {
        setError(null);
        history.push(`${getProgrammeRoute(programme.id)}/customers/${customerId}`);
    };

    return (
        <>
            <PageHeader
                text="Create Customer"
                icon={<LoaderIcon icon="users" />}
                breadcrumbs={getBreadcrumbs()}
            />
            {error && <AlertMessage
                level={AlertLevel.Error}
                heading={"Failed to create customer"}
                content={error.message}
            />
            }
            <Segment attached="bottom">
                <ShowWithOneOfPermission permissions={[Authority.AddCustomer]}>
                    <CustomerForm
                        onCancel={onCancel}
                        onCustomerChanged={onCustomerChanged}
                        onError={setError}
                    />
                </ShowWithOneOfPermission>
            </Segment>
        </>
    );
};

CreateCustomerPage.propTypes = {};

CreateCustomerPage.defaultProps = {};

export default withProgramme(CreateCustomerPage);
