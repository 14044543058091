import React, { Component } from "react";
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Container, Icon, Input, Message, Pagination, Segment, Table } from 'semantic-ui-react';
import Api from 'api/Api';
import PageHeader from 'components/common/PageHeader';
import { Link } from 'react-router-dom';
import LoaderIcon from 'common/LoaderIcon';
import { getCustomerCasesRoute, getCustomerRoute } from 'components/customer/CustomerRouter';
import { pascalCaseWithSpaces } from 'common/util/StringHelpers';
import 'components/customer/CustomerListPage.scss';
import Authority from 'common/auth/Authority';
import { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import CaseStatus from 'components/case/CaseStatus';
import ConfirmCustomerDelete from 'components/customer/ConfirmCustomerDelete';
import CustomerPopup from 'components/customer/CustomerPopup';
import { getSortByFromUrlValue, getSortByUrlQueryValue, getUrlQueryParams } from 'common/util/UrlUtils';
import SortableHeader from 'components/common/SortableHeader';
import cloneDeep from 'lodash/cloneDeep';
import { CaseStateFilters } from 'components/case/withCaseQueryParams';
import { customerFields } from 'common/util/MatchFields';
import withProgramme from 'components/programme/ProgrammeProps';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import { getProgrammeIdFromCurrentPath, getProgrammeRoute } from 'components/programme/ProgrammeRouter';

const PAGE_URL_PARAMETER = "page";
const SORT_BY_URL_PARAMETER = "sort";
const DEFAULT_PAGE_SIZE = 10;

const SEARCH_KEYBOARD_DEBOUNCE = 300;
const CASE_STATE_COLUMNS = [
    CaseStatus.NotStarted,
    CaseStatus.Active,
    CaseStatus.OnHold,
    CaseStatus.Closed,
];
const MATCH_FIELDS = [
    customerFields.REFERENCE_NUMBER,
    customerFields.TITLE,
    customerFields.FIRST_NAME,
    customerFields.LAST_NAME,
];

/**
 * Display a customer as an item in a list
 * @param props Contains the customer data to display
 * @returns {*}
 */
const CustomerListItem = (props) => {
    const customer = props.customer;
    const programmeId = props.programmeId;
    return (
        <Table.Row>
            <Table.Cell className="crn">
                <Link to={getCustomerRoute(programmeId, customer.customerId)}>
                    {customer.referenceNumber}
                </Link>
            </Table.Cell>
            <Table.Cell>{customer.title}</Table.Cell>
            <Table.Cell>{customer.firstName}</Table.Cell>
            <Table.Cell>{customer.lastName}</Table.Cell>
            <Table.Cell textAlign="center" width={1}>
                {customer.deceased && <Icon name="check" />}
            </Table.Cell>
            <Table.Cell textAlign="center">
                {customer.vulnerable && <Icon name="check" />}
            </Table.Cell>
            <Table.Cell textAlign="center">
                {customer.disability && <Icon name="check" />}
            </Table.Cell>
            <Table.Cell textAlign="right">
                <Link to={getCustomerCasesRoute(programmeId, customer.customerId, CaseStateFilters.NotStarted)}>
                    {customer.statusCounts[CaseStatus.NotStarted] || 0}
                </Link>
            </Table.Cell>
            <Table.Cell textAlign="right">
                <Link to={getCustomerCasesRoute(programmeId, customer.customerId, CaseStateFilters.Active)}>
                    {customer.statusCounts[CaseStatus.Active] || 0}
                </Link>
            </Table.Cell>
            <Table.Cell textAlign="right">
                <Link to={getCustomerCasesRoute(programmeId, customer.customerId, CaseStateFilters.OnHold)}>
                    {customer.statusCounts[CaseStatus.OnHold] || 0}
                </Link>
            </Table.Cell>
            <Table.Cell textAlign="right">
                <Link to={getCustomerCasesRoute(programmeId, customer.customerId, CaseStateFilters.Closed)}>
                    {customer.statusCounts[CaseStatus.Closed] || 0}
                </Link>
            </Table.Cell>
            <Table.Cell textAlign="right">
                <Link to={getCustomerCasesRoute(programmeId, customer.customerId, CaseStateFilters.All)}>
                    {customer.statusCounts["Total"] || 0}
                </Link>
            </Table.Cell>
            {props.showPopup &&
                <Table.Cell width={1} textAlign="right">
                    <CustomerPopup
                        customer={customer}
                        onDelete={() => props.onDelete(customer.customerId)}
                        onView={() => props.onView(customer.customerId)}
                    />
                </Table.Cell>
            }
        </Table.Row>
    );
};

CustomerListItem.propTypes = {
    customer: PropTypes.shape().isRequired,
    onDelete: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
    showPopup: PropTypes.bool,
    programmeId: PropTypes.string,
};

CustomerListItem.defaultProps = {
    showPopup: false,
};

/**
 * This class implements a component that retrieves registered customers and
 * displays them as items in list.
 */
class CustomerListPage extends Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            customers: [],
            loading: false,
            searchQuery: '',
            deletingCustomerId: undefined,
            deleteError: null,
            paginationState: {
                activePage: 1,
                boundaryRange: 3,
                totalPages: 0,
                firstItem: null,
                lastItem: null,
            },
        };
        this.onSearchInputChanged = debounce(this.onSearchInputChanged, SEARCH_KEYBOARD_DEBOUNCE);
        this.showActions = false;
    }

    componentDidMount() {
        this.getCustomers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {
            this.getCustomers();
        }
    }

    getCustomers() {
        this.setState({loading: true});
        const queryParams = this.getQueryParams();
        Api.get(`/api/customers?${queryParams.toString()}&matchOn=${MATCH_FIELDS.join(',')}`)
            .then(this.setCustomers)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setCustomers(data) {
        this.setPagination(data.current, data.total);
        this.setState({customers: data.entries});
        this.clearErrors();
    }

    setPagination(current, total) {
        this.setState({
            paginationState: {
                ...this.state.paginationState,
                activePage: current,
                totalPages: total,
            },
        });
    }

    getQueryParams() {
        return getUrlQueryParams(this.parseSortFromUrl(), this.getFilterFromUrl());
    }

    getDataFromUrl(queryParameter, defaultValue) {
        const query = new URLSearchParams(this.props.location.search);
        let value = query.get(queryParameter);
        if (!value) {
            value = defaultValue;
        }
        return value;
    }

    getPageFromUrl() {
        return this.getDataFromUrl(PAGE_URL_PARAMETER, 1);
    }

    getSortFromUrl() {
        return this.getDataFromUrl(SORT_BY_URL_PARAMETER, undefined);
    }

    parseSortFromUrl() {
        const sortValue = this.getSortFromUrl();
        return getSortByFromUrlValue(sortValue);
    }

    getFilterFromUrl() {
        const query = {
            size: DEFAULT_PAGE_SIZE,
        };

        const page = this.getPageFromUrl();
        if (page) {
            query.page = page;
        }

        if (this.state.searchQuery) {
            query.query = this.state.searchQuery;
        }

        return query;
    }

    onSortUpdate(sortState) {
        const sort = getSortByUrlQueryValue(sortState);
        this.setUrl(1, sort);
    }

    setUrl(page, sort) {
        const searchParams = new URLSearchParams();
        searchParams.set(PAGE_URL_PARAMETER, page);
        if (sort) {
            searchParams.set(SORT_BY_URL_PARAMETER, sort);
        }
        this.props.history.push(`${this.props.location.pathname}?${searchParams.toString()}`);
    }

    handlePaginationChange(event, {activePage}) {
        const sort = this.getSortFromUrl();
        this.setUrl(activePage, sort);
    }

    onSearch(data) {
        this.setState({searchQuery: data.value}, this.getCustomers);
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Customers', content: 'Customers'},
        ];
    }

    onDeleteCustomer(customerId) {
        this.setState({deletingCustomerId: customerId});
    }

    onViewCustomer(customerId) {
        this.props.history.push(getCustomerRoute(getProgrammeIdFromCurrentPath(), customerId));
    }

    clearErrors() {
        this.setState({deleteError: null});
    }

    onDeleteError(error) {
        this.setState({deleteError: error});
    }

    deleteCustomer(customerId) {
        Api.delete(`/api/customers/${customerId}`)
            .then(() => this.removeCustomerFromList(customerId))
            .catch(this.onDeleteError)
            .finally(this.closeConfirmDialog);
    }

    removeCustomerFromList(customerId) {
        const newCustomerList = cloneDeep(this.state.customers)
            .filter(item => item.customerId !== customerId);
        this.setState({customers: newCustomerList});
        this.clearErrors();
    }

    closeConfirmDialog() {
        this.setState({deletingCustomerId: undefined});
    }

    onSearchInputChanged(event, data) {
        this.onSearch(data);
    }

    render() {
        const sortState = this.parseSortFromUrl();
        return (
            <>
                <ConfirmCustomerDelete
                    open={!isNil(this.state.deletingCustomerId)}
                    onConfirm={() => this.deleteCustomer(this.state.deletingCustomerId)}
                    onCancel={this.closeConfirmDialog}
                />
                <PageHeader
                    text="Customers"
                    icon={<LoaderIcon icon="users" onClick={this.getCustomers} loading={this.state.loading} />}
                    headerRightComponent={(<>
                        <Input
                            size="mini"
                            icon="search"
                            style={{marginRight: '10px'}}
                            placeholder="Search..."
                            onChange={this.onSearchInputChanged}
                        />
                        <ShowWithOneOfPermission permissions={[Authority.AddCustomer]}>
                            <Button
                                onClick={() => this.props.history.push(`${getProgrammeRoute(this.props.programme.id)}/customers/new`)}
                                compact
                                primary
                                size="tiny"
                            >Add Customer</Button>
                        </ShowWithOneOfPermission>
                    </>)}
                    breadcrumbs={this.getBreadcrumbs()}
                />
                {this.state.deleteError &&
                    <Message negative>
                        <Message.Header>There was an error deleting the customer</Message.Header>
                        <p>{this.state.deleteError}</p>
                    </Message>
                }
                <div>
                    <Table
                        compact
                        attached={this.state.customers.length === 0 ? true : "bottom"}
                        celled
                        selectable
                        unstackable
                        structured
                        className="customer-table"
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={4} />
                                <Table.HeaderCell className="span" colSpan={3}>Customer Attributes</Table.HeaderCell>
                                <Table.HeaderCell className="span" colSpan={5}>Case Status</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <SortableHeader
                                    className="crn"
                                    sortKey="referenceNumber"
                                    sortState={sortState}
                                    onSortUpdate={this.onSortUpdate}
                                    text="Customer Number"
                                    verticalAlign="top"
                                />
                                <Table.HeaderCell
                                    className="title"
                                    content="Title"
                                    verticalAlign="top"
                                />
                                <SortableHeader
                                    className="firstName"
                                    sortKey="firstName"
                                    sortState={sortState}
                                    onSortUpdate={this.onSortUpdate}
                                    text="First Name"
                                    verticalAlign="top"
                                />
                                <SortableHeader
                                    className="lastName"
                                    sortKey="lastName"
                                    sortState={sortState}
                                    onSortUpdate={this.onSortUpdate}
                                    text="Surname"
                                    verticalAlign="top"
                                />
                                <SortableHeader
                                    className="deceased"
                                    sortKey="deceased"
                                    sortState={sortState}
                                    onSortUpdate={this.onSortUpdate}
                                    text="Deceased"
                                    reverseSort
                                    verticalAlign="top"
                                />
                                <SortableHeader
                                    className="vulnerable"
                                    sortKey="vulnerable"
                                    sortState={sortState}
                                    onSortUpdate={this.onSortUpdate}
                                    text="Vulnerable"
                                    reverseSort
                                    verticalAlign="top"
                                />
                                <SortableHeader
                                    className="disability"
                                    sortKey="disability"
                                    sortState={sortState}
                                    onSortUpdate={this.onSortUpdate}
                                    text="Disability"
                                    reverseSort
                                    verticalAlign="top"
                                />
                                {CASE_STATE_COLUMNS.map(state => (
                                    <SortableHeader
                                        key={state}
                                        className="case-state"
                                        sortKey={`caseSummary.${state}`}
                                        sortState={sortState}
                                        onSortUpdate={this.onSortUpdate}
                                        text={pascalCaseWithSpaces(state)}
                                        reverseSort
                                        verticalAlign="top"
                                    />
                                ))}
                                <SortableHeader
                                    key={"total"}
                                    className="case-state"
                                    sortKey={"totalCases"}
                                    sortState={sortState}
                                    onSortUpdate={this.onSortUpdate}
                                    text={"Total"}
                                    reverseSort
                                    verticalAlign="top"
                                />
                                {this.showActions &&
                                    <Table.HeaderCell verticalAlign="top" textAlign="right">
                                        Action
                                    </Table.HeaderCell>
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.customers.map(customer => (
                                <CustomerListItem
                                    key={customer.customerId}
                                    customer={customer}
                                    onDelete={this.onDeleteCustomer}
                                    onView={this.onViewCustomer}
                                    showPopup={this.showActions}
                                    programmeId={this.props.programme.id}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                    {((this.state.customers.length === 0) && (!this.state.loading)) &&
                        <Segment attached="bottom">
                            There are no customers you are authorised to view
                        </Segment>
                    }
                    {(this.state.customers.length > 0) &&
                        <Container textAlign="center">
                            <Pagination onPageChange={this.handlePaginationChange} {...this.state.paginationState} />
                        </Container>
                    }
                </div>
            </>
        );
    }
}

export default withProgramme(CustomerListPage);
