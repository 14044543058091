import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Header, Table } from 'semantic-ui-react';
import LinkDocumentsModal from 'components/case/LinkDocumentsModal';
import MediaViewer from 'common/MediaViewer';
import UploadDocumentsModal from 'components/case/UploadDocumentsModal';
import Authority from 'common/auth/Authority';
import ShowWithPermission, { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import Api from 'api/Api';
import ConfirmDelete from 'components/common/ConfirmDelete';

class StagingRecordLinks extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            name: 'StagingRecordLinks',
            linkDocumentsModalVisible: false,
            uploadFileModalVisible: false,
            deleteFileVisible: false,
            deletingFileId: '',
        };
    }

    onLinkDocuments(type, documents) {
        this.props.onLinkDocuments(type, documents);
        this.setState({
            linkDocumentsModalVisible: false,
        });
    }

    getDownloadButton(linkId) {
        return <Button
            compact
            size="mini"
            target="_blank"
            download
            href={`/api/reviews/${this.props.taskId}/files/${linkId}/download`}
            icon="download"
        />
    }

    openDeleteModal(linkId) {
        this.setState({
            deleteFileVisible: true,
            deletingFileId: linkId,
        })
    }

    onDeleteConfirm() {
        this.unlinkFile(this.state.deletingFileId)
        this.setState({
            deleteFileVisible: false,
        })
    }

    onDeleteCancel() {
        this.setState({
            deletingFile: '',
            deleteFileVisible: false,
        })
    }

    getViewRecordButton(linkId) {
        return <Button
            compact
            size="mini"
            content="View Record"
            target="_blank"
            basic
            color="blue"
            labelPosition="right"
            icon="arrow right"
        />
    }

    getLinkedFileTuple(link, index) {
        return (
            <Table.Row key={`${index}-${link.id}`}>
                <Table.Cell style={{width: '100px'}} textAlign="center">
                    <MediaViewer {...link.previewDetails} showThumbnail={false} />
                </Table.Cell>
                <Table.Cell>{link.title}</Table.Cell>
                <Table.Cell textAlign="right">
                    {this.getDownloadButton(link.storageFileId)}
                    <ShowWithPermission permission={Authority.EditCase}>
                        <Button
                            compact
                            size="mini"
                            icon="trash"
                            onClick={() => this.openDeleteModal(link.id)}
                        />
                    </ShowWithPermission>
                </Table.Cell>
            </Table.Row>
        );
    }

    getLinkedRecordTuple(link, index) {
        return (
            <Table.Row key={`${index}-${link.id}`}>
                <Table.Cell />
                <Table.Cell>{link.title}</Table.Cell>
                <Table.Cell textAlign="right">
                    {this.getViewRecordButton(link.id)}
                </Table.Cell>
            </Table.Row>
        );
    }

    linkFiles(files) {
        const data = {
            stagedFileIds: files.map(file => {
                return file.stagedFileId;
            }),
        };
        Api.put(`/api/reviews/${this.props.taskId}/link`, data)
            .then(() => this.props.onLinkDocuments("files", files))
            .catch(this.props.onError)
    }

    unlinkFile(fileId) {
        const data = {
            stagedFileIds: [fileId],
        }
        Api.put(`/api/reviews/${this.props.taskId}/unlink`, data)
            .then(() => this.props.onUnlinkDocument("files", fileId))
            .catch(this.props.onError)
    }

    render() {

        return (
            <>
                <LinkDocumentsModal
                    onClose={() => this.setState({linkDocumentsModalVisible: false})}
                    visible={this.state.linkDocumentsModalVisible}
                    caseId={this.props.caseId}
                    taskId={this.props.taskId}
                    onLinkDocuments={this.onLinkDocuments}
                />
                <ShowWithOneOfPermission permissions={[Authority.UploadDocuments, Authority.EditCase]}>
                    <UploadDocumentsModal
                        onClose={() => this.setState({uploadFileModalVisible: false})}
                        visible={this.state.uploadFileModalVisible}
                        taskId={this.props.taskId}
                        onUploadDocuments={this.linkFiles}
                        onDeleteDocument={this.unlinkFile}
                    />
                </ShowWithOneOfPermission>
                <ConfirmDelete
                    open={this.state.deleteFileVisible}
                    onConfirm={this.onDeleteConfirm}
                    onCancel={this.onDeleteCancel}
                    title="Delete File?"
                    content={"Are you sure you wish to remove this file from this case?"}
                />
                <Header as="h4" attached="top" block>
                    Supporting Documents
                </Header>
                <Table attached unstackable>
                    <Table.Body>
                        {this.props.allowNew &&
                        <Table.Row>
                            <Table.Cell colSpan="3" textAlign="center">
                                <Button
                                    content="Link Customer Documents"
                                    icon="linkify"
                                    primary
                                    onClick={() => this.setState({linkDocumentsModalVisible: true})}
                                />
                                <ShowWithOneOfPermission permissions={[Authority.UploadDocuments, Authority.EditCase]}>
                                    <Button
                                        content="Upload Stage Documents"
                                        icon="upload"
                                        primary
                                        onClick={() => this.setState({uploadFileModalVisible: true})}
                                    />
                                </ShowWithOneOfPermission>
                            </Table.Cell>
                        </Table.Row>}
                        {this.props.linkedFiles.map((link, i) => (
                            this.getLinkedFileTuple(link, i)
                        ))}
                        {this.props.unsavedLinkedFiles.map((link, i) => (
                            this.getLinkedFileTuple(link, i)
                        ))}
                        {this.props.linkedRecords.map((link, i) => (
                            this.getLinkedRecordTuple(link, i)
                        ))}
                        {this.props.unsavedLinkedRecords.map((link, i) => (
                            this.getLinkedRecordTuple(link, i)
                        ))}
                    </Table.Body>
                </Table>
            </>
        );
    }
}

StagingRecordLinks.propTypes = {
    linkedFiles: PropTypes.arrayOf(PropTypes.shape()),
    unsavedLinkedFiles: PropTypes.arrayOf(PropTypes.shape()),
    linkedRecords: PropTypes.arrayOf(PropTypes.shape()),
    unsavedLinkedRecords: PropTypes.arrayOf(PropTypes.shape()),
    allowNew: PropTypes.bool,
    caseId: PropTypes.string.isRequired,
    taskId: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    reviewStageId: PropTypes.string.isRequired,
    onLinkDocuments: PropTypes.func.isRequired,
    onUnlinkDocument: PropTypes.func.isRequired,
};

StagingRecordLinks.defaultProps = {
    allowNew: false,
    linkedFiles: [],
    unsavedLinkedFiles: [],
    linkedRecords: [],
    unsavedLinkedRecords: [],
};

export default StagingRecordLinks;