import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledTextArea from 'common/StyledTextArea';
import { executeDefaultValidators } from 'external/form/Validators';

const FieldRichText = ({name, value, onChange, disabled, placeHolder, registerValidator, deregisterValidator}) => {
    useEffect(() => {
        registerValidator(name, executeDefaultValidators);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);
    const valueChanged = (text) => {
        onChange(name, text);
    };
    return (
        <StyledTextArea
            onChange={valueChanged}
            value={value}
            disabled={disabled}
            placeHolder={placeHolder}
            readonly={disabled}
        />
    );
};

FieldRichText.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldRichText.defaultProps = {
    value: undefined,
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldRichText;
