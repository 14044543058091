import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from './common/util/AutoBind';
import { withRouter } from 'react-router-dom';
import { Loader } from "semantic-ui-react";
import isEqual from 'lodash/isEqual';
import { isNil } from 'lodash';
import Api from 'api/Api';

const UserContext = createContext({
    user: undefined,
    isLoggedIn: () => {},
    logout: () => {},
    hasPermissions: () => {},
    setContext: () => {},
});

class UserContextProvider extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            user: undefined,
            loading: true,
            retryCount: 0,
        };
    }

    componentDidMount() {
        this.getCurrentContext();
        this.intervalId = setInterval(this.getCurrentContext, 10000);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.state, nextState);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    setContext(response) {
        this.setState({
            user: {
                userId: response.userId,
                username: response.userName,
                permissions: response.permissions,
            },
            retryCount: 0,
        });
    }

    getCurrentContext = () => {
        if (!this.state.loading && !this.isLoggedIn()) {
            return;
        }

        Api.refreshSecurityContext()
            .then(response => {
                if (response) {
                    this.setContext(response);
                }
            })
            .catch((err) => {
                if (this.state.retryCount === 3) {
                    console.warn('Retry count exceeded. Logging user out');
                    this.setState({
                        retryCount: 0,
                        user: undefined,
                    });
                } else if (this.isLoggedIn()) {
                    this.setState({
                        retryCount: this.state.retryCount + 1,
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });

    };

    logout() {
        this.setState({
            user: undefined,
        });
    }

    isLoggedIn() {
        return !isNil(this.state.user);
    }

    hasPermissions(...permissions) {
        return permissions.some(p => this.state.user.permissions.includes(p));
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }

        return (
            <UserContext.Provider
                value={{
                    ...this.state,
                    isLoggedIn: this.isLoggedIn,
                    logout: this.logout,
                    hasPermissions: this.hasPermissions,
                    setContext: this.setContext,
                }}
            >
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

UserContextProvider.propTypes = {
    children: PropTypes.node,
};

UserContextProvider.defaultProps = {
    children: undefined,
};

const UserContextConsumer = UserContext.Consumer;

function withUserContextProp(Component) {
    return props => (
        <UserContextConsumer>
            {value => (
                <Component
                    userContext={value}
                    {...props}
                />
            )}
        </UserContextConsumer>
    );
}

const SomeComponent = withRouter(props => <UserContextProvider {...props} />);

export {
    SomeComponent as default,
    UserContextProvider,
    withUserContextProp,
    UserContext,
};
