import React, {useEffect, useState} from 'react';
import { Message, Icon } from 'semantic-ui-react';
import {MessageProps} from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import cloneDeep from 'lodash/cloneDeep';
import 'components/common/AlertMessage.scss';

enum AlertLevel {
    Error = 1,
    Warning,
    Information,
    Positive
}

export interface AlertMessageProps {

    /** An AlertMessage can be formatted to attach itself to other content. */
    attached?: boolean | 'bottom' | 'top'

    /** The alert to be displayed **/
    level: AlertLevel

    /** The Alert Heading **/
    heading: React.ReactNode

    /** The Alert content **/
    content?: React.ReactNode

    /** Is this alert dismissible **/
    dismissible?: boolean

    /** A function to call when the dismiss button is clicked **/
    onDismiss?: (event: React.MouseEvent<HTMLElement>, data: MessageProps) => void

    /** Additional classes. */
    className?: string
}

const AlertMessage: React.FC<AlertMessageProps> = (props) => {
    return (
        <Message
            positive={props.level === AlertLevel.Positive}
            error={props.level === AlertLevel.Error}
            warning={props.level === AlertLevel.Warning}
            info={props.level === AlertLevel.Information}
            attached={props.attached}
            onDismiss={props.onDismiss}
            className={props.className}
        >
            <Message.Content>
                <Message.Header>{props.heading}</Message.Header>
                {props.content}
            </Message.Content>
        </Message>
    );
}

interface AlertMessageListProps {

    /** The list of AlertMessages **/
    alerts: AlertMessageProps [];

    /** An AlertMessageList can be formatted so that it can be attached to. */
    attached?: boolean | 'bottom';

    /** Display the list of alerts as being attached together in a group **/
    grouped?: boolean | 'expandable'
}

function compare(alert1: AlertMessageProps, alert2: AlertMessageProps) {
    if (alert1.level < alert2.level) {
        return -1;
    }
    if (alert1.level > alert2.level) {
        return 1;
    }
    return 0;
}

const AlertMessageList: React.FC<AlertMessageListProps> = (props) => {

    const [expanded, setExpanded] = useState(true);
    const [alerts, setAlerts] = useState(props.alerts.sort(compare));
    const onExpandButtonClicked = () => {
        setExpanded(!expanded);
    }
    const onDismiss = (alertIndex: number) => {
        const clone = cloneDeep(alerts);
        clone.splice(alertIndex, 1);
        setAlerts(clone);
    }
    const iconName = expanded ? "caret down" : "caret right";

    useEffect(() => {
        setAlerts(props.alerts);
    },[props.alerts])

    return (
        <>
            {props.grouped === "expandable" && alerts.length > 0 &&
             <Message size="small" negative={true} attached={expanded ? "top" : undefined}>
                 <Message.Header>
                     <div onClick={onExpandButtonClicked}>
                         <Icon name={iconName} />{alerts.length} Alert{alerts.length > 1 && <>s</>}
                     </div>
                 </Message.Header>
             </Message>
            }
            {expanded &&
                alerts.map((alertProps, index) => {
                    let attached: AlertMessageProps["attached"] = undefined;
                    if (!props.grouped) {
                        attached = false;
                    } else if (index === 0 && (alerts.length > 1 || props.grouped)) {
                        attached = alerts.length > 1 ? true : props.grouped === "expandable" ? "bottom" : false
                    } else if (index > 0 && index < alerts.length - 1) {
                        attached = true
                    } else if (index > 0 && index === alerts.length - 1) {
                        attached = props.attached ? true : "bottom"
                    } else if (props.attached) {
                        attached = props.attached
                    }
                    return (
                        <AlertMessage
                            key={index}
                            {...alertProps}
                            attached={attached}
                            onDismiss={alertProps.dismissible ? (_e, _d) => onDismiss(index) : undefined}
                        />
                    );
                })
            }
        </>
    );
}

export {
    AlertMessage as default,
    AlertMessageList,
    AlertLevel,
};