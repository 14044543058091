import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import GenericForm from 'common/form/SemanticUIForm';
import cloneDeep from 'lodash/cloneDeep';
import { Icon, Loader, Message, Modal } from "semantic-ui-react";
import Api from 'api/Api';
import { withUserContextProp } from 'UserContext';
import SavedStateLabel, { SavedStatus } from 'components/common/SavedStateLabel';
import DeleteGroupButton from 'components/admin/DeleteGroupButton';

const getGroupFormSchema = (permissions) => {
    return {
        fields: {
            name: {
                type: 'text',
                label: 'Group Name',
                placeHolder: 'Group Name',
                required: true,
            },
            permissions: {
                type: 'multiselect',
                label: 'Permissions',
                placeHolder: 'Select Permissions',
                options: permissions,
            },
        },
        fieldOrder: [
            'name',
            'permissions',
        ],
    };
};

class GroupForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: cloneDeep(getGroupFormSchema([])),
            formData: {
                id: this.props.groupId,
                groupName: '',
                permissions: [],
            },
            loading: false,
            loadingConfig: false,
            error: undefined,
            savedState: SavedStatus.Init,
        };
    }

    componentDidMount() {
        this.getFields();

        if (this.props.groupId) {
            this.loadGroupDetails();
        }
    }

    onError(error) {
        Api.logError(error);
        this.setState({error: error});
    }

    onClose() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    getFields() {
        this.setState({loadingConfig: true});
        Api.get(`/noauth/config`)
            .then(this.setConfig)
            .catch(Api.logError)
            .finally(() => this.setState({loadingConfig: false}));
    }

    loadGroupDetails() {
        this.setState({loading: true});
        Api.get(`/api/groups/${this.props.groupId}`)
            .then(this.setGroupData)
            .catch(this.onError)
            .finally(() => this.setState({loading: false}));
    }

    setConfig(config) {
        const fieldOptions = config.permissions.map(permission => {
            return {
                id: permission.id,
                text: permission.displayName,
            };
        });

        this.setState({
            schema: cloneDeep(getGroupFormSchema(fieldOptions)),
        })
    }

    setGroupData(data) {
        this.setState({
            formData: {
                id: this.props.groupId,
                name: data.name,
                permissions: data.permissions,
            },
        })
    }

    onFormDataChanged(formData) {
        this.setState({
            formData: formData,
            savedState: SavedStatus.Unsaved,
        });
    }

    onSubmitSuccess() {
        this.setState({
            saving: false,
            savedState: SavedStatus.Saved,
        });

        if (this.props.onGroupChanged) {
            this.props.onGroupChanged();
        }
    }

    onSubmitError(error) {
        this.setState({
            saving: false,
        });
        this.onError(error);
    }

    onSubmit(formData) {
        return new Promise((resolve, reject) => {
            this.setState({
                saving: true,
            });

            if (this.props.groupId) {
                Api.put(`/api/groups/${this.props.groupId}`, formData)
                    .then(resolve)
                    .catch(err => reject("Error while trying to update group"));

            } else {
                return Api.post('/api/groups', formData)
                    .then(resolve)
                    .catch(err => reject("Error while trying to create group"));
            }
        })
    }

    getHeader() {
        let title;
        if (this.props.groupId) {
            title = `Edit '${this.state.formData.name}'`;
        } else {
            title = 'Add Group';
        }
        return <>
            {title}
            <SavedStateLabel status={this.state.savedState} />
        </>;
    }

    onDeleteSuccess() {
        this.props.onGroupChanged();
        this.onClose();
    }

    render() {
        if (this.state.loading || this.state.loadingConfig) {
            return <Loader active />;
        }
        return (
            <>
                <Modal
                    onClose={this.onClose}
                    open={this.props.open}
                    centered={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>
                        <Icon name='users' />
                        {this.getHeader()}
                    </Modal.Header>
                    <Modal.Content>
                        {this.state.error &&
                        <Message negative>
                            <Message.Header>Could not update group</Message.Header>
                            <p>{this.state.error}</p>
                        </Message>
                        }
                        <GenericForm
                            schema={this.state.schema}
                            onFormDataChanged={this.onFormDataChanged}
                            formData={this.state.formData}
                            navigationPrompt={false}
                            showButtons={true}
                            styleClass="left-align-fields"
                            onSubmit={this.onSubmit}
                            onSubmitSuccess={this.onSubmitSuccess}
                            onSubmitError={this.onSubmitError}
                            onCancel={this.onClose}
                            submitButtonDisabled={this.state.saving}
                        />
                        <div style={{paddingTop: '0.5em'}}>
                            {this.props.groupId && <DeleteGroupButton
                                groupId={this.props.groupId}
                                setError={this.onError}
                                onDeleteSuccess={this.onDeleteSuccess}
                            />}
                        </div>
                    </Modal.Content>
                </Modal>
            </>
        );
    }

}

GroupForm.propTypes = {
    onGroupChanged: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    userContext: PropTypes.shape({permissions: PropTypes.arrayOf(PropTypes.string).isRequired}).isRequired,
    groupId: PropTypes.string,
    open: PropTypes.bool,
};

GroupForm.defaultProps = {
    groupId: undefined,
    open: false,
}

export default withUserContextProp(GroupForm);