import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Checkbox, Container, Loader, Pagination, Table } from "semantic-ui-react";
import Api from 'api/Api';
import { ISOStringToFriendlyDateTime } from 'common/util/DateUtils';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import { getHistoricReviewRoute } from 'components/case/CaseRouter';
import { Link } from 'react-router-dom';
import ReviewStageFixedHeader from 'components/case/reviews/ReviewStageFixedHeader';
import cloneDeep from 'lodash/cloneDeep';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const DEFAULT_PAGE_SIZE = 10;

class CaseHistory extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            events: [],
            loading: false,
            filterNonReviewSteps: true,
            paginationState: {
                activePage: 1,
                boundaryRange: 2,
                totalPages: 0,
                firstItem: null,
                lastItem: null,
            },
        };
        this.ref = createRef();
    }

    componentDidMount() {
        this.getCaseHistory();
    }

    handlePaginationChange(e, {activePage}) {
        if (activePage && !isNaN(+activePage)) {
            const paginationState = cloneDeep(this.state.paginationState);
            paginationState.activePage = activePage;
            this.setState(
                {paginationState},
                this.getCaseHistory,
            );
        }
    }

    getCaseHistory() {
        this.setState({loading: true});
        const queryData = {
            page: this.state.paginationState.activePage,
            size: DEFAULT_PAGE_SIZE,
            filterNonReview: this.state.filterNonReviewSteps,
        };
        const queryString = new URLSearchParams(queryData);

        Api.get(`/api/cases/${this.props.caseId}/history?${queryString}`)
            .then(this.setCaseHistory)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setCaseHistory(data) {
        this.setPagination(data.total);
        this.setState({events: data.entries});
    }

    setPagination(total) {
        this.setState(
            {
                paginationState: {
                    ...this.state.paginationState,
                    totalPages: total,
                },
            },
        );
    }

    toggleFilter() {
        this.setState({
            filterNonReviewSteps: !this.state.filterNonReviewSteps,
            paginationState: {
                ...this.state.paginationState,
                activePage: 1,
            },
        }, this.getCaseHistory);
    }

    render() {

        return (
            <div ref={this.ref}>
                <ReviewStageFixedHeader reviewId={this.props.taskId} contextRef={this.ref} showHeader={false} />
                <Segment attached={'top'} textAlign={'left'}>
                    <Checkbox
                        checked={this.state.filterNonReviewSteps}
                        onClick={this.toggleFilter}
                        label="Filter out non-review steps"
                    />
                </Segment>
                <Loader active={this.state.loading} />
                <Table attached="bottom">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.events.map(event => (
                            <Table.Row key={`event-${event.historyId}`}>
                                <Table.Cell width={3}>{ISOStringToFriendlyDateTime(event.timeOfEvent)}</Table.Cell>
                                <Table.Cell>{event.userName}</Table.Cell>
                                <Table.Cell>
                                    {event.reviewStepId &&
                                        <Link
                                            to={getHistoricReviewRoute(
                                                getProgrammeIdFromCurrentPath(),
                                                this.props.caseId,
                                                event.reviewStepId,
                                            )}
                                        >
                                            {event.description}
                                        </Link>
                                    }
                                    {!event.reviewStepId && event.description}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Container textAlign="center">
                    <Pagination onPageChange={this.handlePaginationChange} {...this.state.paginationState} />
                </Container>
            </div>
        );
    }
}

CaseHistory.propTypes = {
    caseId: PropTypes.string.isRequired,
    taskId: PropTypes.string,
};

CaseHistory.defaultProps = {};

export default CaseHistory;
