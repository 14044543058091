import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon, Message, Popup, Segment, Table } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import 'components/admin/UserGroupTable.scss';

const UserGroupCell = ({user, group, editing, checked, updateUser}) => {

    const onCheckboxToggle = (e, {checked}) => {
        const newUser = cloneDeep(user);
        if (checked) {
            if (newUser.groups.some(userGroup => userGroup.id === group.id)) {
                return;
            }
            newUser.groups.push({id: group.id, name: group.name});
        } else {
            newUser.groups = newUser.groups.filter(userGroup => userGroup.id !== group.id);
        }
        updateUser(newUser);
    };

    if (editing) {
        return (
            <Table.Cell textAlign="center">
                <Checkbox
                    checked={checked}
                    onClick={onCheckboxToggle}
                />
            </Table.Cell>
        );
    }

    return (
        <Table.Cell textAlign="center">
            {checked && <Icon name="check" color="green" />}
        </Table.Cell>
    );
};

UserGroupCell.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    group: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        permissions: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    editing: PropTypes.bool,
    checked: PropTypes.bool,
    updateUser: PropTypes.func,
};

UserGroupCell.defaultProps = {
    editing: false,
    checked: false,
    updateUser: () => {},
};

const UserGroupTable = ({users, groups, editing, updateUser, error, programmeId}) => {

    return (
        <Segment className="user-permission-table-segment" attached="bottom">
            {error && <Message negative header="Could not update users" content={error} />}
            <Table
                className="user-permission-table"
                striped
                unstackable
                celled
            >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="mask sticky-y sticky-x border-r" />
                        <Table.HeaderCell
                            textAlign="center"
                            colSpan={groups.length}
                            className="permission-table-top-header"
                        >
                            Groups
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="mask sticky-y sticky-x border-b border-r" />
                        {groups.map(group => (
                            <Table.HeaderCell
                                textAlign="left"
                                key={group.id}
                                content={group.name}
                                className="user-table-label-row sticky-y nowrap"
                            />))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users.map((user, userIndex) => (
                        <Table.Row key={user.id} className="user-table-row ">
                            <Table.Cell
                                className="user-table-cell sticky-x border-r nowrap"
                            >
                                {!editing && <>{user.name}</>}
                                {editing && <Popup
                                    content="Click to edit"
                                    inverted
                                    mouseEnterDelay={400}
                                    position="top right"
                                    trigger={
                                        <Link to={`/admin/users/${user.id}`}>
                                            {user.name}
                                            <Icon name="edit" className="user-edit-icon" />
                                        </Link>
                                    }
                                />}

                            </Table.Cell>
                            {groups.map(group => (
                                <UserGroupCell
                                    key={group.id}
                                    user={user}
                                    group={group}
                                    checked={user.groups.some(userGroup => userGroup.id === group.id)}
                                    editing={editing}
                                    updateUser={(newUser) => updateUser(userIndex, newUser)}
                                />
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );
};

UserGroupTable.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        groups: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })),
    })),
    groups: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        permissions: PropTypes.arrayOf(PropTypes.string),
    })),
    editing: PropTypes.bool,
    error: PropTypes.string,
    updateUser: PropTypes.func,
    programmeId: PropTypes.string,
};

UserGroupTable.defaultProps = {
    users: [],
    groups: [],
    editing: false,
    error: "",
    updateUser: () => {},
    programmeId: "",
};

export default UserGroupTable;
