import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDelete from 'components/common/ConfirmDelete';

const ConfirmCustomerDelete = (props) => {
    return (
        <ConfirmDelete
            open={props.open}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            title={"Confirm Delete"}
            content={"Are you sure you want to delete this customer?"}
            />
    );
};

ConfirmCustomerDelete.propTypes = {
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ConfirmCustomerDelete;