import bindClassMethods from 'common/util/AutoBind';

const LOGIN_ROUTE = '/api/auth/basic?remember-me=true';
const LOGOUT_ROUTE = '/api/auth/logout';
const CONTEXT_ROUTE = '/api/auth/context';
export const LOGIN_PATH = '/login';

// CSRF Header used with NCL-Auth
const CSRF_HEADER = {'X-REQUEST-NRT': 'Protect'};
const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    ...CSRF_HEADER,
};

export enum HTTP_METHOD {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

// Monkey Patch Fetch to Intercept HTTP Requests/Responses
const {fetch: originalFetch} = window;
window.fetch = async (resource: RequestInfo | URL, incomingConfig?: RequestInit) => {
    // intercept request
    let config = incomingConfig ?? {};
    config.headers = {
        ...config!.headers,
        ...DEFAULT_HEADERS,
    };

    let response = await originalFetch(resource, config);
    const isJsonResponse = response.headers.get('content-type')?.includes('application/json');
    const responseData = isJsonResponse ? await response.clone().json() : null;
    // intercept response

    if (!response.ok) {
        const error = (responseData && responseData.message) || response.status;
        return Promise.reject(error);
    }

    return response;
};

class Api {

    constructor() {
        bindClassMethods(this);
    }

    /**
     * Perform an HTTP request
     *
     * @returns {Promise<any>} Returns a Promise that resolves to a response body and rejects on any non-200 response.
     * @throws the response status text
     */
    static httpRequest(
        method: HTTP_METHOD,
        url: string,
        data?: BodyInit,
        headers?: Record<string, string>,
    ): Promise<any> {
        const fetchOptions: RequestInit = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: data,
        };

        return fetch(url, fetchOptions)
            .then(async response => {
                if (!response.ok) {
                    Api.logError(response);
                    throw response.statusText;
                }

                // Parse json from text to mitigate errors from empty responses
                let data = await response.text();
                if (data) {
                    data = JSON.parse(data);
                }

                return data;
            });
    }

    static get(url: string): Promise<any> {
        return Api.httpRequest(HTTP_METHOD.GET, url);
    }

    static post(url: string, data?: object): Promise<any> {
        return Api.httpRequest(HTTP_METHOD.POST, url, JSON.stringify(data));
    }

    static put(url: string, data?: object): Promise<any> {
        return Api.httpRequest(HTTP_METHOD.PUT, url, JSON.stringify(data));
    }

    static delete(url: string): Promise<any> {
        return Api.httpRequest(HTTP_METHOD.DELETE, url);
    }

    static getWithQuery(baseUrl: String, queryParameters: Record<string, string>): Promise<any> {
        const url = `${baseUrl}?${new URLSearchParams(queryParameters)}`;
        return Api.httpRequest(HTTP_METHOD.GET, url, undefined);
    }

    static logError(error: Response | string): void {
        console.error('-->', error);
    }

    static postMultiPartForm(url: string, data: BodyInit) {
        return fetch(
            url,
            {
                method: 'POST',
                body: data,
                headers: DEFAULT_HEADERS,
            },
        )
            .then(response => response.json())
            .then(res => {
                if (res.error) {
                    throw res.error;
                }
                return res;
            });
    }

    static addMultipartFile(formData: FormData, fileFieldName: string, file: File) {
        if (file) {
            formData.append(fileFieldName, file);
        }
    }

    static createMultipartJsonAndFileRequest(
        jsonFieldName: string,
        jsonObject: {fileName: string},
        fileFieldName: string,
        file: File,
    ) {
        const formData = new FormData();
        formData.append(
            jsonFieldName,
            new Blob([JSON.stringify(jsonObject)], {type: 'application/json'}),
            jsonObject.fileName,
        );
        Api.addMultipartFile(formData, fileFieldName, file);
        return formData;
    }

    static login(username: string, password: string): Promise<any> {
        return Api.httpRequest(
            HTTP_METHOD.POST,
            LOGIN_ROUTE,
            undefined,
            {
                'credentials': 'include',
                'Authorization': `Basic ${btoa(username + ':' + password)}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        );
    }

    static logout(): Promise<any> {
        return Api.post(LOGOUT_ROUTE, undefined);
    }

    static refreshSecurityContext(): Promise<any> {
        return Api.post(CONTEXT_ROUTE, undefined);
    }

}

export default Api;