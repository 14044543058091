import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { getDateAsJsonString } from 'common/util/DateHelpers';
import { executeDefaultValidators } from 'external/form/Validators';
import 'common/form/fields/FieldDate.css'
import moment from 'moment';

const DATE_FORMAT_STRING = "YYYY-MM-DD";
const DATE_FORMAT_DISPLAY_STRING = "DD-MM-YYYY";

const FieldDate = ({name, value, onChange, disabled, placeHolder, minDateTime, maxDateTime, registerValidator, deregisterValidator, size}) => {
    useEffect(() => {
        registerValidator(name, executeDefaultValidators);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const valueChanged = (event, data) => {
        if (data.value) {
            onChange(name, getDateAsJsonString(data.value));
        } else {
            onChange(name, data.value);
        }
    };
    return (
        <SemanticDatepicker
            format={DATE_FORMAT_DISPLAY_STRING}
            onChange={valueChanged}
            value={value && moment(value, DATE_FORMAT_STRING).toDate()}
            minDate={minDateTime && new Date(minDateTime)}
            maxDate={maxDateTime && new Date(maxDateTime)}
            readOnly={disabled}
            clearable={!disabled}
            size={size}
            className='date-field'
        />
    );
};

FieldDate.propTypes = {
    disabled: PropTypes.bool,
    maxDateTime: PropTypes.string,
    minDateTime: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeHolder: PropTypes.string,
    value: PropTypes.string,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
    size: PropTypes.string
};

FieldDate.defaultProps = {
    disabled: false,
    maxDateTime: undefined,
    minDateTime: undefined,
    onChange: () => {},
    placeHolder: undefined,
    value: undefined,
    registerValidator: () => {},
    deregisterValidator: () => {},
    size: undefined
};

export default FieldDate;
