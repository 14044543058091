import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import isNil from 'lodash/isNil';
import { DEFAULT_UI_THEME } from 'external/form/DefaultFormComponents';
import { isBlank } from 'common/util/StringHelpers';
import Multifield from 'external/form/Multifield';

const convertTypesToOptions = (types) => {
    return types.map((type) => { return {id: type.id.toString(), text: type.name};});
};

// const UnformattedFieldTypes = {
//     // subheading: Subheading,
//     // hidden: HiddenField,
//     // flexible: FieldFlexible,
// };

// TODO: Checkbox layout, text patterns, select filtering

const ExampleSchema = { // eslint-disable-line no-unused-vars
    fields: {
        name: {
            // hidden | text | password | masktext | textarea | select
            // checkbox | datetime | fileupload | imageinput | subheading | plaintextarea | number
            // component | flexible | userpicker | link
            type: 'text',
            component: undefined, // The component to use for the form display
            label: 'Name', // The label to display
            required: true, // Whether field is required
            maxLength: 30, // Maximum length of field
            disabled: true, // Whether field is disabled
            options: ['Option 1', 'Option 2', '...'], // List of drop down options for a select list
            multiple: true, // Whether multiple be selected
            placeHolder: 'Some text to use as a placeholder',
            disallowFutureTimes: true, // For datetime fields, disallow times in the future
            dateFormat: 'DD-MM-YYYY', // date field format
            timeFormat: 'HH:mm', // time field format
            minDateTime: '2017-01-01T00:00:00+00:00', // iso formatted date-time to restrict min selectable date-time
            maxDateTime: '2018-01-01T00:00:00+00:00', // iso formatted date-time to restrict max selectable date-time
            rows: 3, // number of rows of plain textarea
            mask: 'mask', // see https://github.com/Gusto/react-masked-field
            disallowPastTimes: true, // For datetime fields, disallow times in the past
            autoFocus: true, // indicate field is to be default focus
            allowMultipleFields: false, // Whether multiple copies of the field are allowed
            maxFields: 0, // Maximum number of copies of the field, (0 = unlimited)
            loadData: undefined, // Callback to load record data in select field
            // schema: { }, // Schema object used for flexible fields
        },
    },
    fieldOrder: ['name', 'description', '...'], // The ordering of the fields
};

const FieldError = ({name, type}) => {
    return (
        <div className="text-danger">
            Warning! Field not defined: <b>{name} - {type}</b>
        </div>
    );
};

FieldError.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

class GenericForm extends React.Component {

    /**
     * Prevent the form auto submitting if it receives an Enter key press
     */
    static onFormKeyDown(event) {
        if (event.key === 'Enter' && event.target.localName !== 'textarea') {
            event.preventDefault();
        }
    }

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            formData: this.props.formData,
            errors: {},
            fieldReferences: {},
            formEdited: false,
            validators: {},
        };
    }

    componentDidMount() {
        this.props.registerValidateFunctionCallback(() => this.validate(this.props.customValidators));
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            formData: nextProps.formData,
        };
    }

    registerValidator(name, validationFunction) {
        this.setState(prevState => ({
            validators: {
                ...prevState.validators,
                [name]: validationFunction
            }
        }));
    }

    deregisterValidator(name) {
        this.setState(prevState => {
            const validators = {...prevState.validators,};
            delete validators[name];
            return validators;
        });
    }

    onInputChanged(name, value) {
        const newFormData = Object.assign({}, this.state.formData);
        newFormData[name] = value;

        this.setState({
            formData: newFormData,
            formEdited: true,
        });

        if (this.props.onFormDataChanged) {
            this.props.onFormDataChanged(newFormData);
        }
        if (this.props.onInputFieldChanged) {
            this.props.onInputFieldChanged(name, value);
        }
    }

    getFormData() {
        return this.state.formData;
    }

    getMultiField(fieldControl, field) {
        return (
            <Multifield
                key={field.name}
                allowMultipleFields={field.allowMultipleFields}
                maxFields={field.maxFields}
                fieldControl={fieldControl}
                onChange={this.onInputChanged}
                error={this.state.errors[field.name]}
                {...field}
                theme={this.props.theme}
                registerValidator={this.registerValidator}
                deregisterValidator={this.deregisterValidator}
            />
        );
    }

    getFormField(fieldData) {
        const fieldControl = this.props.allowedFields[fieldData.type];
        if (isNil(fieldControl)) {
            const FieldError = this.props.fieldError;
            return <FieldError {...fieldData} />;
        }
        return this.getMultiField(fieldControl, fieldData);
    }

    /**
     * @param customValidators: Map of field names to validator functions.
     * Form fields matching the custom validator key will have the validator function run against the form value.
     *
     * Validator functions should return a validation outcome object as defined below:
     *   (fieldValue, fieldConfiguration, formData) => {error: bool, errorMessage: string}
     * With the following details:
     *   fieldValue: The value that the field has
     *   fieldConfiguration: The options supplied with the field
     *   formData: The options supplied with the field
     *   error: Whether there was a field validation error
     *   errorMessage: User prompt if the value is invalid.
     */
    validate(customValidators) {
        const fieldErrors = {};
        Object.keys(this.state.validators).forEach(fieldKey => {
            const validationFunction = this.state.validators[fieldKey];
            const fieldValue = this.state.formData[fieldKey];
            const fieldConfiguration = this.props.schema.fields[fieldKey];
            this.executeValidationFunction(fieldKey, fieldValue, fieldConfiguration, fieldErrors, validationFunction);
        })


        if (!isNil(customValidators)) {
            this.executeCustomValidators(customValidators, fieldErrors)
        }

        this.setState({
            errors: fieldErrors,
        });

        return Object.keys(fieldErrors).length === 0;
    }

    executeCustomValidators(customValidators, fieldErrors) {
        Object.keys(customValidators).forEach(fieldKey => {
            const validationFunction = customValidators[fieldKey];
            const fieldValue = this.state.formData[fieldKey];
            const fieldConfiguration = this.props.schema.fields[fieldKey];
            this.executeValidationFunction(fieldKey, fieldValue, fieldConfiguration, fieldErrors, validationFunction);
        });
    }

    executeValidationFunction(fieldKey, fieldValue, fieldConfiguration, fieldErrors, validationFunction) {
        const {error, errorMessage} = validationFunction(fieldValue, fieldConfiguration, this.state.formData);
        if (error) {
            fieldErrors[fieldKey] = errorMessage;
        }
    }

    handleSubmitError(error) {
        if (this.props.onSubmitError) {
            this.props.onSubmitError(error);
        } else {
            // ErrorHandler(error);
        }
    }

    submit(event) {
        event.preventDefault();
        if (this.validate(this.props.customValidators)) {
            if (this.props.onSubmit) {
                this.setState({formEdited: false}, () =>
                    this.props.onSubmit(this.state.formData)
                        .then((data) => {
                            if (this.props.onSubmitSuccess) {
                                this.props.onSubmitSuccess(data);
                            }
                        })
                        .catch(this.handleSubmitError),
                );
            }
        }
    }

    cancel(event) {
        event.preventDefault();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        const formFields = this.props.schema.fieldOrder.map((fieldName) => {
            const fieldDetail = Object.assign({
                name: fieldName,
                value: isNil(this.state.formData[fieldName]) ? undefined : this.state.formData[fieldName],
            }, this.props.schema.fields[fieldName]);
            return this.getFormField(fieldDetail);
        });

        let formButtons;
        const Button = this.props.theme.formButton;
        const ButtonContainer = this.props.theme.formButtonListContainer;
        if (this.props.showButtons) {
            formButtons = (
                <ButtonContainer>
                    <Button onClick={this.cancel}>
                        Cancel
                    </Button>
                    <Button disabled={this.props.submitButtonDisabled} primary onClick={this.submit}>
                        {this.props.submitButtonLabel}
                    </Button>
                </ButtonContainer>
            );
        }

        const Form = this.props.theme.form;
        return (
            <div
                onKeyPress={GenericForm.onFormKeyDown}
            >
                <Form>
                    {formFields}
                    {this.props.children}
                    {formButtons}
                </Form>
            </div>
        );
    }
}

const isEmptyFieldValidator = (field) => {
    return (formData) => {
        if (formData[field]) {
            if (isBlank(formData[field].trim())) {
                return false;
            }
        }
        return true;
    };
};

GenericForm.propTypes = {
    children: PropTypes.node,
    schema: PropTypes.shape({
        fields: PropTypes.shape().isRequired,
        fieldOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    formData: PropTypes.shape(),
    formSaved: PropTypes.bool,
    onFormDataChanged: PropTypes.func,
    onInputFieldChanged: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    onSubmitError: PropTypes.func,
    showButtons: PropTypes.bool,
    submitButtonLabel: PropTypes.string,
    submitButtonDisabled: PropTypes.bool,
    styleClass: PropTypes.string,
    formClass: PropTypes.string,
    customValidators: PropTypes.shape(),
    theme: PropTypes.shape({
        form: PropTypes.elementType,
        formField: PropTypes.elementType,
        formButton: PropTypes.elementType,
        formButtonListContainer: PropTypes.elementType,
    }),
    allowedFields: PropTypes.shape(),
    registerValidateFunctionCallback: PropTypes.func,
    fieldError: PropTypes.func,
};

GenericForm.defaultProps = {
    children: undefined,
    formData: {},
    formSaved: false,
    onFormDataChanged: () => {},
    onSubmit: () => {},
    onCancel: undefined,
    onSubmitSuccess: () => {},
    onSubmitError: undefined,
    showButtons: true,
    submitButtonLabel: 'Save', // ActionType.Save,
    submitButtonDisabled: false,
    onInputFieldChanged: undefined,
    styleClass: '',
    formClass: 'form-horizontal col-sm-12 col-md-10 col-lg-10',
    customValidators: {},
    theme: DEFAULT_UI_THEME,
    allowedFields: {},
    registerValidateFunctionCallback: () => {},
    fieldError: FieldError,
};

export {
    GenericForm as default,
    convertTypesToOptions,
    isEmptyFieldValidator,
};
