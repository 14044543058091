import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Dropdown } from 'semantic-ui-react';
// import Select from 'common/components/Select';
// import StyledTextArea from 'common/components/StyledTextArea';
// import DateTimePicker from 'common/components/DateTimePicker';
// import DateTimeZonePicker from 'common/components/DateTimeZonePicker';
// import FileInput from 'common/components/FileInput';
// import ImageInput from 'common/components/ImageInput';
// import ToggleRadioButton from 'common/components/ToggleRadioButton';
// import UserPicker from 'common/components/UserPicker';
// import InputMask from 'react-input-mask';
// import isNil from 'lodash/isNil';
// import GenericForm from 'common/form/GenericForm';
// import InputNumber from 'rc-input-number';
// import 'rc-input-number/assets/index.css';
//
// const Subheading = ({label}) => {
//     return (
//         <h3 className="form-subheading">{label}</h3>
//     );
// };
//
// Subheading.propTypes = {
//     label: PropTypes.string.isRequired,
// };
//
// const HiddenField = ({name, value}) => {
//     return (
//         <input type="hidden" name={name} value={value} />
//     );
// };
//
// HiddenField.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.bool,
//         PropTypes.arrayOf(PropTypes.string),
//         PropTypes.arrayOf(PropTypes.bool),
//     ]),
// };
//
// HiddenField.defaultProps = {
//     value: undefined,
// };

class FieldText extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
    }

    componentDidMount() {
        if (this.props.autoFocus === true) {
            this.inputField.focus();
        }
    }

    valueChanged(event) {
        const target = event.target;
        const newValue = target.type === 'checkbox' ? target.checked : target.value;
        this.props.onChange(this.props.name, newValue);
    }

    render() {
        return (
            <input
                aria-describedby={this.props.name}
                className="form-control"
                name={this.props.name}
                type={this.props.inputType}
                ref={(inputField) => {this.inputField = inputField;}}
                onChange={this.valueChanged}
                value={this.props.value}
                disabled={this.props.disabled}
                placeholder={this.props.placeHolder}
                spellCheck
            />
        );
    }
}

FieldText.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    autoFocus: PropTypes.bool,
    inputType: PropTypes.string,
};

FieldText.defaultProps = {
    value: '',
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    autoFocus: false,
    inputType: 'text',
};

const FieldPassword = (props) => {
    const newProps = Object.assign({}, props, {inputType: 'password'});
    return (
        <FieldText {...newProps} />
    );
};

FieldPassword.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    autoFocus: PropTypes.bool,
};

FieldPassword.defaultProps = {
    value: '',
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    autoFocus: false,
};

// const FieldStyledTextArea = ({name, value, onChange, disabled, placeHolder}) => {
//     const valueChanged = (text) => {
//         onChange(name, text);
//     };
//     return (
//         <StyledTextArea onChange={valueChanged} value={value} disabled={disabled} placeHolder={placeHolder} />
//     );
// };
//
// FieldStyledTextArea.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.string,
//     onChange: PropTypes.func,
//     disabled: PropTypes.bool,
//     placeHolder: PropTypes.string,
// };
//
// FieldStyledTextArea.defaultProps = {
//     value: undefined,
//     onChange: () => {},
//     disabled: false,
//     placeHolder: undefined,
// };
//
// const FieldPlainTextArea = ({name, value, onChange, disabled, placeHolder, rows}) => {
//     const valueChanged = (event) => {
//         const target = event.target;
//         onChange(name, target.value);
//     };
//     return (
//         <textarea
//             className="form-control"
//             onChange={valueChanged}
//             value={value}
//             disabled={disabled}
//             placeholder={placeHolder}
//             rows={rows}
//         />
//     );
// };
//
// FieldPlainTextArea.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.string,
//     onChange: PropTypes.func,
//     disabled: PropTypes.bool,
//     placeHolder: PropTypes.string,
//     rows: PropTypes.number,
// };
//
// FieldPlainTextArea.defaultProps = {
//     value: undefined,
//     onChange: () => {},
//     disabled: false,
//     placeHolder: undefined,
//     rows: 1,
// };
//
const FieldSelect = ({autoFocus, disabled, error, loadData, multiple, name, nullable, onChange, options, placeHolder,
                      value, commitId}) => {

    const callback = (name, value) => {
        onChange(name, value)
    };
    const dropdownOptions = options.map(option => {return {
        key: option.id,
        value: option.id,
        text: option.text,
    };});

    return (
        <Dropdown
            selection
            placeholder={placeHolder}
            // text='File'
            onChange={(event, value) => callback(name, value.value)}
            options={dropdownOptions}
            value={value}
        />
    );
};

FieldSelect.propTypes = {
    autoFocus: PropTypes.bool,
    commitId: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    loadData: PropTypes.func,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    nullable: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape()),
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired, // used by Select to generate React key
        }))]
    ),
};

FieldSelect.defaultProps = {
    autoFocus: false,
    commitId: true,
    disabled: false,
    error: undefined,
    loadData: undefined,
    multiple: false,
    nullable: true,
    onChange: () => {},
    options: [],
    placeHolder: undefined,
    value: undefined,
};

const FieldMultiSelect = ({autoFocus, disabled, error, loadData, multiple, searchable, clearable, name, nullable, icon,
                              onChange, options, placeHolder, value, commitId, closeOnChange, onSearchChange, header}) => {

    const changeCallback = (name, value) => {
        onChange(name, value)
    };

    const dropdownOptions = options.map(option => {return {
        key: option.id,
        value: option.id,
        text: option.text,
    };});

    return (
        <Dropdown
            selection
            placeholder={placeHolder}
            icon={icon}
            // text='File'
            multiple
            search={searchable}
            clearable={clearable}
            onChange={(event, value) => changeCallback(name, value.value)}
            onSearchChange={(event, data) => onSearchChange(event, data)}
            options={dropdownOptions}
            value={value}
            closeOnChange={closeOnChange}
            header={header}
        />
    );
};

FieldMultiSelect.propTypes = {
    autoFocus: PropTypes.bool,
    commitId: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    loadData: PropTypes.func,
    multiple: PropTypes.bool,
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    nullable: PropTypes.bool,
    onChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape()),
    placeHolder: PropTypes.string,
    icon: PropTypes.string,
    header: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired, // used by Select to generate React key
        }))]
    ),
    closeOnChange: PropTypes.bool,
};

FieldMultiSelect.defaultProps = {
    autoFocus: false,
    commitId: true,
    disabled: false,
    error: null,
    loadData: null,
    multiple: false,
    searchable: false,
    clearable: false,
    nullable: true,
    onChange: () => {},
    onSearchChange: () => {},
    options: [],
    placeHolder: null,
    icon: null,
    header: null,
    value: null,
    closeOnChange: false,
};

const FieldCheckBox = ({name, label, value, onChange, disabled}) => {
    const valueChanged = (event) => {
        const target = event.target;
        const newValue = target.type === 'checkbox' ? target.checked : target.value;
        onChange(name, newValue);
    };

    return (
        <div className="checkbox">
            <label htmlFor={name}>
                <input name={name} type="checkbox" onChange={valueChanged} checked={value} disabled={disabled} />
            </label>
        </div>
    );
};

FieldCheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FieldCheckBox.defaultProps = {
    value: false,
    required: false,
    onChange: () => {},
    error: undefined,
    disabled: false,
};

// const FieldDate = ({name, value, onChange, disabled, placeHolder, dateFormat, minDateTime, maxDateTime}) => {
//     const valueChanged = (newValue) => {
//         onChange(name, newValue);
//     };
//     return (
//         <DateTimePicker
//             dateFormat={dateFormat}
//             disabled={disabled}
//             maxDate={maxDateTime}
//             minDate={minDateTime}
//             onChange={valueChanged}
//             placeholder={placeHolder}
//             timeFormat={false}
//             value={value}
//         />
//     );
// };
//
// FieldDate.propTypes = {
//     dateFormat: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.bool,
//     ]),
//     disabled: PropTypes.bool,
//     maxDateTime: PropTypes.string,
//     minDateTime: PropTypes.string,
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func,
//     placeHolder: PropTypes.string,
//     value: PropTypes.string,
// };
//
// FieldDate.defaultProps = {
//     dateFormat: undefined,
//     disabled: false,
//     maxDateTime: undefined,
//     minDateTime: undefined,
//     onChange: () => {},
//     placeHolder: undefined,
//     value: undefined,
// };
//
// const FieldDateTime = ({name, value, onChange, disabled, placeHolder, dateFormat, timeFormat, minDateTime, maxDateTime}) => {
//     const valueChanged = (newValue) => {
//         onChange(name, newValue);
//     };
//     return (
//         <DateTimePicker
//             dateFormat={dateFormat}
//             disabled={disabled}
//             maxDate={maxDateTime}
//             minDate={minDateTime}
//             onChange={valueChanged}
//             placeholder={placeHolder}
//             timeFormat={timeFormat}
//             value={value}
//         />
//     );
// };
//
// FieldDateTime.propTypes = {
//     dateFormat: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.bool,
//     ]),
//     disabled: PropTypes.bool,
//     maxDateTime: PropTypes.string,
//     minDateTime: PropTypes.string,
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func,
//     placeHolder: PropTypes.string,
//     timeFormat: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.bool,
//     ]),
//     value: PropTypes.string,
// };
//
// FieldDateTime.defaultProps = {
//     dateFormat: undefined,
//     disabled: false,
//     maxDateTime: undefined,
//     minDateTime: undefined,
//     onChange: () => {},
//     placeHolder: undefined,
//     timeFormat: undefined,
//     value: undefined,
// };
//
// const FieldDateTimeZone = ({name, value, onChange, disabled, placeHolder, dateFormat, timeFormat, minDateTime, maxDateTime, error}) => {
//     const valueChanged = (newValue) => {
//         onChange(name, newValue);
//     };
//     return (
//         <DateTimeZonePicker
//             dateFormat={dateFormat}
//             disabled={disabled}
//             error={error}
//             maxDate={maxDateTime}
//             minDate={minDateTime}
//             onChange={valueChanged}
//             placeholder={placeHolder}
//             timeFormat={timeFormat}
//             value={value}
//         />
//     );
// };
//
// FieldDateTimeZone.propTypes = {
//     dateFormat: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.bool,
//     ]),
//     disabled: PropTypes.bool,
//     error: PropTypes.string,
//     maxDateTime: PropTypes.string,
//     minDateTime: PropTypes.string,
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func,
//     placeHolder: PropTypes.string,
//     timeFormat: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.bool,
//     ]),
//     value: PropTypes.shape({
//         date: PropTypes.string,
//         tz: PropTypes.string,
//     }),
// };
//
// FieldDateTimeZone.defaultProps = {
//     dateFormat: undefined,
//     disabled: false,
//     error: undefined,
//     maxDateTime: undefined,
//     minDateTime: undefined,
//     onChange: () => {},
//     placeHolder: undefined,
//     timeFormat: undefined,
//     value: {},
// };
//
// const FieldFileUpload = ({name, value, onChange, disabled, placeHolder, autoFocus}) => {
//     const fileChanged = (newValue) => {
//         onChange(name, newValue);
//     };
//
//     let fileName;
//     let downloadUrl;
//     if (!isNil(value)) {
//         fileName = value.name;
//         downloadUrl = value.url;
//     }
//     return (
//         <FileInput
//             onFileInputChange={fileChanged}
//             filename={fileName}
//             downloadUrl={downloadUrl}
//             disabled={disabled}
//             placeholder={placeHolder}
//             autoFocus={autoFocus}
//         />
//     );
// };
//
// FieldFileUpload.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.shape({
//         name: PropTypes.string,
//     }),
//     onChange: PropTypes.func,
//     disabled: PropTypes.bool,
//     placeHolder: PropTypes.string,
//     autoFocus: PropTypes.bool,
// };
//
// FieldFileUpload.defaultProps = {
//     value: undefined,
//     onChange: () => {},
//     disabled: false,
//     placeHolder: undefined,
//     autoFocus: false,
// };
//
// const FieldNumber = ({disabled, maximum, minimum, name, onChange, placeholder, value}) => {
//     const valueChanged = (newValue) => {
//         onChange(name, newValue);
//     };
//     return (
//         <InputNumber
//             disabled={disabled}
//             max={maximum}
//             min={minimum}
//             name={name}
//             onChange={valueChanged}
//             placeholder={placeholder}
//             style={{display: 'block'}}
//             value={value}
//         />
//     );
// };
//
// FieldNumber.propTypes = {
//     disabled: PropTypes.bool,
//     maximum: PropTypes.number,
//     minimum: PropTypes.number,
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func,
//     placeholder: PropTypes.string,
//     value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
// };
//
// FieldNumber.defaultProps = {
//     disabled: false,
//     maximum: undefined,
//     minimum: undefined,
//     onChange: () => {},
//     placeholder: undefined,
//     value: undefined,
// };
//
// const FieldImageInput = ({name, value, onChange, disabled, placeHolder}) => {
//     const fileChanged = (newValue) => {
//         onChange(name, newValue);
//     };
//
//     return (
//         <ImageInput onImageInputChange={fileChanged} image={value} disabled={disabled} placeholder={placeHolder} />
//     );
// };
//
// FieldImageInput.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.shape({
//         url: PropTypes.string,
//         file: PropTypes.instanceOf(File),
//     }),
//     onChange: PropTypes.func,
//     disabled: PropTypes.bool,
//     placeHolder: PropTypes.string,
// };
//
// FieldImageInput.defaultProps = {
//     value: undefined,
//     onChange: () => {},
//     disabled: false,
//     placeHolder: undefined,
// };
//
// const FieldMaskedText = ({name, value, onChange, disabled, mask}) => {
//     const valueChanged = (event) => {
//         const target = event.target;
//         onChange(name, target.value);
//     };
//     return (
//         <InputMask
//             className="form-control mask-input"
//             name={name}
//             type="text"
//             onChange={valueChanged}
//             value={value}
//             disabled={disabled}
//             mask={mask}
//         />
//     );
// };
//
// FieldMaskedText.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.string,
//     onChange: PropTypes.func,
//     disabled: PropTypes.bool,
//     mask: PropTypes.string.isRequired,
// };
//
// FieldMaskedText.defaultProps = {
//     value: undefined,
//     onChange: () => {},
//     disabled: false,
//     placeHolder: undefined,
// };
//
// const FieldToggleButton = ({name, onChange, options, value}) => {
//     const valueChanged = (newValue) => {
//         onChange(name, newValue);
//     };
//
//     return (
//         <ToggleRadioButton options={options} onChange={valueChanged} value={value} />
//     );
// };
//
// FieldToggleButton.propTypes = {
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     options: PropTypes.arrayOf(PropTypes.shape({
//         id: PropTypes.string.isRequired,
//         text: PropTypes.string.isRequired,
//     })).isRequired,
// };
//
// FieldToggleButton.defaultProps = {
//     value: undefined,
//     onChange: () => {},
// };
//
// const FieldComponent = ({name, value, onChange, disabled, component, style}) => {
//     const Component = component;
//     return (
//         <Component name={name} onChange={onChange} value={value} disabled={disabled} style={style} />
//     );
// };
//
// FieldComponent.propTypes = {
//     name: PropTypes.string.isRequired,
//     value: PropTypes.oneOfType([
//         PropTypes.shape(),
//         PropTypes.array,
//         PropTypes.string,
//         PropTypes.number,
//     ]),
//     onChange: PropTypes.func,
//     disabled: PropTypes.bool,
//     component: PropTypes.func.isRequired,
//     style: PropTypes.shape(),
// };
//
// FieldComponent.defaultProps = {
//     value: undefined,
//     onChange: () => {},
//     disabled: false,
//     children: null,
//     style: undefined,
// };
//
// const FieldUserPicker = ({name, onChange, getAll, value, filter, loadData, disabled, error}) => {
//     const valueChanged = (id, action, newValue) => {
//         onChange(name, newValue);
//     };
//     return (
//         <UserPicker
//             getAll={getAll}
//             name={name}
//             onChange={valueChanged}
//             value={value}
//             customFilter={filter}
//             loadData={loadData}
//             disabled={disabled}
//             error={error}
//         />
//     );
// };
//
// FieldUserPicker.propTypes = {
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func,
//     getAll: PropTypes.func,
//     value: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.shape(),
//     ]),
//     filter: PropTypes.func,
//     loadData: PropTypes.func,
//     disabled: PropTypes.bool,
//     error: PropTypes.string,
// };
//
// FieldUserPicker.defaultProps = {
//     onChange: () => {},
//     getAll: () => {},
//     value: undefined,
//     filter: undefined,
//     loadData: undefined,
//     disabled: false,
//     error: undefined,
// };
//
// function upmarkFormDataFromSchema(name, field, formData) {
//     if (!Object.prototype.hasOwnProperty.call(formData, name)) {
//         return;
//     }
//     // eslint-disable-next-line no-param-reassign
//     formData[`@remediation`] = field.type;
// }
//
// function upmarkFormDataFromFieldDefinition(schema, formData) {
//     Object.keys(schema.fields).map(key => upmarkFormDataFromSchema(key, schema.fields[key], formData));
// }
//
// const FieldFlexible = ({schema, name, onChange, value}) => {
//     const formDataChanged = (formData) => {
//         upmarkFormDataFromFieldDefinition(schema, formData);
//         onChange(name, formData);
//     };
//
//     return (
//         <GenericForm
//             schema={schema}
//             showButtons={false}
//             onFormDataChanged={formDataChanged}
//             navigationPrompt={false}
//             formData={value}
//             styleClass=""
//             formClass=""
//         />
//     );
// };
//
// FieldFlexible.propTypes = {
//     schema: PropTypes.shape().isRequired,
//     name: PropTypes.string.isRequired,
//     value: PropTypes.shape(),
//     onChange: PropTypes.func,
// };
//
// FieldFlexible.defaultProps = {
//     value: {},
//     onChange: () => {},
// };
//
export {
//     HiddenField,
    FieldText,
    FieldPassword,
    FieldCheckBox,
    FieldSelect,
    FieldMultiSelect,
//     FieldStyledTextArea,
//     FieldDate,
//     FieldDateTime,
//     FieldDateTimeZone,
//     FieldFileUpload,
//     FieldNumber,
//     FieldImageInput,
//     Subheading,
//     FieldPlainTextArea,
//     FieldMaskedText,
//     FieldToggleButton,
//     FieldComponent,
//     FieldFlexible,
//     FieldUserPicker,
};