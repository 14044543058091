export const customerFields = {
    CUSTOMER_ID: "customerId",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    DISPLAY_NAME: "displayName",
    EMAIL: "email",
    DATE_OF_BIRTH: "dateOfBirth",
    DECEASED: "deceased",
    DELETED: "deleted",
    DISABILITY: "disability",
    HOME_PHONE: "homePhoneNumber",
    MOBILE_PHONE: "mobilePhoneNumber",
    PROGRAMME_OF_WORK_ID: "programmeOfWorkId",
    REFERENCE_NUMBER: "referenceNumber",
    TITLE: "title",
    VULNERABLE: "vulnerable",
}

export const caseFields = {
    DISPLAY_ID: "displayId",
    REVIEW_STAGE_ID: "reviewStageId",
    REVIEW_STATE: "reviewState",
    REVIEW_STATE_ORDER: "reviewStateOrder",
    REVIEW_TASK_ID: "reviewTaskId",
    SCREEN_NAME: "screenName",
    STAGE_ORDER: "stageOrder",
    START_TIME: "startTime",
    STATE: "state",
    WORKFLOW_NAME: "workflowName",
}

export const advisorFields = {
    ADVISOR_ID: "advisorId",
    AFS_LICENSE_NAME: "afsLicenseName",
    AFS_LICENSE_NUMBER: "afsLicenseNumber",
}

const matchFields = {
    customerFields,
    caseFields,
    advisorFields,
}

export default matchFields;