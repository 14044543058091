import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'common/Markdown';
import 'common/form/fields/FieldInformation.scss';
import FieldHelp from 'common/form/FieldHelp';

const FieldInformation = ({text, helpText, label}) => (
    <div className="fieldInformation">
        <div className="helpIcon">
            <FieldHelp title={`${label}`} helpText={helpText} />
        </div>
        <Markdown text={text} />
    </div>
);

FieldInformation.validate = () => {
    return true;
}

FieldInformation.propTypes = {
    text: PropTypes.string.isRequired,
};

export default FieldInformation;
