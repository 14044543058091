import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Container, Divider, Grid, Header, Icon, Loader, Menu, Ref, Sticky } from 'semantic-ui-react';
import { withHeaderAndBreadcrumbs } from 'components/common/PageHeader';
import WaterfallReport from 'components/reports/WaterfallReport';
import { getReportRoute } from 'components/reports/ReportRouter';
import ExternalProgressReport from 'components/reports/ExternalProgressReport';
import RedressReport from 'components/reports/RedressReport';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const SideBarItems = [
    {name: 'waterfall', icon: 'table', text: 'Program Progress Report (Internal)'},
    {name: 'external', icon: 'chart bar outline', text: 'Program Progress Report (External)'},
    {name: 'redress', icon: 'money', text: 'Redress Report'},
    // {name: 'summary', icon: 'pie graph', text: 'Summary Report'},
];

const SideBarMenuItem = ({name, active, icon, text, onItemClicked}) => {
    return (
        <Menu.Item
            name={name}
            active={active}
            onClick={onItemClicked}
        >
            <Container fluid>
                <Icon name={icon} /> {text}
            </Container>
        </Menu.Item>
    );
};

const ReportSideBar = ({activeItem, onItemClicked}) => {

    return (
        <Menu vertical secondary fluid>
            <Menu.Header>
                <Container fluid>
                    <Header size="small">
                        <Icon name="line graph" circular />
                        <Header.Content>
                            Reports
                        </Header.Content>
                    </Header>
                </Container>
                <Divider />
            </Menu.Header>
            {SideBarItems.map((item) => {
                return (
                    <SideBarMenuItem
                        key={item.name}
                        name={item.name}
                        active={activeItem === item.name}
                        icon={item.icon}
                        text={item.text}
                        onItemClicked={() => onItemClicked(item.name)}
                    />
                );
            })}
        </Menu>
    );
};

const WaterfallReportSection = withHeaderAndBreadcrumbs(WaterfallReport);
const ExternalProgressReportSection = withHeaderAndBreadcrumbs(ExternalProgressReport);
const RedressReportSection = withHeaderAndBreadcrumbs(RedressReport);

/**
 * The class implements a top level customer page component
 */
class ReportsPage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            activeItem: props.activeItem,
            loading: false,
        };

        this.contextRef = createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeItem !== prevProps.activeItem) {
            this.setState({activeItem: this.props.activeItem});
        }
    }

    handleItemClick(item) {
        this.setState({activeItem: item});
        this.props.history.push(`${getReportRoute(getProgrammeIdFromCurrentPath())}/${item}`);
    }

    getBreadcrumbs() {
        const breadcrumbs = [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Reports', content: 'Reports'},
        ];

        const activeItem = SideBarItems.find(item => item.name === this.state.activeItem);
        if (activeItem) {
            breadcrumbs.push({key: activeItem.name, content: activeItem.text});
        }
        return breadcrumbs;
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }
        return (
            <Ref innerRef={this.contextRef}>
                <Grid stretched padded="vertically">
                    <Grid.Column tablet={3} widescreen={2}>
                        <Sticky context={this.contextRef} offset={70}>
                            <ReportSideBar
                                activeItem={this.state.activeItem}
                                onItemClicked={this.handleItemClick}
                            />
                        </Sticky>
                    </Grid.Column>
                    <Grid.Column tablet={13} widescreen={13}>
                        {this.state.activeItem === 'waterfall' &&
                            <WaterfallReportSection
                                text={'Program Progress Report (Internal)'}
                                icon={'table'}
                                unattached
                                breadcrumbs={this.getBreadcrumbs()}
                            />
                        }
                        {this.state.activeItem === 'external' &&
                            <ExternalProgressReportSection
                                text={'Program Progress Report (External)'}
                                icon={'chart bar outline'}
                                unattached
                                breadcrumbs={this.getBreadcrumbs()}
                            />
                        }
                        {this.state.activeItem === 'redress' &&
                            <RedressReportSection
                                text={'Redress Report'}
                                icon={'money'}
                                unattached
                                breadcrumbs={this.getBreadcrumbs()}
                            />
                        }
                    </Grid.Column>
                </Grid>
            </Ref>
        );
    }
}

ReportsPage.propTypes = {
    activeItem: PropTypes.string,
};

ReportsPage.defaultProps = {
    activeItem: SideBarItems[0].name,
};

export default withRouter(ReportsPage);
