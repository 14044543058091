import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Header, Form, Input} from 'semantic-ui-react';
import FieldSelect from 'common/form/fields/FieldSelect';
import isEmpty from 'lodash/isEmpty';

const OverrideStageComponent = ({overrideStage, overrideComment, overrideStages, onOverrideStage}) => {
    const [overrideToStage, setOverrideToStage] = useState(overrideStage);
    const [updatedOverrideComment, setOverrideComment] = useState(overrideComment);
    const [emptyComment, setEmptyComment] = useState(false);

    if (overrideStages.length === 0) {
        return null;
    }

    const onOverrideClicked = () => {
        if (isEmpty(updatedOverrideComment)) {
            setEmptyComment(true);
            return;
        }
        onOverrideStage(overrideToStage, updatedOverrideComment);
    }

    return (
        <>
            <Header as="h3">Stage Override</Header>
            <Form autoComplete="off">
                <Form.Field>
                    <FieldSelect name="Override Stage" options={overrideStages} onChange={(name, value) => setOverrideToStage(value)} />
                </Form.Field>
                {overrideToStage &&
                    <Form.Field>
                        <Input
                            action={{
                                color: 'grey',
                                labelPosition: 'left',
                                icon: 'comments',
                                content: 'Comments',
                            }}
                            fluid
                            actionPosition='left'
                            placeholder='Comments...'
                            defaultValue={updatedOverrideComment}
                            onChange={(e, i) => setOverrideComment(i.value)}
                            error={emptyComment}
                        />
                        {emptyComment &&
                            <p style={{color: 'red'}}>You must supply a comment to override this step</p>
                        }
                    </Form.Field>
                }
                <Button onClick={onOverrideClicked} disabled={overrideToStage === undefined}>Override Routing</Button>
            </Form>
        </>
    );
};

OverrideStageComponent.propTypes = {
    overrideStages: PropTypes.arrayOf(PropTypes.shape()),
    onOverrideStage: PropTypes.func,
};

OverrideStageComponent.defaultProps = {
    overrideStages: [],
    onOverrideStage: () => {},
};


export default OverrideStageComponent;
