import moment from 'moment';

export const momentDateTimeFormatString = "DD/MM/YYYY h:mma";
export const momentDateFormatString = "DD/MM/YYYY";

/**
* Takes in an ISO 8601 date string and returns a human readable date and time as a string (e.g. 12-10-2020 11:58am)
*/
export const ISOStringToFriendlyDateTime = (ISOString) => {
    return moment(ISOString).format(momentDateTimeFormatString);
}

/**
* Takes in an ISO 8601 date string and returns a human readable date as a string (e.g. 12-10-2020 )
*/
export const ISOStringToFriendlyDate = (ISOString) => {
    return moment(ISOString).format("DD/MM/YYYY");
}

/**
 * Takes an ISO 8301 date string and returns the time from now, e.g. "3 days ago".
 */
export const ISOStringToTimeFromNow = (ISOString) => {
    return moment(ISOString).fromNow();
}
