import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import GenericForm from 'common/form/SemanticUIForm';
import cloneDeep from 'lodash/cloneDeep';
import { Icon, Loader } from "semantic-ui-react";
import Api from 'api/Api';
import AlertMessage, { AlertLevel } from 'components/common/AlertMessage';
import SavedStateLabel, { SavedStatus } from 'components/common/SavedStateLabel';
import PageHeader from 'components/common/PageHeader';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import withProgramme from 'components/programme/ProgrammeProps';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

const getFormSchema = (fieldOptions, screenOptions) => {
    return {
        fields: {
            screenId: {
                type: 'text',
                label: 'Screen Id',
                placeHolder: '',
                disabled: true,
                required: true,
            },
            screenTag: {
                type: 'text',
                label: 'Screen Tag',
                placeHolder: '',
                disabled: true,
                required: false,
            },
            name: {
                type: 'text',
                label: 'Screen Name',
                placeHolder: '',
                required: true,
            },
            description: {
                type: 'text',
                label: 'Screen description',
                required: true,
            },
            fieldIds: {
                type: 'select',
                label: 'Fields',
                required: true,
                placeHolder: 'Field Type',
                allowMultipleFields: true,
                maxAllowed: 0,
                options: fieldOptions,
            },
            transitionRules: {
                type: 'routingCondition',
                label: 'Routing Rules',
                required: false,
                allowMultipleFields: true,
                maxAllowed: 10,
                inline: false,
                options: screenOptions,
            },
        },
        fieldOrder: [
            'screenId',
            'screenTag',
            'name',
            'description',
            'fieldIds',
            'transitionRules',
        ],
    };
};

const InitialFormData = {
    screenId: '',
    screenTag: '',
    name: '',
    description: '',
    fields: [],
};

class ScreenEditForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: cloneDeep(getFormSchema([], [])),
            formData: cloneDeep(InitialFormData),
            loading: true,
            savedState: SavedStatus.Init,
            error: undefined,
            fieldOptions: [],
            screenOptions: [],
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});
        Promise.all([
                this.getFields(),
                this.getScreenDetails(),
                this.getWorkflowDetails(),
            ])
            .then(this.setFormSchema)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    getFields() {
        return Api.get(`/api/workflow-fields/${this.props.workflowId}`)
            .then(this.setAvailableFields);
    }

    getScreenDetails() {
        return Api.get(`/api/screens/${this.props.workflowId}/${this.props.screenId}`)
            .then(this.setScreenData);
    }

    getWorkflowDetails() {
        return Api.get(`/api/workflow/${this.props.workflowId}`)
            .then(this.setWorkflowData);
    }

    setAvailableFields(fields) {
        const fieldOptions = fields.map(field => {
            return {
                id: field.fieldId,
                text: field.name,
            };
        });

        this.setState({
            fieldOptions: fieldOptions,
        });
    }

    setScreenData(data) {
        this.setState({
            formData: {
                workflowId: this.props.workflowId,
                screenId: data.screenId,
                screenTag: data.screenTag,
                name: data.name,
                description: data.description,
                fieldIds: data.fieldIds,
            },
        });
    }

    setWorkflowData(data) {

        const screenOptions = data.screens.map(screen => ({
            value: screen.screenId,
            text: screen.name,
        }));

        this.setState({screenOptions: screenOptions});
    }

    setFormSchema() {
        this.setState({
            schema: getFormSchema(this.state.fieldOptions, this.state.screenOptions),
        });
    }

    onFormDataChanged(formData) {
        this.setState({formData: formData, savedState: SavedStatus.Unsaved, error: undefined});
    }

    onSubmit() {
        this.setState({loading: true, error: undefined});
        return Api.put(`/api/screens/${this.props.workflowId}/${this.props.screenId}`, this.state.formData);
    }

    onSubmitSuccess() {
        this.loadData();
        this.setState({savedState: SavedStatus.Saved});
    }

    onSubmitError(error) {
        Api.logError(error);
        this.setState({error: error});
    }

    onCancel() {
        this.props.history.push(`${getProgrammeRoute(this.props.programme.id)}/admin/screens`);
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Administration', content: 'Administration'},
            {key: 'Workflow Screens', content: 'Workflow Screens'},
            {key: this.props.screenId, content: this.props.screenId},
        ];
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }
        return (
            <>
                <PageHeader
                    text="Screen Configuration"
                    icon="edit"
                    headerLeftComponent={<SavedStateLabel status={this.state.savedState} />}
                    unattached
                    breadcrumbs={this.getBreadcrumbs()}
                />
                <GenericForm
                    schema={this.state.schema}
                    onFormDataChanged={this.onFormDataChanged}
                    formData={this.state.formData}
                    navigationPrompt={false}
                    showButtons={true}
                    styleClass="left-align-fields"
                    onSubmit={this.onSubmit}
                    onSubmitSuccess={this.onSubmitSuccess}
                    onSubmitError={this.onSubmitError}
                    onCancel={this.onCancel}
                />
                {this.state.error && <AlertMessage level={AlertLevel.Error} heading={this.state.error} />}
            </>
        );
    }
}

ScreenEditForm.propTypes = {
    workflowId: PropTypes.string.isRequired,
    screenId: PropTypes.string.isRequired,
};

export default withProgramme(ScreenEditForm);
