import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { isValueFalse } from 'common/util/Helpers';
import { executeDefaultValidators } from 'external/form/Validators';

const FieldCheckBox = ({name, label, value, onChange, disabled, registerValidator, deregisterValidator}) => {
    useEffect(() => {
        registerValidator(name, executeDefaultValidators);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const valueChanged = (event, data) => {
        onChange(name, data.checked);
    };

    const checked = !isValueFalse(value)
    return (
        <label htmlFor={name}>
            <Checkbox name={name} onClick={valueChanged} checked={checked} disabled={disabled} />
        </label>
    );
};

FieldCheckBox.propTypes = {
    name:  PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]).isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldCheckBox.defaultProps = {
    value: false,
    required: false,
    onChange: () => {},
    error: undefined,
    disabled: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldCheckBox;
