import React from 'react';
import {Divider} from "semantic-ui-react";

type FieldDividerProps = {}

const FieldDivider: React.FunctionComponent<FieldDividerProps> = () => {

    return <Divider />
}

export default FieldDivider;
