import React from 'react';
import PropTypes from 'prop-types';
import { withUserContextProp } from 'UserContext';
import { userHasAnyPermission } from 'common/auth/Authority';

const ShowWithOneOf = ({permissions, userContext, children}) => {
    const hasPermission = userContext.user.permissions && userHasAnyPermission(permissions, userContext);
    if (!hasPermission) {
        return null;
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

ShowWithOneOf.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.node),
};

ShowWithOneOf.defaultProps = {
    permission: [],
};

const ShowWithOneOfPermission = withUserContextProp(ShowWithOneOf);

const ShowWithPermission = ({permission, children}) => {
    if (!permission) {
        return null;
    }

    return (
        <ShowWithOneOfPermission permissions={[permission]}>
            {children}
        </ShowWithOneOfPermission>
    );
}

ShowWithPermission.propTypes = {
    permission: PropTypes.node,
};

ShowWithPermission.defaultProps = {
    permission: undefined,
};


export {
    ShowWithPermission as default,
    ShowWithOneOfPermission,
}