import React, { Component } from 'react';
import bindClassMethods from 'common/util/AutoBind';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { Button, Modal, Container, Icon } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import FileList from 'components/common/FileList';
import AlertMessage, { AlertLevel } from 'components/common/AlertMessage';

class UploadFilesModal extends Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.fileInputRef = React.createRef();
        this.state = {
            files: [],
            error: '',
            dropzoneActive: false,
            uploading: false,
        }
    }

    handleFileInputChange(event) {
        event.preventDefault();
        this.setState({error: ''})

        let file = null;
        if (event.target.files) {
            file = Array.from(event.target.files);
        }

        const newFiles = this.state.files.concat(file);

        this.setState({files: newFiles});
    }

    handleSubmit() {
        this.setState({error: ''})
        if (this.state.files.length) {
            this.setState({
                uploading: true,
            })
            this.props.onSubmit(this.state.files)
                .then(this.onClose)
                .catch(err => this.setState({error: err}))
                .finally(() => this.setState({uploading: false}))
            ;
        } else {
            this.setState({error: "Please Select a File"})
        }
    }

    isSubmissionDisabled() {
        return this.props.submitButtonDisabled
            || this.state.uploading
            || this.state.files.length === 0;
    }

    onDragEnter() {
        this.setState({dropzoneActive: true});
    }

    onDragLeave() {
        this.setState({dropzoneActive: false});
    }

    onDrop(files) {
        if (files) {
            const newFiles = cloneDeep(this.state.files).concat(files);
            this.setState({files: newFiles});
            this.setState({dropzoneActive: false})
        }
    }

    onClose() {
        this.setState({files: [], error: '', dropzoneActive: false});
        this.props.onClose()
    }

    render() {
        return (
            <Modal
                onOpen={this.props.onOpen}
                onClose={this.onClose}
                open={this.props.visible}
            >
                <Modal.Header><Icon name='upload' />{this.props.header}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Dropzone
                            onDrop={this.onDrop}
                            onDragEnter={this.onDragEnter}
                            onDragLeave={this.onDragLeave}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <Container textAlign="center">
                                            {this.state.error && <AlertMessage level={AlertLevel.Error} heading={this.state.error} />}
                                            <p>Select files or drag and drop files here.</p>
                                            <input
                                                type="file"
                                                multiple={this.props.allowMultiple}
                                                onChange={this.handleFileInputChange}
                                                ref={this.fileInputRef}
                                                id="fileUploadInput"
                                                name="fileUploadInput"
                                                style={{opacity: 0, height: 0, width: 0}}
                                            />
                                            <Button
                                                icon='upload'
                                                content='Select File(s)'
                                                onClick={() => this.fileInputRef.current.click()}
                                            />
                                            {this.state.dropzoneActive && <p>Drop files to upload</p>}
                                        </Container>
                                        <Container>
                                            <FileList fileList={this.state.files} />
                                        </Container>

                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Modal.Description>
                    {this.props.children}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.onClose}>
                        Cancel
                    </Button>
                    <Button
                        color='blue'
                        onClick={this.handleSubmit}
                        disabled={this.isSubmissionDisabled()}
                        loading={this.state.uploading}
                    >
                        {this.props.submitButtonLabel}
                    </Button>

                </Modal.Actions>
            </Modal>
        )
    }
}

UploadFilesModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    visible: PropTypes.bool,
    header: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    submitButtonDisabled: PropTypes.bool,
    allowMultiple: PropTypes.bool,
}

UploadFilesModal.defaultProps = {
    visible: false,
    header: 'Upload Files',
    submitButtonLabel: 'Upload Files',
    submitButtonDisabled: false,
    allowMultiple: true,
}

export default UploadFilesModal