import React from 'react';
import { Route } from "react-router-dom";
import RequireAuthentication from 'common/auth/RequireAuthentication';
import ProgrammesHomePage from 'components/programme/ProgrammesHomePage';
import ProgrammePage from 'components/programme/ProgrammePage';
import CaseRouter from 'components/case/CaseRouter';
import CustomerRouter from 'components/customer/CustomerRouter';
import ReportRouter from 'components/reports/ReportRouter';
import AdminRouter from 'components/admin/AdminRouter';
import { subRoute } from 'common/util/RouteHelpers';
import { matchPath } from 'react-router';

const programmeRoute = "/programmes/:programmeId";
const ProgrammeRouter = ({match}) => {
    return (
        <>
            <Route path="/programmes" exact>
                <RequireAuthentication>
                    <ProgrammesHomePage />
                </RequireAuthentication>
            </Route>

            <Route
                path={programmeRoute}
                render={({match}) => {
                    return (
                        <RequireAuthentication>
                            <Route exact path={match.url}>
                                <ProgrammePage programmeId={match.params.programmeId} />
                            </Route>

                            <Route path={subRoute(match, '/cases')} component={CaseRouter} />

                            <Route path={subRoute(match, '/customers')} component={CustomerRouter} />

                            <Route path={subRoute(match, '/reports')} component={ReportRouter} />

                            <Route path={subRoute(match, '/admin')} component={AdminRouter} />

                        </RequireAuthentication>
                    );
                }}
            >
            </Route>

        </>
    );
};

function getProgrammeRoute(programmeId) {
    return `/programmes/${programmeId}`;
}

function getProgrammeIdFromPath(pathName) {
    const match = matchPath(pathName, {
        path: getProgrammeRoute(':programmeId'),
    });
    if (match) {
        return match.params.programmeId;
    }
}

function getProgrammeIdFromCurrentPath() {
    return getProgrammeIdFromPath(window.location.pathname);
}

export {
    ProgrammeRouter as default,
    getProgrammeRoute,
    getProgrammeIdFromPath,
    getProgrammeIdFromCurrentPath,
};
