import React from 'react';
import isNil from 'lodash';
import FileInput from 'common/FileInput';
import PropTypes from 'prop-types';

const FieldFileUpload = ({name, value, onChange, disabled, placeHolder, autoFocus}) => {
    const fileChanged = (newValue) => {
        onChange(name, newValue);
    };

    let fileName;
    let downloadUrl;
    if (!isNil(value)) {
        fileName = value.name;
        downloadUrl = value.url;
    }
    return (
        <FileInput
            onFileInputChange={fileChanged}
            filename={fileName}
            downloadUrl={downloadUrl}
            disabled={disabled}
            placeholder={placeHolder}
            autoFocus={autoFocus}
        />
    );
};

FieldFileUpload.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        name: PropTypes.string,
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    autoFocus: PropTypes.bool,
};

FieldFileUpload.defaultProps = {
    value: undefined,
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    autoFocus: false,
};

export default FieldFileUpload;
