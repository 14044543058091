import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'semantic-ui-react';
import {
    SearchResult,
    CustomerSearchResult,
    CaseSearchResult,
    ReviewTaskSearchResult,
    ProgrammeOfWorkSearchResult,
} from 'components/search/SearchResult';

const ResultType = {
    Customer: 'Customer',
    Case: 'Case',
    ReviewTask: 'ReviewTask',
    ProgrammeOfWork: 'ProgrammeOfWork',
};

const SearchResults = ({results}) => {
    return (
        <Item.Group>
            {results.map(item => {
                if (item.type === ResultType.Customer) {
                    return <CustomerSearchResult key={item.uri} result={item} />
                }
                if (item.type === ResultType.Case) {
                    return <CaseSearchResult key={item.uri} result={item} />
                }
                if (item.type === ResultType.ReviewTask) {
                    return <ReviewTaskSearchResult key={item.uri} result={item} />
                }
                if (item.type === ResultType.ProgrammeOfWork) {
                    return <ProgrammeOfWorkSearchResult key={item.uri} result={item} />
                }
                return <SearchResult key={item.uri} result={item} />;
            })}
        </Item.Group>
    );
};

SearchResults.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape(),
    ),
};

SearchResults.defaultProps = {
    results: [],
};

export default SearchResults;