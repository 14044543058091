import React from 'react';
import { TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const FieldTextArea = ({name, value, onChange, disabled, placeHolder, rows}) => {
    const valueChanged = (event) => {
        const target = event.target;
        onChange(name, target.value);
    };
    return (
        <TextArea
            className="form-control"
            onChange={valueChanged}
            value={value}
            disabled={disabled}
            placeholder={placeHolder}
            rows={rows}
        />
    );
};

FieldTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    rows: PropTypes.number,
};

FieldTextArea.defaultProps = {
    value: undefined,
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    rows: 1,
};

export default FieldTextArea;
