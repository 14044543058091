import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Search } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import Api from 'api/Api';
import { withRouter } from 'react-router-dom';

const MIN_SEARCH_CHARACTERS = 2;
const MAX_RESULTS = 15;
const SEARCH_KEYBOARD_DEBOUNCE = 300;

class HeaderSearch extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            loading: false,
            showNoResults: false,
            results: [],
            selectedResult: undefined,
        };
        this.submitSearch = debounce(this.submitSearch, SEARCH_KEYBOARD_DEBOUNCE);
    }

    submitSearch(query) {
        const queryRequest = {
            q: query,
            size: MAX_RESULTS,
            page: 1,
        };
        this.setState({loading: true});
        Api.post(`/api/search`, queryRequest)
            .then(this.setResults)
            .catch(this.onSearchError)
            .finally(() => this.setState({loading: false}));
    }

    onSearchError(error) {
        Api.logError(error);
        this.setResults({results: []});
    }

    categoriseResults(apiResults) {
        const results = {};
        apiResults.forEach(result => {
            let categoryEntry = results[result.type];
            if (!categoryEntry) {
                categoryEntry = {name: result.type, results: []};
                results[result.type] = categoryEntry;
            }
            categoryEntry.results = categoryEntry.results.concat([result]);
        });
        return results;
    }

    setResults(data) {
        this.setState({
            showNoResults: true,
            results: this.categoriseResults(data.results),
        });
    }

    onSearchChange(event, data) {
        const queryText = data.value.trim();
        if (queryText.length >= MIN_SEARCH_CHARACTERS) {
            this.submitSearch(queryText);
        }
    }

    onResultSelected(event, data) {
        this.props.history.push(data.result.uri);
    }

    onKeyDown(event) {
        const target = event.target;
        const queryText = target.value && target.value.trim();
        if (event.key === 'Enter' && queryText && queryText.length >= 1) {
            if (this.state.selectedResult) {
                this.props.history.push(this.state.selectedResult.uri);
            } else {
                this.props.history.push(`/search?q=${queryText}`);
            }
            event.target.blur();
            event.preventDefault();
            return;
        } else if (event.key === 'Escape') {
            this.clearSelected();
            return;
        } else if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
            this.clearSelected();
        }
    }

    onResultSelectionChanged(event, data) {
        this.setState({selectedResult: data.result})
    }

    clearSelected() {
        this.setState({selectedResult: undefined})
    }

    handleFocus(event) {
        event.target.select();
    }

    render() {
        return (
            <Search
                size="mini"
                category
                onSearchChange={this.onSearchChange}
                showNoResults={this.state.showNoResults}
                loading={this.state.loading}
                results={this.state.results}
                onSelectionChange={this.onResultSelectionChanged}
                onResultSelect={this.onResultSelected}
                onKeyDown={this.onKeyDown}
                aligned="right"
                onFocus={this.handleFocus}
            />
        );
    }
}

HeaderSearch.propTypes = {
    children: PropTypes.node,
};

HeaderSearch.defaultProps = {
    children: undefined,
};

export default withRouter(HeaderSearch);
