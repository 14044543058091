import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'semantic-ui-react';
import { ERROR_FIELD_REQUIRED, getErrorResponse, NoValidationError, isNilOrEmpty } from 'external/form/Validators';
import { getLabel } from 'common/form/FormHelpers';

const COUNTRIES_REQUIRING_STATE = [
    'Australia',
];

function getStateOptions() {
    return [
        {text: "ACT", value: "ACT"},
        {text: "NSW", value: "NSW"},
        {text: "NT", value: "NT"},
        {text: "QLD", value: "QLD"},
        {text: "SA", value: "SA"},
        {text: "TAS", value: "TAS"},
        {text: "VIC", value: "VIC"},
        {text: "WA", value: "WA"},
    ]
}

function requiresState(countryToCheck) {
    return (COUNTRIES_REQUIRING_STATE.find(
        item => item.toLowerCase() === countryToCheck.trim().toLowerCase())
            !== undefined)
}

const StateField = ({country, required, onFieldChange, disabled, value}) => {
    if (requiresState(country)) {
        return (
            <Form.Field
                control={Select}
                options={getStateOptions()}
                label={getLabel("State", required, 'state')}
                placeholder='State'
                onChange={onFieldChange}
                name='state'
                disabled={disabled}
                width={4}
                value={value}
            />
        );
    }
    return (
        <Form.Input
            type='text'
            label={getLabel('State', false, 'state')}
            placeholder='State'
            onChange={onFieldChange}
            width={4}
            value={value}
            name='state'
            disabled={disabled}
        />
    );
};

StateField.propTypes = {
    country: PropTypes.node,
    required: PropTypes.bool,
    onFieldChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.string,
};

StateField.defaultProps = {
    country: undefined,
    required: false,
    disabled: false,
    value: '',
};

function validateField(fieldData, fieldConfiguration) {
    if (!fieldData) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }

    const {street1, city, state, postcode, country} = fieldData;
    if (fieldConfiguration.required && (
        isNilOrEmpty(street1) ||
        isNilOrEmpty(city) ||
        (isNilOrEmpty(state) && requiresState(country)) ||
        isNilOrEmpty(postcode) ||
        isNilOrEmpty(country)
    )) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }
    return NoValidationError;
}

const FieldAddress = ({onChange, disabled, name, value, registerValidator, deregisterValidator, required}) => {

    const {street1, street2, city, state, postcode, country} = value;

    useEffect(() => {
        registerValidator(name, validateField);
        return deregisterValidator;
    }, [registerValidator, deregisterValidator, name])

    const onFormDataChange = (changedKey, newValue) => {
        const address = {
            street1: street1.trim(),
            street2: street2.trim(),
            city: city.trim(),
            state: state.trim(),
            postcode: postcode.trim(),
            country: country.trim(),
        }

        address[changedKey] = newValue;

        onChange(name, address);
    }

    const onFieldChange = (e, {name, value}) => {
        onFormDataChange(name, value)
    }

    return (
        <>
            <Form.Input
                type='text'
                placeholder='Address Line 1'
                onChange={onFieldChange}
                value={value.street1}
                name='street1'
                disabled={disabled}
            />
            <Form.Input
                type='text'
                label='Address Line 2'
                placeholder='Address Line 2'
                onChange={onFieldChange}
                value={value.street2}
                name='street2'
                disabled={disabled}
            />
            <Form.Group>
                <Form.Input
                    type='text'
                    label={getLabel('City', required, 'city')}
                    placeholder='City'
                    onChange={onFieldChange}
                    width={8}
                    value={value.city}
                    name='city'
                    disabled={disabled}
                />
                <StateField
                    country={value.country}
                    required={required}
                    onFieldChange={onFieldChange}
                    disabled={disabled}
                    value={value.state}
                />
                <Form.Input
                    type='text'
                    label={getLabel('Postcode', required, 'postcode')}
                    placeholder='Postcode'
                    onChange={onFieldChange}
                    width={4}
                    value={value.postcode}
                    name='postcode'
                    disabled={disabled}
                />
                <Form.Input
                    type='text'
                    label={getLabel('Country', required, 'country')}
                    placeholder='Country'
                    onChange={onFieldChange}
                    width={4}
                    value={value.country}
                    name='country'
                    disabled={disabled}
                />
            </Form.Group>
        </>
    )
}

FieldAddress.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
        country: PropTypes.string,
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldAddress.defaultProps = {
    value: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        postcode: "",
        country: "",
    },
    onChange: () => {},
    disabled: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldAddress;
