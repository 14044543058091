import React, { useState } from 'react';
import { Button, Checkbox, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { getHumanReadableFileSize } from 'common/util/Helpers';
import MediaViewer from 'common/MediaViewer';
import ShowWithPermission from 'common/auth/ShowWithPermission';
import Authority from 'common/auth/Authority';

const StagedFilesList = ({onFileSelect, files, onFileDelete, downloadUrl}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const onFileSelectHandler = (file, checked) => {
        let newSelectedFiles;
        if (checked) {
            newSelectedFiles = [...selectedFiles, file];
        } else {
            newSelectedFiles = selectedFiles.filter(({stagedFileId}) => file.stagedFileId !== stagedFileId);
        }

        setSelectedFiles(newSelectedFiles);
        if (onFileSelect) {
            onFileSelect(newSelectedFiles);
        }
    }

    const getFileName = (file) => {
        const fileNameAsArray = file.name.split(".");

        if (fileNameAsArray.length < 2) {
            return file.name;
        }
        return fileNameAsArray.slice(0, -1).join('.');
    }

    const getFileExt = (file) => {
        const fileNameAsArray = file.name.split(".");
        if (fileNameAsArray.length < 2) {
            return null;
        }
        return fileNameAsArray.pop();
    }

    const isChecked = (file) => {
        return selectedFiles.some(({stagedFileId}) => stagedFileId === file.stagedFileId);
    }

    return (
        <Table striped unstackable>
            <Table.Header>
                <Table.Row>
                    {onFileSelect && <Table.HeaderCell>Select</Table.HeaderCell>}
                    <Table.HeaderCell colSpan="2">Name</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Size</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {files.map((file, i) => {
                    return (
                        <Table.Row key={file.stagedFileId}>
                            {onFileSelect && (<Table.Cell><Checkbox
                                checked={isChecked(file)}
                                onChange={(
                                    e,
                                    {checked},
                                ) => onFileSelectHandler(file, checked)}
                            /></Table.Cell>)}
                            <Table.Cell style={{width: '100px'}} textAlign="center">
                                <MediaViewer {...file.previewDetails} />
                            </Table.Cell>
                            <Table.Cell>
                                {getFileName(file)}
                            </Table.Cell>
                            <Table.Cell>{getFileExt(file)}</Table.Cell>
                            <Table.Cell>{getHumanReadableFileSize(file.sizeInBytes)}</Table.Cell>
                            <Table.Cell textAlign="right">
                                <Button
                                    compact
                                    as='a'
                                    target='_blank'
                                    download
                                    href={`${downloadUrl}/${file.storageFileId}/download`}
                                    icon='download'
                                />
                                {onFileDelete &&
                                 <ShowWithPermission permission={Authority.UploadDocuments}>
                                     <Button
                                         compact
                                         icon='trash'
                                         onClick={() => onFileDelete(file)}
                                     />
                                 </ShowWithPermission>
                                }
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}

StagedFilesList.propTypes = {
    onFileSelect: PropTypes.func,
    onFileDelete: PropTypes.func,
    downloadUrl: PropTypes.string,
    files: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            sizeInBytes: PropTypes.number,
            stagedFileId: PropTypes.string,
        }),
    ),
}

StagedFilesList.defaultProps = {
    onFileSelect: undefined,
    onFileDelete: undefined,
    files: [],
}

export default StagedFilesList;