import React, { useState } from 'react';
import { Button, Divider, Form, Header, Icon, Message, Modal } from 'semantic-ui-react';

import { useHistory } from 'react-router-dom';
import Api from 'api/Api';

const PasswordChangeModal = (): JSX.Element => {

    const changePasswordRoute = '/api/user/password';
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(true);
    const [currentPasswordInvalid, setCurrentPasswordInvalid] = useState<boolean>(false);
    const [newPasswordsInvalid, setNewPasswordsInvalid] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string | null>(null);
    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    function onClose() {
        setShowModal(false);
        history.goBack();
    }

    function validCurrentPassword(): boolean {
        return !!currentPassword && currentPassword.length >= 5;
    }

    function validNewPasswords(): boolean {
        return (
            !!newPassword && newPassword.length >= 5 &&
            newPasswordConfirmation === newPassword
        );
    }

    function handleSubmit(): void {
        if (!validNewPasswords()) {
            setNewPasswordsInvalid(true);
        }
        if (!validCurrentPassword()) {
            setCurrentPasswordInvalid(true);
        }
        if (!validNewPasswords() || !validCurrentPassword()) {
            return;
        }

        Api.put(changePasswordRoute, {currentPassword, newPassword})
            .then(() => {
                handlePasswordChangeSuccess();
            })
            .catch(handlePasswordChangeError);
    }

    function handlePasswordChangeSuccess(): void {
        setSuccess(true);
    }

    function handlePasswordChangeError(): void {
        setCurrentPasswordInvalid(true);
        Api.logError("The current password is invalid");
    }

    function handleCurrentPasswordChange(event: React.FormEvent<HTMLInputElement>): void {
        setCurrentPasswordInvalid(false);
        setSuccess(false);
        setCurrentPassword(event.currentTarget.value);
    }

    function handleNewPasswordChange(event: React.FormEvent<HTMLInputElement>): void {
        setNewPassword(event.currentTarget.value);
        setSuccess(false);
        setNewPasswordsInvalid(false);
    }

    function handleNewPasswordConfirmationChange(event: React.FormEvent<HTMLInputElement>): void {
        setNewPasswordConfirmation(event.currentTarget.value);
        setSuccess(false);
        setNewPasswordsInvalid(false);
    }

    return (
        <Modal open={showModal} size="tiny" closeOnEscape onClose={onClose} centered={false}>
            <Header as="h3" textAlign="left">
                Change your password
            </Header>
            <Modal.Content>
                {!success && <Modal.Description>
                    <Form size="large" widths="equal">
                        <Form.Field>
                            <Form.Input
                                label="Current password"
                                labelPosition="left"
                                fluid icon="lock"
                                iconPosition="left"
                                type="password"
                                name="oldpassword"
                                placeholder="Current password"
                                onChange={handleCurrentPasswordChange}
                                autoComplete="password"
                                error={currentPasswordInvalid && 'Current password is invalid'}
                            />
                        </Form.Field>
                        <Divider horizontal />
                        <Form.Input
                            label="New password"
                            labelPosition="left"
                            fluid icon="lock"
                            type="password"
                            iconPosition="left"
                            name="new-password"
                            placeholder="New password"
                            onChange={handleNewPasswordChange}
                        />
                        <Form.Input
                            label="Confirm new password"
                            labelPosition="left"
                            fluid icon="lock"
                            type="password"
                            iconPosition="left"
                            name="new-password-confirmation"
                            placeholder="New password confirmation"
                            onChange={handleNewPasswordConfirmationChange}
                            error={newPasswordsInvalid
                                && 'New and confirmation passwords must be provided and must be identical.  Passwords must be greater than four characters'}
                        />
                    </Form>
                </Modal.Description>
                }
                {success &&
                    <Message positive>
                        <Message.Header>Your password has been changed</Message.Header>
                    </Message>
                }
            </Modal.Content>
            <Modal.Actions>
                {!success && <Button size="large" onClick={() => onClose()}>
                    <Icon name="remove" /> Cancel
                </Button>}

                {!success && <Button primary size="large" onClick={handleSubmit}>
                    <Icon name="checkmark" /> Change Password
                </Button>}
                {success && <Button primary size="large" onClick={() => onClose()}>
                    Done
                </Button>}

            </Modal.Actions>


        </Modal>

    );
};

export default PasswordChangeModal;