import React from 'react';
import {Header, Segment, Table} from 'semantic-ui-react';
import MediaViewer from 'common/MediaViewer';

interface CaseLink {
    id: string;
    name: string;
}

interface CaseFileLink extends CaseLink {
    previewDetails: any;
}

interface CaseRecordLink extends CaseLink {
    // TODO:
}

type Props = {
    readonly linkedFiles: CaseFileLink[];
    readonly linkedRecords: CaseRecordLink[];
}

const CaseRecordLinks = (props: Props): JSX.Element => {

    const getLinkedFileRow = (link: CaseFileLink, index: number) => {
        return (
            <Table.Row key={`${index}-${link.id}`}>
                <Table.Cell style={{width: '100px'}} textAlign="center">
                    <MediaViewer {...link.previewDetails} showThumbnail={false} />
                </Table.Cell>
                <Table.Cell>{link.name}</Table.Cell>
            </Table.Row>
        );
    }

    const getLinkedRecordRow = (link: CaseRecordLink, index: number) => {
        return (
            <Table.Row key={`${index}-${link.id}`}>
                <Table.Cell />
                <Table.Cell>{link.name}</Table.Cell>
            </Table.Row>
        );
    }

    const numberOfLinkedDocuments = props.linkedFiles.length + props.linkedRecords.length;
    return (
        <>
            <Header as='h4' attached='top' block>
                Supporting Documents
            </Header>
            {numberOfLinkedDocuments === 0 && (
                <Segment textAlign="center" attached="bottom">
                    <Header as='h5' content='No supporting documents' />
                </Segment>
            )}
            {numberOfLinkedDocuments > 0 && (
                <Table attached unstackable >
                    <Table.Body>
                        {props.linkedFiles.map((link, i) => (
                            getLinkedFileRow(link, i)
                        ))}
                        {props.linkedRecords.map((link, i) => (
                            getLinkedRecordRow(link, i)
                        ))}
                    </Table.Body>
                </Table>
            )}
        </>
    );
}

export default CaseRecordLinks;