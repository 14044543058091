import React, { createRef } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import CaseDetails from 'components/case/CaseDetails';
import CaseFieldData from 'components/case/CaseFieldData';
import CaseHistory from 'components/case/CaseHistory';
import CaseReviewStage from 'components/case/reviews/CaseReviewStage';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Loader,
    Menu,
    Modal,
    Ref,
    Sticky,
} from 'semantic-ui-react';
import CustomerDetails from 'components/customer/CustomerDetails';
import Api from 'api/Api';
import { withHeaderAndBreadcrumbs } from 'components/common/PageHeader';
import { getCaseRoute } from 'components/case/CaseRouter';
import { subRoute } from 'common/util/RouteHelpers';
import ReviewDetails from 'components/case/reviews/ReviewDetails';
import 'components/case/CasePage.scss';
import CustomerSelector from 'components/customer/CustomerSelector';
import cloneDeep from 'lodash/cloneDeep';
import ShowWithPermission, { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import Authority from 'common/auth/Authority';
import { getCustomerRoute } from 'components/customer/CustomerRouter';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const SideBarItems = [
    {name: 'summary', icon: 'briefcase', text: 'Case Summary'},
    {name: 'stage', icon: 'tasks', text: 'Case Review Stage'},
    {name: 'data', icon: 'table', text: 'Case Data'},
    {name: 'history', icon: 'clock outline', text: 'Case History'},
];

const EXPORT_FORMATS = ['pdf', 'html'];

const ConfirmCustomerRemoval = (props) => {
    return (
        <Modal open={props.open} size="small" closeOnEscape={true} onClose={props.onCancel} centered={false}>
            <Header icon="archive" content="Confirm Remove" />
            <Modal.Content>
                Are you sure you want to remove '{props.customer.customerName}' from the case?
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={props.onCancel}>
                    <Icon name="remove" /> No
                </Button>
                <Button color="green" onClick={() => props.onConfirm(props.customer.customerId)}>
                    <Icon name="checkmark" /> Yes
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

ConfirmCustomerRemoval.propTypes = {
    open: PropTypes.bool.isRequired,
    customer: PropTypes.shape().isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

const SideBarMenuItem = ({name, active, icon, text, onItemClicked}) => {
    return (
        <Menu.Item
            name={name}
            active={active}
            onClick={onItemClicked}
        >
            <Container fluid>
                <Icon name={icon} /> {text}
            </Container>
        </Menu.Item>
    );
};

const CustomerMenuItem = ({name, active, deletable, text, onItemClicked, onItemDelete}) => {

    const onDelete = (e) => {
        e.stopPropagation();
        onItemDelete();
    };

    return (
        <Menu.Item
            name={name}
            as={'a'}
            active={active}
            className="customer-menu-item"
            onClick={onItemClicked}
        >
            <Container fluid>
                <div className="customer-menu-item-inner">
                    <div className="customer-menu-item text">
                        <Icon name="user" /> {text}
                    </div>
                    <ShowWithOneOfPermission permissions={[Authority.EditCase]}>
                        {deletable &&
                            <div onClick={onDelete} className="customer-menu-item button">
                                <Icon name="remove" />
                            </div>
                        }
                    </ShowWithOneOfPermission>
                </div>
            </Container>
        </Menu.Item>
    );
};

const AddCustomerMenuItem = ({name, onItemClicked}) => {
    return (
        <Menu.Item
            name={name}
            onClick={onItemClicked}
        >
            <Container>
                <Icon name="plus" size="small" /> Add Customer
            </Container>
        </Menu.Item>
    );
};

const CaseSideBar = ({caseData, activeItem, onItemClicked, onAddCustomer, onRemoveCustomer, history}) => {
    return (
        <>
            <Menu vertical secondary fluid>
                {SideBarItems.map((item) => {
                    return (
                        <SideBarMenuItem
                            key={item.name}
                            name={item.name}
                            active={activeItem === item.name}
                            icon={item.icon}
                            text={item.text}
                            onItemClicked={() => onItemClicked(item.name)}
                        />
                    );
                })}
                <Divider horizontal>Customers</Divider>
                {caseData.customers.map(customer => {
                    const navigateToCustomer = () => {
                        history.push(`${getCustomerRoute(getProgrammeIdFromCurrentPath(), customer.customerId)}`);
                    };

                    return (
                        <CustomerMenuItem
                            key={customer.customerId}
                            name={customer.customerName}
                            active={activeItem === customer.customerId}
                            deletable={caseData.customers.length > 1}
                            text={customer.customerName}
                            onItemClicked={navigateToCustomer}
                            onItemDelete={() => onRemoveCustomer(customer)}
                        />
                    );
                })}
                <ShowWithOneOfPermission permissions={[Authority.EditCase]}>
                    {caseData.customers.length === 1 &&
                        <AddCustomerMenuItem name="addCustomer" onItemClicked={onAddCustomer} />
                    }
                </ShowWithOneOfPermission>
            </Menu>
        </>
    );
};

const CaseCustomerSection = withHeaderAndBreadcrumbs(CustomerDetails);
const CaseSummarySection = withHeaderAndBreadcrumbs(CaseDetails);
const CaseDataSection = withHeaderAndBreadcrumbs(CaseFieldData);
const CaseHistorySection = withHeaderAndBreadcrumbs(CaseHistory);

class CasePage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            caseData: undefined,
            activeItem: props.activeItem,
            addingCustomer: false,
            removingCustomer: null,
        };

        this.contextRef = createRef();
    }

    componentDidMount() {
        this.getCaseDetails(this.props.caseId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeItem !== prevProps.activeItem) {
            this.setState({activeItem: this.props.activeItem});
        }
    }

    getCaseDetails() {
        this.setState({loading: true});
        Api.get(`/api/cases/${this.props.caseId}`)
            .then(this.setCase)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setCase(data) {
        this.setState({
            caseData: data,
        });
    }

    handleItemClick(item) {
        this.setState({activeItem: item});
        this.props.history.push(`${getCaseRoute(getProgrammeIdFromCurrentPath(), this.props.caseId)}/${item}`);
    }

    addCustomerToCase(customer) {
        const caseData = cloneDeep(this.state.caseData);
        caseData.customers.push({
            customerId: customer.id,
            customerName: customer.text,
        });
        this.updateCaseCustomers(caseData, null);
    }

    removeCustomerFromCase(customerId) {
        const caseData = cloneDeep(this.state.caseData);
        caseData.customers = this.state.caseData.customers.filter((c) => c.customerId !== customerId);
        const nextActiveItem = this.state.activeItem === customerId ? caseData.customers[0].customerId : null;
        this.updateCaseCustomers(caseData, nextActiveItem);
    }

    updateCaseDisplay(item) {
        if (item !== null) {
            this.handleItemClick(item);
        } else {
            this.getCaseDetails();
        }
    }

    updateCaseCustomers(caseData, nextActiveItem) {
        const update = {
            customerIds: caseData.customers.map((customer) => customer.customerId),
        };
        Api.put(`/api/cases/${this.props.caseId}`, update)
            .then(() => this.setState({
                caseData: caseData,
            }))
            .then(() => this.updateCaseDisplay(nextActiveItem))
            .catch(Api.logError)
            .finally(() => this.setState(
                {
                    removingCustomer: null,
                    addingCustomer: false,
                }));
    }

    onAddCustomer() {
        this.setState({addingCustomer: true});
    }

    onRemoveCustomer(customer) {
        this.setState({removingCustomer: customer});
    }

    getBreadcrumbs() {
        const breadcrumbs = [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'Cases', content: 'My Cases'},
            {key: 'Case', content: `Case Number: ${this.state.caseData.displayId}`},
        ];

        if (this.props.activeItem === 'stage') {
            breadcrumbs.push({key: 'stage', content: 'Case Review Stage'});
        } else if (this.props.activeItem === 'customer') {
            breadcrumbs.push({key: 'customer', content: 'Customer Details'});
        } else if (this.props.activeItem === 'summary') {
            breadcrumbs.push({key: 'summary', content: 'Case Summary'});
        } else if (this.props.activeItem === 'data') {
            breadcrumbs.push({key: 'data', content: 'Case Data'});
        } else if (this.props.activeItem === 'history') {
            breadcrumbs.push({key: 'history', content: 'Case History'});
        } else {
            breadcrumbs[breadcrumbs.length - 1] = {
                key: 'Case',
                content: `Case ${this.state.caseData.displayId}`,
            };
        }

        return breadcrumbs;
    }

    getCaseExportLink(caseId, format) {
        return `/api/etl/case/${caseId}/${format}`;
    }

    getExportButtons() {
        return (
            <ShowWithPermission permission={Authority.ImportExport}>
                <Button.Group>
                    <Button
                        as={Link}
                        to={this.getCaseExportLink(this.props.caseId, EXPORT_FORMATS[0])}
                        content={"Export"}
                        download
                        target="_blank"
                    />
                    <Dropdown
                        className="button icon"
                    >
                        <Dropdown.Menu>
                            {EXPORT_FORMATS.map(item => {
                                return <Dropdown.Item
                                    key={item}
                                    text={item.toUpperCase()}
                                    as={Link}
                                    to={this.getCaseExportLink(this.props.caseId, item)}
                                    download
                                    target="_blank"
                                />;
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Button.Group>
            </ShowWithPermission>
        );
    }

    render() {
        if (this.state.loading || !this.state.caseData) {
            return <Loader active />;
        }
        const caseCustomerIds = this.state.caseData.customers.map((customer) => {
            return customer.customerId;
        });
        return (
            <>
                {this.state.removingCustomer &&
                    <ConfirmCustomerRemoval
                        open={true}
                        customer={this.state.removingCustomer}
                        onConfirm={this.removeCustomerFromCase}
                        onCancel={() => this.setState({removingCustomer: null})}
                    />}
                <CustomerSelector
                    open={this.state.addingCustomer}
                    onCustomerSelected={this.addCustomerToCase}
                    onCancel={() => this.setState({addingCustomer: false})}
                    excludedCustomerIds={caseCustomerIds}
                />
                <Ref innerRef={this.contextRef}>
                    <Grid padded="vertically">
                        <Grid.Column tablet={3} widescreen={2}>
                            <Sticky context={this.contextRef} offset={70}>
                                <CaseSideBar
                                    caseData={this.state.caseData}
                                    activeItem={this.state.activeItem}
                                    onItemClicked={this.handleItemClick}
                                    onAddCustomer={this.onAddCustomer}
                                    onRemoveCustomer={this.onRemoveCustomer}
                                    history={this.props.history}
                                />
                            </Sticky>
                        </Grid.Column>
                        <Grid.Column tablet={13} widescreen={12}>
                            {this.state.activeItem === 'stage' &&
                                <CaseReviewStage
                                    taskId={this.state.caseData.reviewTaskId}
                                    breadcrumbs={this.getBreadcrumbs()}
                                />}
                            {this.state.caseData.customers.some(c => c.customerId === this.state.activeItem) &&
                                <CaseCustomerSection
                                    text={'Customer Details'}
                                    icon={'user'}
                                    customerId={this.state.activeItem}
                                    onError={() => {}}
                                    unattached
                                    breadcrumbs={this.getBreadcrumbs()}
                                />
                            }
                            {this.state.activeItem === 'summary' &&
                                <CaseSummarySection
                                    text={'Case Summary'}
                                    icon={'briefcase'}
                                    taskId={this.state.caseData.reviewTaskId}
                                    caseId={this.props.caseId}
                                    unattached
                                    breadcrumbs={this.getBreadcrumbs()}
                                />
                            }
                            {this.state.activeItem === 'data' &&
                                <CaseDataSection
                                    text={'Case Data'}
                                    icon={'archive'}
                                    taskId={this.state.caseData.reviewTaskId}
                                    caseId={this.props.caseId}
                                    unattached
                                    breadcrumbs={this.getBreadcrumbs()}
                                    headerRightComponent={this.getExportButtons()}
                                />
                            }
                            {this.state.activeItem === 'history' &&
                                <>
                                    <Route
                                        path={this.props.match.url}
                                        exact
                                        render={({match}) => (
                                            <CaseHistorySection
                                                text={'Case History'}
                                                icon={'clock outline'}
                                                caseId={this.props.caseId}
                                                taskId={this.state.caseData.reviewTaskId}
                                                unattached
                                                breadcrumbs={this.getBreadcrumbs()}
                                            />
                                        )}
                                    />

                                    <Route
                                        path={subRoute(this.props.match, '/:reviewStepId')}
                                        exact
                                        render={({match}) => (
                                            <ReviewDetails
                                                reviewStepId={match.params.reviewStepId}
                                                displayId={this.state.caseData.displayId}
                                            />
                                        )}
                                    />
                                </>
                            }
                        </Grid.Column>
                    </Grid>
                </Ref>
            </>
        );
    }
}

CasePage.propTypes = {
    caseId: PropTypes.string.isRequired,
    activeItem: PropTypes.string,
};

CasePage.defaultProps = {
    activeItem: SideBarItems[0].name,
};

export default withRouter(CasePage);
