import React from 'react';
import PropTypes from 'prop-types';
import { withUserContextProp } from 'UserContext';
import { Redirect, withRouter } from 'react-router-dom';
import { LOGIN_PATH } from 'api/Api';

class RequireAuthentication extends React.Component {

    render() {
        if (!this.props.userContext.isLoggedIn()) {
            let pathname = this.props.location.pathname;
            if (pathname.includes(LOGIN_PATH)) {
                pathname = "/";
            }
            return <Redirect
                to={{
                    pathname: LOGIN_PATH,
                    search: this.props.location.search,
                    state: {referrer: pathname},
                }}
            />;
        }
        return this.props.children;
    }
}

RequireAuthentication.propTypes = {
    children: PropTypes.node,
};

RequireAuthentication.defaultProps = {
    children: undefined,
};

export default withRouter(withUserContextProp(RequireAuthentication));