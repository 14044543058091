/**
 * Supported file types and mapping to corresponding font awesome icon.
 *
 * Mapping file to mime type should be performed server side. Need only need mapping
 * between mime type and icon.
 *
 * Reference: https://github.com/jshttp/mime-db/blob/master/src/apache-types.json
 */

const FILE_TYPES = [
    {
        name: 'WEBM',
        mimeType: 'video/webm',
        extensions: ['webm'],
        icon: 'file video outline',
    },
    {
        name: 'GIF',
        mimeType: 'image/gif',
        extensions: ['gif'],
        icon: 'file image outline',
    },
    {
        name: 'Email',
        mimeType: 'message/rfc822',
        extensions: ['eml', 'msg'],
        icon: 'file alternate outline',
    },
    {
        name: 'MS Excel',
        mimeType: 'application/vnd.ms-excel',
        extensions: ['xls', 'xlm', 'xla', 'xlc', 'xlt', 'xlw'],
        icon: 'file excel outline',
    },
    {
        name: 'MS Excel',
        mimeType: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        extensions: ['xlsx'],
        icon: 'file excel outline',
    },
    {
        name: 'JPEG',
        mimeType: 'image/jpeg',
        extensions: ['jpeg', 'jpg', 'jpe'],
        icon: 'file image outline',
    },
    {
        name: 'MP3',
        mimeType: 'audio/mpeg',
        extensions: ['mpga', 'mp2', 'mp2a', 'mp3', 'm2a', 'm3a', 'm4a'],
        icon: 'file audio outline',
    },
    {
        name: 'MPEG',
        mimeType: 'video/mpeg',
        extensions: ['mpeg', 'mpg', 'mpe', 'm1v', 'm2v'],
        icon: 'file video outline',
    },
    {
        name: 'WMV',
        mimeType: 'video/x-ms-wmv',
        extensions: ['wmv'],
        icon: 'file video outline',
    },
    {
        name: 'MP4',
        mimeType: 'video/mp4',
        extensions: ['mp4'],
        icon: 'file video outline',
    },
    {
        name: 'MS Word',
        mimeType: 'application/msword',
        extensions: ['dot', 'doc'],
        icon: 'file word outline',
    },
    {
        name: 'MS Word',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        extensions: ['docx'],
        icon: 'file word outline',
    },
    {
        name: 'OpenDocument Sheet',
        mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
        extensions: ['ods'],
        icon: 'file excel outline',
    },
    {
        name: 'PDF',
        mimeType: 'application/pdf',
        extensions: ['pdf'],
        icon: 'file pdf outline',
    },
    {
        name: 'PNG',
        mimeType: 'image/png',
        extensions: ['png'],
        icon: 'file image outline',
    },
    {
        name: 'Rich Text Format',
        mimeType: 'application/rtf',
        extensions: ['rtf'],
        icon: 'file alternate outline',
    },
    {
        name: 'Text',
        mimeType: 'text/plain',
        extensions: ['txt'],
        icon: 'file alternate outline',
    },
    {
        name: 'TIFF',
        mimeType: 'image/tiff',
        extensions: ['tiff', 'tif'],
        icon: 'file image outline',
    },
    {
        name: 'WAV',
        mimeType: 'audio/x-wav',
        extensions: ['wav'],
        icon: 'file audio outline',
    },
    {
        name: 'ZIP',
        mimeType: 'application/zip',
        extensions: ['zip'],
        icon: 'file archive outline',
    },
];

const lookupFileType = (mimeType) => {
    const type = FILE_TYPES.find(fileType => fileType.mimeType === mimeType);
    if (type) {
        return type;
    }
    return {
        name: 'Unknown',
        mimeType: 'unknown',
        extensions: [],
        icon: 'file alternate outline',
    };
};

const getExtension = (filename) => {
    const extension = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : '';
    return extension.toString();
};

const getMimeType = (filename) => {
    const extension = getExtension(filename);
    if (extension) {
        const type = FILE_TYPES.find((fileType) => {
            const index = fileType.extensions.indexOf(extension);
            return index !== -1;
        });
        if (type) {
            return type.mimeType;
        }
    }
    return 'unknown';
};

const isImageContent = (mimeType) => {
    return mimeType.startsWith('image');
};

export {
    lookupFileType,
    getExtension,
    getMimeType,
    isImageContent,
};

