import React from 'react';
import {Icon, Message} from 'semantic-ui-react';

const UnderConstructionWarning = () => {
    return (
        <Message attached='bottom' warning>
            <Icon name='warning sign' /> This page is still under construction
        </Message>
    );
};

export default UnderConstructionWarning;