import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Loader, Message } from 'semantic-ui-react';
import PageHeader from 'components/common/PageHeader';
import GenericForm from 'common/form/SemanticUIForm';
import { withRouter } from 'react-router-dom';
import Api from 'api/Api';

class NewUserForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: this.getFormSchema(),
            formData: {},
            loading: false,
            error: '',
        };
    }

    componentDidMount() {
        this.getFields();
    }

    getFields() {
        this.setState({loading: true});
        Api.get(`/api/groups`)
            .then(this.setGroups)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setGroups(data) {
        const fieldOptions = data.map(group => {
            return {
                id: group.id,
                text: group.name,
            };
        });

        this.setState({
            schema: this.getFormSchema(fieldOptions),
        });
    }

    getBreadcrumbs() {
        return [

            {key: 'Administration', content: 'Administration'},
            {key: 'Users', content: 'Users & User Groups'},
            {key: 'New User', content: 'New User'},
        ];
    }

    getFormSchema(groups = []) {
        return {
            fields: {
                username: {
                    type: 'text',
                    label: 'Username',
                    placeHolder: '',
                    required: true,
                },
                displayName: {
                    type: 'text',
                    label: 'Display Name',
                    required: true,
                    placeholder: '',
                },
                password: {
                    type: 'password',
                    label: 'Password',
                    required: true,
                },
                groupIds: {
                    type: 'multiselect',
                    label: 'Groups',
                    required: true,
                    placeHolder: '',
                    options: groups,
                },
            },
            fieldOrder: [
                'username',
                'displayName',
                'password',
                'groupIds',
            ],
        };
    }

    onFormDataChanged(data) {
        this.setState({formData: data});
    }

    onSubmit(formData) {
        this.setState({
            saving: true,
            error: '',
        });
        return Api.post(`/api/users`, formData);
    }

    onCancel() {
        this.props.redirect();
    }

    onSubmitSuccess() {
        this.setState({saving: false});
        this.props.redirect();
    }

    onError(error) {
        Api.logError(error);
        this.setState({error: 'Error while creating new user', saving: false});
    }

    render() {
        if (this.state.loading || this.state.loadingConfig) {
            return <Loader active />;
        }
        return (
            <>
                <PageHeader icon="user" text="New User" breadcrumbs={this.getBreadcrumbs()} />
                {this.state.error &&
                    <Message negative>
                        <Message.Header>Could not create user</Message.Header>
                        <p>{this.state.error}</p>
                    </Message>
                }
                <GenericForm
                    schema={this.state.schema}
                    onFormDataChanged={this.onFormDataChanged}
                    formData={this.state.formData}
                    navigationPrompt={false}
                    showButtons={true}
                    styleClass="left-align-fields"
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    onSubmitSuccess={this.onSubmitSuccess}
                    onSubmitError={this.onError}
                    submitButtonDisabled={this.state.saving}
                />
            </>
        );
    }
}

NewUserForm.propTypes = {
    redirect: PropTypes.func.isRequired,
};

NewUserForm.defaultProps = {};

export default withRouter(NewUserForm);