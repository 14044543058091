/**
 * Allow classes to autobind all their functions
 * Usage: in the class constructor:
 *      autobind(this);
 * @param self The class instance (e.g. this)
 * @returns The class instance
 */
const autoBind = (self) => {
    Object.getOwnPropertyNames(self.constructor.prototype).forEach((propertyName) => {
        const val = self[propertyName];

        if (propertyName !== 'constructor' && typeof val === 'function') {
            self[propertyName] = val.bind(self); // eslint-disable-line no-param-reassign
        }
    });

    return self;
};

export default autoBind;
