import React from 'react';
import PropTypes from 'prop-types';
import { withUserContextProp } from 'UserContext';
import { Redirect, withRouter } from 'react-router-dom';
import { userHasAllPermissions, userHasAnyPermission } from 'common/auth/Authority';

const RedirectToLoginPage = ({location}) => (
    <Redirect
        to={{
            pathname: "/login",
            search: `?redirect=${location.pathname}`,
            state: {referrer: location.pathname},
        }}
    />
);

RedirectToLoginPage.propTypes = {
    location: PropTypes.shape().isRequired,
};

let RequireAnyPermission = ({permissions, userContext, location, children}) => {
    const hasPermission = userContext.user.permissions && userHasAnyPermission(permissions, userContext);
    if (!hasPermission) {
        return <RedirectToLoginPage location={location} />;
    }

    return (
        <>
            {children}
        </>
    );
};

RequireAnyPermission.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
};

RequireAnyPermission.defaultProps = {
    permissions: [],
};

let RequireAllPermissions = ({permissions, userContext, location, children}) => {
    const hasPermission = userContext.permissions && userHasAllPermissions(permissions, userContext);
    if (!hasPermission) {
        return <RedirectToLoginPage location={location} />;
    }

    return (
        <>
            {children}
        </>
    );
};

RequireAllPermissions.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
};

RequireAllPermissions.defaultProps = {
    permissions: [],
};

RequireAnyPermission = withRouter(withUserContextProp(RequireAnyPermission));
RequireAllPermissions = withRouter(withUserContextProp(RequireAllPermissions));

const RequirePermission = ({permission, children}) => {
    return (
        <RequireAnyPermission permissions={[permission]}>
            {children}
        </RequireAnyPermission>
    );
};

RequirePermission.propTypes = {
    permission: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export {
    RequirePermission as default,
    RequireAnyPermission,
    RequireAllPermissions,
};