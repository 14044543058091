import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import { withRouter } from 'react-router-dom';
import { Container, Divider, Search } from 'semantic-ui-react';
import Api from 'api/Api';
import SearchResults from 'components/search/SearchResults';
import isEqual from 'lodash/isEqual';

const MAX_RESULTS = 20;
const PAGE_NUMBER = 1; // TODO: Allow paged results
const URL_PARAMS = {
    QueryString: 'q',
    Size: 'size',
    Page: 'page',
};

class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        const queryObject = this.getQueryFromUrl();
        this.state = {
            searchInputText: queryObject.q,
            queryObject: queryObject,
            results: [],
            loading: false,
            size: MAX_RESULTS,
            page: PAGE_NUMBER,
            responseReceived: false,
        };
    }

    componentDidMount() {
        if (this.state.queryObject.q) {
            this.submitSearch(this.state.queryObject);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const queryObject = this.getQueryFromUrl();
        if (!isEqual(queryObject, this.state.queryObject)) {
            this.setState({
                queryObject: queryObject,
                searchInputText: queryObject.q,
            })
        }
        if (!isEqual(this.state.queryObject, prevState.queryObject)) {
            this.submitSearch(this.state.queryObject);
        }
    }

    getQueryFromUrl() {
        const params = new URLSearchParams(this.props.location.search);
        const queryObject = {
            q: "",
        };

        if (params.has(URL_PARAMS.QueryString)) {
            queryObject.q = params.get(URL_PARAMS.QueryString);
        }

        if (params.has(URL_PARAMS.Size)) {
            queryObject.size = params.get(URL_PARAMS.Size);
        }

        if (params.has(URL_PARAMS.Page)) {
            queryObject.page = params.get(URL_PARAMS.Page);
        }
        return queryObject;
    }

    submitSearch(queryObject) {
        this.setState({loading: true});
        Api.post(`/api/search`, queryObject)
            .then(this.setResults)
            .catch(this.onSearchError)
            .finally(() => this.setState({loading: false}));
    }

    setResults(data) {
        this.setState({
            showNoResults: true,
            results: data.results,
            responseReceived: true,
        });
    }

    onSearchChange(event, data) {
        this.setState({searchInputText: data.value})
    }

    onResultSelected(event, data) {
        this.props.history.push(data.result.uri);
    }

    handleEnter(event) {
        const target = event.target;
        const queryText = target.value && target.value.trim();
        if (event.key === 'Enter' && queryText && queryText.length >= 1) {
            this.props.history.push(`/search?q=${queryText}`);
            event.preventDefault();
        }
    }

    render() {
        return (
            <Container textAlign="left">
                <h3>
                    Search
                    <Search
                        value={this.state.searchInputText}
                        onSearchChange={this.onSearchChange}
                        showNoResults={false}
                        loading={this.state.loading}
                        onKeyDown={this.handleEnter}
                    />
                </h3>
                <Divider />
                {(this.state.responseReceived && this.state.results.length === 0) &&
                 <Container textAlign="left">
                     <em>No results found.</em>
                 </Container>
                }
                <Container textAlign="left">
                    <SearchResults results={this.state.results} />
                </Container>
            </Container>
        );
    }
}

SearchPage.propTypes = {};

SearchPage.defaultProps = {};

export default withRouter(SearchPage);