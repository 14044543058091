import { useEffect, useState } from 'react';

/**
 * A hook that performs a fetch request to a supplied url.
 *
 * Supports returning any errors, loading states and is cancellable if the component unmounts.
 *
 * @example
 * const {response, error, loading} = useFetch<ResponseData>("/api/getdata");
 * // response will be null until request is finished.
 *
 * @param {string} url The url to fetch from
 * @param {any[]} [dependencies=[]] (optional) List of dependencies. Will re-fetch if dependencies change.
 *
 * @returns {{response:ResponseType, error: any, loading: boolean}} {response, error, loading}
 * */
export const useFetch = <ResponseType>(url: string, dependencies: any[] = []) => {
    const [response, setResponse] = useState<ResponseType | null>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const doFetch = async () => {
            setLoading(true);
            try {
                const response = await fetch(url);
                if (response.ok) {
                    const json: ResponseType = await response.json() as ResponseType;
                    if (!signal.aborted) {
                        setResponse(json);
                    }
                }
            } catch (e) {
                if (!signal.aborted) {
                    setError(e);
                }
            } finally {
                if (!signal.aborted) {
                    setLoading(false);
                }
            }
        };
        doFetch();

        return () => abortController.abort();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, ...dependencies]);

    return {response, error, loading};
};