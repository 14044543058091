import React, { Component } from 'react';
import bindClassMethods from 'common/util/AutoBind';
import PropTypes from 'prop-types';
import { Button, Checkbox, Container, Table } from 'semantic-ui-react';

class StagedRecordsList extends Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            records: [],
            selectedRecords: [],
            _isMounted: false,
        }
    }

    componentDidMount() {
        this.setState({_isMounted: true});
    }

    componentWillUnmount() {
        this.setState({_isMounted: false});
    }

    onRecordSelectHandler(record, isSelected) {
        let newSelectedRecords;
        if(isSelected){
            newSelectedRecords = [...this.state.selectedRecords, record];
        } else {
            newSelectedRecords = this.state.selectedRecords.filter(({stagedRecordId}) => stagedRecordId !== record.stagedRecordId);
        }

        this.setState({selectedRecords: newSelectedRecords});
        if(this.props.onRecordSelect){
            this.props.onRecordSelect(newSelectedRecords);
        }
    }

    render() {
        return (
            <>
                {!this.props.readOnly &&
                 <Container textAlign="right" fluid>
                     <Button
                         compact
                         primary
                         onClick={() => console.log("Adding record")}
                         icon='add'
                         content='Add Structured Data'
                     />
                 </Container>
                }
                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            {this.props.onRecordSelect && (<Table.HeaderCell>Select</Table.HeaderCell>)}
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Source Reference</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Link</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.records.map(record => {
                            return (
                                <Table.Row key={record.stagedRecordId}>
                                    {this.props.onRecordSelect && (<Table.Cell key='checkbox'><Checkbox
                                        checked={record.selected}
                                        onChange={(
                                            e,
                                            {checked},
                                        ) => this.onRecordSelectHandler(
                                            record,
                                            checked,
                                        )}
                                    /></Table.Cell>)}
                                    <Table.Cell>{record.type}</Table.Cell>
                                    <Table.Cell>{record.sourceReference}</Table.Cell>
                                    <Table.Cell>{record.description}</Table.Cell>
                                    <Table.Cell>{record.url && <a
                                        href={record.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >View &raquo;</a>}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </>
        )
    }
}

StagedRecordsList.propTypes = {
    records: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onRecordSelect: PropTypes.func,
    readOnly: PropTypes.bool,
}

StagedRecordsList.defaultProps = {
    onRecordSelect: undefined,
    readOnly: false,
}

export default StagedRecordsList