import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { ERROR_FIELD_REQUIRED, getErrorResponse, NoValidationError } from 'external/form/Validators';
import isNil from 'lodash/isNil';

function isValid(fieldValue, fieldConfiguration) {
    if (isNil(fieldValue) && fieldConfiguration.required) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }
    return NoValidationError;
}

const DefaultOptions = {
    option1: {
        label: 'No',
        color: 'red',
        value: false
    },
    option2: {
        label: 'Yes',
        color: 'green',
        value: true
    }
}

/**
 * Displays a TriBool GenericForm field component.
 * Schema should be of the form:
 * [name]: {
 *     type: 'tribool',
 *     label: [FormLabel:string],
 *     options: {
 *         option1: {
 *              label: 'Ok',
 *              color: 'blue',
 *              value: true
 *          },
 *          option2: {
 *              label: 'Nope',
 *              color: 'orange',
 *              value: false
 *          }
 *      },
 *     required: [true:false],
 *  }
 *  Returns a validation error if neither option is selected.
 */
const FieldTriBool = ({name, label, options, value, onChange, disabled, required, registerValidator, deregisterValidator}) => {
    useEffect(() => {
        registerValidator(name, isValid);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const isRequired = !isNil(required) && required;
    const [rValue, setRValue] = useState(value);
    const valueChanged = (val) => {
        if (val !== rValue) {
            setRValue(val);
            onChange(name, val);
        } else if (!isRequired) {
            setRValue(undefined);
            onChange(name, undefined);
        }
    };
    return (
        <label htmlFor={name}>
            <Button
                compact disabled={disabled} attached='left'
                color={rValue === options.option1.value ? options.option1.color : undefined}
                onClick={() => valueChanged(options.option1.value)}
            >
                {options.option1.label}
            </Button>
            <Button
                compact disabled={disabled} attached='right'
                color={rValue === options.option2.value ? options.option2.color : undefined}
                onClick={() => valueChanged(options.option2.value)}
            >
                {options.option2.label}
            </Button>
        </label>
    );
};

FieldTriBool.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.shape({
        option1: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        }),
        option2: PropTypes.shape({
            label:PropTypes.string,
            value: PropTypes.any,
        }),
    }),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldTriBool.defaultProps = {
    value: undefined,
    required: false,
    options: DefaultOptions,
    onChange: () => {},
    error: undefined,
    disabled: false,
    indeterminate: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldTriBool;
