import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Divider, Segment, Icon } from 'semantic-ui-react';
import InformationModal from 'common/form/InformationModal';
import Markdown from 'common/Markdown';

const BypassAnnotation = ({data, divider}) => {
    if (!data) {
        return null;
    }

    const body = (
        <>
            The following review comments were made:
            <Divider />
            <Markdown text={data.text} />
        </>
    );

    return (
        <>
            <Segment inverted color="blue" tertiary>
                <Icon name="caret right" />
                {data.title}.&nbsp;
                <InformationModal
                    title={data.title}
                    body={body}
                    trigger={
                        <Link to={"#"} style={{textDecoration: 'underline', color: '#FFFFFF'}}>
                            Click for details...
                        </Link>}
                />
            </Segment>
            {divider && <Divider />}
        </>
    );
}

BypassAnnotation.propTypes = {
    data: PropTypes.shape(),
    divider: PropTypes.bool,
};

BypassAnnotation.defaultProps = {
    divider: false,
};

export default BypassAnnotation;