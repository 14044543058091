import React from 'react';
import { ConfigurationDataProps, withConfiguration } from 'components/common/ConfigurationContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getProgrammeIdFromPath } from 'components/programme/ProgrammeRouter';
import { ProgrammeOfWork } from 'components/programme/ProgrammeTypes';

export interface WithProgrammeOfWorkProps {
    programme: ProgrammeOfWork;
}

function createDisplayName(wrappedComponent: React.ComponentType<any>): string {
    return wrappedComponent.displayName || wrappedComponent.name || 'Component';
}

type RouteAndConfigProps = RouteComponentProps & ConfigurationDataProps;

export default function withProgramme<T extends WithProgrammeOfWorkProps = WithProgrammeOfWorkProps>(WrappedComponent: React.ComponentType<T>) {
    const displayName = createDisplayName(WrappedComponent);

    const ComponentWithProgramme = (props: Omit<T & RouteAndConfigProps, keyof WithProgrammeOfWorkProps>) => {
        const programmeId = getProgrammeIdFromPath(props.location.pathname);
        if (programmeId && programmeId in props.configurationData.programmes) {
            const programme = props.configurationData.programmes[programmeId];
            // props comes afterwards so the can override the default ones.
            return <WrappedComponent {...(props as T)} programme={programme} />;
        } else {
            return <p>Invalid Programme ID</p>;
        }
    };

    ComponentWithProgramme.displayName = `withProgramme(${displayName})`;

    return withRouter(withConfiguration(ComponentWithProgramme));
}
