import React, {SyntheticEvent} from 'react';
import {CustomerAddress, CustomerDetails} from "types/CustomerTypes";
import {Checkbox, CheckboxProps, Table} from "semantic-ui-react";
import {hasKey, isAddress} from "common/util/TypescriptHelpers";
import moment from "moment";
import ShowWithCustomerEditPermission from "components/customer/CustomerHelpers";

interface CustomerSchemaItem {
    label: string,
    customerKey: string,
    type: "string" | "toggle" | "date" | "address",
    custom?: boolean
}

type CustomerSchema = CustomerSchemaItem[][]

const DATE_FORMAT = 'DD-MM-YYYY';
const DISPLAY_NAME_KEY = "name"

type ToggleCheckboxProps = {
    checked: boolean
    onClick: (event: SyntheticEvent, data: CheckboxProps) => void
    readOnly: boolean
}

const ToggleCheckbox: React.FunctionComponent<ToggleCheckboxProps> = ({checked, onClick, readOnly}) => {
    return (
        <Checkbox
            toggle
            onClick={readOnly ? () => null : onClick}
            checked={checked}
            readOnly={readOnly}
        />
    )
}

type CustomerDetailsTableProps = {
    customerDetails: CustomerDetails
    updateCustomer: (key: string, value: any) => void
    customerCanBeEdited: boolean
    schema: CustomerSchema
}

const CustomerDetailsTable: React.FunctionComponent<CustomerDetailsTableProps> = ({
    customerDetails,
    updateCustomer,
    customerCanBeEdited,
    schema
}) => {
    const getValue = (customerKey: string) => {
        if (hasKey<CustomerDetails>(customerDetails, customerKey)) {
            return customerDetails[customerKey];
        }

        if (customerKey in customerDetails.customAttributes) {
            return customerDetails.customAttributes[customerKey];
        }

        return ""
    }

    const renderDateItem = (schemaItem: CustomerSchemaItem) => {
        const dateString = getValue(schemaItem.customerKey);
        if (typeof dateString !== 'string') {
            return null
        }
        return moment(dateString).format(DATE_FORMAT)
    }

    const renderToggleItem = (schemaItem: CustomerSchemaItem) => {
        let currentValue = getValue(schemaItem.customerKey);
        if (currentValue === null) {
            currentValue = false;
        }
        if (typeof currentValue != 'boolean' ) {
            return null
        }

        const updateFixedAttribute = (_event: SyntheticEvent, {checked}: CheckboxProps) => updateCustomer(schemaItem.customerKey, checked);
        const updateCustomAttribute = (_event: SyntheticEvent, {checked}: CheckboxProps) => updateCustomer("customAttributes", {[schemaItem.customerKey]: checked});

        return (
            <React.Fragment>
                <ShowWithCustomerEditPermission>
                    <ToggleCheckbox
                        checked={currentValue}
                        onClick={schemaItem.custom ? updateCustomAttribute : updateFixedAttribute}
                        readOnly={!customerCanBeEdited}
                    />
                </ShowWithCustomerEditPermission>
            </React.Fragment>
        )
    }

    const renderAddressItem = (schemaItem: CustomerSchemaItem) => {
        const address = getValue(schemaItem.customerKey);
        if (!isAddress(address)) {
            return
        }
        const {street1, street2, city, country, postcode, state}: CustomerAddress = address;
        return <React.Fragment>
            {`${street1}, ${street2 ? street2 + ', ' : ''}${city} ${state} ${postcode} ${country}`}
        </React.Fragment>
    }

    const renderSchemaItem = (schemaItem: CustomerSchemaItem) => {
        if (schemaItem.type === "string") {
            if (schemaItem.customerKey === DISPLAY_NAME_KEY && customerDetails?.deleted){
                return <>{getValue(schemaItem.customerKey)} (Deleted)</>;
            }
            return <>{getValue(schemaItem.customerKey)}</>;
        }

        if (schemaItem.type === "date") {
            return <>{renderDateItem(schemaItem)}</>;
        }

        if (schemaItem.type === "toggle") {
            return renderToggleItem(schemaItem);
        }

        if (schemaItem.type === "address") {
            return renderAddressItem(schemaItem);
        }

        return null;
    }

    return (
        <React.Fragment>
            {schema.map((tableBlock, index)=> (
                <Table key={tableBlock.map(block => block.customerKey).join('')} definition attached={index === 0 ? 'bottom' : undefined}>
                    <Table.Body>
                        {tableBlock.map(schemaItem => (
                            <Table.Row key={schemaItem.customerKey} >
                                <Table.Cell width={4} content={schemaItem.label} />
                                <Table.Cell>
                                    {renderSchemaItem(schemaItem)}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            ))}
        </React.Fragment>
    )
}

export default CustomerDetailsTable;
