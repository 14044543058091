import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'common/util/AutoBind';
// import ErrorPage from 'common/components/ErrorPage';
import {Container, Header, Icon} from 'semantic-ui-react';

/**
 * A top-level application error boundary.
 *
 * Displays an error page on any unhandled exception.  Without this any unhandled exception will
 * cause the entire React component hierarchy to be unmounted from the DOM, displaying a blank
 * page to the user.
 */
class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            hasError: false,
        };
    }

    // eslint-disable-next-line no-unused-vars
    componentDidCatch(error, info) {
        // Display error component
        this.setState({ hasError: true });

        // NB: If we ever implement an error reporting service for UI exceptions, report to that service here.
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container text style={{ marginTop: '7em' }} textAlign="center">
                    <Header as='h2' icon>
                        <Icon name='warning sign' />
                        Something went wrong
                        <Header.Subheader>
                            Please refresh the page and try again.
                        </Header.Subheader>
                    </Header>
                </Container>
                // <ErrorPage
                //     errorMessage="Something went wrong.  Please refresh the page and try again."
                //     showBack={false}
                //     showHeader={false}
                // />
            );
        }
        return this.props.children;
    }

}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
