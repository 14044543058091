import React from 'react';
import { Route, Switch } from "react-router-dom";
import AdminPage from 'components/admin/AdminPage';
import FieldControl from 'components/admin/FieldControl';
import ScreenList from 'components/admin/ScreenList';
import FieldControlEdit from 'components/admin/FieldControlEdit';
import FieldList from 'components/admin/FieldList';
import FieldEditForm from 'components/admin/FieldEditForm';
import ScreenEditForm from 'components/admin/ScreenEditForm';
import { RequireAnyPermission } from 'common/auth/RequirePermission';
import DataLoadRouter from 'components/admin/dataLoad/DataLoadRouter';
import { AdminScreenPermissions, AdminWorkflowPermissions } from 'common/auth/Authority';
import { subRoute } from 'common/util/RouteHelpers';
import ProgrammesList from 'components/admin/ProgrammesList';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

const AdminRouter = ({match}) => (
    <RequireAnyPermission permissions={[...AdminScreenPermissions, ...AdminWorkflowPermissions]}>
        <Switch>
            <Route
                exact
                path={match.url}
                render={({match}) => (
                    <AdminPage />
                )}
            />

            <Route path={subRoute(match, '/fields')} exact>
                <FieldList />
            </Route>

            <Route
                path={subRoute(match, '/fields/:workflowId/:fieldId')}
                render={({match}) => (
                    <FieldEditForm workflowId={match.params.workflowId} fieldId={match.params.fieldId} />)}
            />
            <Route
                path={subRoute(match, '/fields/new')}
                render={({match}) => (<FieldEditForm />)}
            />

            <Route path={subRoute(match, '/field-control')} exact>
                <FieldControl />
            </Route>
            <Route
                path={subRoute(match, '/field-control/:fieldId')}
                render={({match}) => (<FieldControlEdit fieldId={match.params.fieldId} />)}
            />

            <Route
                path={subRoute(match, '/screens')}
                exact
                render={({match}) => (<ScreenList programmeId={match.params.programmeId} />)}
            />

            <Route
                path={subRoute(match, '/screens/:workflowId/:screenId')}
                render={({match}) => (
                    <ScreenEditForm
                        workflowId={match.params.workflowId}
                        screenId={match.params.screenId}
                    />)}
            />

            <Route
                path={subRoute(match, '/programmes')}
                exact
                render={({match}) => (
                    <ProgrammesList />
                )}
            >
            </Route>

            <DataLoadRouter match={match} />
        </Switch>
    </RequireAnyPermission>
);

/**
 * Get the admin route
 */
function getAdminRoute(programmeId) {
    return `${getProgrammeRoute(programmeId)}/admin`;
}

export {
    AdminRouter as default,
    getAdminRoute,
};
