import React, { Component } from 'react';
import { Button, Form, Icon, Search } from "semantic-ui-react";
import bindClassMethods from 'common/util/AutoBind';
import Api from 'api/Api';
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { executeDefaultValidators } from 'external/form/Validators';

const SEARCH_KEYBOARD_DEBOUNCE = 300;

class FieldTagSearch extends Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);

        this.state = {
            loading: false,
            error: undefined,
            tagIds: this.props.value ? this.props.value.tagIds : [],
            searchOptions: [],
            tagResults: [],
            selectedTags: this.props.value ? this.props.value.selectedTags : [],
            searchValue: "",
            disabled: this.props.disabled,
        };

        this.submitSearch = debounce(this.submitSearch, SEARCH_KEYBOARD_DEBOUNCE);
    }

    onMount() {
        if (this.props.registerValidator) {
            this.props.registerValidator(this.props.name, executeDefaultValidators())
        }

        this.setState({
            loading: true,
            tagIds: this.props.tagIds || [],
        });
    }

    onChangeTag(event, data) {
        if (this.state.selectedTags.length < this.props.maxSelections) {
            const matchingTag = this.state.tagResults.find(tag => tag.id === data.result.id)

            const selectedTags = [...this.state.selectedTags, matchingTag]
            const tagIds = [...this.state.tagIds, matchingTag.id]

            const searchOptions = []
            const tagResults = this.state.tagResults
                .filter(tag => tag[this.props.idField] !== matchingTag[this.props.idField])

            this.setState({
                searchValue: "",
                selectedTags: selectedTags,
                tagIds: tagIds,
                searchOptions: searchOptions,
                tagResults: tagResults,
            })

            let values = {
                selectedTags: selectedTags,
                tagIds: tagIds,
            }

            this.props.onChange(this.props.name, values);
        }
    }

    onSearchChange(event) {
        this.setState({searchValue: event.currentTarget.value})
        if (event.currentTarget.value.length === 0) {
            this.setState({searchOptions: []})
        } else {
            this.submitSearch()
        }
    }

    componentWillUnmount() {
        if (this.props.deregisterValidator) {
            this.props.deregisterValidator(this.props.name)
        }
    }

    submitSearch() {
        Api.get(`${this.props.searchPath}${this.state.searchValue}`)
            .then((result) => {
                if (!Array.isArray(result)) {
                    result = result.entries;
                }
                const tags = result
                    .filter(tag => !this.state.selectedTags.some(e => {
                        return e.id === tag[this.props.idField]
                    }))
                    .map((c) => this.props.mapToTag(c))

                this.setState({
                    tagResults: tags,
                    searchOptions: tags,
                })
            })
            .catch(this.onError);
    }

    removeTag(index) {
        const selectedTags = [...this.state.selectedTags]
        const tagIds = [...this.state.tagIds]
        const tagResults = [...this.state.tagResults]

        tagResults.push(selectedTags[index]);
        selectedTags.splice(index, 1);
        tagIds.splice(index, 1);

        this.setState({
            selectedTags: selectedTags,
            tagIds: tagIds,
            tagResults: tagResults,
        })

        let values = {
            selectedTags: selectedTags,
            tagIds: tagIds,
        }

        this.props.onChange(this.props.name, values);
    }

    render() {
        return (
            <React.Fragment>
                {this.props.tagIds.length === 0 &&
                    <Form.Field>
                        <Search
                            name={`Search ${this.queryType}`}
                            placeholder="Search..."
                            results={this.state.searchOptions}
                            value={this.state.searchValue}
                            onSearchChange={this.onSearchChange}
                            onResultSelect={this.onChangeTag}
                            disabled={this.state.tagIds.length === this.props.maxSelections || this.disabled}
                            fluid
                            selectFirstResult
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault()
                                }
                            }}
                        />
                        <Icon
                            name="info circle"
                            color="grey"
                        />{`${this.props.maxSelections} ${this.props.fetchType}s maximum`}
                    </Form.Field>
                }
                {this.state.selectedTags.length > 0 &&
                    <Form.Field>
                        <label>{`Selected ${this.props.fetchType}(s)`}</label>
                        {
                            this.state.selectedTags.map((tag, index) => {
                                return <Button
                                    key={tag.id}
                                    size="small"
                                    disabled={this.disabled}
                                    onClick={() => this.removeTag(index)}
                                >
                                    {tag.name}
                                    <Icon name="remove" style={{marginLeft: "6px", marginRight: "0px"}} />
                                </Button>
                            })
                        }
                    </Form.Field>
                }
            </React.Fragment>
        )
    }
}

FieldTagSearch.propTypes = {
    tagIds: PropTypes.arrayOf(PropTypes.string),
}

FieldTagSearch.defaultProps = {
    tagIds: [],
    trigger: undefined,
}

export default FieldTagSearch;