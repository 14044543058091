import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Link } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';
import Api from 'api/Api';
import PageHeader from 'components/common/PageHeader';
import LoaderIcon from 'common/LoaderIcon';
import 'components/admin/ScreenList.scss';
import withProgramme from 'components/programme/ProgrammeProps';

class ProgrammesList extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            programmes: [],
        };
    }

    componentDidMount() {
        this.loadSummary();
    }

    loadSummary() {
        this.setState({loading: true});
        Api.get(`/api/programmes`)
            .then(this.setProgrammes)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setProgrammes(data) {
        this.setState({
            programmes: data.sort((a, b) => a.programmeId > b.programmeId ? 1 : -1),
        });
    }

    getBreadcrumbs() {
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'ProgramOfWork', content: 'Programs of Work'},
        ];
    }

    /**
     * Temporary method to disable editing not yet implemented programmes
     * @param programme
     * @returns Edit link for a programme
     */
    getEditLink(programme) {
        const disabledProgrammes = [
            "CRI",
            "HRLO",
            "VRLO",
            "CCI",
            "PRLO",
        ];

        if (disabledProgrammes.includes(programme.prefix)) {
            return (
                <p>Edit</p>
            );
        }

        return (
            <Link
                to={`/admin/programmes/edit/${programme.programmeId}`}
                className="nowrap"
            >
                Edit &raquo;
            </Link>
        );
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader
                    text="Program Screens"
                    icon={<LoaderIcon icon="lock" onClick={this.loadSummary} loading={this.state.loading} />}
                    breadcrumbs={this.getBreadcrumbs()}
                    headerRightComponent={
                        <Button
                            as={Link}
                            to={`/admin/programmes/new`}
                            compact
                            size="tiny"
                            color="blue"
                            content="Add Program"
                            icon="plus"
                        />
                    }
                />
                <div className="programme-programmes">
                    <Table attached="bottom" selectable className="programme-programmes">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Program ID</Table.HeaderCell>
                                <Table.HeaderCell>Program Name</Table.HeaderCell>
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.programmes.map(programme => (
                                <Table.Row key={`programme-${programme.programmeId}`}>
                                    <Table.Cell>
                                        {programme.programmeId}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {programme.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {this.getEditLink(programme)}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </React.Fragment>
        );
    }
}

ProgrammesList.propTypes = {
    children: PropTypes.node,
};

ProgrammesList.defaultProps = {
    children: undefined,
};

export default withProgramme(ProgrammesList);
