import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form } from 'semantic-ui-react';
import { isValueFalse } from 'common/util/Helpers';
import { getErrorResponse, NoValidationError } from 'external/form/Validators';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

function minChecksRequiredError(fieldConfiguration) {
    if (fieldConfiguration.minChecksRequired >= fieldConfiguration.options.length) {
        return `All options must be selected`;
    }
    return `At least ${fieldConfiguration.minChecksRequired} options must be selected`;
}

function getCheckedOptions(options, value) {
    if (isNil(value)) {
        return {};
    }

    const checkedOptions = {};
    options.forEach(item => {
        checkedOptions[item.id] = !isValueFalse(value[item.id]);
    })
    return checkedOptions;
}

function validateField(fieldValue, fieldConfiguration) {
    if (fieldConfiguration.required) {
        const minChecksRequired = fieldConfiguration.minChecksRequired;
        if (minChecksRequired <= 0) {
            return NoValidationError;
        }
        if (isNil(fieldValue)) {
            return getErrorResponse(minChecksRequiredError(fieldConfiguration));
        }
        const numCheckedFields = fieldConfiguration.options
            .map(option => fieldValue[option.id])
            .filter(fieldValueBoolean => fieldValueBoolean)
            .length

        if (numCheckedFields < minChecksRequired) {
            return getErrorResponse(minChecksRequiredError(fieldConfiguration))
        }
    }
    return NoValidationError;
}

const FieldCheckList = ({name, label, options, value, onChange, disabled, registerValidator, deregisterValidator}) => {
    useEffect(() => {
        registerValidator(name, validateField);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const checkedOptions = getCheckedOptions(options, value);

    const valueChanged = (event, data) => {
        if (disabled) {
            return;
        }
        const newValue = cloneDeep(checkedOptions);
        newValue[data.name] = data.checked;
        onChange(name, newValue);
    };

    return (
        <Form.Group grouped unstackable>
            <label htmlFor={name}>
                {options.map(item =>
                    <Form.Field key={item.id}>
                        <Checkbox
                            name={item.id}
                            label={item.text}
                            onClick={valueChanged}
                            checked={checkedOptions[item.id]}
                            disabled={disabled}
                        />
                    </Form.Field>,
                )}
            </label>
        </Form.Group>
    );
};

FieldCheckList.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape()),
    value: PropTypes.shape(),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldCheckList.defaultProps = {
    value: {},
    required: false,
    options: [],
    onChange: () => {},
    error: undefined,
    disabled: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldCheckList;
