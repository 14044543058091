import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Container } from 'semantic-ui-react';

const InformationModal = ({trigger, title, body}) => {
    const [open, setOpen] = React.useState(false)
    if (!body) {
        return null;
    }
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={trigger}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {body}
            </Modal.Content>
            <Modal.Actions>
                <Container textAlign="center">
                    <Button
                        content="Dismiss"
                        onClick={() => setOpen(false)}
                        primary
                    />
                </Container>
            </Modal.Actions>
        </Modal>
    )
};

InformationModal.propTypes = {
    trigger: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    markdown: PropTypes.string,
};

InformationModal.defaultProps = {
    markdown: undefined,
};

export default InformationModal;