import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Header, Modal } from 'semantic-ui-react';
import GenericForm from 'common/form/SemanticUIForm';
import _ from 'lodash'
import Api from 'api/Api';

export class CreateCustomerCorrespondenceModal extends React.Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            formData: {},
            visible: this.props.visible,
        }
    }

    onClose() {
        this.setState({visible: false, formData: {}});
    }

    onOpen() {
        this.setState({visible: true});
    }

    onSubmit() {
        return new Promise((resolve, reject) => {
            const formData = this.state.formData;
            const createCorrespondenceForm = new FormData();
            const dataWithoutFile = _.cloneDeep(_.omit(formData, "attachment"));

            createCorrespondenceForm.append(
                "data",
                new Blob([JSON.stringify(dataWithoutFile)], {type: 'application/json'}),
            );

            if (formData.attachment && formData.attachment instanceof File) {
                createCorrespondenceForm.append("file", formData.attachment);
            }

            Api.postMultiPartForm(`/api/customers/${this.props.customerId}/correspondence`, createCorrespondenceForm)
                .then(() => {
                    this.onClose();
                    this.props.update();
                    resolve();
                })
                .catch((err) => {
                    Api.logError(err);
                    reject(err);
                });
        })
    }

    updateFormDataField(key, value) {
        this.setState({formData: {...this.state.formData, [key]: value}});
    }

    render() {

        const schema = {
            fields: {
                date: {
                    type: 'date',
                    label: 'Date',
                    onChange: this.updateFormDataField,
                    required: true,
                },
                type: {
                    type: 'select',
                    label: 'Type',
                    options: [
                        {
                            id: 'PhoneCall',
                            text: 'PhoneCall',
                        },
                        {
                            id: 'Letter',
                            text: 'Letter',
                        },
                        {
                            id: 'SMS',
                            text: 'SMS',
                        },
                        {
                            id: 'Fax',
                            text: 'Fax',
                        },
                        {
                            id: 'Email',
                            text: 'Email',
                        }],
                    onChange: this.updateFormDataField,
                    value: this.state.formData.type,
                    required: true,
                },
                direction: {
                    type: 'select',
                    label: 'Direction',
                    options: [{id: 'Outbound', text: 'Outbound'}, {id: 'Inbound', text: 'Inbound'}],
                    onChange: this.updateFormDataField,
                    value: this.state.formData.direction,
                    required: true,
                },
                description: {
                    type: 'plaintextarea',
                    label: 'Description',
                    rows: 3,
                    onChange: this.updateFormDataField,
                    required: true,
                },
                attachment: {
                    type: 'fileupload',
                    multiple: true,
                    label: 'Attachment',
                    onChange: this.updateFormDataField,
                },
            },
            fieldOrder: [
                'date',
                'type',
                'direction',
                'description',
                'attachment',
            ],
        }

        return (
            <React.Fragment>
                <Button content="Add Correspondence" onClick={this.onOpen} compact primary icon="add" />
                <Modal
                    open={this.state.visible}
                    onClose={this.onClose}
                    onOpen={this.onOpen}
                >
                    <Header icon='phone' content='Add Correspondence' />
                    <Modal.Content>

                        <GenericForm
                            schema={schema}
                            formData={this.state.formData}
                            navigationPrompt={false}
                            showButtons
                            styleClass="left-align-fields"
                            onSubmit={this.onSubmit}
                            onCancel={this.onClose}
                            submitButtonLabel={'Add Correspondence'}
                            onFormDataChange={(data) => this.setState({formData: data})}
                        />
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

CreateCustomerCorrespondenceModal.propTypes = {
    customerId: PropTypes.string,
    visible: PropTypes.bool,
    update: PropTypes.func,
}

CreateCustomerCorrespondenceModal.defaultProps = {
    visible: false,
    update: () => {}
}

export default CreateCustomerCorrespondenceModal;