import React, { useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { executeDefaultValidators } from 'external/form/Validators';
import PropTypes from 'prop-types';

const FieldMultiSelect = ({
        autoFocus, disabled, error, loadData, multiple, name, nullable, onChange, options, placeHolder,
        value, commitId, registerValidator, deregisterValidator,
    }) => {
        useEffect(() => {
            registerValidator(name, executeDefaultValidators);
            return function cleanup() {
                deregisterValidator(name);
            };
        }, [name, registerValidator, deregisterValidator]);

        const callback = (name, value) => {
            onChange(name, value)
        };

        const dropdownOptions = options.map(option => {
            return {
                key: option.id,
                value: option.id,
                text: option.text,
            };
        });

        return (
            <Dropdown
                selection
                placeholder={placeHolder}
                // text='File'
                multiple
                onChange={(event, value) => callback(name, value.value)}
                options={dropdownOptions}
                value={value}
                readOnly={disabled}
                disabled={disabled}
            />
        );
    }
;

FieldMultiSelect.propTypes = {
    autoFocus: PropTypes.bool,
    commitId: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    loadData: PropTypes.func,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    nullable: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape()),
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired, // used by Select to generate React key
        }))],
    ),
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldMultiSelect.defaultProps = {
    autoFocus: false,
    commitId: true,
    disabled: false,
    error: undefined,
    loadData: undefined,
    multiple: false,
    nullable: true,
    onChange: () => {},
    options: [],
    placeHolder: undefined,
    value: [],
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldMultiSelect;
