import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Header, Icon, Label, Loader, Popup, Segment } from 'semantic-ui-react';
import Api from 'api/Api';
import GenericForm from 'common/form/SemanticUIForm'
import cloneDeep from 'lodash/cloneDeep';
import CaseRecordLinks from 'components/case/CaseRecordLinks';
import './CaseDataAccordion.scss';

const PAST_SCREEN_STATUSES = ["Completed", "Bypassed", "CurrentStage"];

const CaseDataAccordionItem = ({screen, caseId}) => {
    const [loaded, setLoaded] = useState(false);
    const [screenData, setScreenData] = useState({});
    const [active, setActive] = useState(false);

    const {name: screenName, screenId, screenStatus} = screen;

    const toggleActive = () => {
        setActive(!active);
    }

    const getScreenData = () => {
        Api.get(`/api/cases/${caseId}/screens/${screenId}/data`)
            .then(setScreenData)
            .catch(Api.logError)
            .finally(() => setLoaded(true))
    }

    const onAccordionClick = () => {
        if (!loaded) {
            getScreenData();
        }
        toggleActive();
    }

    const getReadOnlySchema = (schema) => {
        const newSchema = cloneDeep(schema);
        Object.keys(newSchema.fields).forEach(key => newSchema.fields[key].disabled = true);
        return newSchema;
    }

    const getScreenStatusIcon = (screenStatus) => {
        switch (screenStatus) {
            case "Completed":
                return (
                    <Popup
                        trigger={
                            <Icon name="check" color="green" size="large" />
                        }
                        content="Completed"
                    />
                )
            case "Bypassed":
                return (
                    <Popup
                        trigger={
                            <Icon name="check" color="grey" size="large" />
                        }
                        content="Bypassed"
                    />
                )
            case "Override":
                return (
                    <Popup
                        trigger={
                            <Icon name="step forward" color="grey" size="large" />
                        }
                        content="Stage not required"
                    />
                )
            case "CurrentStage":
                return (
                    <Popup
                        trigger={
                            <Icon name="edit" size="large" />
                        }
                        content="Current"
                    />
                )
            case "OnHold":
                return (
                    <Popup
                        trigger={
                            <Icon name="pause" color="orange" size="large" />
                        }
                        content="On Hold"
                    />
                )
            case "Rejected":
                return (
                    <Popup
                        trigger={
                            <Icon name="undo" color="red" size="large" />
                        }
                        content="Rejected"
                    />
                )
            case "FutureStage":
            case "NA":
            default:
                return null;
        }
    }

    return (
        <React.Fragment>
            <Accordion.Title
                active={active}
                onClick={onAccordionClick}
            >
                <Header as='h4' >
                    <Label
                        size="large"
                        className="accordion-label"
                        style={{
                            color: PAST_SCREEN_STATUSES.includes(screenStatus) ? "black" : undefined,
                        }}
                    >
                        <div>
                            <Icon name="dropdown" />
                            {screenName}
                        </div>
                        <div>
                            {getScreenStatusIcon(screenStatus)}
                        </div>
                    </Label>
                </Header>
            </Accordion.Title>
            <Accordion.Content
                as={Segment}
                attached='bottom'
                active={active}
                className="accordion-content"
            >
                {!loaded && <Loader active />}
                {loaded && (
                    <>
                        <GenericForm
                            schema={getReadOnlySchema(screenData.schema)}
                            formData={screenData.fieldData}
                            showButtons={false}
                        />
                        <CaseRecordLinks
                            linkedFiles={screenData.linkedStagedFiles}
                            linkedRecords={[]}
                        />
                    </>
                )}
            </Accordion.Content>
        </React.Fragment>
    )
}

CaseDataAccordionItem.propTypes = {
    screen: PropTypes.shape({
        screenId: PropTypes.string,
        name: PropTypes.string,
        fieldCount: PropTypes.number,
        workflowId: PropTypes.string,
        workflowName: PropTypes.string,
    }).isRequired,
    caseId: PropTypes.string.isRequired,
    active: PropTypes.bool,
    toggleActive: PropTypes.func,
}

CaseDataAccordionItem.defaultProps = {
    toggleActive: () => {},
    active: false,
}

const CaseDataAccordion = ({screens, caseId}) => {

    return (
        <Accordion exclusive fluid>
            {screens.map((screen, i) => (
                <CaseDataAccordionItem
                    caseId={caseId}
                    screen={screen}
                    key={screen.screenId}
                />
            ))}
        </Accordion>
    )
}

CaseDataAccordion.propTypes = {
    screens: PropTypes.arrayOf(PropTypes.shape({
        screenId: PropTypes.string,
        name: PropTypes.string,
        fieldCount: PropTypes.number,
        workflowId: PropTypes.string,
        workflowName: PropTypes.string,
    })).isRequired,
    caseId: PropTypes.string.isRequired,
};

CaseDataAccordion.defaultProps = {
    screens: [],
};

export default CaseDataAccordion;
