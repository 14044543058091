import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, Item, Label } from 'semantic-ui-react';

const SearchResult = ({result, icon}) => {
    return (
        <Item>
            <Icon name={icon} />
            <Item.Content>
                <Item.Header><Link to={result.uri}>{result.title}</Link></Item.Header>
                <Item.Meta>{result.meta}</Item.Meta>
                <Item.Description>{result.description}</Item.Description>
                {result.tags &&
                 <Item.Extra>
                     {result.tags.map(item => <Label key={item} size="tiny" color="blue">{item}</Label>)}
                 </Item.Extra>
                }
            </Item.Content>
        </Item>
    );
};

SearchResult.propTypes = {
    result: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        labels: PropTypes.arrayOf(PropTypes.string),
    }),
    icon: PropTypes.string,
};

SearchResult.defaultProps = {
    result: undefined,
    icon: 'folder',
};

function resultWithIcon(icon) {
    return (props) => <SearchResult {...props} icon={icon} />
}

const CustomerSearchResult = resultWithIcon("user");
const CaseSearchResult = resultWithIcon("folder open");
const ReviewTaskSearchResult = resultWithIcon("tasks");
const ProgrammeOfWorkSearchResult = resultWithIcon("sitemap");

export {
    SearchResult,
    CustomerSearchResult,
    CaseSearchResult,
    ReviewTaskSearchResult,
    ProgrammeOfWorkSearchResult,
};