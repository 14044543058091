import React from 'react';
import {
    Container,
    Grid,
    Header, Icon, Image,
    Segment,
} from 'semantic-ui-react';
import Responsive from 'semantic-ui-react/dist/commonjs/addons/Responsive';

const FOOTER_MIN_WIDTH = 767;

type Props = {
    showLogo: boolean,
};

const Footer: React.FunctionComponent<Props> = ({
    showLogo = true,
}) => (
    <Responsive minWidth={FOOTER_MIN_WIDTH}>
        <Segment inverted vertical style={{margin: '2em 0em 0em', padding: '1em 0em'}}>
            <Container textAlign="center" fluid>
                <Grid divided inverted stackable>
                    <Grid.Column width={2} textAlign="left" verticalAlign="middle">
                        {showLogo && (
                            <Image size="small" src="/Remedio-Logo-White.svg" style={{marginLeft: '1.5em'}} />
                        )}
                    </Grid.Column>
                    <Grid.Column width={6} textAlign="right" verticalAlign="middle" style={{boxShadow: 'none'}}>
                        <Header as="h4" inverted style={{display: 'inline-block'}} textAlign="center">
                            <Icon name="cogs" />
                            <Header.Content>
                                NQRY
                                <Header.Subheader>Technical Integration</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={6} textAlign="left" verticalAlign="middle">
                        <Header as="h4" inverted style={{display: 'inline-block'}} textAlign="center">
                            <Icon name="search" />
                            <Header.Content>
                                Resolve Evolve Technology
                                <Header.Subheader>Domain Expertise & Solutions Design</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                </Grid>
            </Container>
        </Segment>
    </Responsive>
);

export default Footer;
