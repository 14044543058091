import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Icon } from 'semantic-ui-react';
import PageHeader from 'components/common/PageHeader';
import MenuSelect from 'common/layout/MenuSelect';
import Authority from 'common/auth/Authority';
import { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import OffsetColumnLayout from 'common/layout/OffsetColumnLayout';
import withProgramme from 'components/programme/ProgrammeProps';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

const getAdminItems = (programmeId) => [

    {
        id: '1',
        name: 'Fields',
        meta: 'Manage fields on workflow screens',
        uri: `${getProgrammeRoute(programmeId)}/admin/fields`,
        icon: 'language',
        permissions: [Authority.EditWorkFlowFields],
    },
    {
        id: '2',
        name: 'Field Control',
        meta: 'Manage field level permissions',
        uri: `${getProgrammeRoute(programmeId)}/admin/field-control`,
        icon: 'lock',
        permissions: [Authority.FieldPermissions],
    },
    {
        id: '3',
        name: 'Workflow Screens',
        meta: 'Manage workflows and screens',
        uri: `${getProgrammeRoute(programmeId)}/admin/screens`,
        icon: 'tv',
        permissions: [Authority.EditWorkFlowScreens],
    },

];

class AdminPage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
            {key: 'ProgramAdministration', content: 'Program Administration'},
        ];
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader text="Program Administration" breadcrumbs={this.getBreadcrumbs()} />
                <OffsetColumnLayout>
                    {getAdminItems(this.props.programme.id).map(item => (
                        <ShowWithOneOfPermission permissions={item.permissions} key={item.id}>
                            <MenuSelect
                                name={item.name}
                                uri={item.uri}
                                meta={item.meta}
                                icon={item.icon}
                            />
                        </ShowWithOneOfPermission>
                    ))}
                </OffsetColumnLayout>
            </React.Fragment>
        );
    }
}

AdminPage.propTypes = {
    children: PropTypes.node,
};

AdminPage.defaultProps = {
    children: undefined,
};

export default withProgramme(AdminPage);
