import React from 'react';
import { Table } from "semantic-ui-react";
import SortToggle from "components/common/SortToggle";
import SortDirection from "components/common/SortDirection";
import SortState from "components/common/SortState";

type Props = {
    readonly name: string;
    readonly text: string;
    readonly className?: string;
    readonly sortKey: string;
    readonly sortState: SortState | { sortKey: "", direction: SortDirection.None };
    readonly onSortUpdate: () => void;
    readonly right?: boolean;
    readonly reverseSort?: boolean;
};

const SortableHeader = (props: Props): JSX.Element => {
    let cssClass = 'sortable';
    if (props.className) {
        cssClass = `sortable ${props.className}`;
    }
    const {name, text, className, sortKey, sortState, onSortUpdate, right, reverseSort, ...rest} = props;
    return (
        <Table.HeaderCell
            className={cssClass}
            {...rest}
        >
            <SortToggle
                sortKey={props.sortKey}
                sortState={props.sortState}
                setSortState={props.onSortUpdate}
                headerText={props.text}
                right={props.right}
                reverseSort={props.reverseSort}
            />
        </Table.HeaderCell>
    )
};

export default SortableHeader;