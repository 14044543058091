import {CustomerAddress} from "types/CustomerTypes";

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj;
}

export function isAddress(obj: any): obj is CustomerAddress {
    return obj
           && obj.hasOwnProperty("street1")
           && obj.hasOwnProperty("street2")
           && obj.hasOwnProperty("city")
           && obj.hasOwnProperty("state")
           && obj.hasOwnProperty("postcode")
           && obj.hasOwnProperty("country")
}