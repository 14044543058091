import moment from 'moment';
import isNil from 'lodash/isNil';

import { round } from 'common/util/Helpers';

const DATE_FORMAT_STRING = "YYYY-MM-DD"

const getDateTimeAsJsonString = (date) => {
    // In future, perhaps use: moment().format() or moment.utc().format()
    return date.toJSON();
};

const getDateAsJsonString = (date) => {
    return moment(date).format(DATE_FORMAT_STRING);
};

const getCurrentDateTimeAsJsonString = () => {
    return getDateTimeAsJsonString(new Date(Date.now()));
};

const getDateFromJson = (dateString) => {
    return (new Date(dateString));
};

const getHumanReadableDate = (dateObject) => {
    if (isNil(dateObject)) {
        return '';
    }
    return moment(dateObject).format('DD/MM/YYYY');
};

const humanReadableFormatToDate = (formattedDate) => {
    if (isNil(formattedDate)) {
        return '';
    }
    return moment(formattedDate, 'DD/MM/YYYY');
};

const getHumanReadableTime = (dateObject) => {
    if (isNil(dateObject)) {
        return '';
    }
    return moment(dateObject).format('h:mm a');
};

const getHumanReadableDateTime = (dateObject) => {
    if (isNil(dateObject)) {
        return '';
    }
    return moment(dateObject).format('DD/MM/YYYY HH:mm');
};

const getHumanReadableZonedDateTime = (zonedDateTime) => {
    if (isNil(zonedDateTime)) {
        return '';
    }
    const formattedTz = zonedDateTime.tz.replace('_', ' ');
    return `${moment.tz(zonedDateTime.date, zonedDateTime.tz).format('DD/MM/YYYY HH:mm')} (${formattedTz})`;
};

const isValidDateTime = (isoFormattedDateTime) => {
    if (isNil(isoFormattedDateTime)) {
        return false;
    }
    return moment(isoFormattedDateTime, moment.ISO_8601).isValid();
};

const isValidDate = (dateString) => {
    if (isNil(dateString)) {
        return false;
    }
    return moment(dateString, DATE_FORMAT_STRING).isValid();
};

const convertTimeToIsoString = (time) => {
    if (isNil(time)) {
        return '';
    }
    return moment(time).toJSON();
};

const getShortDateFormat = (dateObject) => {
    if (isNil(dateObject)) {
        return '';
    }
    return moment(dateObject).format('DD MMM. YYYY');
};

const getDateAs_DDMMYY_24HHMM = (dateObject) => { // eslint-disable-line camelcase
    if (isNil(dateObject)) {
        return '';
    }
    return moment(dateObject).format('DD/MM/YYYY HH:mm');
};

const getDateAs_DDMMYY_24HHMMSS = (dateObject) => { // eslint-disable-line camelcase
    if (isNil(dateObject)) {
        return '';
    }
    return moment(dateObject).format('DD/MM/YYYY HH:mm:ss');
};

const convertJsonTimesToDatesInObjects = (timeAttributeName, arrayOfObjects) => {
    return arrayOfObjects.map((theObject) => {
        theObject[timeAttributeName] = getDateFromJson(theObject[timeAttributeName]); // eslint-disable-line no-param-reassign
        return theObject;
    });
};

const getHumanReadableTimeText = (dateObject) => {
    const now = Date.now();
    const eventTime = dateObject.getTime();

    const timeDeltaInSeconds = (now - eventTime) / 1000;
    if (timeDeltaInSeconds < 120) { // This prevents having to deal with singular minutes
        return 'Just now';
    } else if (timeDeltaInSeconds < 3600) {
        return `${round(timeDeltaInSeconds / 60, 0)} minutes ago`;
    } else if (timeDeltaInSeconds < 7200) {
        return 'An hour ago';
    } else if (timeDeltaInSeconds < 60 * 60 * 24) {
        return `${round(timeDeltaInSeconds / 3600, 0)} hours ago`;
    } else if (timeDeltaInSeconds < 60 * 60 * 48) {
        return 'Yesterday';
    }

    return getHumanReadableDateTime(dateObject);
};

function toDurationTextFromJson(timestamp) {
    if (isNil(timestamp)) {
        return '';
    }
    const eventTime = getDateFromJson(timestamp).getTime();
    const now = Date.now();
    let label;
    const timeDeltaInSeconds = (now - eventTime) / 1000;
    if (timeDeltaInSeconds < 3600) {
        const mins = round(timeDeltaInSeconds / 60, 0);
        label = `${mins} min${mins === 1 ? '' : 's'}`;
    } else if (timeDeltaInSeconds < 60 * 60 * 24) {
        const hours = round(timeDeltaInSeconds / 3600, 0);
        label = `${hours} hr${hours === 1 ? '' : 's'}`;
    } else {
        const days = round(timeDeltaInSeconds / (3600 * 24), 0);
        label = `${days} day${days === 1 ? '' : 's'}`;
    }
    return label;
}

const isFutureDateTime = (dateTime) => {
    return moment(dateTime, moment.ISO_8601).isAfter(moment());
};

const isPastDateTime = (dateTime) => {
    return moment(dateTime, moment.ISO_8601).isBefore(moment());
};

const getListOfTimeZones = () => {
    return moment.tz.names();
};

const getCurrentTimeZone = () => {
    return moment.tz.guess();
};

const toZonedDateTime = (localDateTime, timeZone) => {
    if (localDateTime) {
        if (moment.isMoment(localDateTime)) {
            return moment.tz(localDateTime.format('YYYY-MM-DDTHH:mm:ss'), timeZone);
        }
        return moment.tz(localDateTime, timeZone);
    }
    return undefined;
};

const isZonedDateTimeValid = (zonedDate) => {
    return !isNil(zonedDate.date) && !isNil(zonedDate.tz);
};

const isBefore = (date1, date2) => {
    if (isNil(date1) || isNil(date2)) {
        return false;
    }
    const firstMomentDate = moment(date1, moment.ISO_8601);
    const secondMomentDate = moment(date2, moment.ISO_8601);
    return firstMomentDate.isBefore(secondMomentDate);
};

const compareDates = (date1, date2) => {
    if (isNil(date1) && isNil(date1)) {
        return 0;
    }
    if (isNil(date1)) {
        return -1;
    }
    if (isNil(date2)) {
        return 1;
    }
    const firstMomentDate = moment(date1, moment.ISO_8601);
    const secondMomentDate = moment(date2, moment.ISO_8601);
    if (firstMomentDate.isBefore(secondMomentDate)) {
        return -1;
    } else if (firstMomentDate.isSame(secondMomentDate)) {
        return 0;
    }
    return 1;
};

function getTimeAtEndOfToday() {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
}

export {
    getDateTimeAsJsonString,
    getDateAsJsonString,
    getDateFromJson,
    getCurrentDateTimeAsJsonString,
    getHumanReadableDate,
    getHumanReadableTime,
    getHumanReadableDateTime,
    getHumanReadableZonedDateTime,
    getDateAs_DDMMYY_24HHMM, // eslint-disable-line camelcase
    getDateAs_DDMMYY_24HHMMSS, // eslint-disable-line camelcase
    getShortDateFormat,
    convertJsonTimesToDatesInObjects,
    getHumanReadableTimeText,
    toDurationTextFromJson,
    humanReadableFormatToDate,
    isFutureDateTime,
    isPastDateTime,
    getListOfTimeZones,
    getCurrentTimeZone,
    isValidDateTime,
    isValidDate,
    convertTimeToIsoString,
    isBefore,
    toZonedDateTime,
    isZonedDateTimeValid,
    compareDates,
    getTimeAtEndOfToday,
};
