import React from 'react';
import {
    Container,
    Menu,
} from 'semantic-ui-react';

const StickyLayout: React.FunctionComponent<Props> = ({
    header,
    body,
    footer,
}) => (
    <div className="site">
        {header && (
            <Menu fixed="top" inverted size="large">
                {header}
            </Menu>
        )}
        <div className="site-content">
            {body && (
                <Container fluid style={{marginTop: '4em', paddingLeft: '20px', paddingRight: '20px'}}>
                    {body}
                </Container>
            )}
        </div>
        <footer>
            {footer}
        </footer>
    </div>
);

type Props = {
    header?: React.ReactNode | null,
    body?: React.ReactNode | null,
    footer?: React.ReactNode | null,
};

export default StickyLayout;
