import FieldTagSearch from 'common/form/fields/FieldTagSearch';
import React from 'react';

const FieldCustomerTagSearch = (props) => {
    const MAX_RESULTS = 5;
    const searchPath = `/api/customers?size=${MAX_RESULTS}&query=`

    const mapToTag = (tag) =>{
        return{
            key: tag.customerId,
            id: tag.customerId,
            name: tag.displayName,
            title: `${tag.displayName} [${tag.referenceNumber}]`,
        }
    }

    return(
        <FieldTagSearch
            {...props}
            mapToTag = {mapToTag}
            searchPath = {searchPath}
            idField = {"customerId"}
            queryType = "Customer"
        />
    )
}

export default FieldCustomerTagSearch;