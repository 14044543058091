const Authority = {
    UserPermissions: 'UserPermissions',
    GroupPermissions: 'GroupPermissions',
    FieldPermissions: 'FieldPermissions',
    EditWorkFlowFields: 'EditWorkFlowFields',
    EditWorkFlowScreens: 'EditWorkFlowScreens',
    ViewAuditLog: 'ViewAuditLog',
    ImportExport: 'ImportExport',
    DatabaseManagement: 'DatabaseManagement',
    Reporting: 'Reporting',
    AddCustomer: 'AddCustomer',
    EditCustomer: 'EditCustomer',
    ViewCustomer: 'ViewCustomer',
    ViewAllCustomers: 'ViewAllCustomers',
    DeleteCustomer: 'DeleteCustomer',
    UploadDocuments: 'UploadDocuments',
    CreateCorrespondence: 'CreateCorrespondence',
    AllocateCase: 'AllocateCase',
    CreateCase: 'CreateCase',
    EditCase: 'EditCase',
    RejectCase: 'RejectCase',
    AbortCase: 'AbortCase',
    ReopenCase: 'ReopenCase',
    OverrideStage: 'OverrideStage',
    BypassStage: 'BypassStage',
    ManageProgrammes: 'ManageProgrammes',
}

/**
 * List of permissions that will allow access to the admin screen
 */
const AdminScreenPermissions = [
    Authority.UserPermissions,
    Authority.GroupPermissions,
    Authority.ViewAuditLog,
    Authority.ImportExport,
    Authority.DatabaseManagement,
    Authority.ManageProgrammes,
];

const AdminWorkflowPermissions = [
    Authority.EditWorkFlowFields,
    Authority.EditWorkFlowScreens,
    Authority.FieldPermissions,
];

function userHasAnyPermission(permissions, userContext) {
    for (let permission of permissions) {
        if (userContext.user.permissions.includes(permission)) {
            return true;
        }
    }
    return false;
}

function userHasAllPermissions(permissions, userContext) {
    for (let permission of permissions) {
        if (!userContext.user.permissions.includes(permission)) {
            return false;
        }
    }
    return true;
}

export {
    Authority as default,
    AdminScreenPermissions,
    AdminWorkflowPermissions,
    userHasAnyPermission,
    userHasAllPermissions,
}
