import React from 'react';
import {Grid} from "semantic-ui-react";

type OffsetColumnLayoutProps = {}

const OffsetColumnLayout: React.FunctionComponent<OffsetColumnLayoutProps> = ({children}) => {

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column tablet={1} computer={1} />
                <Grid.Column mobile={16} tablet={15} computer={15}>
                    {children}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default OffsetColumnLayout;
