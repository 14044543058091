import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import Api from 'api/Api';
import { Button, Loader, Dropdown, Header, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class ReopenCaseModal extends React.Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            screens: [],
            selectedScreen: null,
            loading: true,
        }
    }

    componentDidMount() {
        this.getScreenConfigurations();
    }

    getScreenConfigurations() {
        this.setState({loading: true})
        Api.get(`/api/workflow/${this.props.selectedCase.workflowId}`)
            .then(this.setScreens)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setScreens(workflow) {
        const screens = workflow.screens?.map((screen) => ({
            key: screen.screenId,
            value: screen.screenId,
            text: screen.name,
        }));
        this.setState({screens});
    }

    onClose() {
        this.props.onClose();
    }

    onSubmit() {
        this.props.onSubmit(this.props.selectedCase.caseId, this.state.selectedScreen);
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                open={this.props.visible}
                onClose={this.onClose}
            >
                <Header>
                    Reopen Task?
                </Header>
                <Modal.Content>
                    <p>
                        Which Case Stage would you like to set this case to?
                    </p>
                    {this.state.loading ?
                        <Loader active inline="centered" /> :
                        <Dropdown
                            fluid
                            search
                            selection
                            clearable
                            placeholder="Select a Case Stage"
                            options={this.state.screens}
                            onChange={(event, data) => this.setState({selectedScreen: data.value})}
                        />
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.onClose}
                        content="Cancel"
                    />
                    <Button
                        primary
                        onClick={this.onSubmit}
                        content="Submit"
                        disabled={!this.state.selectedScreen}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

ReopenCaseModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedCase: PropTypes.object.isRequired,
    visible: PropTypes.bool,
}

ReopenCaseModal.defaultProps = {
    visible: false,
}

export default ReopenCaseModal