import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import ShowWithPermission, { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';
import Authority from 'common/auth/Authority';
import { Icon } from 'semantic-ui-react';
import PageHeader from 'components/common/PageHeader';
import CaseForm from 'components/case/CaseForm';
import MenuSelect from 'common/layout/MenuSelect';
import OffsetColumnLayout from 'common/layout/OffsetColumnLayout';
import AlertMessage from 'components/common/AlertMessage';
import { getCaseCreatedMessageProps } from 'components/case/CaseCreatedMessage';
import ProgrammeBreadcrumbLink from 'components/programme/ProgrammeBreadcrumbLink';
import withProgramme from 'components/programme/ProgrammeProps';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

class ProgrammePage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            message: 'Case created',
            showMessage: false,
            createCaseModalOpen: this.props.location.state?.createCase || false,
            lastLocation: this.props.location.key,
        };
    }

    PROGRAMME_ITEMS = [
        {
            id: '1',
            name: 'My Cases',
            meta: 'Manage cases assigned to you',
            uri: `${getProgrammeRoute(this.props.programme.id)}/cases`,
            icon: 'briefcase',
            permissions: [
                Authority.EditCase,
                Authority.AllocateCase,
                Authority.AbortCase,
            ],
        },
        {
            id: '2',
            name: 'Reports',
            meta: 'View Reports',
            uri: `${getProgrammeRoute(this.props.programme.id)}/reports`,
            icon: 'chart line',
            permissions: [Authority.Reporting],
        },
        {
            id: '3',
            name: 'Customers',
            meta: 'Manage customers',
            uri: `${getProgrammeRoute(this.props.programme.id)}/customers`,
            icon: 'users',
            permissions: [
                Authority.AddCustomer,
                Authority.ViewAllCustomers,
                Authority.EditCase,
            ],
        },
        {
            id: '4',
            name: 'Manage workflow',
            meta: 'Manage workflow',
            uri: `${getProgrammeRoute(this.props.programme.id)}/admin`,
            icon: 'cogs',
            permissions: [Authority.EditWorkFlowFields, Authority.EditWorkFlowScreens, Authority.FieldPermissions],
        },
    ];

    getCaseCreatedMessage(caseData) {
        return (
            <AlertMessage {...getCaseCreatedMessageProps(caseData)} />
        );
    }

    onCreateCase(caseData) {
        this.setState({
            message: this.getCaseCreatedMessage(caseData),
            showMessage: true,
        });
    }

    hideMessage() {
        this.setState({
            showMessage: false,
        });
    }

    getBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: <ProgrammeBreadcrumbLink />},
        ];
    }

    getUnallocatedBreadcrumbs() {
        return [
            {key: 'Programs', content: <><Icon name="home" /> Programs</>},
            {key: 'Program', content: 'No permission'},
        ];
    }

    renderPermissionedItem(item) {
        return (
            <ShowWithOneOfPermission key={item.id} permissions={item.permissions}>
                <MenuSelect
                    key={item.id}
                    name={item.name}
                    uri={item.uri}
                    meta={item.meta}
                    icon={item.icon}
                />
            </ShowWithOneOfPermission>
        );
    }

    componentDidUpdate() {
        if (this.state.lastLocation !== this.props.location.key) {
            this.setState({createCaseModalOpen: true, lastLocation: this.props.location.key});
        }
    }

    renderRestrictedPage() {
        return (
            <>
                {this.props.programme && <PageHeader
                    text={this.props.programme.name}
                    breadcrumbs={this.getBreadcrumbs()}
                />}
                <OffsetColumnLayout fluid textAlign="left">
                    <MenuSelect
                        key={1}
                        name="Restricted Program"
                        meta="You are restricted from accessing this program"
                        uri="/programmes"
                        icon="lock"
                    />
                </OffsetColumnLayout>
            </>
        );
    }

    render() {
        if (this.props.programme?.restricted) {
            return this.renderRestrictedPage();
        }

        const programmeItemsStart = this.PROGRAMME_ITEMS.slice(0, 1);
        const programmeItemsEnd = this.PROGRAMME_ITEMS.slice(1, 4);

        return (
            <>
                {this.props.programme && <PageHeader
                    text={this.props.programme.name}
                    breadcrumbs={this.getBreadcrumbs()}
                />}
                {this.state.showMessage && this.state.message}
                <OffsetColumnLayout>
                    {programmeItemsStart.map(this.renderPermissionedItem)}
                    <ShowWithPermission permission={Authority.CreateCase}>
                        <CaseForm
                            onSubmit={this.onCreateCase}
                            open={this.state.createCaseModalOpen}
                            setOpen={(value) => this.setState({createCaseModalOpen: value})}
                            trigger={(props) =>
                                <MenuSelect
                                    name="Create Case"
                                    meta="Start a new case"
                                    icon="plus"
                                />
                            }
                        />
                    </ShowWithPermission>
                    {programmeItemsEnd.map(this.renderPermissionedItem)}
                </OffsetColumnLayout>
            </>
        );
    }
}

ProgrammePage.propTypes = {
    children: PropTypes.node,
    programme: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }),
};

ProgrammePage.defaultProps = {
    children: undefined,
    programme: undefined,

};

export default withProgramme(ProgrammePage);
