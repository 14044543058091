import { cloneDeep } from 'lodash';

const getReadOnlyFormSchema = (schema) => {
    const readOnlySchema = cloneDeep(schema);
    for (const key in readOnlySchema.fields) {
        readOnlySchema.fields[key].disabled = true
    }
    return readOnlySchema
}

export { getReadOnlyFormSchema }