import React from 'react';
import PropTypes from 'prop-types';
import {Container, Header, List} from 'semantic-ui-react';

class Help extends React.Component {

    render() {
        return (
            <Container textAlign="left">
                <Header as="h2">Triage the Request</Header>
                <p>For the given customer Id, perform the following steps:</p>
                <List as='ol'>
                    <List.Item as='li'>Find all products that are associated with the customer</List.Item>
                    <List.Item as='li'>
                        Check if any fall into the prescribed list of products that require remediation. These include:
                        <List.List as='ol'>
                            <List.Item as='li' value='-'><em>Loan Product 3A</em></List.Item>
                            <List.Item as='li' value='-'><em>Interest Product 4xC4</em></List.Item>
                        </List.List>
                    </List.Item>
                    <List.Item as='li'>If the customer has any association with the products, a further investigation will be required.</List.Item>
                </List>
            </Container>
        );
    }
}

Help.propTypes = {
    children: PropTypes.node,
};

Help.defaultProps = {
    children: undefined,
};

export default Help;