import React from 'react';
import PropTypes from 'prop-types';
import * as FileTypes from 'common/media/FileTypes';
import isNil from 'lodash/isNil';
import 'common/media/mediaPreview.css';
import PdfViewer from 'common/media/PdfPreview';
import { Image } from 'semantic-ui-react';
import SheetPreview from 'common/media/SheetPreview';

const DefaultPreview = ({url, mimeType}) => {
    const iconClass = FileTypes.lookupFileType(mimeType).icon;
    let previewSection = <span className={`fa-2x ${iconClass}`} />;
    if (iconClass === 'far fa-file') {
        previewSection = (
            <span className="fa-layers fa-lg">
                <i className="far fa-file fa-stack-2x" />
                <i className="far fa-download fa-stack-1x" />
            </span>
        );
    }

    return (
        <div className="text-center shrink-to-content">
            <a href={url} target="_blank" rel="noopener noreferrer" download>
                {previewSection}
            </a>
            <br />
            <i>Preview not available</i>
        </div>
    );
};

DefaultPreview.propTypes = {
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string,
};

DefaultPreview.defaultProps = {
    mimeType: 'application/octet-stream',
};

const OpenUrlInNewWindowViewer = ({url}) => {
    setTimeout(() => window.open(url), 1000);
    return <span className="preview-text">Content opening in new window...</span>;
};

OpenUrlInNewWindowViewer.propTypes = {
    url: PropTypes.string.isRequired,
};

const ImagePreview = ({url, mimeType}) => {
    let imageUrl = url;
    if (FileTypes.isImageContent(mimeType) && isNil(url)) {
        imageUrl = '/images/camera.svg';
    }

    return (
        <div>
            <Image fluid src={imageUrl} alt="" />
        </div>
    );
};

ImagePreview.propTypes = {
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
};

const AudioPreview = ({url, mimeType}) => {
    return (
        <audio controls>
            <source src={url} type={mimeType} />
        </audio>
    );
};

AudioPreview.propTypes = {
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
};

const VideoPreview = ({url, mimeType}) => {
    return (
        <video controls="controls" className="img-responsive">
            <source src={url} type={mimeType} />
        </video>
    );
};

VideoPreview.propTypes = {
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
};

const WebComponentLookup = {
    'application/pdf' : PdfViewer,
    'image/jpeg' : ImagePreview,
    'image/gif' : ImagePreview,
    'image/tiff' : ImagePreview,
    'image/png' : ImagePreview,
    'audio/mpeg' : AudioPreview,
    'audio/x-wav' : AudioPreview,
    'video/x-ms-wmv' : VideoPreview,
    'video/mpeg' : VideoPreview,
    'video/mp4' : VideoPreview,
    'video/webm' : VideoPreview,
    'application/vnd.oasis.opendocument.spreadsheet' : SheetPreview,
};

const OfflineComponentLookup = {
    'application/pdf' : OpenUrlInNewWindowViewer,
    'image/jpeg' : ImagePreview,
    'image/gif' : ImagePreview,
    'image/tiff' : ImagePreview,
    'image/png' : ImagePreview,
    'audio/mpeg' : AudioPreview,
    'audio/x-wav' : AudioPreview,
    'video/x-ms-wmv' : VideoPreview,
    'video/mpeg' : VideoPreview,
    'video/mp4' : VideoPreview,
    'video/webm' : VideoPreview,
};

function getMediaPreviewComponent(url, mimeType, viewerComponents) {
    const MediaComponent = viewerComponents[mimeType];
    if (isNil(MediaComponent)) {
        // Nothing rendered for now as DefaultPreview was confusing to users
        return null;
    }
    return <MediaComponent url={url} mimeType={mimeType} />;
}

class MediaPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'MediaPreview',
        };
    }

    render() {
        return getMediaPreviewComponent(this.props.url, this.props.mimeType, this.props.viewerComponents);
    }
}

MediaPreview.propTypes = {
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string,
    viewerComponents: PropTypes.shape(),
};

MediaPreview.defaultProps = {
    mimeType: null,
    viewerComponents: WebComponentLookup,
};

export {
    MediaPreview as default,
    DefaultPreview,
    WebComponentLookup,
    OfflineComponentLookup,
};