import React from 'react';
import { AlertLevel, AlertMessageProps } from 'components/common/AlertMessage';
import { Link } from 'react-router-dom';
import { getCaseRoute } from 'components/case/CaseRouter';
import { CreateCaseResponse } from 'types/CaseTypes';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const getCaseCreatedMessageProps = (caseData: CreateCaseResponse): AlertMessageProps => {
    return {
        level: AlertLevel.Positive,
        heading: 'Case Created',
        content: (
            <Link to={getCaseRoute(getProgrammeIdFromCurrentPath(), caseData.caseId)}>
                A new case has been launched ({caseData.displayId})
            </Link>),
    };
};

export { getCaseCreatedMessageProps };
