import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from "semantic-ui-react";

const LoaderIcon = ({loading, icon, onClick}) => {
    const iconName = loading ? 'circle notch' : icon;
    return (
        <Icon loading={loading} name={iconName} onClick={onClick} />
    );
};

LoaderIcon.propTypes = {
    loading: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

LoaderIcon.defaultProps = {
    loading: false,
    onClick: () => {}
};

export default LoaderIcon;