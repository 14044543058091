import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';

class FileInput extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
    }

    componentDidMount() {
        if (this.props.autoFocus === true) {
            this.inputField.focus();
        }
    }

    handleFileInputChange(event) {
        event.preventDefault();
        let file = null;
        if (event.target.files) {
            if (!this.props.allowMultiple) {
                file = event.target.files['0'];
            } else {
                file = Array.from(event.target.files);
            }
        }

        if (this.props.onFileInputChange) {
            this.props.onFileInputChange(file);
        }
    }

    render() {
        return (
            <input
                type="file"
                id="file-upload"
                name="file-upload"
                onChange={this.handleFileInputChange}
                ref={(inputField) => {this.inputField = inputField;}}
                multiple={this.props.allowMultiple}
                data-cy="upload-file"
            />
        );
    }

}

FileInput.propTypes = {
    allowMultiple: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onFileInputChange: PropTypes.func,
};

FileInput.defaultProps = {
    allowMultiple: false,
    autoFocus: false,
    onFileInputChange: () => { },
};

export default FileInput;