import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import Api from 'api/Api';
import { Loader } from 'semantic-ui-react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

class NextStageDisplay extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            nextStep: undefined,
            reason: undefined,
            calculating: true,
        };
        this.getNextStep = debounce(this.getNextStep, 1000);
    }

    componentDidMount() {
        if (this.props.taskId) {
            this.getNextStep(this.props.taskId, this.props.reviewData);
        }
    }

    hasPropsChanged(nextProps) {
        return (nextProps.taskId !== this.props.taskId)
            || (!isEqual(nextProps.reviewData, this.props.reviewData));
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.hasPropsChanged(nextProps)) {
            this.setState({calculating: true});
            return true;
        }
        if (!isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.hasPropsChanged(prevProps)) {
            this.getNextStep(this.props.taskId, this.props.reviewData);
        }
    }

    getNextStep(taskId, reviewData) {
        Api.post(`/api/reviews/${this.props.taskId}/next`, {fieldData: reviewData})
            .then(this.setNextStep)
            .catch(this.onCalculateError)
            .finally(() => this.setState({calculating: false}));
    }

    setNextStep(result) {
        this.setState({
            nextStep: result.nextStep,
            reason: result.reason,
        });
    }

    onCalculateError() {
        this.setState({
            nextStep: 'Could not determine next step',
            reason: undefined,
        });
    }

    render() {
        if (this.state.calculating) {
            return (
                <div>
                    <Loader active inline size="tiny" /> Calculating
                </div>
            );
        }

        return (
            <div>
                <strong>{this.state.nextStep} ({this.state.reason})</strong>
            </div>
        );
    }
}

NextStageDisplay.propTypes = {
    taskId: PropTypes.string.isRequired,
    reviewData: PropTypes.shape().isRequired,
};

export default NextStageDisplay;