import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import PropTypes from 'prop-types';
import { Button, Container, Modal } from 'semantic-ui-react';
import Api from 'api/Api';
import StagedFilesList from 'components/customer/StagedFilesList';
import AlertMessage, { AlertLevel } from 'components/common/AlertMessage';
import ConfirmDelete from 'components/common/ConfirmDelete';
import UploadFilesModal from 'components/common/UploadFilesModal';
import { createReviewStageFile } from 'common/util/FileUploadHelpers';

class UploadDocumentsModal extends React.Component {
    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            _isMounted: false,
            loadingFiles: false,
            apiError: null,
            deletingFile: null,
            uploadFileModalVisible: false,
            files: [],
        }
    }

    componentDidMount() {
        this.setState({_isMounted: true});
    }

    componentWillUnmount() {
        this.setState({_isMounted: false});
    }

    handleApiError(error) {
        this.setState({apiError: error});
        Api.logError(error);
    }

    getFiles(callback) {
        this.setState({loadingFiles: true}, );
        Api.get(`/api/reviews/${this.props.taskId}/files`)
            .then((data) => {
                this.setFiles(data);
                if (callback) {
                    callback(data);
                }
            })
            .catch(this.handleApiError)
            .finally(() => {
                if (this.state._isMounted) {
                    this.setState({loadingFiles: false});
                }
            });
    }

    setFiles(data) {
        if (this.state._isMounted) {
            this.setState({files: data});
        }
    }

    onUploadFiles(files) {
        return new Promise((resolve, reject) => {
            if (files) {
                const uploadPromiseArray = files.map((file) => createReviewStageFile(this.props.taskId, file))
                return Promise.all(uploadPromiseArray)
                    .then(res => {
                        this.getFiles(this.props.onUploadDocuments);
                        return resolve(res)
                    })
                    .catch(err => reject(err));
            }
            return reject("Please Select a file.");
        })
    }

    deleteSelectedFile() {
        Api.delete(`/api/reviews/${this.props.taskId}/files/${this.state.deletingFile.stagedFileId}`)
            .then(() => this.props.onDeleteDocument(this.state.deletingFile.stagedFileId))
            .then(this.getFiles)
            .catch(this.handleApiError)
            .finally(() => {
                if (this.state._isMounted) {
                    this.setState({deletingFile: null})
                }
            });
    }

    onClose() {
        this.setState({apiError: null});
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                onMount={this.getFiles}
                open={this.props.visible}
                centered={false}
            >
                <Modal.Header>
                    Upload Stage Documents
                </Modal.Header>
                <Modal.Content>
                        {this.state.apiError &&
                         <AlertMessage
                             level={AlertLevel.Error}
                             heading={"API Error"}
                             content={this.state.apiError}
                             dismissible={true}
                         />
                        }
                    <ConfirmDelete
                        open={this.state.deletingFile !== null}
                        onConfirm={this.deleteSelectedFile}
                        onCancel={() => this.setState({deletingFile: null})}
                        title="Delete File?"
                        content={"Are you sure you want to delete this file?"}
                    />
                    <UploadFilesModal
                        visible={this.state.uploadFileModalVisible}
                        onClose={() => this.setState({uploadFileModalVisible: false})}
                        onSubmit={this.onUploadFiles}
                    />
                    <Container textAlign="right" fluid>
                        <Button
                            compact
                            primary
                            onClick={() => this.setState({uploadFileModalVisible: true})}
                            icon='add'
                            content='Add Artefacts'
                        />
                    </Container>
                    <StagedFilesList
                        onFileSelect={this.props.onFileSelect}
                        onFileDelete={this.props.readOnly ? undefined : (stagedFile) => this.setState({deletingFile: stagedFile})}
                        downloadUrl={`/api/reviews/${this.props.taskId}/files`}
                        files={this.state.files}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Ok"
                        primary
                        onClick={this.onClose}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

}

UploadDocumentsModal.propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    taskId: PropTypes.string.isRequired,
    onUploadDocuments: PropTypes.func.isRequired,
    onDeleteDocument: PropTypes.func.isRequired,
}

UploadDocumentsModal.defaultProps = {
    visible: false,
}

export default UploadDocumentsModal;