import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { isValueFalse } from 'common/util/Helpers';
import { executeDefaultValidators } from 'external/form/Validators';

const FieldToggle = ({name, label, value, onChange, disabled, registerValidator, deregisterValidator}) => {
    useEffect(() => {
        registerValidator(name, executeDefaultValidators);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const valueChanged = (event, data) => {
        if(disabled) {
            return
        }
        onChange(name, data.checked);
    };

    const checked = !isValueFalse(value)
    return (
        <label htmlFor={name}>
            <Checkbox toggle name={name} onClick={valueChanged} checked={checked} disabled={disabled} />
        </label>
    );
};

FieldToggle.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldToggle.defaultProps = {
    value: false,
    required: false,
    onChange: () => {},
    error: undefined,
    disabled: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldToggle;
