import SortDirection from 'components/common/SortDirection';
import isNil from 'lodash/isNil';

const SORT_INDICATOR_MAP = {
    [SortDirection.Ascending]: '+',
    [SortDirection.Descending]: '-',
}

const SORT_INDICATOR_REVERSE_MAP = {
    '+': SortDirection.Ascending,
    '-': SortDirection.Descending,
}

function getSortByUrlQueryValue(sortState) {
    if (sortState.sortKey && sortState?.direction !== SortDirection.None) {
        const sortIndicator = SORT_INDICATOR_MAP[sortState.direction];
        return `${sortIndicator}${sortState.sortKey}`;
    }
    return undefined;
}

function getSortByFromUrlValue(urlValue) {
    if (!isNil(urlValue) && urlValue.length > 0) {
        const sortIndicator = urlValue[0];
        const sortKey = urlValue.slice(1);
        return {
            direction: SORT_INDICATOR_REVERSE_MAP[sortIndicator],
            sortKey: sortKey,
        }
    }
    return {
        direction: SortDirection.None,
        sortKey: undefined
    };
}

/**
 * Takes in a sortState in the form of {sortKey: 'customerName', direction: SortDirection.Ascending}
 * Takes in any other params in the form of {param1: value1, param2: value2}
 * Outputs a urlsearch params object that can be added to the end of a url
 * */
function getUrlQueryParams(sortState, extraQueryParams = {}) {
    const urlQueryParams = new URLSearchParams(extraQueryParams);

    const sortValue = getSortByUrlQueryValue(sortState);
    if (sortValue !== undefined) {
        urlQueryParams.append('orderBy', sortValue);
    }
    return urlQueryParams;
}

export {
    getUrlQueryParams,
    getSortByUrlQueryValue,
    getSortByFromUrlValue,
}