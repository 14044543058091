import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import { Form, Label, Loader, Message, Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom'
import 'components/reports/ExternalProgressReport.scss';
import Api from 'api/Api';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from 'moment';
import { getTimeAtEndOfToday } from 'common/util/DateHelpers';
import withProgramme from 'components/programme/ProgrammeProps';

const DATE_URL_PARAMETER = "date";
const DATE_URL_FORMAT = 'YYYY-MM-DD';

class ExternalProgressReport extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            data: [],
            reportDate: null,
            loading: false,
            error: null,
            noSampleAvailable: false,
            isNextAvailableSample: false,
            initialDate: new Date(),
            maxDate: getTimeAtEndOfToday(),
        };
    }

    componentDidMount() {
        this.fetchReport();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.location.search !== prevProps.location.search)
            && (this.getDateFromUrl() !== undefined)) {
            this.fetchReport();
        }
    }

    fetchError(error) {
        this.setState({error: error});
    }

    getDateFromUrl() {
        const query = new URLSearchParams(this.props.location.search);
        if (!query.has(DATE_URL_PARAMETER)) {
            return this.state.initialDate;
        }

        let date = query.get(DATE_URL_PARAMETER);
        if (date) {
            const momentDateAtStartOfDay = new moment(date, DATE_URL_FORMAT);
            date = this.addTimeOfDay(momentDateAtStartOfDay).toDate();
        } else {
            date = undefined;
        }
        return date;
    }

    addTimeOfDay(momentDate) {
        const now = new moment();
        return momentDate
            .add(now.hours(), 'h')
            .add(now.minutes(), 'm')
            .add(now.seconds(), 's');
    }

    getDateForApiRequest() {
        let date = this.getDateFromUrl();
        if (date) {
            return moment(date).utc().format(DATE_URL_FORMAT);
        }
        return undefined;
    }

    getApiQuery() {
        const date = this.getDateForApiRequest();
        if (date) {
            return {date: date};
        }
        return {};
    }

    fetchReport() {
        this.setState({loading: true});
        Api.getWithQuery(`/api/reports/external`, this.getApiQuery())
            .then(this.setReportData)
            .catch(this.fetchError)
            .finally(() => this.setState({loading: false}));
    }

    setReportData(response) {
        this.setState({
            data: response.segments,
            reportDate: response.date,
            noSampleAvailable: response.noSampleAvailable,
            isNextAvailableSample: response.nextAvailableSample,
            error: null,
        });
    }

    onSelectDate(event, data) {
        const newDate = data.value;
        let urlDate = undefined;
        if (newDate) {
            urlDate = moment(newDate).format(DATE_URL_FORMAT)
        }
        this.setUrl(urlDate)
    }

    setUrl(date) {
        const searchParams = new URLSearchParams();
        if (date) {
            searchParams.set(DATE_URL_PARAMETER, date);
        } else {
            searchParams.set(DATE_URL_PARAMETER, "");
        }
        this.props.history.push(`${this.props.location.pathname}?${searchParams.toString()}`)
    }

    renderCount(count) {
        if (count === null || count === 0) {
            return '-';
        }
        return count.toLocaleString();
    }

    renderPercent(percent) {
        if (percent !== null && percent >= 0) {
            return <>{percent}%</>;
        }
        return null;
    }

    renderReportDate(date) {
        return moment(date).format('ddd DD MMM YYYY');
    }

    renderNoSampleInfoMessage() {
        if (this.state.isNextAvailableSample) {
            return (
                <Message info>
                    <Message.Header>No data available</Message.Header>
                    <p>There is no data for the selected date. The next available date was used instead.</p>
                </Message>
            );
        }

        if (this.state.noSampleAvailable) {
            return (
                <Message info>
                    <Message.Header>No data available</Message.Header>
                    <p>There is no data for the selected date.</p>
                </Message>
            );
        }
    }

    render() {
        const selectedDate = this.getDateFromUrl();
        const showTable = (selectedDate != null)
                          && (!this.state.error)
                          && (this.state.data)
                          && (this.state.data.length > 0);

        return (
            <div>
                <Loader active={this.state.loading} />
                {this.state.error &&
                 <Message negative>
                     <Message.Header>There was an error generating the report</Message.Header>
                     <p>{this.state.error}</p>
                 </Message>
                }
                {this.renderNoSampleInfoMessage()}
                <Form>
                    <Form.Group inline>
                        <label>Date</label>
                        <SemanticDatepicker
                            format="DD/MM/YYYY"
                            value={selectedDate}
                            onChange={this.onSelectDate}
                            maxDate={this.state.maxDate}
                        />
                        {(selectedDate == null) &&
                         <Label basic color='red' pointing='left'>
                             Please select a valid date
                         </Label>
                        }
                    </Form.Group>
                </Form>
                {showTable &&
                 <Table collapsing unstackable className="external">
                     <Table.Header>
                         <Table.Row>
                             <Table.HeaderCell />
                             <Table.HeaderCell textAlign="right" colSpan="2">As at Date</Table.HeaderCell>
                             <Table.HeaderCell />
                             <Table.HeaderCell textAlign="right">% Completion</Table.HeaderCell>
                         </Table.Row>
                         <Table.Row>
                             <Table.HeaderCell />
                             <Table.HeaderCell textAlign="right" colSpan="2">{this.renderReportDate(this.state.reportDate)}</Table.HeaderCell>
                             <Table.HeaderCell />
                             <Table.HeaderCell />
                         </Table.Row>
                     </Table.Header>
                     <Table.Body>
                         {this.state.data.map(segment => {
                             return (
                                 <React.Fragment key={segment.segmentId}>
                                     <Table.Row className="header-2">
                                         <Table.Cell colSpan="5">{segment.title}</Table.Cell>
                                     </Table.Row>
                                     {segment.items.map(item => {
                                         let classname = item.type;
                                         if (item.error) {
                                             classname = `${item.type} report-error`;
                                         }
                                         return (
                                             <Table.Row className={classname} key={item.itemId}>
                                                 <Table.Cell textAlign="right">
                                                     <div>{item.itemId}</div>
                                                 </Table.Cell>
                                                 <Table.Cell textAlign="left">{item.title}</Table.Cell>
                                                 <Table.Cell textAlign="right">{this.renderCount(item.count)}</Table.Cell>
                                                 <Table.Cell />
                                                 <Table.Cell textAlign="right">{this.renderPercent(item.percent)}</Table.Cell>
                                             </Table.Row>
                                         );
                                     })}
                                 </React.Fragment>
                             );
                         })}
                     </Table.Body>
                 </Table>
                }
            </div>
        );
    }
}

ExternalProgressReport.propTypes = {};

ExternalProgressReport.defaultProps = {};

export default withRouter(withProgramme(ExternalProgressReport));