import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Form, Table } from 'semantic-ui-react';
import FieldDate from 'common/form/fields/FieldDate';
import FieldSelect from 'common/form/fields/FieldSelect';
import { generateUuid } from 'common/util/Helpers';
import { getHumanReadableDate } from 'common/util/DateHelpers';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { ERROR_FIELD_REQUIRED, getErrorResponse, NoValidationError } from 'external/form/Validators';
import { AUDollarFormatter } from 'common/util/StringHelpers';
import { cloneDeep } from 'lodash';

const IsEvidenceEmptyId = 'No Evidence';

const EVIDENCE_TYPES = [
    {id: 'Primary Evidence', text: 'Primary Evidence'},
    {id: 'Secondary Evidence', text: 'Secondary Evidence'},
    {id: 'No Evidence Available', text: 'No Evidence Available'},
];

const FEE_TYPES = [
    {id: "Annual Service Fee (ASF)", text: "Annual Service Fee (ASF)"},
    {id: "Ongoing Service Fee", text: "Ongoing Service Fee"},
    {id: "One-off Advice Fee", text: "One-off Advice Fee"},
    {id: "Trail Commission", text: "Trail Commission"},
    {id: "Mix of Trail Commission & ASF ", text: "Mix of Trail Commission & ASF "},
];

const PRIMARY_EVIDENCE_SUPPORT = [
    {id: "Statement of Advice (SOA)", text: "Statement of Advice (SOA)"},
    {id: "Record of Advice (ROA)", text: "Record of Advice (ROA)"},
    {id: "Fee Disclosure Statement (FDS)", text: "Fee Disclosure Statement (FDS)"},
];

const SECONDARY_EVIDENCE_SUPPORT = [
    {id: "Adviser Email", text: "Adviser Email"},
    {id: "Adviser Phone Call(s)", text: "Adviser Phone Call(s)"},
    {id: "Adviser Notes", text: "Adviser Notes"},
    {id: "Other Written Correspondence", text: "Other Written Correspondence"},
    {id: "Client Provided Evidence", text: "Client Provided Evidence"},
];

const NO_EVIDENCE_SUPPORT = [{id: "No Evidence", text: "No Evidence"}];

const EditableFeeItem = ({onSaved, onCancel, initialData}) => {
    const [date, setDate] = useState(undefined);
    const [feePaid, setFeePaid] = useState("");
    const [feeType, setFeeType] = useState(null);
    const [annualAdviceFee, setAnnualAdviceFee] = useState("");
    const [evidenceType, setEvidenceType] = useState(null);
    const [evidenceSupport, setEvidenceSupport] = useState(null);
    const [refundAmount, setRefundAmount] = useState(0);

    const [feePaidError, setFeePaidError] = useState(undefined);
    const [annualAdviceFeeError, setAnnualAdviceFeeError] = useState(undefined);

    const [evidenceSupportOptions, setEvidenceSupportOptions] = useState([]);

    useEffect(() => {
        if ((evidenceType === 'No Evidence Available') && (annualAdviceFee !== '')) {
            setRefundAmount(parseFloat(annualAdviceFee));
        } else {
            setRefundAmount(0);
        }
    }, [annualAdviceFee, evidenceType]);

    useEffect(() => {
        if (initialData) {
            setDate(initialData.date);
            setFeePaid(initialData.feePaid);
            setFeeType(initialData.feeType);
            setAnnualAdviceFee(initialData.annualAdviceFee);
            onEvidenceTypeChange(null, initialData.evidenceType);
            setEvidenceSupport(initialData.evidenceSupport);
        }
    }, [initialData]);

    const finishButtonEnabled = date
        && (feePaid !== '')
        && feeType
        && (annualAdviceFee !== '')
        && evidenceType
        && evidenceSupport;

    const clearErrors = () => {
        setAnnualAdviceFeeError(undefined);
        setFeePaidError(undefined);
    }

    const onFeePaidChange = (event) => {
        clearErrors();
        const newVal = parseFloat(event.target.value);
        if (!isNaN(newVal)) {
            setFeePaid(newVal);
        } else {
            setFeePaid('');
        }
    }

    const onAnnualAdviceFeeChange = (event) => {
        clearErrors();
        const newVal = parseFloat(event.target.value);
        if (!isNaN(newVal)) {
            setAnnualAdviceFee(newVal);
        } else {
            setAnnualAdviceFee('');
        }
    }

    const onEvidenceTypeChange = (event, value) => {
        setEvidenceType(value);
        setEvidenceSupport(null);

        if (value === "Primary Evidence") {
            return setEvidenceSupportOptions(PRIMARY_EVIDENCE_SUPPORT)
        }

        if (value === "Secondary Evidence") {
            return setEvidenceSupportOptions(SECONDARY_EVIDENCE_SUPPORT)
        }

        setEvidenceSupport("No Evidence")
        return setEvidenceSupportOptions(NO_EVIDENCE_SUPPORT);
    }

    const validateInputs = () => {
        let inputsValid = true;

        if (parseFloat(annualAdviceFee) > parseFloat(feePaid)) {
            setAnnualAdviceFeeError("Annual advice fee component must not be greater than the fees paid");
            inputsValid = false;
        }

        if (feePaid < 0) {
            setFeePaidError("Values less than zero are not allowed");
            inputsValid = false;
        }

        if (annualAdviceFee < 0) {
            setAnnualAdviceFeeError("Values less than zero are not allowed");
            inputsValid = false;
        }

        return inputsValid
    }

    const onAddHandler = (e) => {
        if (validateInputs()) {
            onSaved({
                id: initialData?.id,
                date,
                feePaid: parseFloat(feePaid),
                feeType,
                annualAdviceFee: parseFloat(annualAdviceFee),
                evidenceType,
                evidenceSupport,
                refundAmount: parseFloat(refundAmount),
            })
        }
    }

    return (
        <>
            <Table.Row>
                <Table.Cell verticalAlign="top">
                    <FieldDate
                        name="date"
                        placeHolder="Date"
                        onChange={(n, v) => setDate(v)}
                        value={date}
                    />
                </Table.Cell>

                <Table.Cell verticalAlign="top">
                    <Form.Input
                        style={{maxWidth: '100px'}}
                        labelPosition='left'
                        type="number"
                        placeholder='Fee Paid'
                        value={feePaid}
                        onChange={onFeePaidChange}
                        error={feePaidError ? {content: feePaidError, pointing: 'below'} : false}
                    >
                    </Form.Input>
                </Table.Cell>

                <Table.Cell verticalAlign="top">
                    <FieldSelect
                        name="feeType"
                        options={FEE_TYPES}
                        placeHolder="Fee Type"
                        onChange={(n, v) => setFeeType(v)}
                        value={feeType}
                        fluid
                    />
                </Table.Cell>

                <Table.Cell verticalAlign="top" textAlign="right">
                    <Form.Input
                        style={{maxWidth: '130px'}}
                        fluid
                        labelPosition='left'
                        type="number"
                        placeholder='Advice Fee'
                        error={annualAdviceFeeError ? {
                            content: annualAdviceFeeError,
                            pointing: 'below',
                        } : false}
                        value={annualAdviceFee}
                        onChange={onAnnualAdviceFeeChange}
                    />
                </Table.Cell>

                <Table.Cell verticalAlign="top">
                    <FieldSelect
                        name="evidenceType"
                        options={EVIDENCE_TYPES}
                        placeHolder="Type"
                        onChange={onEvidenceTypeChange}
                        value={evidenceType}
                        fluid
                    />
                </Table.Cell>

                <Table.Cell verticalAlign="top">
                    <FieldSelect
                        name="evidenceSupport"
                        options={evidenceSupportOptions}
                        placeHolder="Support"
                        onChange={(n, v) => setEvidenceSupport(v)}
                        value={evidenceSupport}
                        fluid
                    />
                </Table.Cell>

                <Table.Cell verticalAlign="top" textAlign="right">
                    <span style={{minWidth: '100px'}}>
                        {AUDollarFormatter.format(refundAmount)}
                    </span>

                </Table.Cell>

                <Table.Cell verticalAlign="top">
                    <Button.Group>
                        <Button
                            positive
                            icon="check"
                            disabled={!finishButtonEnabled}
                            onClick={onAddHandler}
                        />
                        <Button icon="cancel" onClick={onCancel} />
                    </Button.Group>
                </Table.Cell>
            </Table.Row>
        </>
    );
};

EditableFeeItem.propTypes = {
    onSaved: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialData: PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        feePaid: PropTypes.number,
        feeType: PropTypes.string,
        annualAdviceFee: PropTypes.number,
        evidenceType: PropTypes.string,
        evidenceSupport: PropTypes.string,
        refundAmount: PropTypes.number,
    }),
};

EditableFeeItem.defaultProps = {};

class FieldFeeSchedule extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            isAdding: false,
            editingIds: [],
        };
    }

    componentDidMount() {
        this.props.registerValidator(this.props.name, this.validate);
    }

    componentWillUnmount() {
        this.props.deregisterValidator(this.props.name);
    }

    validate(fieldData, fieldConfiguration) {
        if (fieldConfiguration.required && (isNil(fieldData) || isEmpty(fieldData))) {
            return getErrorResponse(ERROR_FIELD_REQUIRED);
        }
        return NoValidationError;
    }

    onAdded({date, feePaid, feeType, annualAdviceFee, evidenceType, evidenceSupport, refundAmount}) {
        this.onCancelAdd();
        const feePaidFloat = parseFloat(feePaid);
        const annualAdviceFeeFloat = parseFloat(annualAdviceFee);
        const refundAmountFloat = parseFloat(refundAmount);
        const newValues = this.props.value.concat([{
            id: generateUuid(),
            date: date,
            feePaid: feePaidFloat,
            feeType: feeType,
            annualAdviceFee: annualAdviceFeeFloat,
            evidenceType: evidenceType,
            evidenceSupport: evidenceSupport,
            refundAmount: refundAmountFloat,
        }]);
        this.props.onChange(this.props.name, newValues)
    }

    onCancelAdd() {
        this.setState({isAdding: false});
    }

    onAddClicked() {
        this.setState({isAdding: true});
    }

    deleteEntry(id) {
        const newValues = this.props.value.filter(item => item.id !== id);
        this.props.onChange(this.props.name, newValues)
    }

    editEntry(id) {
        const newEditIds = this.state.editingIds.concat([id]);
        this.setState({editingIds: newEditIds});
    }

    onEditSave(data) {
        this.onCancelEdit(data.id);
        const newValues = cloneDeep(this.props.value);
        const itemToEditIndex = newValues.findIndex(item => item.id === data.id);
        newValues[itemToEditIndex] = data;
        this.props.onChange(this.props.name, newValues);
    }

    onCancelEdit(id) {
        const newEditIds = this.state.editingIds.filter(val => val !== id);
        this.setState({editingIds: newEditIds});
    }

    renderEvidenceRow(item) {
        const isNegative = item.evidenceType === IsEvidenceEmptyId;

        if (this.state.editingIds.includes(item.id)) {
            return (
                <EditableFeeItem
                    key={item.id}
                    onCancel={() => {this.onCancelEdit(item.id)}}
                    onSaved={(data) => this.onEditSave(data)}
                    initialData={item}
                />);
        }

        return (
            <Table.Row key={item.id}>
                <Table.Cell textAlign="left">{getHumanReadableDate(item.date)}</Table.Cell>
                <Table.Cell textAlign="right">{AUDollarFormatter.format(item.feePaid)}</Table.Cell>
                <Table.Cell textAlign="center">{item.feeType}</Table.Cell>
                <Table.Cell textAlign="right">{AUDollarFormatter.format(item.annualAdviceFee)}</Table.Cell>
                <Table.Cell textAlign="center">{item.evidenceType}</Table.Cell>
                <Table.Cell textAlign="center">{item.evidenceSupport}</Table.Cell>
                <Table.Cell
                    textAlign="right"
                    negative={isNegative}
                >{AUDollarFormatter.format(item.refundAmount)}</Table.Cell>
                {!this.props.disabled &&
                 <Table.Cell textAlign="right">
                     <Button.Group>
                         <Button
                             icon='trash'
                             onClick={() => this.deleteEntry(item.id)}
                             className="hover-select"
                         />
                         <Button
                             icon="edit"
                             onClick={() => this.editEntry(item.id)}
                             className="hover-select"
                         />
                     </Button.Group>
                 </Table.Cell>
                }
            </Table.Row>
        );
    }

    render() {
        let {value} = this.props;
        if (isNil(value)) {
            value = [];
        }
        const feesPaidSum = value.reduce((sum, item) => sum + item.feePaid, 0);
        const adviceFeeSum = value.reduce((sum, item) => sum + item.annualAdviceFee, 0);
        const refundSum = value.reduce((sum, item) => sum + item.refundAmount, 0);

        return (
            <Table attached celled striped unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left">Date</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Fee Paid ($)</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Fee Type</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Annual Advice Fee Component ($)
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Evidence Type</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Evidence Support</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Fees to be Refunded ($)</Table.HeaderCell>
                        {!this.props.disabled &&
                         <Table.HeaderCell />
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {value.map(this.renderEvidenceRow)}
                    {(value.length === 0 && !this.state.isAdding) &&
                     <Table.Row>
                         <Table.Cell textAlign="center" colSpan={8}>There are no entries</Table.Cell>
                     </Table.Row>
                    }
                    {this.state.isAdding &&
                     <EditableFeeItem onSaved={this.onAdded} onCancel={this.onCancelAdd} />
                    }
                </Table.Body>

                <Table.Footer>
                    {value.length !== 0 &&
                     <>
                         <Table.Row active>
                             <Table.Cell textAlign="right"><em>Total</em></Table.Cell>
                             <Table.Cell textAlign="right">
                                 <strong>{AUDollarFormatter.format(feesPaidSum)}</strong>
                             </Table.Cell>
                             <Table.Cell />
                             <Table.Cell textAlign="right">
                                 <strong>{AUDollarFormatter.format(adviceFeeSum)}</strong>
                             </Table.Cell>
                             <Table.Cell colSpan={2} />
                             <Table.Cell textAlign="right">
                                 <strong>{AUDollarFormatter.format(refundSum)}</strong>
                             </Table.Cell>
                             {!this.props.disabled && <Table.Cell />}
                         </Table.Row>
                     </>
                    }
                    {(!this.props.disabled && !this.state.isAdding) &&
                     <Table.Row>
                         <Table.Cell textAlign="center" colSpan={8}>
                             <Button
                                 primary
                                 onClick={this.onAddClicked}
                                 disabled={this.state.isAdding}
                             >+ Add Fee Charged</Button>
                         </Table.Cell>
                     </Table.Row>
                    }
                </Table.Footer>
            </Table>
        );
    }
}

FieldFeeSchedule.validate = (fieldData, fieldConfiguration) => {
    if (fieldConfiguration.required && (isNil(fieldData) || isEmpty(fieldData))) {
        return getErrorResponse(ERROR_FIELD_REQUIRED);
    }
    return NoValidationError;
}

FieldFeeSchedule.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            date: PropTypes.string,
            feePaid: PropTypes.number,
            feeType: PropTypes.string,
            annualAdviceFee: PropTypes.number,
            evidenceType: PropTypes.string,
            evidenceSupport: PropTypes.string,
            refundAmount: PropTypes.number,
        })),
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    autoFocus: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldFeeSchedule.defaultProps = {
    value: [],
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    autoFocus: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldFeeSchedule;
