import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Checkbox, Form, Loader, Message, Select, Table } from 'semantic-ui-react';
import Api from 'api/Api';
import { getCaseListRoute } from 'components/case/CaseRouter';
import TaskProcessState from 'components/case/reviews/TaskProcessState';
import {
    CASE_STATE_URL_PARAMETER,
    REVIEW_STATE_URL_PARAMETER,
    STAGE_URL_PARAMETER,
} from 'components/case/withCaseQueryParams';
import 'components/reports/WaterfallReport.scss';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { convertTimeToIsoString, getDateAsJsonString, getTimeAtEndOfToday } from 'common/util/DateHelpers';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const IntervalOptions = ['Daily', 'Weekly', 'Monthly', 'Yearly']
    .map(item => {return {key: item, value: item, text: item};});

const SampleOptions = ['5', '10', '15', '20']
    .map(item => {return {key: item, value: item, text: item};});

const StatusTextMap = {
    [TaskProcessState.Unallocated]: 'Unallocated',
    [TaskProcessState.NotStarted]: 'Not Started',
    [TaskProcessState.InProgress]: 'In Progress',
    [TaskProcessState.OnHold]: 'On Hold',
    [TaskProcessState.Closed]: 'Completed',
};

const TaskProcessStateMap = {
    [TaskProcessState.Unallocated]: 'unallocated',
    [TaskProcessState.NotStarted]: 'notstarted',
    [TaskProcessState.InProgress]: 'inprogress',
    [TaskProcessState.OnHold]: 'onhold',
    [TaskProcessState.Closed]: 'closed',
};

const OPEN_SEARCH_IN_NEW_WINDOW = false;

const INTERVAL_URL_PARAMETER = 'interval';
const SAMPLES_URL_PARAMETER = 'samples';
const DELTAS_URL_PARAMETER = 'deltas';
const DELTAS_URL_VALUE = '1';
const DATE_URL_PARAMETER = "date";
const DATE_URL_FORMAT = 'YYYY-MM-DD';

const ALL_CASE_STATE_FILTER_VALUE = 'all';
const EMPTY_SAMPLE_TEXT = '-';

const CLASS_VERTICAL_PRIMARY = "vertical title primary";
const CLASS_VERTICAL_SECONDARY = "vertical title secondary";

const AccountingValue = ({value}) => {
    if (value < 0) {
        return <span className="accounting-value">({Math.abs(value).toLocaleString()})</span>;
    }

    let displayValue = value;
    if (!isNaN(displayValue)) {
        displayValue = value.toLocaleString();
    }
    return (
        <span className="accounting-value offset">{displayValue}</span>
    );
};

AccountingValue.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};

class WaterfallReport extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            data: null,
            loading: false,
            error: null,
            initialDate: new Date(),
        };
    }

    componentDidMount() {
        this.fetchReport();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.fetchReport();
        }
    }

    getIntervalFromUrl() {
        const query = new URLSearchParams(this.props.location.search);
        let interval = query.get(INTERVAL_URL_PARAMETER);
        if (!interval) {
            interval = IntervalOptions[0].value;
        }
        return interval;
    }

    getSamplesFromUrl() {
        const query = new URLSearchParams(this.props.location.search);
        let samples = query.get(SAMPLES_URL_PARAMETER);
        if (!samples) {
            samples = SampleOptions[0].value;
        }
        return samples;
    }

    getDeltaFromUrl() {
        const query = new URLSearchParams(this.props.location.search);
        return query.has(DELTAS_URL_PARAMETER);
    }

    getDateFromUrl() {
        const query = new URLSearchParams(this.props.location.search);
        if (!query.has(DATE_URL_PARAMETER)) {
            return null;
        }

        let dateString = query.get(DATE_URL_PARAMETER);
        if (!dateString) {
            return null;
        }

        const momentDateAtStartOfDay = new moment(dateString, DATE_URL_FORMAT);
        return this.addTimeOfDay(momentDateAtStartOfDay).toDate();
    }

    addTimeOfDay(momentDate) {
        const now = new moment();
        return momentDate
            .add(now.hours(), 'h')
            .add(now.minutes(), 'm')
            .add(now.seconds(), 's');
    }

    getDateForApiRequest() {
        let date = this.getDateFromUrl();
        if (date) {
            return convertTimeToIsoString(date);
        }
        return null;
    }

    fetchReport() {
        this.setState({loading: true});
        const query = {
            date: this.getDateForApiRequest(),
            interval: this.getIntervalFromUrl(),
            count: this.getSamplesFromUrl(),
        };
        if (this.getDeltaFromUrl()) {
            query.showDeltas = DELTAS_URL_VALUE;
        }

        Api.getWithQuery(`/api/reports/waterfall`, query)
            .then(this.setReportData)
            .catch(this.fetchError)
            .finally(() => this.setState({loading: false}));
    }

    fetchError(error) {
        Api.logError(error);
        this.setState({error: error});
    }

    setReportData(data) {
        this.setState({
            data: data,
            error: null,
        });
    }

    getCasesInStageLink(stage) {
        const searchParams = new URLSearchParams();
        searchParams.set(STAGE_URL_PARAMETER, stage.id);
        searchParams.set(CASE_STATE_URL_PARAMETER, ALL_CASE_STATE_FILTER_VALUE);
        return `${getCaseListRoute(getProgrammeIdFromCurrentPath())}?${searchParams.toString()}`;
    }

    getCasesByStageAndStateLink(stage, state) {
        const searchParams = new URLSearchParams();
        searchParams.set(STAGE_URL_PARAMETER, stage.id);
        searchParams.set(REVIEW_STATE_URL_PARAMETER, state);
        searchParams.set(CASE_STATE_URL_PARAMETER, ALL_CASE_STATE_FILTER_VALUE);
        return `${getCaseListRoute(getProgrammeIdFromCurrentPath())}?${searchParams.toString()}`;
    }

    openSearchByStage(stage) {
        this.openSearch(this.getCasesInStageLink(stage));
    }

    openSearchByStageAndState(stage, taskState) {
        const caseState = TaskProcessStateMap[taskState];
        this.openSearch(this.getCasesByStageAndStateLink(stage, caseState));
    }

    openSearch(url) {
        if (OPEN_SEARCH_IN_NEW_WINDOW) {
            const win = window.open(url, '_blank');
            if (win != null) {
                win.focus();
            }
        } else {
            this.props.history.push(url);
        }
    }

    setDate(event, data) {
        const newDate = data.value;
        this.setUrl(this.getIntervalFromUrl(), this.getSamplesFromUrl(), this.getDeltaFromUrl(), newDate);
    }

    setInterval(event, data) {
        const newInterval = data.value;
        this.setUrl(newInterval, this.getSamplesFromUrl(), this.getDeltaFromUrl(), this.getDateFromUrl());
    }

    setSnapshotCount(event, data) {
        const newSamples = data.value;
        this.setUrl(this.getIntervalFromUrl(), newSamples, this.getDeltaFromUrl(), this.getDateFromUrl());
    }

    setIncludeDeltas(event, data) {
        const includeDeltas = data.checked;
        this.setUrl(this.getIntervalFromUrl(), this.getSamplesFromUrl(), includeDeltas, this.getDateFromUrl());
    }

    setUrl(interval, samples, includeDeltas, date = null) {
        const searchParams = new URLSearchParams();
        searchParams.set(INTERVAL_URL_PARAMETER, interval);
        searchParams.set(SAMPLES_URL_PARAMETER, samples);
        if (date != null) {
            searchParams.set(DATE_URL_PARAMETER, getDateAsJsonString(date));
        }
        if (includeDeltas) {
            searchParams.set(DELTAS_URL_PARAMETER, DELTAS_URL_VALUE);
        }
        this.props.history.push(`${this.props.location.pathname}?${searchParams.toString()}`);
    }

    renderValue(count, index) {
        if (index && this.isDeltaColumn(index)) {
            if (count > 0) {
                return <span className="up nowrap"><AccountingValue value={count} /></span>;
            }
            if (count < 0) {
                return <span className="down nowrap"><AccountingValue value={count} /></span>;
            }
        }
        if (count > 0) {
            return <AccountingValue value={count} />;
        }
        return <AccountingValue value={EMPTY_SAMPLE_TEXT} />;
    }

    renderDate(date) {
        const momentDate = moment(date);
        return (
            <>
                <div>{momentDate.format('ddd')}</div>
                <div>{momentDate.format('DD MMM')}</div>
                <div>{momentDate.format('YYYY')}</div>
            </>
        );
    }

    isDeltaColumn(index) {
        return this.state.data.isDeltaFlags[index];
    }

    isCurrentSnapshot(index) {
        return this.state.data.isCurrentSnapshotFlags[index];
    }

    renderDateCell(date, columnIndex) {
        const isDeltaColumn = this.isDeltaColumn(columnIndex);
        const isCurrentSnapshot = this.isCurrentSnapshot(columnIndex);
        const isLastColumn = columnIndex === this.state.data.isDeltaFlags.length - 1;
        const colSpan = (!isDeltaColumn && !isLastColumn && this.state.data.includesDeltas) ? 2 : 1;

        if (isDeltaColumn) {
            return null;
        }

        return (
            <Table.HeaderCell key={`${date}-${columnIndex}`} className="date-column" colSpan={colSpan}>
                <div className="date-cell">
                    {isCurrentSnapshot && <>Current</>}
                    {!isCurrentSnapshot && this.renderDate(date)}
                </div>
            </Table.HeaderCell>
        );
    }

    renderSnapshotTypeCell(date, columnIndex) {
        const isDeltaColumn = this.isDeltaColumn(columnIndex);
        return (
            <Table.HeaderCell
                key={`${date}-${columnIndex}-type`}
                className={`subtitle-cell ${this.getColumnClassName(columnIndex)}`}
            >
                {!isDeltaColumn && <>Total</>}
                {isDeltaColumn && <>Change</>}
            </Table.HeaderCell>
        );
    }

    renderDatesRow() {
        if (this.state.data.includesDeltas) {
            return (
                <>
                    <Table.Row>
                        <Table.HeaderCell className={CLASS_VERTICAL_PRIMARY} />
                        <Table.HeaderCell
                            className={CLASS_VERTICAL_SECONDARY}
                            style={{verticalAlign: 'top'}}
                            textAlign="right"
                        >
                            As at date
                        </Table.HeaderCell>
                        {this.state.data.snapshotTimes.map(this.renderDateCell)}
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className={CLASS_VERTICAL_PRIMARY}>Workflow Stage</Table.HeaderCell>
                        <Table.HeaderCell className={CLASS_VERTICAL_SECONDARY}>Stage Status</Table.HeaderCell>
                        {this.state.data.includesDeltas
                            && this.state.data.snapshotTimes.map(this.renderSnapshotTypeCell)}
                    </Table.Row>
                </>
            );
        }
        return (
            <Table.Row>
                <Table.HeaderCell className={CLASS_VERTICAL_PRIMARY} style={{verticalAlign: "bottom"}}>
                    Workflow Stage
                </Table.HeaderCell>
                <Table.HeaderCell className={CLASS_VERTICAL_SECONDARY}>
                    <div className="text-right">As at date</div>
                    <div>&nbsp;</div>
                    <div>Stage Status</div>
                </Table.HeaderCell>
                {this.state.data.snapshotTimes.map(this.renderDateCell)}
            </Table.Row>
        );
    }

    getColumnClassName(columnIndex) {
        if (this.isDeltaColumn(columnIndex)) {
            return 'data-cell delta';
        }
        return 'data-cell';
    }

    renderPopulationRows() {
        return (
            <>
                {this.renderSpacerRow()}
                <Table.Row className="population">
                    <Table.Cell rowSpan="3" className={`${CLASS_VERTICAL_PRIMARY} scope`}>
                        In-Scope Population
                    </Table.Cell>
                    <Table.Cell className={CLASS_VERTICAL_SECONDARY}>
                        Customers
                    </Table.Cell>
                    {this.state.data.customerCounts.map((population, index) => (
                        <Table.Cell
                            key={`scope-population-${index}`}
                            textAlign="right"
                            className={this.getColumnClassName(index)}
                        >
                            {this.renderValue(population, index)}
                        </Table.Cell>
                    ))}
                </Table.Row>
                <Table.Row className="population">
                    <Table.Cell className={CLASS_VERTICAL_SECONDARY}>
                        Customers under Review
                    </Table.Cell>
                    {this.state.data.customersInReviewCounts.map((population, index) => (
                        <Table.Cell
                            key={`review-population-${index}`}
                            textAlign="right"
                            className={this.getColumnClassName(index)}
                        >
                            {this.renderValue(population, index)}
                        </Table.Cell>
                    ))}
                </Table.Row>
                <Table.Row className="population">
                    <Table.Cell className={CLASS_VERTICAL_SECONDARY}>
                        Created Cases
                    </Table.Cell>
                    {this.state.data.launchedCases.map((launchedCases, index) => (
                        <Table.Cell
                            key={`launchedCases-${index}`}
                            textAlign="right"
                            className={this.getColumnClassName(index)}
                        >
                            {this.renderValue(launchedCases, index)}
                        </Table.Cell>
                    ))}
                </Table.Row>
            </>
        );
    }

    renderSpacerRow() {
        return (
            <Table.Row className="spacer">
                <Table.Cell className={CLASS_VERTICAL_PRIMARY} />
                <Table.Cell className={CLASS_VERTICAL_SECONDARY} />
                <Table.Cell colSpan={this.state.data.snapshotTimes.length} />
            </Table.Row>
        );
    }

    renderStageRows(stage, isFinalStage) {
        return (
            <React.Fragment key={`${stage.id}`}>
                {this.renderSpacerRow()}
                {stage.stats.map((stat, index) => (
                    <Table.Row key={`${stage.id}-${stat.state}`} className={stat.state}>
                        {index === 0 &&
                            <Table.Cell
                                onClick={() => this.openSearchByStage(stage)}
                                className={`stage-name hover-select ${CLASS_VERTICAL_PRIMARY}`}
                                rowSpan={stage.stats.length}
                            >
                                <Link to={this.getCasesInStageLink(stage)} onClick={(e) => e.preventDefault()}>
                                    {stage.name}
                                </Link>
                            </Table.Cell>
                        }
                        {this.renderStageStateHeader(stage, stat.state, isFinalStage)}
                        {stat.stats.map((count, index) => this.renderStat(count, stage, stat, index, isFinalStage))}
                    </Table.Row>
                ))}
            </React.Fragment>
        );
    }

    renderStat(count, stage, stat, index, isFinalStage) {
        const isClosedState = stat.state === TaskProcessState.Closed;
        const isFirstStat = index === 0;
        const renderLink = isFirstStat && (!isClosedState || isFinalStage);
        return (
            <Table.Cell
                key={`${stage.id}-${stat.state}-${index}`}
                textAlign="right"
                className={this.getColumnClassName(index)}
            >
                {renderLink &&
                    <Link to={this.getCasesByStageAndStateLink(stage, TaskProcessStateMap[stat.state])}>
                        {this.renderValue(count, index)}
                    </Link>
                }
                {!renderLink && <>{this.renderValue(count, index)}</>}
            </Table.Cell>
        );
    }

    renderStageStateHeader(stage, state, isFinalStage) {
        const isClosed = state === TaskProcessState.Closed;
        const text = StatusTextMap[state];
        let className = `state-name hover-select ${CLASS_VERTICAL_SECONDARY}`;
        let entry = (
            <Link to={this.getCasesByStageAndStateLink(stage, state)} onClick={(e) => e.preventDefault()}>
                {text}
            </Link>
        );
        let onClick = () => this.openSearchByStageAndState(stage, state);
        if (isClosed && !isFinalStage) {
            className = `state-name ${CLASS_VERTICAL_SECONDARY}`;
            entry = <>{text}</>;
            onClick = () => {};
        }

        return (
            <Table.Cell
                onClick={onClick}
                className={className}
            >
                {entry}
            </Table.Cell>
        );
    }

    renderTable() {
        let className = 'waterfall';
        if (this.state.data.includesDeltas) {
            className = 'waterfall deltas';
        }
        return (
            <Table className={className} collapsing compact unstackable>
                <Table.Header>
                    {this.renderDatesRow()}
                </Table.Header>
                <Table.Body>
                    {this.renderPopulationRows()}
                    {this.state.data.stages.map((stage, index) => {
                        const isFinalStage = index === this.state.data.stages.length - 1;
                        return this.renderStageRows(stage, isFinalStage);
                    })}
                </Table.Body>
            </Table>
        );
    }

    renderErrorMessage() {
        if (this.state.error) {
            return (
                <Message negative>
                    <Message.Header>There was an error generating the report</Message.Header>
                    <p>{this.state.error}</p>
                </Message>
            );
        }
        return null;
    }

    renderInformationMessage() {
        if (this.state.data && this.state.data.informationMessage) {
            return (
                <Message info>
                    {/*<Message.Header>No data available</Message.Header>*/}
                    <p>{this.state.data.informationMessage}</p>
                </Message>
            );
        }
        return null;
    }

    renderControls() {
        return (
            <Form>
                <Form.Group width="equal">
                    <Form.Field>
                        <label>From Date</label>
                        <SemanticDatepicker
                            format="DD/MM/YYYY"
                            onChange={this.setDate}
                            maxDate={getTimeAtEndOfToday()}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Interval</label>
                        <Select
                            fluid
                            options={IntervalOptions}
                            value={this.getIntervalFromUrl()}
                            onChange={this.setInterval}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Number of Periods</label>
                        <Select
                            fluid
                            options={SampleOptions}
                            value={this.getSamplesFromUrl()}
                            onChange={this.setSnapshotCount}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Show Change Analysis</label>
                        <Checkbox
                            checked={this.getDeltaFromUrl()}
                            onClick={this.setIncludeDeltas}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        );
    }

    render() {
        return (
            <>
                <Loader active={this.state.loading} />
                {this.renderErrorMessage()}
                {this.renderInformationMessage()}
                {this.renderControls()}
                {this.state.data && this.renderTable()}
            </>
        );
    }
}

WaterfallReport.propTypes = {};

WaterfallReport.defaultProps = {};

export default withRouter(WaterfallReport);
