import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import CustomerStagedFilesList from 'components/customer/CustomerStagedFilesList';
import StagedRecordsList from 'components/customer/StagedRecordsList';
import CustomerCorrespondenceList from 'components/customer/CustomerCorrespondenceList';
import { withRouter } from 'react-router-dom';

const StagingViewTabs = (props) => {

    const tabPanes = [
        {
            menuItem: 'Artefacts',
            pane: (
                <Tab.Pane key='artefacts'>
                    <CustomerStagedFilesList
                        customerId={props.customerId}
                        readOnly={props.readOnly}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Structured Data',
            pane: (
                <Tab.Pane key='structured data'>
                    <StagedRecordsList
                        records={[]} // not yet supported
                        readOnly={props.readOnly}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Correspondence',
            pane: (
                <Tab.Pane key='correspondence'>
                    <CustomerCorrespondenceList
                        customerId={props.customerId}
                        readOnly={props.readOnly}
                    />
                </Tab.Pane>
            ),
        },
    ];

    const onTabChange = (event, data, activeIndex) => {
        const currentTab = tabPanes[data.activeIndex].menuItem.toLowerCase();
        props.history.push(`#${currentTab}`);
    }

    let activeTabIndex = tabPanes.findIndex(({menuItem}) => menuItem.toLowerCase() === props.location.hash.slice(1));
    activeTabIndex = activeTabIndex === -1 ? 0 : activeTabIndex;

    return (
        <Tab
            defaultActiveIndex={activeTabIndex}
            renderActiveOnly={false}
            panes={tabPanes}
            onTabChange={onTabChange}
        />
    )
}

StagingViewTabs.propTypes = {
    customerId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
}

StagingViewTabs.defaultProps = {
    readOnly: false,
}

export default withRouter(StagingViewTabs);