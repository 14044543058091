import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Header, Loader, Message, Segment } from 'semantic-ui-react';
import cloneDeep from "lodash/cloneDeep";
import bindClassMethods from 'common/util/AutoBind';
import { withRouter } from 'react-router-dom';
import Api from 'api/Api';
import { UserContext } from 'UserContext';
import { withConfiguration } from 'components/common/ConfigurationContext';

class LoginForm extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            submitted: false,
            formData: {},
            error: this.props.location.error,
        };
    }

    handleChange(event) {
        const target = event.target;
        const value = target.name === 'agreeToTerms' ? target.checked : target.value;
        const name = target.name;
        this.setVariable(name, value);
    }

    setVariable(name, value) {
        const formData = cloneDeep(this.state.formData);
        formData[name] = value;
        this.setState({
            formData: formData,
        });
    }

    onLoginError(error) {
        console.log('-->', error);
        this.setState({
            submitted: false,
            error: error,
        });
    }

    getRedirectLocation() {
        const params = new URLSearchParams(this.props.location.search);
        return params.get('redirect');
    }

    async handleSubmit(event) {
        this.setState({submitted: true});
        const data = this.state.formData;

        if (this.context.isLoggedIn()) {
            await Api.logout();
            this.context.logout();
        }

        Api.login(data.username, data.password)
            .then(async response => {
                this.context.setContext(response);
                await this.props.configurationData.reloadProgrammes();

                let redirectLocation = this.getRedirectLocation() ? this.getRedirectLocation() : "/";
                this.props.history.push(redirectLocation);
            })
            .catch(this.onLoginError);
    }

    render() {
        return (
            <Grid textAlign="center" style={{height: '50vh'}} verticalAlign="middle">
                <Grid.Column style={{maxWidth: 450}}>
                    <Header as="h2" textAlign="center">
                        Log in to your account
                    </Header>
                    <Form size="large">
                        <Segment>
                            <Form.Input
                                fluid icon="user"
                                iconPosition="left"
                                name="username"
                                placeholder="User name"
                                onChange={this.handleChange}
                                autoComplete="username"
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Password"
                                type="password"
                                name="password"
                                onChange={this.handleChange}
                                autoComplete="password"
                            />
                            <Form.Input
                                fluid
                                icon="shield"
                                iconPosition="left"
                                placeholder="Enter 6-digit pin"
                                name="code"
                                onChange={this.handleChange}
                                autoComplete="code"
                            />

                            <Button primary fluid size="large" onClick={this.handleSubmit}>
                                Login
                            </Button>
                        </Segment>
                    </Form>
                    {this.state.error &&
                        <Message negative>
                            <Message.Header>Error logging in</Message.Header>
                            <p>{this.state.error}</p>
                        </Message>
                    }
                    <Loader active={this.state.submitted} />
                </Grid.Column>
            </Grid>
        );
    }
}

LoginForm.propTypes = {
    location: PropTypes.shape().isRequired,
    configurationData: PropTypes.shape().isRequired,
};

LoginForm.defaultProps = {
    location: {},
};

export default withRouter(withConfiguration(LoginForm));
