import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { executeDefaultValidators } from 'external/form/Validators';

const FieldSelect = ({
    autoFocus, disabled, error, loadData, multiple, name, nullable, onChange, options, placeHolder,
    value, commitId, registerValidator, deregisterValidator, fluid
}) => {

    useEffect(() => {
        registerValidator(name, executeDefaultValidators);
        return function cleanup() {
            deregisterValidator(name);
        };
    }, [name, registerValidator, deregisterValidator]);

    const callback = (name, value) => {
        onChange(name, value)
    };
    const dropdownOptions = options.map(option => {
        return {
            key: option.id,
            value: option.id,
            text: option.text,
            icon: option.icon ? option.icon : ""
        };
    });

    return (
        <Dropdown
            disabled={disabled}
            selection
            placeholder={placeHolder}
            onChange={(event, value) => callback(name, (value.value))}
            options={dropdownOptions}
            value={value}
            search
            clearable={!disabled}
            fluid={fluid}
        />
    );
};

FieldSelect.propTypes = {
    autoFocus: PropTypes.bool,
    commitId: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    loadData: PropTypes.func,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    nullable: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape()),
    placeHolder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired, // used by Select to generate React key
        }))],
    ),
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
    fluid: PropTypes.bool,
};

FieldSelect.defaultProps = {
    autoFocus: false,
    commitId: true,
    disabled: false,
    error: undefined,
    loadData: undefined,
    multiple: false,
    nullable: true,
    onChange: () => {},
    options: [],
    placeHolder: undefined,
    value: undefined,
    registerValidator: () => {},
    deregisterValidator: () => {},
    fluid: false,
};

export default FieldSelect;
