import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import PageHeader from 'components/common/PageHeader';
import MenuSelect from 'common/layout/MenuSelect';
import OffsetColumnLayout from 'common/layout/OffsetColumnLayout';
import { Loader } from "semantic-ui-react";
import { withConfiguration } from 'components/common/ConfigurationContext';
import { withRouter } from 'react-router-dom';

function sortProgramme(a, b) {
    return ('' + a.id).localeCompare(b.id);
}

class ProgrammesHomePage extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            programmes: [],
        };
    }

    componentDidMount() {
        this.setProgrammes();
    }

    setProgrammes() {
        const programmes = Object
            .keys(this.props.configurationData.programmes)
            .map(key => this.props.configurationData.programmes[key]);
        programmes.sort(sortProgramme)
        this.setState({
            programmes: programmes,
        });
    }

    renderProgramme(programme) {
        return (
            <MenuSelect
                key={programme.id}
                name={programme.name}
                uri={programme.uri}
                meta={programme.meta}
                icon={programme.icon}
            />
        );
    }

    goToUri(uri) {
        this.props.history.push(uri);
    }

    render() {
        if (this.state.loading) {
            return <Loader active />
        }
        return (
            <React.Fragment>
                <PageHeader text="Programs of work" />
                <OffsetColumnLayout>
                {this.state.programmes.map(this.renderProgramme)}
                </OffsetColumnLayout>
            </React.Fragment>
        );
    }
}

ProgrammesHomePage.propTypes = {
    configurationData: PropTypes.shape({
        programmes: PropTypes.shape({}),
    }).isRequired,
    children: PropTypes.node,
};

ProgrammesHomePage.defaultProps = {
    children: undefined,
};

export default withConfiguration(withRouter(ProgrammesHomePage));
