import React from 'react';
import StickyLayout from 'common/StickyLayout';
import LoginForm from 'common/auth/LoginForm';
import Footer from 'common/Footer';
import { Image } from 'semantic-ui-react';

const LoginPage: React.FunctionComponent<void> = () => (
    <StickyLayout
        footer={<Footer showLogo={false} />}
        body={
            <>
                <Image size="medium" centered src="/RemedioUX-Branding-Black.svg" />
                <div style={{marginTop: '70px'}}>
                    <LoginForm />
                </div>
            </>
        }
    />
);

export default LoginPage;
