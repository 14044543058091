import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Container, Grid, Header, Icon } from 'semantic-ui-react';
import { AlertMessageList } from 'components/common/AlertMessage';
import { Link } from 'react-router-dom';
import { matchPath } from 'react-router';

const PageHeader = ({
    text,
    icon,
    onIconClick,
    unattached,
    block,
    headerLeftComponent,
    headerRightComponent,
    subHeader,
    message,
    style,
    children,
}) => {

    let iconComponent = icon;
    if (typeof icon === 'string' || icon instanceof String) {
        iconComponent = <Icon name={icon} onClick={onIconClick} />;
    }

    return (
        <Header
            as="h3"
            attached={unattached ? null : 'top'}
            block={block}
            style={style}
        >
            {message && (<Header.Subheader>{message}</Header.Subheader>)}
            <Header.Content style={{width: '100%'}}>
                <Grid columns="equal" relaxed>
                    <Grid.Row columns="equal">
                        <Grid.Column textAlign="left" verticalAlign="middle">
                            {iconComponent && iconComponent}
                            {text}
                            {headerLeftComponent}
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            {headerRightComponent}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Header.Content>
            <Header.Subheader>
                {subHeader}
                {children}
            </Header.Subheader>
        </Header>
    );
};

PageHeader.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    onIconClick: PropTypes.func,
    unattached: PropTypes.bool,
    block: PropTypes.bool,
    headerLeftComponent: PropTypes.element,
    headerRightComponent: PropTypes.element,
    subHeader: PropTypes.string,
};

PageHeader.defaultProps = {
    icon: undefined,
    onIconClick: () => {},
    unattached: false,
    block: false,
    headerLeftComponent: undefined,
    headerRightComponent: undefined,
    subHeader: undefined,
};

const Breadcrumbs = ({sections}) => {
    if (!sections) {
        return null;
    }

    let crumbs = getCrumbsWithPaths(sections);
    return (
        <Container fluid textAlign="left">
            <Breadcrumb icon="right angle" sections={crumbs} />
        </Container>
    );
};

Breadcrumbs.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.shape()),
};

Breadcrumbs.defaultProps = {
    sections: undefined,
};

function withBreadcrumbs(Component) {
    return props => (
        <>
            <Breadcrumbs sections={props.breadcrumbs} />
            <Component {...props} />
        </>
    );
}

const PageHeaderWithBreadcumbs = withBreadcrumbs(PageHeader);

function withHeaderAndBreadcrumbs(Component) {
    return props => (
        <>
            <PageHeaderWithBreadcumbs {...props} />
            <Component {...props} />
        </>
    );
}

function withHeaderBreadcrumbsAndAlerts(Component) {
    return props => (
        <>
            <Breadcrumbs sections={props.breadcrumbs} />
            <AlertMessageList alerts={props.alerts} attached={props.attached} grouped={props.grouped} />
            <PageHeader {...props} />
            <Component {...props} />
        </>
    );
}

function getCrumbsWithPaths(breadCrumbs) {
    let paths = getPaths();
    let crumbsWithPaths = [];

    for (let i = 0; i < breadCrumbs.length - 1; i++) {
        crumbsWithPaths.push({
            ...breadCrumbs[i],
            link: true,
            as: Link,
            to: paths[i],
        });
    }
    crumbsWithPaths.push({
        ...breadCrumbs[breadCrumbs.length - 1],
        active: true,
    });

    return crumbsWithPaths;
}

function getPaths() {
    let match = matchPath(window.location.pathname, {
        path: '/admin*',
    });
    if (!match) {
        match = matchPath(window.location.pathname, {
            path: '/programmes*',
        });
    }
    const routes = match.url.split("/");
    let paths = [];
    for (let i = 2; i < routes.length + 1; i++) {
        let path = "";
        for (let j = 0; j < i; j++) {
            path += routes[j];
            if ((j + 1) !== i) {
                path += "/";
            }
        }
        paths.push(path);
    }
    return paths;
}

export {
    PageHeaderWithBreadcumbs as default,
    Breadcrumbs,
    withBreadcrumbs,
    withHeaderAndBreadcrumbs,
    withHeaderBreadcrumbsAndAlerts,
};
