import React from 'react';
import Authority from 'common/auth/Authority';
import { ShowWithOneOfPermission } from 'common/auth/ShowWithPermission';

export const ShowWithCustomerEditPermission = ({children}) => (
    <ShowWithOneOfPermission permissions={[Authority.EditCustomer]}>
        {children}
    </ShowWithOneOfPermission>
);

const ShowWithCustomerDeletePermission = ({children}) => (
    <ShowWithOneOfPermission permissions={[Authority.DeleteCustomer]}>
        {children}
    </ShowWithOneOfPermission>
);

export {
    ShowWithCustomerEditPermission as default,
    ShowWithCustomerDeletePermission,
}

