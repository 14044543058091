import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm';
import 'common/Markdown.scss';

const Markdown = ({text}) => {
    return (
        <ReactMarkdown plugins={[gfm]} linkTarget="_blank" className="react-markdown">
            {text}
        </ReactMarkdown>
    );
};

Markdown.propTypes = {
    text: PropTypes.string,
};

Markdown.defaultProps = {
    text: undefined,
};

export default Markdown;