import React, {SyntheticEvent, useState} from 'react';
import { Button } from "semantic-ui-react";
import Api from 'api/Api';
import ConfirmDelete from "components/common/ConfirmDelete";

type DeleteUserButtonProps = {
    userId: string
    setError: (error: string) => void
    onDeleteSuccess?: () => void
}

const DeleteUserButton: React.FunctionComponent<DeleteUserButtonProps> = ({userId, setError, onDeleteSuccess}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const onDeleteUser = (event: SyntheticEvent) => {
        event.preventDefault();
        Api.delete(`/api/users/${userId}`)
            .then(() => {
                if (onDeleteSuccess) {
                    onDeleteSuccess();
                }
            })
            .catch(error => {
                Api.logError(error);
                setModalVisible(false);
                setError(error);
            });
    }
    return (
        <React.Fragment>
            <ConfirmDelete
                open={modalVisible}
                onConfirm={onDeleteUser}
                onCancel={() => setModalVisible(false)}
                title="Delete User"
                content={"Are you sure you want to delete this user?"}
            />
            <Button icon={"trash"} primary onClick={() => setModalVisible(true)} content="Delete User" />
        </React.Fragment>
    )
}

export default DeleteUserButton;