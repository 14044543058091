import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import './defaultForm.css';

const HIDDEN_FIELD_TYPE = 'hidden';

const DefaultFormField = (props) => {
    let style = undefined;
    if (props.type && props.type.toLowerCase() === HIDDEN_FIELD_TYPE) {
        style = {
            display: 'none',
        };
    }
    return (
        <div style={style}>
            <label htmlFor={props.propertyName}>
                {props.label}{props.required ? <span className="default-form-required-field">*</span> : '' }
            </label>
            <div>
                {props.children}
                {!isNil(props.error) && <div className="default-form-error-message">{props.error}</div>}
            </div>
        </div>
    );
};

DefaultFormField.propTypes = {
    children: PropTypes.element.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.node.isRequired,
    propertyName: PropTypes.string.isRequired,
    type: PropTypes.string,
};

DefaultFormField.defaultProps = {
    error: undefined,
    required: false,
    type: undefined,
};

const DefaultForm = ({children}) => {
    return (
        <form>
            {children}
        </form>
    );
};

DefaultForm.propTypes = {
    children: PropTypes.node,
};

DefaultForm.defaultProps = {
    children: undefined,
};

const DefaultButton = ({children, onClick}) => {
    return (
        <button onClick={onClick}>
            {children}
        </button>
    );
};

DefaultButton.propTypes = {
    label: PropTypes.node,
    onClick: PropTypes.func,
};

DefaultButton.defaultProps = {
    label: null,
    onClick: () => {},
};

const DefaultButtonContainer = ({children}) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

DefaultButtonContainer.propTypes = {
    children: PropTypes.node,
};

DefaultButtonContainer.defaultProps = {
    children: undefined,
};

const DEFAULT_UI_THEME = {
    form: DefaultForm,
    formField: DefaultFormField,
    formButton: DefaultButton,
    formButtonListContainer: DefaultButtonContainer,
};

export {
    DefaultForm as Form,
    DefaultFormField as FormField,
    DefaultButton as FormButton,
    DEFAULT_UI_THEME,
};