import React from 'react';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Loader } from "semantic-ui-react";
import Api from 'api/Api';
import { withUserContextProp } from 'UserContext';

import GroupForm from 'components/admin/GroupForm';
import PageHeader from 'components/common/PageHeader';
import GroupPermissionTable from 'components/admin/GroupPermissionTable';
import { cloneDeep } from 'lodash';

class GroupList extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            groups: [],
            permissions: [],
            loading: false,
            selectedGroupId: undefined,
            modalOpen: false,
            editing: false,
            newGroups: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        Promise.all([
            this.getGroups(),
            this.getPermissions(),
        ]).finally(() => this.setState({loading: false}));
    }

    getGroups() {
        this.setState({loading: true});
        Api.get(`/api/groups`)
            .then(this.setGroups)
            .catch(Api.logError);
    }

    getPermissions() {
        Api.get('/noauth/config')
            .then(this.setPermissions)
            .catch(Api.logError)
    }

    setGroups(data) {
        this.setState({groups: data});
    }

    setPermissions({permissions}) {
        this.setState({permissions: permissions});
    }

    onAddGroup() {
        this.setState({
            selectedGroupId: undefined,
            modalOpen: true,
        });
    }

    onEditGroup(groupId) {
        this.setState({
            selectedGroupId: groupId,
            modalOpen: true,
        });
    }

    onGroupChanged() {
        this.setState({
            editing: false,
        })
        this.onCloseModal();
        this.getData();
    }

    onCloseModal() {
        this.setState({
            selectedGroupId: undefined,
            modalOpen: false,
        });
    }

    onEditGroups() {
        this.setState({
            newGroups: cloneDeep(this.state.groups),
            editing: true,
        });
    }

    onSaveGroups() {
        this.setState({saving: true});
        Api.put('/api/groups', {groups: cloneDeep(this.state.newGroups)})
            .then(this.onSaveSuccess)
            .catch(Api.logError)
            .finally(() => this.setState({saving: false}))
    }

    onSaveSuccess() {
        this.setState({
            editing: false,
            newGroups: [],
        });
        this.getData();
    }

    updateGroup(groupIndex, newGroup) {
        const newGroups = cloneDeep(this.state.newGroups);
        newGroups[groupIndex] = newGroup
        this.setState({newGroups: newGroups});
    }

    getBreadcrumbs() {
        return [
            {key: 'Administration', content: 'Administration'},
            {key: 'User Group Permissions', content: 'User Group Permissions'},
        ];
    }

    render() {
        if (this.state.loading) {
            return <Loader active />;
        }

        const addAndEditButtons = (<>
            <Button
                compact
                size="tiny"
                color="blue"
                onClick={this.onAddGroup}
                content="Add Group"
                icon="plus"
            />
            <Button
                compact
                size="tiny"
                color="blue"
                onClick={this.onEditGroups}
                content="Edit Groups"
                icon="edit"
            />
        </>);

        const saveButtons = (<>
            <Button
                compact
                size="tiny"
                color="blue"
                onClick={this.onSaveGroups}
                content="Save"
                icon="save"
            />
            <Button
                compact
                size="tiny"
                color="blue"
                onClick={() => this.setState({editing: false})}
                content="Cancel"
                icon="times"
            />
        </>);

        return (
            <>
                {this.state.modalOpen &&
                    <GroupForm
                        groupId={this.state.selectedGroupId}
                        open={this.state.modalOpen}
                        onGroupChanged={this.onGroupChanged}
                        onCancel={this.onCloseModal}
                    />}
                <PageHeader
                    text="User Group Permissions"
                    icon="users"
                    headerRightComponent={this.state.editing ? saveButtons : addAndEditButtons}
                    breadcrumbs={this.getBreadcrumbs()}
                />
                <GroupPermissionTable
                    groups={this.state.editing ? this.state.newGroups : this.state.groups}
                    permissions={this.state.permissions}
                    editing={this.state.editing}
                    onUpdateGroup={this.updateGroup}
                    onEditGroup={this.onEditGroup}
                />
            </>
        );
    }
}

GroupList.propTypes = {};

GroupList.defaultProps = {};

export default withUserContextProp(GroupList);