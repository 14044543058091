import React from 'react';
import { Route } from "react-router-dom";
import ReportsPage from 'components/reports/ReportsPage';
import RequirePermission from 'common/auth/RequirePermission';
import Authority from 'common/auth/Authority';
import { subRoute } from 'common/util/RouteHelpers';
import { getProgrammeRoute } from 'components/programme/ProgrammeRouter';

const ReportRouter = ({match}) => (
    <RequirePermission permission={Authority.Reporting}>
        <Route
            path={subRoute(match, '/:activeItem?')}
            render={({match}) => (
                <ReportsPage
                    activeItem={match.params.activeItem}
                />
            )}
        />

    </RequirePermission>
);

function getReportRoute(programmeId) {
    return `${getProgrammeRoute(programmeId)}/reports`;
}

export {
    ReportRouter as default,
    getReportRoute,
};
