import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import { Button, Form, Popup, Table } from 'semantic-ui-react';
import FieldHelp from 'common/form/FieldHelp';
import FieldToggle from 'common/form/fields/FieldToggle';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { ERROR_FIELD_REQUIRED, getErrorResponse, NoValidationError } from 'external/form/Validators';
import { AUDollarFormatter } from 'common/util/StringHelpers';
import cloneDeep from 'lodash/cloneDeep';

const RedressLineItem = ({lineItemOption, value, onAdded, onDeleted, disabled}) => {
    const [applicable, setApplicability] = useState(false);
    const [amount, setAmount] = useState("");
    const [saved, setSaved] = useState(false);

    const [amountError, setAmountError] = useState(undefined);

    useEffect(() => {
        if (!isNil(value) && value[lineItemOption.id]) {
            setAmount(value[lineItemOption.id]);
            setApplicability(true);
            setSaved(true);
        } else {
            setAmount("");
        }

    }, [value, lineItemOption]);

    const resetState = () => {
        setAmount("");
        setSaved(false);
        setAmountError(undefined);
    }

    const onSaveHandler = () => {
        if (isNaN(parseFloat(amount))) {
            setAmountError("Please enter a valid number");
        } else if (Math.sign(parseFloat(amount)) === -1) {
            setAmountError("Please enter a positive number");
        } else {
            setSaved(true);
            onAdded({
                lineItemId: lineItemOption.id,
                amount: amount,
            })
        }
    }

    const onToggleHandler = (e, value) => {
        if (value === false) {
            resetState();
            onDeleted(lineItemOption.id);
        }
        setApplicability(value);
    }

    let amountToShow = 0;
    if (applicable) {
        amountToShow = value[lineItemOption.id];
    }

    return (
        <Table.Row key={lineItemOption.id}>
            <Table.Cell textAlign="left">
                {lineItemOption.text}<FieldHelp title={lineItemOption.text} helpText={lineItemOption.helpText} />
            </Table.Cell>
            <Table.Cell textAlign="left">
                <FieldToggle
                    value={applicable}
                    onChange={onToggleHandler}
                    name="applicable"
                    label="applicable"
                    disabled={disabled}
                />
            </Table.Cell>
            <Table.Cell textAlign="right">
                {!disabled && applicable && !saved && (
                    <Form.Input
                        labelPosition='right'
                        type='number'
                        placeholder='Amount'
                        error={amountError ? {content: amountError, pointing: 'below'} : false}
                        action={<Button icon='check' onClick={onSaveHandler} />}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                )}

                {!disabled && applicable && saved && (
                    <Popup
                        content='Click to edit'
                        inverted
                        mouseEnterDelay={500}
                        position='top right'
                        trigger={
                            <div onClick={() => setSaved(false)} className="hover-select">
                                <strong>{AUDollarFormatter.format(amountToShow)}</strong>
                            </div>
                        }
                    />
                )}

                {(disabled || !applicable) && <>{AUDollarFormatter.format(amountToShow)}</>}

            </Table.Cell>
        </Table.Row>
    )
}

RedressLineItem.propTypes = {
    lineItemOption: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        helpText: PropTypes.string,
    }).isRequired,
    value: PropTypes.shape({}),
    onAdded: PropTypes.func,
    onDeleted: PropTypes.func,
    disabled: PropTypes.bool,
}

RedressLineItem.defaultProps = {
    onAdded: () => {},
    onDeleted: () => {},
    disabled: false,
}

class FieldRedressSchedule extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {};
    }

    componentDidMount() {
        this.props.registerValidator(this.props.name, this.validate);
    }

    componentWillUnmount() {
        this.props.deregisterValidator(this.props.name);
    }

    validate(fieldData, fieldConfiguration) {
        if (fieldConfiguration.required && (isNil(fieldData) || isEmpty(fieldData))) {
            return getErrorResponse(ERROR_FIELD_REQUIRED);
        }
        return NoValidationError;
    }

    deleteEntry(lineItemId) {
        const newValue = {...this.props.value};
        delete newValue[lineItemId];
        this.props.onChange(this.props.name, newValue)
    }

    onAdded({lineItemId, amount}) {
        const newValue = cloneDeep(this.props.value);
        newValue[lineItemId] = parseFloat(amount);
        this.props.onChange(this.props.name, newValue)
    }

    render() {
        let {value} = this.props;
        if (isNil(value)) {
            value = {};
        }
        const totalSum = Object.keys(value).reduce((sum, key) => {return sum + value[key]}, 0);

        return (
            <Table attached celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={6}>Redress Type</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={6}>Applicable</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" width={4}>Amount</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.options.map(item => (
                        <RedressLineItem
                            key={item.id}
                            lineItemOption={item}
                            value={this.props.value}
                            onAdded={this.onAdded}
                            onDeleted={this.deleteEntry}
                            disabled={this.props.disabled}
                        />))}
                </Table.Body>
                <Table.Footer>
                    {value.length !== 0 &&
                     <Table.Row active>
                         <Table.Cell textAlign="right" colSpan="2">
                             <strong>Redress Total</strong>
                         </Table.Cell>
                         <Table.Cell textAlign="right">
                             <strong>{AUDollarFormatter.format(totalSum)}</strong>
                         </Table.Cell>
                     </Table.Row>
                    }
                </Table.Footer>
            </Table>
        );
    }
}

FieldRedressSchedule.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape(),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            text: PropTypes.string,
            helpText: PropTypes.string,
        }),
    ),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    autoFocus: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldRedressSchedule.defaultProps = {
    value: {},
    options: [],
    onChange: () => {},
    disabled: false,
    placeHolder: undefined,
    autoFocus: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldRedressSchedule;
