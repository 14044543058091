import React, { RefObject, useState } from 'react';
import { Accordion, Button, Header, Icon, Popup, Segment, Sticky, Table } from 'semantic-ui-react';
import { CustomerDataItem, ReviewDisplayDataResponse } from 'types/ReviewTypes';
import { useFetch } from 'hooks/useFetch';
import InformationModal from 'common/form/InformationModal';
import Markdown from 'common/Markdown';
import './ReviewStageFixedHeader.scss';
import { Link } from 'react-router-dom';
import SavedStateLabel from 'components/common/SavedStateLabel';
import OnHoldLabel from 'components/case/reviews/OnHoldLabel';
import { toDurationTextFromJson } from 'common/util/DateHelpers';
import { pascalCaseWithSpaces } from 'common/util/StringHelpers';
import TaskProcessState from 'components/case/reviews/TaskProcessState';
import { getCustomerRoute } from 'components/customer/CustomerRouter';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

enum SavedStatus {
    Init = 'Init',
    Saved = 'Saved',
    Unsaved = 'Unsaved'
}

type ReviewStageFixedHeaderProps = {
    reviewId: string,
    contextRef: RefObject<HTMLDivElement>,
    renderSaveBtn?: () => Element,
    renderHoldBtn?: () => Element,
    renderRejectBtn?: () => Element,
    savedState?: SavedStatus | undefined,
    stageStatus?: TaskProcessState | undefined,
    holdReason?: string | null,
    showHeader?: boolean
}

const ReviewStageFixedHeader: React.FunctionComponent<ReviewStageFixedHeaderProps> = ({
    reviewId,
    contextRef,
    renderHoldBtn = () => null,
    renderRejectBtn = () => null,
    renderSaveBtn = () => null,
    savedState,
    stageStatus,
    holdReason,
    showHeader = true,
}) => {
    const [expanded, setExpanded] = useState(false);
    const {response} = useFetch<ReviewDisplayDataResponse>(`/api/reviews/${reviewId}/displaydata`, [stageStatus]);

    const getGuidanceModal = () => {
        if (!response) {
            return null;
        }

        return (
            <InformationModal
                title={`${response.title} Guidance`}
                body={<Markdown text={response.guidanceText} />}
                trigger={
                    <Button
                        icon
                        labelPosition="right"
                        circular
                        primary
                        basic
                        style={{padding: '0.7em'}}
                    >
                        Show Regulatory Guidance <Icon name="arrow right" />
                    </Button>
                }
            />
        );
    };

    const renderAttributeCell = (attribute: any) => {
        if (typeof attribute === 'boolean') {
            return (
                <Table.Cell className="bold">
                    <Icon name={attribute ? 'check' : 'times'} />
                </Table.Cell>
            );
        }

        return (
            <Table.Cell className="bold">
                <Popup
                    content={attribute}
                    trigger={<span>{attribute}</span>}
                    mouseEnterDelay={500}
                    mouseLeaveDelay={500}
                    on="hover"
                />
            </Table.Cell>
        );
    };

    const renderRow = (rowData: CustomerDataItem[]) => {
        return (
            <Table.Row key={rowData.map(d => d.label).join('')}>
                {rowData.map(dataItem => renderCustomerDisplayData(dataItem))}
            </Table.Row>
        );
    };

    const renderAttributeTable = (displayData: CustomerDataItem[]) => {
        const chunks = [];
        for (let i = 0; i < displayData.length; i += 2) {
            chunks.push([displayData[i], displayData[i + 1]]);
        }

        return chunks.map(rowItem => renderRow(rowItem));
    };

    const renderCustomerDisplayData = (data: CustomerDataItem) => {
        return (
            <React.Fragment key={data.label}>
                <Table.Cell><Popup
                    content={data.label}
                    trigger={<span>{data.label}</span>}
                    mouseEnterDelay={500}
                    mouseLeaveDelay={500}
                    on="hover"
                /></Table.Cell>
                {renderAttributeCell(data.value)}
            </React.Fragment>
        );
    };

    if (!response) {
        return null;
    }
    if (!stageStatus) {
        stageStatus = response.stageStatus;
    }
    return (
        <Sticky context={contextRef} offset={48}>
            {showHeader && (<Header
                as="h3"
                block
                attached="top"
            >
                <div className="fixed-header-title">
                    <div>
                        {response.title}
                        <SavedStateLabel status={savedState ? savedState : SavedStatus.Init} />
                        <OnHoldLabel
                            onHold={stageStatus === TaskProcessState.OnHold}
                            onHoldReason={holdReason ? holdReason : response.holdReason}
                        />
                        <Header.Subheader>{response.description}</Header.Subheader>
                    </div>
                    <div>
                        {getGuidanceModal()}
                    </div>
                </div>
            </Header>)}
            <Accordion fluid>
                <Accordion.Title
                    active={expanded}
                    onClick={() => setExpanded(!expanded)}
                    secondary
                    as={Segment}
                    attached={showHeader ? (expanded ? true : 'bottom') : (expanded ? 'top' : undefined)}
                >
                    <div className="fixed-header-info">
                        <div className="fixed-header-data">
                            <Header as="h4" className="fixed-header-heading">
                                <Icon name="dropdown" />
                            </Header>
                            <div className="fixed-header-data-item">
                                Case Number: <span className="bold">{response.caseNumber}</span><br />
                                Customer Name{response.customers.length > 1 ? 's' : ''}:
                                {response.customers
                                    .map((customer, index) => (
                                        <React.Fragment key={customer.customerId}>
                                            {index ? ', ' : ' '}
                                            <Link
                                                to={`${getCustomerRoute(
                                                    getProgrammeIdFromCurrentPath(),
                                                    customer.customerId,
                                                )}`} className="bold"
                                            >
                                                {customer.customerName}
                                            </Link>
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                            <div className="fixed-header-data-item">
                                Case Status: <span className="bold">{pascalCaseWithSpaces(response.caseStatus)}</span>
                                <br />
                                Case Age: <span className="bold">{toDurationTextFromJson(response.caseStarted)}</span>
                            </div>
                            <div className="fixed-header-data-item">
                                Stage Status: <span className="bold">{pascalCaseWithSpaces(stageStatus)}</span>
                                <br />
                                Stage Age: <span className="bold">{toDurationTextFromJson(response.stageStarted)}</span>
                            </div>
                        </div>
                        <div className="fixed-header-btn-container">
                            {renderSaveBtn()}
                            {renderHoldBtn()}
                            {renderRejectBtn()}
                        </div>
                    </div>
                </Accordion.Title>
                <Accordion.Content
                    active={expanded}
                    as={Segment}
                    attached="bottom"
                    className="fixed-header-customer-segment"
                >
                    <div className="fixed-header-customer-container">
                        {response.customers.map(customer => (
                            <div key={customer.customerId} className="fixed-header-customer-box">
                                <div className="fixed-header-customer-table-box">
                                    <Table compact="very" basic="very" unstackable>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell><Popup
                                                    content="Customer Name"
                                                    trigger={<span>Customer Name</span>}
                                                    mouseEnterDelay={500}
                                                    mouseLeaveDelay={500}
                                                    on="hover"
                                                /></Table.Cell>
                                                <Table.Cell textAlign="left" className="bold">
                                                    <Popup
                                                        content={customer.customerName}
                                                        trigger={<span>{customer.customerName}</span>}
                                                        mouseEnterDelay={500}
                                                        mouseLeaveDelay={500}
                                                        on="hover"
                                                    />
                                                </Table.Cell>
                                                <Table.Cell><Popup
                                                    content="Customer Number"
                                                    trigger={<span>Customer Number</span>}
                                                    mouseEnterDelay={500}
                                                    mouseLeaveDelay={500}
                                                    on="hover"
                                                /></Table.Cell>
                                                <Table.Cell textAlign="left" className="bold">
                                                    <Popup
                                                        content={customer.customerNumber}
                                                        trigger={<span>{customer.customerNumber}</span>}
                                                        mouseEnterDelay={500}
                                                        mouseLeaveDelay={500}
                                                        on="hover"
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            {renderAttributeTable(customer.displayData)}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        ))}
                    </div>
                </Accordion.Content>
            </Accordion>
        </Sticky>
    );
};

export default ReviewStageFixedHeader;
