import React, {SyntheticEvent, useState} from 'react';
import Api from "api/Api";
import { Button } from "semantic-ui-react";
import ConfirmDelete from "components/common/ConfirmDelete";

type DeleteGroupButtonProps = {
    groupId: string
    setError: (error: string) => void
    onDeleteSuccess?: () => void
}

const DeleteGroupButton: React.FunctionComponent<DeleteGroupButtonProps> = ({groupId, setError, onDeleteSuccess}) => {

    const [modalVisible, setModalVisible] = useState(false);
    const onDeleteGroup = (event: SyntheticEvent) => {
        event.preventDefault();
        Api.delete(`/api/groups/au.com.nqry.cms`)
            .then(() => {
                if (onDeleteSuccess) {
                    onDeleteSuccess();
                }
            })
            .catch(error => {
                Api.logError(error);
                setModalVisible(false);
                setError(error);
            });
    }

    return (
        <React.Fragment>
            <ConfirmDelete
                open={modalVisible}
                onConfirm={onDeleteGroup}
                onCancel={() => setModalVisible(false)}
                title="Delete Group"
                content={"Are you sure you want to delete this group?"}
            />
            <Button
                primary
                icon='trash'
                content={"Delete Group"}
                onClick={() => setModalVisible(true)}
            />
        </React.Fragment>
    )
}

export default DeleteGroupButton;