import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

const FileList = ({fileList, asLinks, customerId}) => {

    const getIcon = (file) => {
        const fileNameArray = file.name.split(".")
        if (fileNameArray.length === 1) {
            return "file outline"
        }
        const fileExt = fileNameArray.pop().toLowerCase();

        const documentExtensions = ["doc", "docx", "txt"];
        const imageExtensions = ["jpg", "png", "gif", "jpeg"];
        const pdfExtensions = ["pdf"];
        const audioExtensions = ["wav", "mp3"];
        const videoExtensions = ["mp4", "mov"];

        if (documentExtensions.includes(fileExt)) {
            return "file alternate outline"
        }
        if (imageExtensions.includes(fileExt)) {
            return "file image outline"
        }
        if (pdfExtensions.includes(fileExt)) {
            return "file pdf outline"
        }
        if (audioExtensions.includes(fileExt)) {
            return "file audio outline"
        }
        if (videoExtensions.includes(fileExt)) {
            return "file video outline"
        }

        return "file outline"
    }

    if (!fileList) {
        return null
    }

    if (asLinks) {
        return (
            <List>
                {fileList.map(file => (
                    <List.Item
                        key={file.stagedFileId}
                        content={file.name}
                        icon={getIcon(file)}
                        as="a"
                        target="_blank"
                        href={`/api/customers/${customerId}/files/${file.stagedFileId}/download`}
                    />
                ))}
            </List>
        )
    }

    return (
        <List>
            {fileList.map((file, i) => (
                <List.Item
                    key={file.stagedFileId || i}
                    content={file.name}
                    icon={getIcon(file)}
                />
            ))}
        </List>
    )
}

FileList.propTypes = {
    fileList: PropTypes.arrayOf(PropTypes.shape({})),
    asLinks: PropTypes.bool,
    customerId: PropTypes.string,
}

FileList.defaultProps = {
    fileList: [],
    asLinks: false,
    customerId: undefined,
}

export default FileList;
