import React from 'react';
import PropTypes from 'prop-types';
// import 'semantic-ui-css/semantic.min.css'

const Theme = ({children}) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

Theme.propTypes = {
    children: PropTypes.node,
};

Theme.defaultProps = {
    children: undefined,
};

export default Theme;