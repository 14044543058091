import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Api from 'api/Api';
import { Checkbox, Form } from 'semantic-ui-react';
import 'common/form/fields/FieldCustomerAttributes.css';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { NoValidationError } from 'external/form/Validators';

const AttributeFieldBoolean = ({label, value, defaultValue, disabled, onChange}) => {

    const onChangeHandler = (event, {checked}) => {
        onChange(checked);
    }

    return (
        <Form.Field>
            <label>{label}</label>
            <Checkbox toggle onChange={onChangeHandler} checked={value ?? false} disabled={disabled} />
        </Form.Field>
    )
}

const AttributeFieldText = ({label, value, defaultValue, disabled, onChange}) => {

    const onChangeHandler = (event) => {
        onChange(event.currentTarget.value);
    }

    return (
        <Form.Field>
            <label>{label}</label>
            <input onChange={onChangeHandler} value={value ?? ""} disabled={disabled} />
        </Form.Field>
    )
}

const AttributeFieldDate = ({label, value, defaultValue, disabled, onChange}) => {

    const onChangeHandler = (event, {value}) => {
        onChange(value);
    }

    return (
        <Form.Field>
            <label>{label}</label>
            <SemanticDatepicker
                onChange={onChangeHandler}
                value={value && new Date(value)}
                className='date-field'
            />
        </Form.Field>
    )
}

const FieldCustomerAttributes = ({name, value, onChange, disabled, registerValidator, deregisterValidator}) => {
    const [schema, setSchema] = useState([]);

    useEffect(() => {
        Api.get('/api/customers/attributeschema')
            .then((data) => setSchema(data))
            .catch(Api.logError);
    }, []);

    useEffect(() => {
        registerValidator(name, validate);
        return () => deregisterValidator(name);
    }, [registerValidator, deregisterValidator, name])

    const validate = (fieldData, fieldConfiguration) => {
        return NoValidationError;
    }

    const onAttributeChange = (attributeId, newAttributeValue) => {
        const newValue = {...value, [attributeId]: newAttributeValue};
        if (onChange) {
            onChange(name, newValue);
        }
    }

    const renderAttributeField = (attributeItem) => {
        switch (attributeItem.fieldConfig.clientField.type) {
            case "checkbox":
            case "toggle":
                return <AttributeFieldBoolean
                    key={attributeItem.id}
                    name={attributeItem.id}
                    label={attributeItem.fieldConfig.label}
                    value={value[attributeItem.id]}
                    disabled={disabled}
                    onChange={(newValue) => onAttributeChange(attributeItem.id, newValue)}
                />
            case "date":
                return <AttributeFieldDate
                    key={attributeItem.id}
                    label={attributeItem.fieldConfig.label}
                    value={value[attributeItem.id]}
                    defaultValue={attributeItem.defaultValue}
                    disabled={disabled}
                    onChange={(newValue) => onAttributeChange(attributeItem.id, newValue)}
                />
            case "integer":
            case "text":
                return <AttributeFieldText
                    key={attributeItem.id}
                    label={attributeItem.fieldConfig.label}
                    value={value[attributeItem.id]}
                    defaultValue={attributeItem.fieldConfig.defaultValue}
                    disabled={disabled}
                    onChange={(newValue) => onAttributeChange(attributeItem.id, newValue)}
                />
            default:
                return null;
        }
    }

    if (schema.length <= 0) {
        return null;
    }

    return schema.map(attributeItem => renderAttributeField(attributeItem))
}

FieldCustomerAttributes.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    registerValidator: PropTypes.func,
    deregisterValidator: PropTypes.func,
};

FieldCustomerAttributes.defaultProps = {
    value: {},
    required: false,
    onChange: () => {},
    disabled: false,
    registerValidator: () => {},
    deregisterValidator: () => {},
};

export default FieldCustomerAttributes;
