import React from 'react';
import PropTypes from 'prop-types';
import bindClassMethods from 'common/util/AutoBind';
import GenericForm from 'common/form/SemanticUIForm';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Container, Loader, Segment } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';
import Api from 'api/Api';
import UnderConstructionWarning from 'components/common/UnderConstructionWarning';
import { getProgrammeIdFromCurrentPath } from 'components/programme/ProgrammeRouter';

const getFormSchema = (isNew, workflows) => {
    return {
        fields: {
            workflowId: {
                type: 'select',
                label: 'Client Workflow',
                placeHolder: '',
                disabled: !isNew,
                options: workflows,
                required: true,
            },
            fieldId: {
                type: 'text',
                label: 'Field Id',
                placeHolder: '',
                disabled: !isNew,
                required: true,
            },
            fieldTag: {
                type: 'text',
                label: 'Field Tag',
                placeHolder: '',
                disabled: true,
                required: false,
            },
            fieldName: {
                type: 'text',
                label: 'Field Name',
                placeHolder: '',
                disabled: false,
                required: true,
            },
            label: {
                type: 'text',
                label: 'Label / User Question / Prompt',
                placeHolder: '',
                disabled: false,
                required: true,
            },
            fieldType: {
                type: 'select',
                label: 'Type',
                required: true,
                placeHolder: 'Type',
                disabled: !isNew,
                options: [
                    {id: 'Text', text: 'Text box entry'},
                    {id: 'RichText', text: 'Formatted text entry'},
                    {id: 'Checkbox', text: 'Check box'},
                    {id: 'PickList', text: 'Picklist'},
                    {id: 'Date', text: 'Date picker'},
                    {id: 'DateTime', text: 'Date & Time picker'},
                    // {id: 'multiselect', text: 'Drop down list (multiple)'},
                    // {id: 'passfail', text: 'Drop down list (pass/fail)'},
                    // {id: 'yesno', text: 'Drop down list (yes/no)'},
                ],
            },
        },
        fieldOrder: [
            'workflowId',
            'fieldId',
            'fieldTag',
            'fieldName',
            'label',
            'fieldType',
        ],
    };
};
const getFormSchema2 = () => {
    return {
        fields: {
            pickListOptions: {
                type: 'text',
                label: 'Picklist Options',
                placeHolder: '',
                required: true,
                allowMultipleFields: true,
            },

        },
        fieldOrder: [
            'pickListOptions',
        ],
    };
};

const InitialFormData = {
    fieldId: '',
    fieldTag: '',
    fieldName: '',
    permissions: [],
};

class FieldEditForm extends React.Component {

    constructor(props) {
        super(props);
        bindClassMethods(this);
        this.state = {
            schema: cloneDeep(getFormSchema(this.isNewField(), [])),
            formData: cloneDeep(InitialFormData),
            loading: false,
            loadingConfig: false,
        };
    }

    isNewField() {
        return (!this.props.workflowId || !this.props.fieldId);
    }

    componentDidMount() {
        if (!this.isNewField()) {
            this.loadFieldDetails(this.props.fieldId);
        }
        this.loadOptions();
    }

    loadFieldDetails() {
        this.setState({loading: true});
        Api.get(`/api/workflow/${this.props.workflowId}/fields/${this.props.fieldId}`)
            .then(this.setFieldData)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setFieldData(data) {
        this.setState({
            formData: {
                workflowId: data.workflowId,
                fieldId: this.props.fieldId,
                fieldTag: data.fieldTag,
                fieldName: data.name,
                fieldType: data.type,
                label: data.label,
                permissions: [],
                pickListOptions: data.options.map(item => {return item.text;}),
            },
        })
    }

    loadOptions() {
        this.setState({loading: true});
        Api.get(`/api/workflows`)
            .then(this.setOptions)
            .catch(Api.logError)
            .finally(() => this.setState({loading: false}));
    }

    setOptions(data) {
        const workflows = data.map(item => {
            return {
                id: item.workflowId,
                text: item.name,
            }
        });
        this.setState({schema: getFormSchema(this.isNewField(), workflows)});
    }

    onFormDataChanged(formData) {
        console.log('-onFormDataChanged->', formData);
        this.setState({
            formData: formData,
        });
    }

    onSave() {
        this.requestFormValidation();
    }

    onSubmit() {
        return Promise.resolve();
    }

    onCancel() {
        this.props.history.push(`/programmes/${getProgrammeIdFromCurrentPath()}/admin/fields`);
    }

    onSubmitSuccess() {
        this.props.history.push(`/programmes/${getProgrammeIdFromCurrentPath()}/admin/fields`);
    }

    onSubmitFailure() {
        this.props.history.push(`/programmes/${getProgrammeIdFromCurrentPath()}/admin/fields`);
    }

    render() {
        if (this.state.loading || this.state.loadingConfig) {
            return <Loader active />;
        }
        return (
            <>
                <UnderConstructionWarning />
                <GenericForm
                    schema={this.state.schema}
                    onFormDataChanged={this.onFormDataChanged}
                    formData={this.state.formData}
                    navigationPrompt={false}
                    showButtons={false}
                    styleClass="left-align-fields"
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    onSubmitSuccess={this.onSubmitSuccess}
                    onSubmitFailure={this.onSubmitFailure}
                    registerValidateFunctionCallback={formValidationFunc => this.requestFormValidation = formValidationFunc}
                />
                {this.state.formData.fieldType === 'PickList' &&
                 <Segment secondary style={{marginLeft: '50px'}}>
                     <GenericForm
                         schema={getFormSchema2()}
                         // onFormDataChanged={this.onFormDataChanged}
                         formData={this.state.formData}
                         // navigationPrompt={false}
                         showButtons={false}
                         // styleClass="left-align-fields"
                         // onSubmit={this.onSubmit}
                         // onCancel={this.onCancel}
                         // onSubmitSuccess={this.onSubmitSuccess}
                         // onSubmitFailure={this.onSubmitFailure}
                     />
                 </Segment>
                }
                <Container textAlign="right">
                    <Button>
                        Cancel
                    </Button>
                    <Button
                        primary
                        onClick={this.onSave}
                        // disabled={this.state.saving}
                    >
                        Save
                    </Button>
                </Container>

            </>
        );
    }
}

FieldEditForm.propTypes = {
    workflowId: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
};

export default withRouter(FieldEditForm);