import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Comment, Container, Form } from 'semantic-ui-react';
import { getHumanReadableDateTime } from 'common/util/DateHelpers';
import ShowWithCustomerEditPermission from 'components/customer/CustomerHelpers';

export const CustomerNotes = (props) => {
    const [isEditing, setEditing] = useState(false);
    const [textBoxContent, setTextBoxContent] = useState("");

    const onChange = (e, {name, value}) => {
        e.preventDefault();
        setTextBoxContent(value);
        if (props.onChange) {
            props.onChange(value);
        }
    }

    const onButtonClicked = (e) => {
        e.preventDefault();
        if (!isEditing) {
            setEditing(true);
        } else {
            setEditing(false);
            if (props.onSave) {
                props.onSave(textBoxContent);
            }
            setTextBoxContent("");
        }
    }

    return (
        <React.Fragment>
            <Comment.Group style={{maxWidth: '100%'}}>
                {props.notes.map((note, index) => (
                    <Comment key={index}>
                        <Comment.Content>
                            <Comment.Author as='a'>{note.authorName}</Comment.Author>
                            <Comment.Metadata>
                                <div>{getHumanReadableDateTime(note.timestamp)}</div>
                            </Comment.Metadata>
                            <Comment.Text>{note.text}</Comment.Text>
                        </Comment.Content>
                    </Comment>
                ))}
                <Form onSubmit={onButtonClicked} autoComplete="false">
                    {isEditing && (
                        <Form.TextArea
                            name="commentbox"
                            onChange={onChange}
                            value={textBoxContent}
                        >
                        </Form.TextArea>
                    )}
                    <ShowWithCustomerEditPermission>
                        <Container fluid textAlign="center">
                            {!props.readOnly &&
                             <Button
                                content={isEditing ? 'Save' : 'Add Note'}
                                labelPosition={isEditing ? undefined : "left"}
                                icon={isEditing ? undefined : 'plus'}
                                primary
                                disabled={isEditing ? textBoxContent.trim().length === 0 : false}
                                onClick={onButtonClicked}
                            />}
                            {isEditing && <Button content='Cancel' onClick={() => setEditing(false)} />}
                        </Container>
                    </ShowWithCustomerEditPermission>
                </Form>
            </Comment.Group>
        </React.Fragment>
    )
}

CustomerNotes.propTypes = {
    notes: PropTypes.arrayOf(PropTypes.shape({})),
    onSave: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
}

CustomerNotes.defaultProps = {
    notes: [],
    readOnly: false,
}

export default CustomerNotes;