import React from 'react';
import FieldTagSearch from 'common/form/fields/FieldTagSearch';

const FieldAdvisorTagSearch = (props) => {
    const MAX_RESULTS = 5;
    const searchPath = `/api/advisors?size=${MAX_RESULTS}&query=`;

    const mapToTag = (tag) => {
        return {
            key: tag.advisorId,
            id: tag.advisorId,
            name: tag.afsLicenseName,
            title: `${tag.afsLicenseName} [${tag.afsLicenseNumber}]`,
        };
    };

    return (
        <FieldTagSearch
            {...props}
            mapToTag={mapToTag}
            searchPath={searchPath}
            idField={"advisorId"}
        />
    );
};

export default FieldAdvisorTagSearch;